import { Layout, Skeleton } from 'antd';
import React, { PureComponent } from 'react';

import { getDefaultCollapsedSubMenus } from '../../utils';
import Logo from '../Logo/Logo';
import BaseMenu, { MenuItemProps } from './BaseMenu';
import styles from './SideMenu.module.scss';

const { Sider } = Layout;

interface InternalProps {
  loading: boolean;
  collapsed?: boolean;
  location: Location;
  isMobile?: boolean;
  theme?: 'dark' | 'light';
  width?: number | string;
  menu: MenuItemProps[];
  flatMenuKeys: string[];
  onCollapse?: (collapsed: boolean) => void;
}

interface InternalState {
  openKeys: string[];
  pathname: string;
  flatMenuKeysLen: number;
}

class SideMenu extends PureComponent<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.handleOpenChange = this.handleOpenChange.bind(this);
    this.state = {
      openKeys: [],
      pathname: '/',
      flatMenuKeysLen: 0,
    };
  }

  private static getDerivedStateFromProps(props: InternalProps, state: InternalState) {
    const { pathname, flatMenuKeysLen } = state;
    const { location, flatMenuKeys } = props;

    if (location.pathname !== pathname || flatMenuKeys.length !== flatMenuKeysLen) {
      return {
        pathname: location.pathname,
        flatMenuKeysLen: flatMenuKeys.length,
        openKeys: getDefaultCollapsedSubMenus(location.pathname, flatMenuKeys),
      };
    }
    return null;
  }

  public isMainMenu(key: string): boolean {
    const { menu } = this.props;
    return menu.some((item: MenuItemProps) => {
      if (!key) {
        return false;
      }
      return item.path === key;
    });
  }

  public handleOpenChange(openKeys: string[]) {
    this.setState({ openKeys });
  }

  public render() {
    const { openKeys } = this.state;
    const { menu, theme, loading, flatMenuKeys, location, width, collapsed } = this.props;

    return (
      <Sider theme={theme} breakpoint="xl" width={width || 240} collapsed={collapsed} className={styles.sideMenu}>
        <Logo collapsed={collapsed} />
        <Skeleton title={false} loading={loading} active paragraph={{ rows: 4 }} className={styles.skeletonDark}>
          <BaseMenu
            menu={menu}
            theme={theme}
            openKeys={openKeys}
            location={location}
            collapsed={!!collapsed}
            className={styles.baseMenu}
            flatMenuKeys={flatMenuKeys}
            onOpenChange={this.handleOpenChange}
          />
        </Skeleton>
      </Sider>
    );
  }
}

export default SideMenu;

import { notification } from 'antd';
import { routerRedux } from 'dva/router';
import queryString from 'query-string';

import { ServerEnv } from '../env';
import { Routes, RoutesConfig, SysMenu } from '../routes';
import { AggregationService, AxiosInstance, MapService, UaaService, UserService } from '../services';
import { isEmpty, mapSysMenu, saveAppViewStore } from '../utils';
import { handleAuthorize, overrideToken } from '../utils/authUtils';
import { localStorageKey, ScenarioType } from '../utils/constants';
import { UrlQueryReg } from '../utils/regTool';
import { Action, Effects } from './dispatch';
import { Auth, UserInfo } from './typed';

// Global state interface
export interface GlobalState {
  auth: Auth;
  collapsed: boolean;
  hubList: any[];
  authData: any;
  currentHub: any;
  userInfo: UserInfo;
  userId: string | null;
  mapBlockList: any[];
  hasHeader: boolean;
  hasFooter: boolean;
  showBindMobile: boolean;
  requireUpdate: boolean;
  hasBreadCrumbs: boolean;
}

const initialState: GlobalState = {
  auth: {
    isAuthenticated: false,
    permissions: [],
  },
  userId: null,
  userInfo: {
    name: '',
    menu: {},
    mobile: '',
    avatar: '',
    nickname: '',
    authPath: [],
  },
  hubList: [],
  authData: null,
  mapBlockList: [],
  currentHub: null,
  collapsed: false,
  hasFooter: true,
  hasHeader: true,
  showBindMobile: false,
  requireUpdate: false,
  hasBreadCrumbs: true,
};

export default {
  namespace: 'global',

  state: initialState,

  effects: {
    *login({ payload }: Action, { call, put }: any) {
      try {
        const { username, password } = payload;
        const formData = new FormData();

        formData.append('username', username);
        formData.append('password', password);
        formData.append('grant_type', 'password');

        const { data } = yield call(UaaService.authByPwd, formData);

        if (data) {
          const accessToken = data.access_token;
          const requireUpdate = data.requireUpdatePassword;

          overrideToken(accessToken);

          yield put({ type: 'loadAuthData', payload: data });
          yield put.resolve({ type: 'fetchUserInfo', payload: data.userId });

          // 如果是第一次登录，跳转到设置新密码页面
          if (requireUpdate) {
            yield put({ type: 'changeLayoutVisible', key: 'requireUpdate', payload: requireUpdate });
          } else {
            yield put({ type: 'initLogin' });
          }
        }
      } catch (error) {
        const { response } = error;

        if (response) {
          const { data, status } = response;
          notification.error({ message: `请求错误 ${status}`, description: data.error_description });
        } else {
          throw error;
        }
      }
    },

    *loginByCode({ payload }: Action, { call, put }: any) {
      try {
        const { data } = yield call(UaaService.auth, payload);

        if (data) {
          const accessToken = data.access_token;

          overrideToken(accessToken);

          yield put({ type: 'loadAuthData', payload: data });
          yield put.resolve({ type: 'fetchUserInfo', payload: data.userId });
          yield put({ type: 'initLogin' });
        }
      } catch (error) {
        const { response } = error;

        if (response) {
          const { data, status } = response;
          notification.error({ message: `请求错误 ${status}`, description: data.error_description });
        } else {
          throw error;
        }
      }
    },

    *initLogin({ payload }: Action, { select, put }: Effects) {
      const { authData } = yield select(({ global }: any) => global);

      yield put({ type: 'saveAuthData', payload: authData });
      yield put({
        type: 'authorize',
        payload: { isAuthenticated: true, permissions: [] },
      });

      const queryParams = window.location.hash.match(UrlQueryReg);
      const params = queryParams ? queryString.parse(queryParams[0]) : null;

      if (params?.redirectUrl && typeof params.redirectUrl === 'string') {
        const pathname = params.redirectUrl.indexOf('#') !== -1 && params.redirectUrl.split('#')[1];
        yield put({ type: 'redirectToApp', payload: pathname });
      } else {
        yield put({ type: 'redirectToApp' });
      }

      yield put({ type: 'resetLoginView' });
    },

    *logout({ payload }: Action, { put }: Effects) {
      AxiosInstance.defaults.headers = {
        Authorization: ServerEnv().token,
      };
      yield put({
        type: 'deauthorize',
        payload: {
          isAuthenticated: false,
          permissions: [],
          collapsed: false,
        },
      });
      yield put(routerRedux.push({ pathname: Routes.Login, search: payload ? `redirectUrl=${payload}` : '' }));
    },

    *fetchUserInfo({ payload }: Action, { call, put }: any) {
      try {
        const { data } = yield call(AggregationService.fetchAggUser, payload, {
          resolveHub: true,
          resolveRole: true,
        });

        if (data) {
          yield put({ type: 'loadHubList', payload: data.hubs });
          yield put.resolve({ type: 'redirect', payload: data });
        }
      } catch (error) {
        throw error;
      }
    },

    *fetchUserByToken({ payload }: Action, { call, put }: any) {
      try {
        const { data } = yield call(UserService.fetchUserByToken, payload);
        const authData = {
          userId: data.id,
          token_type: 'Bearer',
          access_token: payload,
        };

        yield put({ type: 'loadAuthData', payload: authData });
        yield put({ type: 'saveAuthData', payload: authData });
        yield put.resolve({ type: 'fetchUserInfo', payload: data.id });
        yield put.resolve({ type: 'initLogin' });
      } catch (error) {
        throw error;
      }
    },

    *redirect({ payload }: Action, { put }: Effects) {
      try {
        const userInfo = payload;

        // 如果用户没有驿站数据或者没有驿站id, 重定向到园区切换页面
        if (!userInfo.hub && !userInfo.hubId) {
          yield put(routerRedux.push(Routes.SwitchHub));
        } else {
          const hubData = JSON.parse(localStorage.getItem(localStorageKey.HUB_KEY_STORE) || '{}');
          let hub = null;

          // 如果用户的 hubData 有数据
          if (!isEmpty(hubData)) {
            // 如果是超级管理员
            if (userInfo.superAdmin) {
              hub = hubData;
            } else {
              // 如果用户的 hubs 有数据
              if (userInfo.hubs?.length) {
                hub = userInfo.hubs.find((item: any) => item.id === hubData.id) || userInfo.hubs[0];
              }
            }
          } else {
            if (userInfo.hubs?.length) {
              hub = userInfo.hubs[0];
            }
          }

          // 如果所选驿站有 roleIds 或者超级管理员可以直接访问
          if (hub?.roleIds || userInfo.superAdmin) {
            const user = { ...userInfo, hub, hubId: hub?.id };

            yield put({ type: 'loadUserInfo', payload: user });
            yield put({ type: 'fetchUserAuth', payload: user });
            yield put({ type: 'loadCurrentHub', payload: hub });
          } else {
            notification.error({
              message: '访问失败',
              description: '未分配角色权限，暂时无法访问系统，请联系管理员添加角色权限！',
            });
          }
        }
      } catch (error) {
        throw error;
      }
    },

    // 选择园区逻辑
    *selectHub({ payload }: Action, { put, select }: Effects) {
      const { userInfo } = yield select(({ global }: any) => global);

      // 如果所选驿站有 roleIds 或者超级管理员可以直接访问
      if (payload.roleIds || userInfo.superAdmin) {
        yield put({ type: 'loadUserInfo', payload: { ...userInfo, hub: payload, hubId: payload.id } });
        yield put({ type: 'fetchUserAuth', payload });
        yield put({ type: 'loadCurrentHub', payload });
        yield put({ type: 'redirectToApp' });
      } else {
        notification.error({
          message: '访问失败',
          description: '未分配角色权限，暂时无法访问系统，请联系管理员添加角色权限！',
        });
      }
    },

    // 获取用户权限逻辑
    *fetchUserAuth({ payload }: Action, { call, put, select }: Effects) {
      try {
        let { userInfo } = yield select(({ global }: any) => global);
        const { data } = yield call(UaaService.fetchAuthPath, {
          id: payload && payload.roleIds ? payload.roleIds.join() : '',
        });
        let menu: SysMenu = {};

        if (data) {
          menu = mapSysMenu(RoutesConfig, data);
          userInfo = { ...userInfo, authPath: data, menu };

          // 如果用户为超级管理员, 可以访问所有模块
          if (userInfo.superAdmin) {
            userInfo.menu = RoutesConfig;
          }

          yield put({ type: 'loadUserInfo', payload: userInfo });
        }
      } catch (error) {
        yield put({
          type: 'deauthorize',
          payload: {
            isAuthenticated: false,
            permissions: [],
            collapsed: false,
          },
        });
        throw error;
      }
    },

    *fetchHubList({ payload }: Action, { call, put, select }: Effects) {
      try {
        const params = { ...payload, pageSize: 200, selectable: true };
        const { userId } = yield select(({ global }: any) => global);
        const { data: userInfo } = yield call(AggregationService.fetchAggUser, userId, {
          resolveHub: true,
          resolveRole: true,
        });

        if (!userInfo?.superAdmin) {
          yield put({ type: 'loadUserInfo', payload: userInfo });
          yield put({ type: 'loadHubList', payload: userInfo.hubs });
        } else {
          const { data } = yield call(AggregationService.fetchAggHubList, params);

          if (data) {
            yield put({ type: 'loadUserInfo', payload: { ...userInfo, hubs: data } });
            yield put({ type: 'loadHubList', payload: data });
          }
        }
      } catch (error) {
        throw error;
      }
    },

    // 重定向到指定页面或者首页
    *redirectToApp({ payload }: Action, { put, take }: Effects) {
      if (payload) {
        yield put(routerRedux.push(payload));
      } else {
        yield put(routerRedux.push(Routes.Dashboard));
      }
    },

    *createHub({ payload }: Action, { put, call }: Effects) {
      try {
        const { roleTemplate, ...others } = payload;
        const { data } = yield call(MapService.postHub, others);

        if (data) {
          yield call(UaaService.postRoleTmp, {
            hubId: data.id,
            templateId: roleTemplate?.length ? roleTemplate : [],
          });
          yield put({ type: 'common/showOrHideModal', key: 'showHubModal', payload: false });
          yield put({ type: 'fetchHubList' });
        }
      } catch (error) {
        throw error;
      }
    },

    *forgetPassword({ payload }: Action, { put, call }: Effects) {
      try {
        const response = yield call(UaaService.forgetPassword, payload);
        if (response) {
          notification.success({ message: '重置密码成功!' });
          yield put(routerRedux.push(Routes.Login));
        }
      } catch (error) {
        throw error;
      }
    },

    *setPassword({ payload }: Action, { put, call, select, take }: Effects) {
      try {
        const { userInfo } = yield select(({ global }: any) => global);
        const response = yield call(UaaService.resetPassword, userInfo.id, payload);

        if (response) {
          notification.success({ message: '设置新密码成功!' });

          if (userInfo.mobile) {
            yield put({ type: 'initLogin' });
          } else {
            yield put({ type: 'changeLayoutVisible', key: 'showBindMobile', payload: true });
          }
        }
      } catch (error) {
        throw error;
      }
    },

    *verifyMobile({ payload }: Action, { put, call, select }: any) {
      try {
        const { mobile: phoneNumber, code } = payload;
        const { userInfo } = yield select(({ global }: any) => global);
        yield call(UaaService.verifyCaptcha, { code, phoneNumber });
        yield call(UserService.patchUser, userInfo.id, { mobile: phoneNumber });
        yield put.resolve({ type: 'initLogin' });
        notification.success({ message: '手机号绑定成功成功!' });
        yield put({ type: 'resetLoginView' });
      } catch (error) {
        throw error;
      }
    },

    *updateHubInfo({ payload }: Action, { call, put }: Effects) {
      try {
        const { id, roleTemplate, addressTypes, collectDeliveryMethods, sendDeliveryMethods, ...params } = payload;

        yield call(MapService.patchHub, id, params);
        yield call(UaaService.postRoleTmp, {
          hubId: id,
          templateId: roleTemplate?.length ? roleTemplate : [],
        });

        if (collectDeliveryMethods) {
          yield call(MapService.patchHubScenarioConfig, id, ScenarioType.LOGISTICS, { collectDeliveryMethods });
        }

        if (addressTypes) {
          yield call(MapService.patchHubScenarioConfig, id, ScenarioType.SHARED, { addressTypes });
        }

        if (sendDeliveryMethods) {
          yield call(MapService.patchHubScenarioConfig, id, ScenarioType.SEND, { sendDeliveryMethods });
        }

        yield put({ type: 'common/showOrHideModal', key: 'showHubModal', payload: false });
        yield put({ type: 'fetchHubList' });
      } catch (error) {
        throw error;
      }
    },

    *resetLoginView(action: Action, { call, put }: Effects) {
      // 重置登录界面
      yield put({ type: 'changeLayoutVisible', key: 'requireUpdate', payload: false });
      yield put({ type: 'changeLayoutVisible', key: 'showBindMobile', payload: false });
    },
  },

  reducers: {
    authorize(state: GlobalState, { payload }: Action): GlobalState {
      return { ...state, auth: payload };
    },

    deauthorize(state: GlobalState, { payload }: Action): GlobalState {
      localStorage.removeItem(localStorageKey.APP_KEY_STORE);
      return { ...initialState, auth: payload };
    },

    changeLayoutCollapsed(state: GlobalState, { payload }: Action): GlobalState {
      // 保存菜单栏收起状态数据
      saveAppViewStore('collapsed', payload);
      return { ...state, collapsed: payload };
    },

    changeLayoutVisible(state: GlobalState, { key, payload }: Action): GlobalState {
      return { ...state, [key]: payload };
    },

    loadUserInfo(state: GlobalState, { payload }: Action): GlobalState {
      return { ...state, userInfo: payload };
    },

    loadUserId(state: GlobalState, { payload }: Action): GlobalState {
      return { ...state, userId: payload };
    },

    loadHubList(state: GlobalState, { payload }: Action): GlobalState {
      return { ...state, hubList: payload };
    },

    loadCurrentHub(state: GlobalState, { payload }: Action): GlobalState {
      // 保存园区数据
      localStorage.setItem(localStorageKey.HUB_KEY_STORE, JSON.stringify(payload));
      return { ...state, currentHub: payload };
    },

    loadAuthData(state: GlobalState, { payload }: Action): GlobalState {
      return { ...state, authData: payload };
    },

    saveAuthData(state: GlobalState, { payload }: Action): GlobalState {
      localStorage.setItem(localStorageKey.APP_KEY_STORE, JSON.stringify(payload));
      return state;
    },
  },

  subscriptions: {
    // 用于订阅一个数据源, 然后根据需要 dispatch 相应的 action
    setup({ dispatch, history }: any) {
      return history.listen(({ pathname }: Location) => {
        if (pathname === Routes.Auth) {
          return;
        }

        // 处理登录逻辑
        handleAuthorize(pathname, dispatch);
      });
    },
  },
};

import React, { FC, useState } from 'react';

import styles from './MonitorVideo.module.scss';

interface InternalState {
  direction: 1 | 2 | 3 | 4;
}

export const MonitorVideo: FC<any> = (props) => {

  const [direction, setDirection] = useState(1);

  return (
    <div className={styles.monitorVideo}>
      <div className={styles.title}>无人车车载监控</div>
      <div className={styles.video}>视频</div>
      <div className={styles.controllers}>
        <span
          className={direction === 1 ? styles.active : ''}
          onClick={() => setDirection(1)}>
          前
        </span>
        <span
          className={direction === 2 ? styles.active : ''}
          onClick={() => setDirection(2)}>
          后
        </span>
        <span
          className={direction === 3 ? styles.active : ''}
          onClick={() => setDirection(3)}>
          左
        </span>
        <span
          className={direction === 4 ? styles.active : ''}
          onClick={() => setDirection(4)}>
          右
        </span>
      </div>
    </div>
  );
};

export default MonitorVideo;

import { Descriptions, Typography } from 'antd';
import { DescriptionsProps } from 'antd/lib/descriptions';
import React, { FunctionComponent } from 'react';

const { Text } = Typography;

interface Item {
  label: string;
  value: string;
  render?: (v: Item, k: React.Key, items: Item[]) => React.ReactNode;
}

interface InternalProps extends DescriptionsProps {
  items: Item[];
  [propNames: string]: any;
}

export const FormDetails: FunctionComponent<InternalProps> = ({ items, ...reset }) => {
  return (
    <Descriptions {...reset}>
      {items.map((v: any, k: React.Key) => {
        const { label, value, render } = v;
        return (
          <Descriptions.Item label={label} key={k}>
            <Text strong>{value || (typeof render === 'function' && render(v, k, items))}</Text>
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};

export default FormDetails;

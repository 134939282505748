import { DoubleRightOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import React, { CSSProperties, FC, useState } from 'react';

import styles from './TopDrawer.module.scss';

interface InternalProps {
  height?: number;
  title?: string;
  className?: string;
  style?: CSSProperties;
}

export const TopDrawer: FC<InternalProps> = (props) => {
  const { height, title, children, className, style } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const text = visible ? '收起' : '展开';
  const classNames = [styles.topDrawer, className || ''].join(' ');
  const drawerStyle: CSSProperties = {
    padding: visible || height ? 16 : 0,
    height: visible ? 'auto' : height || 0,
  };

  return (
    <Row className={classNames} style={style}>
      <Row className={styles.drawer} style={drawerStyle}>
        {children}
      </Row>
      <Row justify="center" align="middle" className={styles.clickPanel} onClick={() => setVisible(!visible)}>
        <span className="base">
          {text}
          {title || '过滤'}
        </span>
        <DoubleRightOutlined style={{ transform: `rotate(${visible ? '-90deg' : '90deg'})` }}/>
      </Row>
    </Row>
  );
};

export default TopDrawer;

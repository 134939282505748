import { DvaInstance, Model } from 'dva';

// Register model dynamically and cache the registered model
const cached: any = {};

/**
 * 注册dva model数据
 * @param app
 * @param model
 */
export default function registerModel(app: DvaInstance, model: Model) {
  if (!cached[model.namespace]) {
    app.model(model);
    cached[model.namespace] = 1;
  }
}

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Divider, Modal, Row, Space, Typography } from 'antd';
import { format } from 'date-fns';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { CSSProperties, FC, useEffect } from 'react';

import { Avatar, EditableCascader, EditableForm, EditableInput, EditableSelect } from '../../../components';
import { Dispatch } from '../../../models/dispatch';
import {
  CollectionMethodStr,
  DeliveryMethodOption,
  DeliveryMethodStr,
  MILLISECOND_IN_A_DAY,
  OrderState,
  OrderStateStr,
  OrderType,
  PackageSizeStr,
  SendDeliveryMethodOption,
  SendDeliveryMethodStr,
  SendOrderStatusStr,
} from '../../../utils/constants';
import { DeliveryMethod, MixedOrderState } from '../../../utils/constants';
import { PhoneReg } from '../../../utils/regTool';
import { RobotDispatchingOrderModal } from '../components';
import { AssignCourierModal, CancelOrderModal } from './../../SendCenter/components';
import ShippingOrderInfo from './ShippingOrderInfo';

const { Text } = Typography;
const { confirm } = Modal;
const buildingOption = [{ label: '选择大楼', value: null, disabled: true }];
const floorOption = [{ label: '选择楼层', value: null, disabled: true }];
const waypointOption = [{ label: '选择取件点', value: null, disabled: true }];
const OrderWaypointTitle = {
  COLLECT: '取件点',
  SEND: '揽件点',
};
const OrderMethodTitle = {
  COLLECT: '配送方式',
  SEND: '上门方式',
};
const orderStates = [OrderState.PENDING]; // 本来非超管用户寄件和派件都有这个可编辑
const collectedOrderStates = [OrderState.COLLECTED, OrderState.CLOSED];
const wrapperStyle: CSSProperties = { paddingLeft: 0 };

interface DvaProps {
  match: any;
  userInfo: any;
  orderInfo: any;
  loading: boolean;
  courierOption: any[];
  cascaderOptions: any[];
  showCancelOrderModal: boolean;
  showAssignCourierModal: boolean;
  showRobotDeliveredOrderModal: boolean;
  fetchOrderInfo: (id: string) => void;
  postPrint: (params?: object) => void;
  fetchCourierOption: (params: object) => void;
  postOrderAssignment: (params: object) => void;
  fetchBuildingOption: (params?: object) => void;
  toShowModal: (key: string, show: boolean) => void;
  updateWaypoint: (id: string, params?: object) => void;
  updateOrderInfo: (id: string, params?: object) => void;
  updateAddressInfo: (orderId: string, orderType: OrderType, params?: object) => void;
}

interface InternalProps extends DvaProps {
  orderId: string;
}

export const OrderInfo: FC<InternalProps> = (props) => {
  const {
    orderId,
    loading,
    userInfo,
    orderInfo,
    postPrint,
    toShowModal,
    fetchOrderInfo,
    courierOption,
    updateWaypoint,
    updateOrderInfo,
    cascaderOptions,
    updateAddressInfo,
    fetchBuildingOption,
    fetchCourierOption,
    postOrderAssignment,
    showCancelOrderModal,
    showAssignCourierModal,
    showRobotDeliveredOrderModal,
  } = props;
  const { superAdmin } = userInfo;
  const { addressEntity, assignedUser, orderState, createdTime, deliveryMethod, processedTime, orderType } = orderInfo;
  const isSendOrder = orderType === OrderType.SEND;
  const editable = [OrderState.CONFIRMED, OrderState.PENDING].includes(orderState);

  // 禁止切换配送方式
  const disabledSwitch = deliveryMethod === DeliveryMethod.MANUAL && !orderInfo.shelfNumber;
  // 是否为驿站寄件，驿站寄件无揽件点
  const isHUB = deliveryMethod === DeliveryMethod.HUB;

  useEffect(() => {
    fetchOrderInfo(orderId);
    fetchBuildingOption({ resolveFloors: true, resolveWaypoints: true });
  }, [fetchOrderInfo, fetchBuildingOption, orderId]);

  useEffect(() => {
    fetchCourierOption({ pageSize: 200 });
  }, [fetchCourierOption]);

  const handleSave = (record: any) => {
    const { id, ...otherProps } = record;
    updateOrderInfo(id, { ...otherProps });
  };

  const handleWaypointSave = (record: any) => {
    const { id } = record;
    updateWaypoint(orderId, { waypointId: id });
  };

  const handleAddressSave = (record: any) => {
    const { id, ...otherProps } = record;
    updateAddressInfo(orderInfo.id, orderType, { ...addressEntity, ...otherProps, userId: orderInfo.userId });
  };

  const handlePrint = async (record: any) => {
    confirm({
      centered: true,
      title: `确认打印订单`,
      onOk: async () => {
        await postPrint({
          values: {
            entityId: record.id,
            printSceneType: 'SEND_ORDER',
            printTaskState: 'PRINTING',
            printClientType: 'BLUETOOTH_DESKTOP',
          },
        });
      },
    });
  };

  const handleCancel = () => {
    toShowModal('showCancelOrderModal', true);
  };

  const handleCancelModal = () => {
    toShowModal('showCancelOrderModal', false);
    toShowModal('showRobotDeliveredOrderModal', false);
  };

  const handleSubmitValues = (values: any) => {
    updateOrderInfo(orderInfo.id, { ...values, orderState: OrderState.CANCELLED });
  };

  const handleSubmitData = (values: any) => {
    const { userIds } = values;
    postOrderAssignment({ userIds, orderIds: [orderInfo.id], orderTypes: ['SEND'] });
  };

  const onSubmit = (id: object, params: object) => {
    updateOrderInfo(orderInfo.id, params);
  };

  return (
    <Card
      title="订单详情"
      bordered={false}
      loading={loading}
      style={{ margin: '0 16px 16px' }}
      extra={
        <Space>
          {isSendOrder ? (
            <Space>
              {MixedOrderState.includes(orderState) && deliveryMethod === DeliveryMethod.MANUAL && (
                <Button onClick={() => toShowModal('showAssignCourierModal', true)}>重新分配</Button>
              )}
              {MixedOrderState.includes(orderState) && deliveryMethod === DeliveryMethod.HIVE && (
                <Button onClick={() => toShowModal('showRobotDeliveredOrderModal', true)}>转人工</Button>
              )}
              {orderInfo.shipperNumber && (
                <Button type="primary" onClick={() => handlePrint(orderInfo)}>
                  打印
                </Button>
              )}
              {MixedOrderState.includes(orderState) ? <Button onClick={handleCancel}>取消订单</Button> : null}
            </Space>
          ) : null}
          <Button onClick={() => window.history.back()}>返回</Button>
        </Space>
      }
    >
      <EditableForm index={0} type="normal">
        <Row>
          <Col span={orderInfo.backupImages && orderInfo.backupImages.length ? 18 : 24}>
            <Descriptions title={`${isSendOrder ? '寄' : '派'}件信息`}>
              <Descriptions.Item>
                <EditableInput
                  required
                  editable
                  title="订单号"
                  dataIndex="orderNumber"
                  wrapperStyle={wrapperStyle}
                  onSubmit={handleSave}
                  record={orderInfo || {}}
                >
                  <Text copyable={{ text: get(orderInfo, 'orderNumber') }}>
                    订单号: {get(orderInfo, 'orderNumber', '暂无')}
                  </Text>
                </EditableInput>
              </Descriptions.Item>
              <Descriptions.Item label="快递公司">
                <Row align="middle">
                  <Avatar src={get(orderInfo, 'company.logoUrl', '')} name={get(orderInfo, 'company.name', '')} />
                  <Text strong style={{ marginLeft: 8 }}>
                    {get(orderInfo, 'company.name', '暂无')}
                  </Text>
                </Row>
              </Descriptions.Item>
              {!isSendOrder && (
                <Descriptions.Item label="包裹号">
                  <Text strong>{get(orderInfo, 'shelfNumber') || get(orderInfo, 'usedShelfNumber', '暂无')}</Text>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="协议价格">
                <Text strong>{get(orderInfo, 'agreementPrice', '暂无')}</Text>
              </Descriptions.Item>
              {isSendOrder && (
                <Descriptions.Item label="寄件码">
                  <Text strong>{get(orderInfo, 'pickUpCode', '暂无')}</Text>
                </Descriptions.Item>
              )}
              {isSendOrder && deliveryMethod === 'MANUAL' && (
                <Descriptions.Item label="揽件员">
                  <Text strong>
                    {get(assignedUser, 'name')
                      ? get(assignedUser, 'mobile')
                        ? `${get(assignedUser, 'name')} (${get(assignedUser, 'mobile')})`
                        : get(assignedUser, 'name')
                      : '暂无'}
                  </Text>
                </Descriptions.Item>
              )}
              {isSendOrder && (
                <Descriptions.Item label="快递单号">
                  <Text strong>{get(orderInfo, 'shipperNumber', '暂无')}</Text>
                </Descriptions.Item>
              )}
              {isSendOrder && (
                <Descriptions.Item label={OrderWaypointTitle[orderType]} className="editable-row">
                  {(superAdmin || (!superAdmin && orderStates.includes(orderInfo.orderState))) && (
                    <EditableCascader
                      required
                      options={cascaderOptions}
                      editable={!isSendOrder}
                      wrapperStyle={wrapperStyle}
                      onSubmit={handleWaypointSave}
                      record={get(orderInfo, 'waypoint')}
                      title={OrderWaypointTitle[orderType]}
                      dataIndex={['buildingId', 'floorId', 'id']}
                    >
                      <Text strong>
                        {isHUB
                          ? '暂无'
                          : get(orderInfo, 'waypoint.name') ||
                          (orderInfo.floor && get(orderInfo, 'building.name') + '/' + get(orderInfo, 'floor.name')) ||
                          get(orderInfo, 'building.name') ||
                          '暂无'}
                      </Text>
                    </EditableCascader>
                  )}
                  {!superAdmin && !orderStates.includes(orderInfo.orderState) && (
                    <Text strong>
                      {isHUB
                        ? '暂无'
                        : get(orderInfo, 'waypoint.name') ||
                        (orderInfo.floor && get(orderInfo, 'building.name') + '/' + get(orderInfo, 'floor.name')) ||
                        get(orderInfo, 'building.name') ||
                        '暂无'}
                    </Text>
                  )}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="订单状态">
                <Text strong>
                  {isSendOrder ? orderState && SendOrderStatusStr[orderState] : orderState && OrderStateStr[orderState]}
                </Text>
              </Descriptions.Item>
              {orderInfo.orderType === OrderType.COLLECT && (
                <>
                  <Descriptions.Item label="是否滞留">
                    <Text strong>{orderInfo.stranded ? '是' : '否'}</Text>
                  </Descriptions.Item>
                  {orderInfo.stranded && orderInfo.strandedTime && (
                    <Descriptions.Item label="滞留天数">
                      <Text strong>
                        {new Date().getTime() - orderInfo.strandedTime >= MILLISECOND_IN_A_DAY
                          ? Math.ceil((new Date().getTime() - orderInfo.strandedTime) / MILLISECOND_IN_A_DAY) + '天'
                          : '0天'}
                      </Text>
                    </Descriptions.Item>
                  )}
                </>
              )}
              {isSendOrder && (
                <Descriptions.Item label={OrderMethodTitle[orderType]} className={editable ? 'editable-row' : ''}>
                  {(superAdmin || (!superAdmin && orderStates.includes(orderInfo.orderState))) && (
                    <EditableSelect
                      editable={editable}
                      record={orderInfo}
                      onSubmit={handleSave}
                      dataIndex="deliveryMethod"
                      wrapperStyle={wrapperStyle}
                      options={isSendOrder ? SendDeliveryMethodOption : DeliveryMethodOption}
                    >
                      <Text strong>
                        {isSendOrder
                          ? deliveryMethod && SendDeliveryMethodStr[deliveryMethod]
                          : deliveryMethod && DeliveryMethodStr[deliveryMethod]}
                      </Text>
                    </EditableSelect>
                  )}
                  {!superAdmin && !orderStates.includes(orderInfo.orderState) && (
                    <Text strong>
                      {isSendOrder
                        ? deliveryMethod && SendDeliveryMethodStr[deliveryMethod]
                        : deliveryMethod && DeliveryMethodStr[deliveryMethod]}
                    </Text>
                  )}
                </Descriptions.Item>
              )}
              {orderInfo.deliveryMethod === DeliveryMethod.HIVE && (
                <Descriptions.Item label="储物箱名称">
                  <Text strong>{get(orderInfo, 'locker.name', '暂无')}</Text>
                </Descriptions.Item>
              )}
              {!isSendOrder && (
                <Descriptions.Item label="包裹大小">
                  <Text strong>{get(PackageSizeStr, 'packageSize', '暂无')}</Text>
                </Descriptions.Item>
              )}
              {isSendOrder && (
                <Descriptions.Item label="创建人">
                  <Text strong>{get(orderInfo, 'createdByUser.name', '暂无')}</Text>
                </Descriptions.Item>
              )}
              {isSendOrder && deliveryMethod === DeliveryMethod.HUB && (
                <Descriptions.Item label="操作员">
                  <Text strong>{get(orderInfo, 'lastModifiedByUser.user', '暂无')}</Text>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="入库时间">
                <Text strong>{createdTime && format(new Date(createdTime), 'yyyy-MM-dd HH:mm:ss')}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="出库时间">
                <Text strong>{processedTime ? format(new Date(processedTime), 'yyyy-MM-dd HH:mm:ss') : '暂无'}</Text>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          {orderInfo.backupImages && orderInfo.backupImages.length && (
            <Col span={6}>
              <img src={orderInfo.backupImages[0]} style={{ width: '100%', height: '350px' }} alt="图片走丢了..." />
            </Col>
          )}
        </Row>
        <Divider />
        {!isSendOrder && (
          <Descriptions title="配送信息">
            <Descriptions.Item label="收件人姓名" className="editable-row">
              {(superAdmin || (!superAdmin && !collectedOrderStates.includes(orderInfo.orderState))) && (
                <EditableInput
                  required
                  editable
                  title="姓名"
                  dataIndex="name"
                  wrapperStyle={wrapperStyle}
                  onSubmit={handleAddressSave}
                  record={orderInfo || {}}
                >
                  <Text strong>{get(orderInfo, 'name') || '暂无'}</Text>
                </EditableInput>
              )}
              {!superAdmin && collectedOrderStates.includes(orderInfo.orderState) && (
                <Text strong>{get(orderInfo, 'name') || '暂无'}</Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="收件人电话" className="editable-row">
              {(superAdmin || (!superAdmin && !collectedOrderStates.includes(orderInfo.orderState))) && (
                <EditableInput
                  required
                  editable
                  title="手机号"
                  dataIndex="mobile"
                  onSubmit={handleSave}
                  wrapperStyle={wrapperStyle}
                  record={orderInfo || {}}
                  rules={[{ pattern: PhoneReg, message: '请输入正确的手机号!' }]}
                >
                  <Text strong>{get(orderInfo, 'mobile') || '暂无'}</Text>
                </EditableInput>
              )}
              {!superAdmin && collectedOrderStates.includes(orderInfo.orderState) && (
                <Text strong>{get(orderInfo, 'mobile') || '暂无'}</Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={OrderMethodTitle[orderType]} className={editable ? 'editable-row' : ''}>
              {(superAdmin || (!superAdmin && !collectedOrderStates.includes(orderInfo.orderState))) && (
                <EditableSelect
                  editable={editable && !disabledSwitch}
                  record={orderInfo}
                  onSubmit={handleSave}
                  dataIndex="deliveryMethod"
                  wrapperStyle={wrapperStyle}
                  options={isSendOrder ? SendDeliveryMethodOption : DeliveryMethodOption}
                >
                  <Text strong>
                    {isSendOrder
                      ? deliveryMethod && SendDeliveryMethodStr[deliveryMethod]
                      : deliveryMethod && DeliveryMethodStr[deliveryMethod]}
                  </Text>
                  {disabledSwitch ? (
                    <Text>
                      <InfoCircleOutlined style={{ marginLeft: '16px', marginRight: '4px', verticalAlign: 'middle' }} />
                      切换配送方式，请到app切换。
                    </Text>
                  ) : null}
                </EditableSelect>
              )}
              {!superAdmin && collectedOrderStates.includes(orderInfo.orderState) && (
                <Text strong>
                  {isSendOrder
                    ? deliveryMethod && SendDeliveryMethodStr[deliveryMethod]
                    : deliveryMethod && DeliveryMethodStr[deliveryMethod]}
                </Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={OrderWaypointTitle[orderType]} className="editable-row">
              {(superAdmin || (!superAdmin && !collectedOrderStates.includes(orderInfo.orderState))) && (
                <EditableCascader
                  required
                  options={cascaderOptions}
                  editable={!isSendOrder}
                  wrapperStyle={wrapperStyle}
                  onSubmit={handleWaypointSave}
                  record={get(orderInfo, 'waypoint')}
                  title={OrderWaypointTitle[orderType]}
                  dataIndex={['buildingId', 'floorId', 'id']}
                >
                  <Text strong>
                    {get(orderInfo, 'waypoint.name') ||
                      (orderInfo.floor && get(orderInfo, 'building.name') + '/' + get(orderInfo, 'floor.name')) ||
                      get(orderInfo, 'building.name') ||
                      '暂无'}
                  </Text>
                </EditableCascader>
              )}
              {!superAdmin && collectedOrderStates.includes(orderInfo.orderState) && (
                <Text strong>
                  {get(orderInfo, 'waypoint.name') ||
                    (orderInfo.floor && get(orderInfo, 'building.name') + '/' + get(orderInfo, 'floor.name')) ||
                    get(orderInfo, 'building.name') ||
                    '暂无'}
                </Text>
              )}
            </Descriptions.Item>
            {deliveryMethod === DeliveryMethod.MANUAL && (
              <>
                <Descriptions.Item label="快递员姓名">
                  <Text strong>{get(orderInfo, 'assignedUser.name', '暂无')}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="快递员电话/手机号">
                  <Text strong>{get(orderInfo, 'assignedUser.mobile', '暂无')}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="妥投方式">
                  <Text strong>{CollectionMethodStr[get(orderInfo, 'collectionMethod')] || '暂无'}</Text>
                </Descriptions.Item>
              </>
            )}
            {deliveryMethod === DeliveryMethod.HIVE && (
              <>
                <Descriptions.Item label="配送箱名称">
                  <Text strong>{get(orderInfo, 'usedHive.name') || get(orderInfo, 'hive.name', '暂无')}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="格口编号">
                  <Text strong>{get(orderInfo, 'usedLocker.name') || get(orderInfo, 'locker.name', '暂无')}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="取件码">
                  <Text strong>{get(orderInfo, 'pickUpCode') || get(orderInfo, 'usedPickUpCode', '暂无')}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="当前点">
                  <Text strong>
                    {get(orderInfo, 'hive.wayPoint.name') || get(orderInfo, 'usedHive.wayPoint.name', '暂无')}
                  </Text>
                </Descriptions.Item>
              </>
            )}
            {deliveryMethod === DeliveryMethod.HUB && (
              <>
                <Descriptions.Item label="取件方式">
                  <Text strong>{orderInfo.collectInPerson === true ? '本人' : '非本人'}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="代收人姓名">
                  <Text strong>{get(orderInfo, 'authorizedUser.name', '暂无')}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="代收人电话">
                  <Text strong>{get(orderInfo, 'authorizedUser.mobile', '暂无')}</Text>
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="异常内容">
              <Text strong>{get(orderInfo, 'failReason', '暂无')}</Text>
            </Descriptions.Item>
          </Descriptions>
        )}
        {isSendOrder && <ShippingOrderInfo orderInfo={orderInfo} loading={loading} />}
      </EditableForm>
      <Divider />
      <CancelOrderModal visible={showCancelOrderModal} onCancel={handleCancelModal} onSubmit={handleSubmitValues} />
      <AssignCourierModal
        visible={showAssignCourierModal}
        courierOption={courierOption}
        onSubmit={handleSubmitData}
        onCancel={() => toShowModal('showAssignCourierModal', false)}
      />
      <RobotDispatchingOrderModal
        onSubmit={onSubmit}
        record={[orderInfo.id]}
        onCancel={handleCancelModal}
        visible={showRobotDeliveredOrderModal}
      />
    </Card>
  );
};

const mapStateToProps = ({ global, common, pieceOrder, loading }: any) => {
  const cascaderOptions = common.buildingOption.map((item: any) => {
    const floorOptions = item.floors
      ? item.floors.map((item: any) => {
        const children = item.waypoints ? item.waypoints.map((d: any) => ({ label: d.name, value: d.id })) : [];

        return {
          label: item.name,
          value: item.id,
          children: waypointOption.concat(children),
        };
      })
      : [];

    return {
      label: item.name,
      value: item.id,
      isLeaf: false,
      children: floorOption.concat(floorOptions),
    };
  });

  return {
    userInfo: global.userInfo,
    orderInfo: pieceOrder.orderInfo,
    courierOption: common.courierOption,
    showCancelOrderModal: common.showCancelOrderModal,
    showAssignCourierModal: common.showAssignCourierModal,
    cascaderOptions: buildingOption.concat(cascaderOptions),
    showRobotDeliveredOrderModal: common.showRobotDeliveredOrderModal,
    loading: loading.effects['pieceOrder/fetchOrderInfo'],
    updating: loading.effects['pieceOrder/patchOrderInfo'],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchOrderInfo: (id: string) => {
    dispatch({ type: 'pieceOrder/fetchOrderInfo', payload: id });
  },

  postPrint: (params: object) => {
    dispatch({ type: 'sendOrderList/postPrint', payload: params });
  },

  toShowModal: (key: string, show: boolean) => {
    dispatch({ type: 'common/showOrHideModal', key, payload: show });
  },

  fetchBuildingOption: (params?: object) => {
    dispatch({ type: 'common/fetchBuildingOption', payload: params });
  },

  fetchCourierOption: (params: object) => {
    dispatch({ type: 'common/fetchCourierOption', payload: params });
  },

  updateOrderInfo: (id: string, params?: object) => {
    dispatch({ type: 'pieceOrder/patchOrderInfo', payload: { id, params } });
  },

  updateAddressInfo: (orderId: string, orderType: OrderType, params?: object) => {
    dispatch({ type: 'pieceOrder/postAddressInfo', payload: { orderId, orderType, params } });
  },

  updateWaypoint: (id: string, params?: object) => {
    dispatch({ type: 'pieceOrder/patchOrderWaypoint', payload: { id, params } });
  },

  postOrderAssignment: (params: object) => {
    dispatch({ type: 'pieceOrder/postOrderAssignment', payload: params });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfo);
// tslint:disable-next-line: max-file-line-count

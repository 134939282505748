export enum EnvType {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
  Local = 'local',
  Test = 'test',
}

interface ServerConfig {
  name: string;
  host: string;
  api: string;
  token: string;
  appId: string;
  appName: string;
}

interface Environment {
  test: ServerConfig;
  local: ServerConfig;
  development: ServerConfig;
  staging: ServerConfig;
  production: ServerConfig;
}

const serverEnvironments: Environment = {
  [EnvType.Local]: {
    name: '本地环境',
    host: 'http://localhost:3000',
    api: 'http://localhost:3001',
    appId: 'wx9dc90f2dc00c72b4',
    appName: 'PORTAL_WEB',
    token: 'Basic UFlpWGJtOWRpTUp5YnJoTjpZVnduZVFpTFQ0TFdQbW9j',
  },
  [EnvType.Development]: {
    name: '开发环境',
    host: 'https://dev.portal.ctirobot.com',
    api: 'https://development.api.ctirobot.com',
    appId: 'wx9dc90f2dc00c72b4',
    appName: 'PORTAL_WEB',
    token: 'Basic UFlpWGJtOWRpTUp5YnJoTjpZVnduZVFpTFQ0TFdQbW9j',
  },
  [EnvType.Test]: {
    name: '测试环境',
    host: 'https://test.portal.ctirobot.com',
    api: 'https://test.api.ctirobot.com',
    appId: 'wxacd0aa5ad992dae1',
    appName: 'PORTAL_WEB',
    token: 'Basic UFlpWGJtOWRpTUp5YnJoTjpZVnduZVFpTFQ0TFdQbW9j',
  },
  [EnvType.Staging]: {
    name: '预发布环境',
    host: 'https://stg.portal.ctirobot.com',
    api: 'https://staging.api.ctirobot.com',
    appId: 'wx69ea62c7b1c2359e',
    appName: 'PORTAL_WEB',
    token: 'Basic UFlpWGJtOWRpTUp5YnJoTjpZVnduZVFpTFQ0TFdQbW9j',
  },
  [EnvType.Production]: {
    name: '',
    host: 'https://portal.ctirobot.com',
    api: 'https://api.ctirobot.com',
    appId: 'wx54277a2f2cd67fd6',
    appName: 'PORTAL_WEB',
    token: 'Basic UFlpWGJtOWRpTUp5YnJoTjptamtCQWFwdGs5UE1qaXlw',
  },
};

// Node process.env.NODE_ENV 变量
export const Env: EnvType = process.env.NODE_ENV as EnvType;

export const rsaPublicKey =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDOp+BddkhBrCHf3NQlJPJ7YtriAN7YYpfVc6D77YLCVaIPrit+BiMjnV/wof0Z45+whtyW1JcCnNqVxlwA5HU5YGsWmmHO611CcO9x+R123QGw/cVjcjqyILlwQbXIrkuIeXBNLX7rZp/atQNb413f+xcPJ4wFmgKvN77EtdghcQIDAQAB';

// Node process.env.REACT_APP_BUILD
export const BuildEnv: EnvType = process.env.REACT_APP_BUILD as EnvType;

// Env 是否为开发环境
export const __DEV__: boolean = Env === EnvType.Development || BuildEnv === EnvType.Development;

// Env 是否为测试环境
export const __TEST__: boolean = BuildEnv === EnvType.Test;

// Env 是否为预发布环境
export const __STAG__: boolean = BuildEnv === EnvType.Staging;

// Env 是否为生产环境
export const __PROD__: boolean = Env === EnvType.Production;

// 获取服务端环境变量函数
export const ServerEnv = (): ServerConfig => {
  if (process.env.REACT_APP_BUILD === EnvType.Staging) {
    // For staging build
    return serverEnvironments[EnvType.Staging];
  }
  if (process.env.REACT_APP_BUILD === EnvType.Test) {
    // For staging build
    return serverEnvironments[EnvType.Test];
  }

  if (process.env.REACT_APP_BUILD === EnvType.Development) {
    // For development build
    return serverEnvironments[EnvType.Development];
  }
  return serverEnvironments[Env];
};

// 获取浏览器 User Agent
export const getBrowserUA = (appName: string, version: string): string => {
  const { userAgent } = window.navigator;
  return `${userAgent} ${appName}/${version}`;
};

import { SwapRightOutlined } from '@ant-design/icons';
import { Row, Table, Typography } from 'antd';
import { endOfDay, format, getTime, startOfDay } from 'date-fns';
import { connect } from 'dva';
import React, { FC, useEffect } from 'react';

import debounce from 'lodash/debounce';
import { Pagination as PaginationView } from '../../../components';
import { Dispatch } from '../../../models/dispatch';
import { PaymentMethodStr, TableColumnProps } from '../../../utils/constants';
import { transformDataSource } from '../../../utils/utils';
import HandOverHistoryTitle from './HandOverHistoryTitle';

const { Text } = Typography;

interface InternalProps {
  historyList: any[];
  userOption: any[];
  historyParams: any;
  companyOption: any[];
  historyOrderPagination: any;
  historyOrderLoading: boolean;
  fetchUserOption: (params: object) => any;
  handleHistoryParams: (params: object) => any;
  fetchCompanyOption: (params?: object) => void;
}

const HandOverHistory: FC<InternalProps> = (props) => {
  const {
    historyList,
    userOption,
    companyOption,
    historyParams,
    fetchUserOption,
    historyOrderLoading,
    handleHistoryParams,
    fetchCompanyOption,
    historyOrderPagination,
  } = props;

  const onPageChange = (page: number, pageSize: number | undefined) => {
    const tempObj = {
      page: page - 1,
      pageSize,
    };
    handleHistoryParams(tempObj);
  };

  useEffect(() => {
    fetchCompanyOption({ pageSize: 30 });
  }, [fetchCompanyOption]);

  const onPickDate = (dates: any, dateStrings: [string, string]) => {
    const start = dateStrings[0] ? getTime(startOfDay(new Date(dateStrings[0]))) : null;
    const end = dateStrings[1] ? getTime(endOfDay(new Date(dateStrings[1]))) : null;
    handleHistoryParams({ start, end, page: 0 });
  };

  const handleSearchUser = (value: any) => {
    fetchUserOption({ name: value, employee: true });
    handleHistoryParams({ name: value });
  };

  const columns: TableColumnProps<any>[] = [
    {
      title: '清单序号',
      dataIndex: 'key',
    },
    {
      title: '清单编号',
      key: 'inventoryNumber',
      dataIndex: 'order',
      render: (record: any) => record && record.inventory && record.inventory.inventoryNumber,
    },
    {
      title: '快递公司',
      key: 'company',
      dataIndex: 'order',
      render: (record: any) => record && record.company && record.company.name,
    },
    {
      title: '寄件信息',
      key: 'order',
      dataIndex: 'order',
      render: (record: any) => (
        <>
          <Row>
            <Text>{record && record.addressEntity && record.addressEntity.user && record.addressEntity.user.name}</Text>
            <SwapRightOutlined
              style={{ fontSize: '26px', color: '#1890ff', margin: '0 4px', verticalAlign: 'middle' }}
            />
            <Text>
              {record &&
                record.receiverAddressEntity &&
                record.receiverAddressEntity.user &&
                record.receiverAddressEntity.name}
            </Text>
          </Row>
        </>
      ),
    },
    {
      title: '支付信息',
      key: 'deliveryFeeType',
      dataIndex: 'order',
      render: (record: any) => (
        <>
          <Row>{(record && PaymentMethodStr[record.deliveryFeeType]) || '暂无'}</Row>
          <Row>{(record.paid ? '已支付' : '未支付') + '/' + (record.cost || '暂无')}</Row>
        </>
      ),
    },
    {
      title: '运单号',
      key: 'shipperNumber',
      dataIndex: 'order',
      render: (record: any) => record && record.shipperNumber,
    },
    {
      title: '创建时间',
      key: 'createdTime',
      dataIndex: 'order',
      render: (record: any) =>
        record &&
        record.inventory &&
        record.inventory.createdTime &&
        format(new Date(record.inventory.createdTime), 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      title: '交接时间',
      key: 'handleTime',
      dataIndex: 'createdTime',
      render: (record: any) => record && format(new Date(record), 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      title: '操作人',
      dataIndex: 'modifiedByEntity',
      render: (record: any) => record && record.name,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={historyList}
        title={() => (
          <HandOverHistoryTitle
            reqParams={historyParams}
            onPickDate={onPickDate}
            companyOption={companyOption}
            userOption={userOption}
            handleSearchUser={debounce(handleSearchUser, 500)}
            handleChange={(key: string, value: any) => handleHistoryParams({ [key]: value })}
          />
        )}
        scroll={{ x: 1200 }}
        loading={historyOrderLoading}
        pagination={PaginationView(historyOrderPagination, onPageChange)}
      />
    </>
  );
};

export const mapStateToProps = ({ common }: any) => {
  return {
    userOption: transformDataSource(common.userOption),
    companyOption: common.companyOption,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUserOption: (params?: object) => {
      dispatch({
        type: 'common/fetchUserOption',
        payload: params,
      });
    },
    fetchCompanyOption: (params?: object) => {
      dispatch({
        type: 'common/fetchCompanyOption',
        payload: params,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HandOverHistory);

import React from 'react';

import { Pagination } from '../../utils/constants';

/**
 *
 * @param {Pagination} pagination
 * @param {Function} handleChange
 * @param {string} size
 * @param {boolean} showSizeChanger
 * @param {boolean} showQuickJumper
 * @param {boolean} simple
 */
export default function PaginationView(
  pagination: Pagination,
  handleChange: (page: number, pageSize?: number) => void,
  size?: 'small' | 'default',
  showSizeChanger?: boolean,
  showQuickJumper?: boolean,
  simple?: boolean
) {
  const { page, pageSize, totalCount, totalPageCount } = pagination;

  return {
    size,
    pageSize,
    simple: simple ? simple : false,
    showSizeChanger: showSizeChanger !== undefined ? showSizeChanger : true,
    showQuickJumper: showQuickJumper !== undefined ? showQuickJumper : true,
    current: page + 1,
    total: totalCount,
    showTotal: (total: number) => {
      return (
        <span style={{ marginRight: '16px' }}>
          共 {total} 条记录 第 {page + 1} / {totalPageCount} 页
        </span>
      );
    },
    onChange: handleChange,
    onShowSizeChange: handleChange,
  };
}

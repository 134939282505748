import { Descriptions, Skeleton, Typography } from 'antd';
import { get } from 'lodash';
import React, { FC } from 'react';

import { DeviceStatusStr, HiveLayoutTypeStr } from '../../../utils/constants';

const { Text } = Typography;

interface InternalProps {
  orderHiveInfo: any;
}

export const OrderHiveInfo: FC<InternalProps> = (props) => {
  const { orderHiveInfo } = props;
  const { name, deviceStatus, layoutType, navigationState, targetWaypoint, waypoint } = orderHiveInfo;

  return (
    <Skeleton loading={false}>
      <Descriptions title={`快递柜名称: ${name ? name : ''}`} style={{ padding: 16 }}>
        {deviceStatus && (
          <Descriptions.Item label="设备状态">
            <Text strong>{deviceStatus ? DeviceStatusStr[deviceStatus] : '暂无'}</Text>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="设备类型">
          <Text strong>{layoutType ? HiveLayoutTypeStr[layoutType] : '暂无'}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="导航状态">
          <Text strong>{navigationState ? navigationState : '暂无'}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="当前点">
          <Text strong>{get(waypoint, 'name', '暂无')}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="目标点">
          <Text strong>{get(targetWaypoint, 'name', '暂无')}</Text>
        </Descriptions.Item>
      </Descriptions>
    </Skeleton>
  );
};

export default OrderHiveInfo;

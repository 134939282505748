import { Descriptions, Skeleton, Typography } from 'antd';
import { connect } from 'dva';
import { Link } from 'dva/router';
import { get } from 'lodash';
import React, { FC, useEffect } from 'react';

import { Dispatch } from '../../../models/dispatch';
import { Routes } from '../../../routes';
import { DeviceStatusStr, HiveLayoutTypeStr } from '../../../utils/constants';

const { Text } = Typography;

interface DvaProps {
  match: any;
  orderHiveInfo: any;
  loading: boolean;
  cascaderOptions: any[];
  fetchAggHiveDetail: (id: string) => void;
}

interface InternalProps extends DvaProps {
  orderId: string;
}

export const OrderInfo: FC<InternalProps> = (props) => {
  const { orderId, loading, orderHiveInfo, fetchAggHiveDetail } = props;
  const {
    name,
    deviceStatus,
    layoutType,
    targetWaypoint,
    waypoint,
    coreVersion,
    battery,
    multiple,
    schedulingDisabled,
  } = orderHiveInfo;

  useEffect(() => {
    fetchAggHiveDetail(orderId);
  }, [fetchAggHiveDetail, orderId]);

  return (
    <Skeleton loading={loading}>
      <Descriptions
        title={(name && <Link to={`${Routes.DeviceHiveCell}/${orderHiveInfo.id}`}>快递柜: {name}</Link>) || '快递柜 : '}
        style={{ padding: 16 }}
      >
        {deviceStatus && (
          <Descriptions.Item label="设备状态">
            <Text strong>{deviceStatus ? DeviceStatusStr[deviceStatus] : '暂无'}</Text>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="格口布局类型">
          <Text strong>{layoutType ? HiveLayoutTypeStr[layoutType] : '暂无'}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="当前点">
          <Text strong>{get(waypoint, 'name', '暂无')}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="版本号">
          <Text strong>{coreVersion || '暂无'}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="设备电量">
          <Text strong>{get(battery, 'percentage', 0)}%</Text>
        </Descriptions.Item>
        <Descriptions.Item label="格口业务模式">
          <Text strong>{multiple ? '一格多件' : '一格一件'}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="调度是否打开">
          <Text strong>{schedulingDisabled ? '否' : '是'}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="目标点">
          <Text strong>{get(targetWaypoint, 'name', '暂无')}</Text>
        </Descriptions.Item>
      </Descriptions>
    </Skeleton>
  );
};

const mapStateToProps = ({ common, pieceOrder, loading }: any) => {
  return {
    orderInfo: pieceOrder.orderInfo,
    orderHiveInfo: pieceOrder.orderHiveInfo,
    loading: loading.effects['pieceOrder/fetchAggHiveDetail'],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAggHiveDetail: (id: string) => {
    dispatch({
      type: 'pieceOrder/fetchAggHiveDetail',
      payload: id,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfo);

export interface FieldItem {
  field: string;
  value?: any;
}

export enum InputType {
  Number = 'number',
  Select = 'select',
  Cascader = 'cascader',
  Input = 'input',
}

import { AuthUserDetail, AuthUserList, DownloadContent, RoleList } from './Auth';
import { Dashboard, DashRoleList, HubReport, SwitchHub, TaskReminder } from './Dashboard';
import { Cruise } from './DeliveryCenter';
import { DeviceCommentList, DeviceMonitor, HiveCellDetail, HiveCellList, ReplayList, ShelfList } from './DeviceCenter';
import { NoPermission, NotFound, ServerError } from './Exception';
import { FaultConfig, FaultHistory, FaultHistoryDetail, FaultLog, FaultNotifier, FaultStrategy } from './FaultAlarm';
import { OrderReconciliation, SendOrderReconciliation } from './Financial';
import {
  AreaEditOrAdd,
  AreaList,
  BasicSetting,
  GarbageClearSetting,
  HubDetail,
  HubSettings,
  LiftList,
  RobotAreaList,
  RobotSetting,
  RobotSettingDetail,
  RulesSetting,
  SchedulingDisabledSetting,
  SendRulesSetting,
  ServiceTypeSetting,
  ShareSetting,
  TakeOutSetting,
} from './Hub';
import { HubPickupList, HubScreenReport } from './HubPickup';
import { Auth, ForgetPassword, Login } from './Login';
import { MessageList } from './Message';
import {
  CheckInventory,
  CheckInventoryDetail,
  DeliveryCompanyPrice,
  OrderDetail,
  PendingUserEdit,
  PendingUserList,
  PieceExceptionOrder,
  PieceHubOrder,
  PieceManualOrder,
  PieceManualOrderDetail,
  PieceOrder,
  PieceOrderSync,
  PieceRobotDeliveredOrder,
  PieceRobotOrder,
  PieceToCompanyOrder,
  PieceWarehousedOrder,
  RobotOrderDetail,
} from './Order';
import { OTAList, SoftwareList } from './OTACenter';
import { RemoteMaintenanceScreen } from './RemoteMaintenance';
import {
  CommandHistory,
  DeliveryDetail,
  DeliveryHistory,
  MonitorCenter,
  RobotDetail,
  RobotList,
  ScheduleHistory,
  ScheduleRequest,
} from './Robot';
import {
  BeSentOrder,
  HandOverCompany,
  HandOverCompanyDetail,
  PreviewPrice,
  PricingManage,
  SendExceptionOrder,
  SendHubOrder,
  SendManualOrder,
  SendManualOrderDetail,
  SendOrderDetail,
  SendOrderList,
  SendRobotOrder,
  SendRobotOrderDetail,
  SettingPrice,
  ToSendOrder,
} from './SendCenter';
import {
  CabinetHiveDetail,
  CabinetHiveList,
  CabinetOrder,
  CabinetOrderDetail,
  CabinetUserDetail,
  CabinetUserList,
  ElectricFence,
} from './SharedCabinet';
import { SharedOrderDetail, SharedOrderList } from './SharedRobot';
import {
  DisinfectDetail,
  DisinfectList,
  MaterialTransportDetail,
  MaterialTransportList,
  RubbishDetail,
  RubbishList,
} from './TaskRecord';
import { CollectionDetail, FeedbackList, UserCollection, UserDetail, UserList } from './UserCenter';

import {
  DeviceCandlelight,
  DeviceCandlelightDetail,
  DeviceFunctionBox,
  DeviceFunctionBoxDetail,
  DeviceHiveSize,
  DeviceHiveSizeDetail,
  DeviceModelManage,
  DeviceProductManage,
  DeviceProductManageDetail,
  DeviceSpecification,
  DeviceSunlight,
  DeviceSunlightDetail,
} from './DeviceRegister';

import { DeviceGateManage, DeviceGateManageDetail } from './DeviceManage';
import { FaceEntry } from './VisitReception';

/************** Public Pages **************/

export const PublicPages = { Auth, Login, ForgetPassword, NoPermission, NotFound, ServerError };

/************** App Pages **************/

export const AppPages = {
  AreaEditOrAdd,
  AreaList,
  AuthUserList,
  AuthUserDetail,
  DownloadContent,
  BasicSetting,
  BeSentOrder,
  CabinetOrder,
  CabinetOrderDetail,
  CheckInventory,
  CheckInventoryDetail,
  CabinetHiveList,
  CabinetHiveDetail,
  CabinetUserDetail,
  CabinetUserList,
  CollectionDetail,
  CommandHistory,
  Cruise,
  DashRoleList,
  Dashboard,
  DeliveryDetail,
  DeliveryHistory,
  DeliveryCompanyPrice,
  DeviceMonitor,
  DeviceCommentList,
  DisinfectList,
  DisinfectDetail,
  DeviceCandlelight,
  DeviceCandlelightDetail,
  DeviceProductManage,
  DeviceModelManage,
  DeviceSpecification,
  DeviceFunctionBox,
  ElectricFence,
  FaultLog,
  FaultConfig,
  FaultStrategy,
  FaultNotifier,
  FaultHistory,
  FaultHistoryDetail,
  FeedbackList,
  HandOverCompany,
  HandOverCompanyDetail,
  HiveCellList,
  HiveCellDetail,
  HubDetail,
  HubReport,
  HubPickupList,
  HubScreenReport,
  HubSettings,
  LiftList,
  MessageList,
  MonitorCenter,
  MaterialTransportList,
  MaterialTransportDetail,
  OrderDetail,
  OrderReconciliation,
  OTAList,
  PendingUserEdit,
  PendingUserList,
  PieceHubOrder,
  PieceManualOrder,
  PieceManualOrderDetail,
  PieceOrder,
  PieceOrderSync,
  PieceRobotOrder,
  PieceExceptionOrder,
  PieceToCompanyOrder,
  PieceWarehousedOrder,
  PieceRobotDeliveredOrder,
  PricingManage,
  PreviewPrice,
  RemoteMaintenanceScreen,
  ReplayList,
  RobotDetail,
  RobotList,
  RobotOrderDetail,
  RobotSettingDetail,
  RobotSetting,
  RoleList,
  RobotAreaList,
  RubbishList,
  RubbishDetail,
  RulesSetting,
  ScheduleRequest,
  ScheduleHistory,
  SendOrderList,
  SendOrderDetail,
  SendManualOrder,
  SendManualOrderDetail,
  SendRulesSetting,
  SettingPrice,
  SendHubOrder,
  SendExceptionOrder,
  SendRobotOrder,
  SendRobotOrderDetail,
  ServiceTypeSetting,
  SendOrderReconciliation,
  SchedulingDisabledSetting,
  SharedOrderDetail,
  SharedOrderList,
  ShareSetting,
  ShelfList,
  SoftwareList,
  SwitchHub,
  UserList,
  UserDetail,
  UserCollection,
  TaskReminder,
  TakeOutSetting,
  ToSendOrder,

  DeviceProductManageDetail,
  DeviceHiveSize,
  DeviceSunlight,
  DeviceSunlightDetail,
  DeviceHiveSizeDetail,
  DeviceFunctionBoxDetail,
  GarbageClearSetting,
  DeviceGateManage,
  DeviceGateManageDetail,
  FaceEntry,
};

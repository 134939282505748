import { Cascader, Col, Form, Input, Modal, Row } from 'antd';
import { get } from 'lodash';
import React, { FC, useEffect } from 'react';

import options from '../../../utils/addressOptions';
import { PhoneReg } from '../../../utils/regTool';

interface InternalProps {
  orderInfo: any;
  visible: boolean;
  modifyMsg: string;
  onCancel: () => void;
  afterClose: () => void;
  onSubmit: (values: any) => void;
}

const ModifyAddressModal: FC<InternalProps> = (props) => {
  const { visible, onCancel, onSubmit, modifyMsg, orderInfo, afterClose } = props;
  const [form] = Form.useForm();
  const { resetFields, validateFields, setFieldsValue } = form;

  const msg = modifyMsg === 'addressEntity' ? '寄件' : '收件';
  const addressInfo = get(orderInfo, modifyMsg);

  useEffect(() => {
    const initArea = [get(addressInfo, 'province'), get(addressInfo, 'city'), get(addressInfo, 'area')];
    const { name, mobile, address } = addressInfo || {};
    const initialValue = {
      name,
      mobile,
      address,
      area: initArea,
    };

    setFieldsValue(initialValue);
  }, [addressInfo, setFieldsValue]);

  const handleSubmit = async () => {
    let values = await validateFields();
    const { area } = values;
    const { id } = addressInfo;
    values = {
      ...values,
      id,
      province: area[0],
      city: area[1],
      area: area[2],
    };

    onSubmit(values);
  };

  const handleAfterClose = () => {
    resetFields();
    afterClose();
  };

  return (
    <Modal
      centered
      forceRender
      destroyOnClose={false}
      getContainer={false}
      title={`修改${msg}人信息`}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      afterClose={handleAfterClose}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="name" label={`${msg}人姓名`} rules={[{ required: true, message: '请输入姓名!' }]}>
              <Input placeholder="姓名" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="mobile"
              label={`${msg}人号码`}
              rules={[
                { required: true, message: '请输入电话!' },
                { pattern: PhoneReg, message: '请输入正确的手机号！' },
              ]}
            >
              <Input placeholder="电话" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="area" label="所在地区" rules={[{ required: true, message: '请选择所在地区!' }]}>
              <Cascader placeholder="请选择所在地区" options={options} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="address" label="详细地址" rules={[{ required: true, message: '请输入详细地址!' }]}>
              <Input placeholder="请输入详细地址" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModifyAddressModal;

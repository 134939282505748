import { Col, Form, Input, Row, Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { ChangeEvent, FC, useEffect } from 'react';

import { Dispatch } from '../../../models/dispatch';
import { DeviceStatusOption } from '../../../utils/constants';
import { filterOption, splitQuery, transformDataSource } from '../../../utils/utils';
import { OrderParams } from '../models/typed';

const { Item } = Form;
const { Search } = Input;
const { Option } = Select;

interface DvaProps {
  buildingOptions: any[];
  companyOptions: DataSourceItemObject[];
  fetchCompanyOption: (params?: object) => void;
  fetchBuildingOption: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  query: OrderParams;
  onSearchChange: (params: OrderParams) => void;
}

export const RobotOrderHeader: FC<InternalProps> = (props) => {
  const { query, buildingOptions, fetchCompanyOption, fetchBuildingOption, onSearchChange } = props;

  useEffect(() => {
    fetchCompanyOption();
    fetchBuildingOption();
  }, [fetchCompanyOption, fetchBuildingOption]);

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递柜名称">
            <Search
              allowClear
              placeholder="请输入快递柜名称"
              defaultValue={get(query, 'name', '')}
              onSearch={(value: string) => onSearchChange({ name: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ name: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="当前楼栋">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择楼栋"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'buildingId')}
              onChange={(value: string[]) => onSearchChange({ buildingId: value.join() })}
            >
              {buildingOptions.map((item: any, index: number) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="设备状态">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择设备状态"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'deviceStatus')}
              onChange={(value: string[]) => onSearchChange({ deviceStatus: value.join() })}
            >
              {DeviceStatusOption.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ common }: any) => ({
  buildingOptions: common.buildingOption,
  companyOptions: transformDataSource(common.companyOption),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompanyOption: (params?: object) => {
    dispatch({
      type: 'common/fetchCompanyOption',
      payload: params,
    });
  },
  fetchBuildingOption: (params?: object) => {
    dispatch({
      type: 'common/fetchBuildingOption',
      payload: params,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RobotOrderHeader);

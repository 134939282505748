// 综合业务管理平台路由配置
export enum Routes {
  Login = '/login',
  Auth = '/auth',
  ForgetPassword = '/forget-password',

  // 数据大屏
  SwitchHub = '/switch-hub',
  HubReport = '/hub-report',
  RoleList = '/role-list',
  TaskReminder = '/task-reminder',
  HubPickupList = '/hub-pickup',
  HubScreenReport = '/hub-screen-report',

  // 综合业务管理平台
  App = '/app',
  Dashboard = '/app/dashboard',
  Download = '/app/export/download',

  // 全局设置
  Settings = '/app/settings',

  // 异常报错
  NoPermission = '/exception/403',
  NotFound = '/exception/404',
  ServerError = '/exception/500',
  AppNoPermission = '/app/exception/403',
  AppNotFound = '/app/exception/404',
  AppServerError = '/app/exception/500',

  Retail = '/app/retail',
  User = '/app/user',
  Courier = '/app/courier',
  Cruise = '/app/courier/cruise',
  DeliveryHistory = '/app/robot/delivery-history',
  CommandHistory = '/app/robot/command-history',
  DeliveryDetail = '/app/robot/delivery-detail',
  MileageCounts = '/app/robot/mileage-counts',
  InventoryDetail = '/app/retail/inventory-list',

  // 派件管理
  Order = '/app/order',
  PieceHubOrder = '/app/order/hub',
  PieceOrder = '/app/order/piece-list',
  PieceRobotOrder = '/app/order/robot',
  PieceManualOrder = '/app/order/manual',
  PieceToCompanyOrder = '/app/order/to-company',
  PendingClientInfo = '/app/order/client-info',
  PieceExceptionOrder = '/app/order/exception',
  CheckInventory = '/app/order/check-inventory',
  PieceWarehousedOrder = '/app/order/warehoused',
  PieceRobotDeliveredOrder = '/app/order/robot-delivered',
  DeliveryCompanyPrice = '/app/order/delivery-company-price',

  // 递易订单同步页面
  PieceOrderSync = '/app/order/order-sync',

  // 驿站管理
  HubCenter = '/app/hub',
  HubDetail = '/app/hub/hub-detail',
  HubAreaList = '/app/hub/area-list',
  HubLiftList = '/app/hub/lift-list',
  HubSettings = '/app/hub/settings',
  HubBuilding = '/app/hub/building-list',
  RobotAreaList = '/app/hub/robot-area',
  BasicSetting = '/app/hub/settings/basic',
  HubAreaEdit = '/app/hub/area-list/modify',
  ShareSetting = '/app/hub/settings/share-setting',
  RulesSetting = '/app/hub/settings/rules-setting',
  RobotSetting = '/app/hub/settings/robot-setting',
  ElectricFence = '/app/hub/settings/electric-fence',
  TakeOutSetting = '/app/hub/settings/take-out-setting',
  ServiceTypeSetting = '/app/hub/settings/service-type',
  SendRulesSetting = '/app/hub/settings/send-rules-setting',
  RobotSettingDetail = '/app/hub/settings/robot-setting-detail',
  SchedulingDisabledSetting = '/app/hub/settings/scheduling-setting',
  GarbageClearSetting = '/app/hub/settings/garbage-clear-setting',

  // 客户中心
  ClientCenter = '/app/client-center',
  ClientList = '/app/client-center/client',
  ClientFeedback = '/app/client-center/feedback',
  ClientCollection = '/app/client-center/collection',

  // 仓储管理
  Warehouse = '/app/warehouse',
  SmartShelf = '/app/warehouse/smart-shelf',
  FrontWarehouse = '/app/warehouse/front-warehouse',
  ShipmentPort = '/app/warehouse/smart-shelf/shipment-port',

  // 寄件管理
  SendOrderCenter = '/app/send-center',
  SendOrderList = '/app/send-center/send-order-list',
  SendManualOrder = '/app/send-center/manual-order-list',
  SendRobotOrder = '/app/send-center/robot-order-list',
  SendHubOrder = '/app/send-center/hub-order-list',
  SendExceptionOrder = '/app/send-center/exception-order-list',
  ToSendOrder = '/app/send-center/to-send-order-list',
  BeSentOrder = '/app/send-center/be-sent-order-list',
  HandOverCompany = '/app/send-center/hand-over-company',
  PricingManage = '/app/send-center/pricing-manage',
  SettingPrice = '/app/send-center/pricing-manage/setting-price',
  PreviewPrice = '/app/send-center/pricing-manage/preview-price',
  ShippingList = '/app/shipping/shipping-list',
  ShippingOrderDetail = '/app/shipping/shipping-order-detail',
  CreatShippingOrder = '/app/order/creat-shipping-order',

  // 设备管控
  Device = '/app/device-manage',

  // 设备中心
  DeviceCenter = '/app/device-manage/device-center',
  DeviceHive = '/app/device-manage/device-center/hive',
  DeviceShelf = '/app/device-manage/device-center/shelf',
  DeviceHiveCell = '/app/device-manage/device-center/hive-cell',
  DeviceLocker = '/app/device-manage/device-center/locker',
  DeviceRobot = '/app/device-manage/device-center/robot',

  // 远程监控
  DeviceMonitor = '/app/device-manage/monitor',
  RobotMonitor = '/app/device-manage/monitor/robot',
  ReplayCenter = '/app/device-manage/monitor/replay',
  AnomalyRecord = '/app/device-manage/monitor/anomaly',

  // OTA升级
  OTA = '/app/device-manage/ota',
  OTAList = '/app/device-manage/ota/ota-list',
  SoftwareList = '/app/device-manage/ota/software-list',

  // 故障告警
  FaultWarning = '/app/device-manage/fault-warning',
  FaultStrategy = '/app/device-manage/fault-warning/strategy',
  FaultHistory = '/app/device-manage/fault-warning/history',
  FaultNotifier = '/app/device-manage/fault-warning/notifier',
  FaultLog = '/app/device-manage/fault-warning/log',
  FaultAnalyze = '/app/device-manage/fault-warning/analyze',
  FaultConfig = '/app/device-manage/fault-warning/config',

  // 远程维护
  RemoteMaintenance = '/app/device-manage/remote-maintenance',
  RemoteMaintenanceScreen = '/app/device-manage/remote-maintenance/screen',

  // 调度
  Schedule = '/app/device-manage/schedule',
  ScheduleTask = '/app/device-manage/schedule/task',
  ScheduleHistory = '/app/device-manage/schedule/history',
  ScheduleRequest = '/app/device-manage/schedule/request',

  // 用户中心
  AppAuth = '/app/auth',
  AuthRole = '/app/auth/role',
  AuthUser = '/app/auth/user',

  // 消息中心
  MessageCenter = '/app/message-center',
  MessageList = '/app/message-center/message-list',

  // 财务对账
  Financial = '/app/financial',
  SendOrderReconciliation = '/app/financial/send-reconciliation',
  CollectOrderReconciliation = '/app/financial/collect-reconciliation',

  // 外卖快递柜
  SharedCabinet = '/app/shared-cabinet',
  SharedCabinetOrder = '/app/shared-cabinet/order',
  CabinetHiveList = '/app/shared-cabinet/hive-list',
  CabinetUserList = '/app/shared-cabinet/user-list',

  // 共享配送
  SharedRobot = '/app/shared-robot',
  SharedRobotOrder = '/app/shared-robot/order',

  // 任务记录
  TaskRecord = '/app/task-record',
  DisinfectList = '/app/task-record/disinfect-list',
  RubbishList = '/app/task-record/rubbish-list',
  MaterialTransportList = '/app/task-record/material-transport-list',

  // 来访接待
  VisitReception = '/app/visit-reception',
  VisitFaceEntry = '/app/visit-reception/face-entry',
}

// 设备管控平台路由配置
export enum DeviceRoutes {
  // 设备管控平台
  Device = '/device',
  Dashboard = '/device/dashboard',

  // 设备注册
  DeviceRegister = '/device/device-register',
  DeviceProductManage = '/device/device-register/product-manage',
  DeviceModelManage = '/device/device-register/model-manage',
  DeviceSpecification = '/device/device-register/specification',
  DeviceHiveSize = '/device/device-register/hive-size',
  DeviceSunlight = '/device/device-register/sunlight',
  DeviceCandlelight = '/device/device-register/candlelight',
  DeviceFunctionBox = '/device/device-register/function-box',

  // 设备信息管理
  DeviceInfo = '/device/device-info',
  DeviceInfoRobot = '/device/device-info/robot',
  DeviceInfoHive = '/device/device-info/hive',

  // 设备管理
  DeviceManage = '/device/device-manage',
  DeviceGateManage = '/device/device-manage/gate-manage',
}

// 异常路由列表
export const ExceptionRoutes: string[] = [Routes.AppNoPermission, Routes.AppNotFound, Routes.AppServerError];

import * as React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const SendOrderList = Loadable.Map({
  loader: {
    SendOrderList: () => import('./SendOrderList'),
    model: () => import('./models/SendOrderList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendOrderList = loaded.SendOrderList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendOrderList {...props} />
      </Loading>
    );
  },
});

const SendHubOrder = Loadable.Map({
  loader: {
    SendOrder: () => import('./containers'),
    model: () => import('./models/SendOrderList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendHubOrder = loaded.SendOrder.SendHubOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendHubOrder {...props} />
      </Loading>
    );
  },
});

const SendRobotOrder = Loadable.Map({
  loader: {
    SendOrder: () => import('./containers'),
    model: () => import('./models/SendOrderList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendRobotOrder = loaded.SendOrder.SendRobotOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendRobotOrder {...props} />
      </Loading>
    );
  },
});

const SendRobotOrderDetail = Loadable.Map({
  loader: {
    SendRobotOrderDetail: () => import('./SendRobotOrderDetail'),
    model: () => import('./models/SendOrderList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendRobotOrderDetail = loaded.SendRobotOrderDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendRobotOrderDetail {...props} />
      </Loading>
    );
  },
});

const SendManualOrder = Loadable.Map({
  loader: {
    SendManualOrder: () => import('./SendManualOrder'),
    model: () => import('./models/SendManualOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendManualOrder = loaded.SendManualOrder.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendManualOrder {...props} />
      </Loading>
    );
  },
});

const SendExceptionOrder = Loadable.Map({
  loader: {
    SendOrder: () => import('./containers'),
    model: () => import('./models/SendOrderList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendExceptionOrder = loaded.SendOrder.SendExceptionOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendExceptionOrder {...props} />
      </Loading>
    );
  },
});

const ToSendOrder = Loadable.Map({
  loader: {
    SendOrder: () => import('./containers'),
    model: () => import('./models/SendOrderList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const ToSendOrder = loaded.SendOrder.ToSendOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <ToSendOrder {...props} />
      </Loading>
    );
  },
});

const BeSentOrder = Loadable.Map({
  loader: {
    SendOrder: () => import('./containers'),
    model: () => import('./models/SendOrderList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const BeSentOrder = loaded.SendOrder.BeSentOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <BeSentOrder {...props} />
      </Loading>
    );
  },
});

const SendManualOrderDetail = Loadable.Map({
  loader: {
    SendManualOrderDetail: () => import('./SendManualOrderDetail'),
    model: () => import('./models/SendManualOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendManualOrderDetail = loaded.SendManualOrderDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendManualOrderDetail {...props} />
      </Loading>
    );
  },
});

const SendOrderDetail = Loadable.Map({
  loader: {
    SendOrderDetail: () => import('./../Order/OrderDetail'),
    model: () => import('./../Order/models/PieceOrder'),
    sendModal: () => import('./models/SendOrderList'),
    historyModel: () => import('./../Order/models/OrderHistory'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendOrderDetail = loaded.SendOrderDetail.default;
    const model = loaded.model.default;
    const sendModal = loaded.sendModal.default;
    const historyModel = loaded.historyModel.default;

    registerModel(props.app, model);
    registerModel(props.app, sendModal);
    registerModel(props.app, historyModel);

    return (
      <Loading>
        <SendOrderDetail {...props} />
      </Loading>
    );
  },
});

const HandOverCompany = Loadable.Map({
  loader: {
    HandOverCompany: () => import('./HandOverCompany'),
    model: () => import('./models/HandOverCompany'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const HandOverCompany = loaded.HandOverCompany.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <HandOverCompany {...props} />
      </Loading>
    );
  },
});

const HandOverCompanyDetail = Loadable.Map({
  loader: {
    HandOverCompanyDetail: () => import('./HandOverCompanyDetail'),
    model: () => import('./models/HandOverCompanyDetail'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const HandOverCompanyDetail = loaded.HandOverCompanyDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <HandOverCompanyDetail {...props} />
      </Loading>
    );
  },
});

const PricingManage = Loadable.Map({
  loader: {
    PricingManage: () => import('./PricingManage'),
    model: () => import('./models/PricingManage'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PricingManage = loaded.PricingManage.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PricingManage {...props} />
      </Loading>
    );
  },
});

const SettingPrice = Loadable.Map({
  loader: {
    SettingPrice: () => import('./containers/connectedSettingPrice'),
    model: () => import('./models/SettingPrice'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SettingPrice = loaded.SettingPrice.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SettingPrice {...props} />
      </Loading>
    );
  },
});

const PreviewPrice = Loadable.Map({
  loader: {
    PreviewPrice: () => import('./PreviewPrice'),
    model: () => import('./models/SettingPrice'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PreviewPrice = loaded.PreviewPrice.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PreviewPrice {...props} />
      </Loading>
    );
  },
});

export {
  SendOrderList,
  SendOrderDetail,
  SendManualOrder,
  SendManualOrderDetail,
  SendRobotOrder,
  SendRobotOrderDetail,
  SendHubOrder,
  SendExceptionOrder,
  ToSendOrder,
  BeSentOrder,
  HandOverCompany,
  PricingManage,
  SettingPrice,
  PreviewPrice,
  HandOverCompanyDetail,
};

import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { FC } from 'react';

import { GoodsTypeOption, PaymentMethodOptions, PayStatusOptions } from '../../../utils/constants';
const { Option } = Select;
const { Search } = Input;

interface InternalProps {
  reqParams: any;
  handleExport: () => void;
  handleConfirmDelivery: () => void;
  handleChange: (key: string, value: any) => void;
}

const HandOverCompanyDetailHeader: FC<InternalProps> = (props: InternalProps) => {
  const { reqParams, handleChange, handleExport } = props;

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="快递运单号">
            <Search
              allowClear
              placeholder="请输入"
              defaultValue={reqParams.shipperNumber}
              onChange={(e) => handleChange('shipperNumber', e.target.value)}
              onSearch={(value) => handleChange('shipperNumber', value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="寄件人">
            <Search
              allowClear
              placeholder="请输入"
              defaultValue={reqParams.sender}
              onChange={(e: any) => handleChange('sender', e.target.value)}
              onSearch={(value: string) => handleChange('sender', value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="收件人">
            <Search
              allowClear
              placeholder="请输入"
              defaultValue={reqParams.receiver}
              onChange={(e: any) => handleChange('receiver', e.target.value)}
              onSearch={(value: string) => handleChange('receiver', value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="支付方式">
            <Select
              allowClear
              placeholder="请选择"
              defaultValue={reqParams.deliveryFeeType}
              onChange={(value: string) => handleChange('deliveryFeeType', value)}
            >
              {PaymentMethodOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="支付状态">
            <Select
              allowClear
              placeholder="请选择"
              defaultValue={reqParams.paid ? String(reqParams.paid) : undefined}
              onChange={(value: string) => handleChange('paid', Boolean(value))}
            >
              {PayStatusOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="物品类型">
            <Select
              allowClear
              placeholder="请选择"
              defaultValue={reqParams.goodsType}
              onChange={(value: string) => handleChange('goodsType', value)}
            >
              {GoodsTypeOption.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col>
          <Button type="primary" onClick={handleExport}>
            导出列表
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default HandOverCompanyDetailHeader;

import { ApiUrl } from './ApiConfig';
import { DELETE, GET, POST } from './AxiosInstance';

export class AggregationService {
  public static fetchAggRetailList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_RETAIL, params);
  }

  public static fetchAggHubList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_HUB, params);
  }

  public static fetchAggAreaList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_AREA, params);
  }

  public static fetchBuildingData(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_BUILDING, params);
  }

  public static fetchAggHub(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.AGG_HUB}/${id}`, params);
  }

  public static fetchAggOrderList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_ORDER, params);
  }

  public static fetchAggOrder(id: string, params?: object): Promise<any> {
    return GET(ApiUrl.AGG_ORDER + '/' + id, params);
  }

  public static fetchAggLockerList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_LOCKER, params);
  }

  public static fetchAggHiveList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_HIVE, params);
  }

  public static fetchAggHive(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.AGG_HIVE}/${id}`, params);
  }

  public static fetchAggHistory(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_HISTORY, params);
  }

  public static fetchAggRobot(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.AGG_ROBOT}/${id}`, params);
  }

  public static fetchAggRobotList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_ROBOT, params);
  }

  public static fetchAggItemList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_RETAIL_ITEM, params);
  }

  public static fetchAggTripList(params: object): Promise<any> {
    return GET(ApiUrl.AGG_TRIP, params);
  }

  public static fetchAggCommandList(params: object): Promise<any> {
    return GET(ApiUrl.AGG_COMMAND, params);
  }

  public static fetchAggCommand(id: string, resolveRequester: boolean = false): Promise<any> {
    return GET(`${ApiUrl.AGG_COMMAND}/${id}?resolveRequester=${resolveRequester}`);
  }

  public static fetchAggUserList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_USER, params);
  }

  public static fetchAggUser(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.AGG_USER}/${id}`, params);
  }

  public static fetchAggMessNumberList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_NOTIFICATION, params);
  }

  public static fetchAggAdvertisement(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_ADVERTISEMENT, params);
  }

  public static fetchSchedulingTransit(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_SCHEDULING_TRANSIT, params);
  }

  public static fetchSchedulingHistory(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_SCHEDULING_TRANSIT_HISTORY, params);
  }

  public static fetchAggCheckOrder(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_CHECK_ORDER, params);
  }

  public static fetchAggCheckOrderRecord(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_CHECK_ORDER_RECORD, params);
  }

  public static fetchAggParticipant(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_PARTICIPANT, params);
  }

  public static getExportTable(params?: object, responseType?: any): Promise<any> {
    return GET(ApiUrl.AGG_NOTIFICATION_EXPORT, params, { responseType });
  }

  public static fetchAggTemplate(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_TEMPLATE, params);
  }

  public static fetchAggAddress(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_ADDRESS, params);
  }

  public static fetchAggCheckOrderExcel(params?: object, responseType?: any): Promise<any> {
    return GET(ApiUrl.AGG_CHECK_ORDER_EXPORT, params, { responseType, timeout: 40000 });
  }

  public static fetchAggWaypoint(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_WAYPOINT, params);
  }

  public static fetchAggDock(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_DOCK, params);
  }

  public static fetchAggDockExcel(params?: object, responseType?: any): Promise<any> {
    return GET(ApiUrl.AGG_DOCK_EXCEL, params, { responseType });
  }

  public static fetchAggWaypointExcel(params?: object, responseType?: any): Promise<any> {
    return GET(ApiUrl.AGG_WAYPOINT_EXPORT, params, { responseType });
  }

  public static fetchFeedbackList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_FEEDBACK, params);
  }

  public static fetchAggCompanyPostage(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_COMPANY_POSTAGE, params);
  }

  public static fetchAggOrderInventory(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_ORDER_INVENTORY, params);
  }

  public static fetchExportExcel(url: string, params?: object, responseType?: any): Promise<any> {
    return GET(url, params, { responseType, timeout: 40000 });
  }

  public static fetchAggCollectionList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_DELIVERY_COLLECTION, params);
  }

  public static fetchAggAlertList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_STATUS_ALERT, params);
  }

  public static fetchAggTriggerList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_STATUS_TRIGGER, params);
  }

  public static fetchAggCommentList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_ROBOT_COMMENT, params);
  }

  public static fetchAggRecordList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_MEDIA_RECORD, params);
  }

  public static fetchAggTaskHistory(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_TASK_HISTORY, params);
  }

  public static fetchAggTask(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_TASK, params);
  }

  public static clearCacheWaypoint(id: string): Promise<any> {
    return DELETE(ApiUrl.AGG_CACHE_WAYPOINT);
  }

  public static fetchAggFunctionBoxList(params: object): Promise<any> {
    return GET(ApiUrl.AGG_HIVE, params);
  }

  public static fetchAggDeviceCandlelightList(params: any): Promise<any> {
    return GET(ApiUrl.AGG_HIVE, params);
  }

  public static fetchOrderExport(params: any): Promise<any> {
    return GET('/report/v1/aggregation/order/export', params);
  }
}

import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { CSSProperties, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { EditableContext } from './EditableForm';

import './EditableCell.scss';

interface InternalProps {
  id?: string;
  editing?: boolean;
  editable?: boolean;
  wrapperStyle?: CSSProperties;
  onSave?: () => void;
  renderArea: (form: any) => ReactNode;
  onEdit: (editing: boolean) => void;
}

export const EditableWrapper: FC<InternalProps> = (props) => {
  const { id, editing, editable, onEdit, wrapperStyle, children, renderArea, onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const form = useContext(EditableContext);
  const ref = useRef(null);

  useClickAway(ref, (event: any) => {
    if (visible) {
      onSave && onSave();
      setVisible(false);
    }
  });

  useEffect(() => {
    setVisible(!!editing);
  }, [editing]);

  const handleEdit = () => {
    setVisible(true);
    onEdit(true);
  };

  const renderCell = (form: any) => {
    return visible ? (
      renderArea(form)
    ) : (
      <div className="editable-cell-value-wrap" style={wrapperStyle}>
        {children}
        <Tooltip placement="top" title="编辑" getPopupContainer={(triggerNode: HTMLElement) => triggerNode}>
          <EditOutlined className="editable-cell-value-wrap-i" onClick={handleEdit} />
        </Tooltip>
      </div>
    );
  };

  return (
    <div ref={ref} className="editable-wrapper" id={id}>
      {editable ? renderCell(form) : children}
    </div>
  );
};

export default EditableWrapper;

import { Avatar as AntdAvatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import randomColor from 'randomcolor';
import React, { FC } from 'react';

const avatarColor = randomColor({
  luminosity: 'dark',
  format: 'rgba',
  alpha: 0.5,
});

interface InternalProps extends AvatarProps {
  name?: string;
}

export const Avatar: FC<InternalProps> = (props) => {
  const { size, name, ...restProps } = props;

  return (
    <AntdAvatar size={size || 32} style={{ background: avatarColor }} {...restProps}>
      {name ? name.split('')[0] : ''}
    </AntdAvatar>
  );
};

export default Avatar;

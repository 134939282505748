import { BuildEnv, EnvType } from '../env';

// tslint:disable-next-line: no-var-requires
const { version } = require('../../package.json');

interface LoggerEnvironment {
  local: string;
  development: string;
  test: string;
  production: string;
  staging: string;
}

type Func = (str: string) => boolean;

type IgnoreValue = [string, RegExp, Func] | string | RegExp | Func;

interface Ignore {
  ignoreUrls?: IgnoreValue;
  ignoreApis?: IgnoreValue;
  ignoreErrors?: IgnoreValue;
}

type Environment = 'local' | 'daily' | 'pre' | 'prod' | 'gray';

interface DefaultConfig {
  pid: string; // 项目 id, ARMS 创建站点自动生成
  appType: string; // 监控类型
  imgUrl: string; // 日志上传地址
  release: string; // 应用版本号
  environment: Environment; // 环境
  ignore: Ignore; // 忽略 URL, API, JS错误
}

interface LoggerConfig extends DefaultConfig {
  sendResource?: boolean; // 上报页面静态资源
  enableLinkTrace?: boolean; // 前后端链路追踪
  behavior?: boolean; // 是否为了便于排查错误而记录报错的用户行为
  useFmp?: boolean; // 采集首屏FMP
  enableSPA?: boolean; // 监听页面的 hashchange 事件并重新上报 PV, 适用于单页面应用场景
  parseHash?: (hash: string) => string; // 与 enableSPA 搭配使用
  disabled?: boolean; // 禁用日志上报功能
  autoSendPerf?: boolean; // 是否允许自动发送性能日志
  disableHook?: boolean; // 禁用AJAX请求监听
}

interface Configs {
  local: LoggerConfig;
  development: LoggerConfig;
  test: LoggerConfig;
  production: LoggerConfig;
  staging: LoggerConfig;
}

const loggerEnvironment: LoggerEnvironment = {
  [EnvType.Local]: 'local',
  [EnvType.Test]: 'daily',
  [EnvType.Development]: 'daily',
  [EnvType.Staging]: 'pre',
  [EnvType.Production]: 'prod',
};

const defaultConfig: DefaultConfig = {
  pid: 'd6e25nk4qh@81817fd0198e0ef',
  appType: 'web',
  imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
  release: version,
  environment: loggerEnvironment[BuildEnv] as Environment,
  ignore: {
    // ignoreApis: (str) => !str.includes('ctirobot'), // 只上报请求到后台服务的接口
    ignoreErrors: (str) => {
      return str.includes('ResizeObserver loop limit exceeded');
    }, // 不上报 'ResizeObserver loop limit exceeded'
  },
};

const configs: Configs = {
  [EnvType.Local]: {
    ...defaultConfig,

    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    useFmp: true,
    enableSPA: true,
    disabled: true,
  },
  [EnvType.Development]: {
    ...defaultConfig,

    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    useFmp: true,
    enableSPA: true,
    disabled: true,
  },
  [EnvType.Test]: {
    ...defaultConfig,

    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    useFmp: true,
    enableSPA: true,

    disabled: true,
  },
  [EnvType.Production]: {
    ...defaultConfig,

    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    useFmp: true,
    enableSPA: true,
  },
  [EnvType.Staging]: {
    ...defaultConfig,

    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    useFmp: true,
    enableSPA: true,
  },
};

// tslint:disable-next-line: no-var-requires
const BrowserLogger = require('alife-logger');
BrowserLogger.singleton(configs[BuildEnv]);

import { Col, Form, Input, Row, Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { ChangeEvent, FC, useEffect } from 'react';

import { Dispatch } from '../../../models/dispatch';
import { filterOption, splitQuery, transformDataSource } from '../../../utils';
import { DeliveryMethod, DeliveryMethodOption, OrderStateOption } from '../../../utils/constants';
import { OrderParams } from '../models/typed';

const { Item } = Form;
const { Search } = Input;
const { Option } = Select;
const deliveryMethods = [DeliveryMethod.MANUAL, DeliveryMethod.HUB, DeliveryMethod.HIVE];

interface DvaProps {
  buildingOptions: any[];
  companyOptions: DataSourceItemObject[];
  fetchCompanyOption: (params?: object) => void;
  fetchBuildingOption: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  query: OrderParams;
  onSearchChange: (params: OrderParams) => void;
}

export const WarehousingOrderHeader: FC<InternalProps> = (props) => {
  const { query, companyOptions, fetchCompanyOption, fetchBuildingOption, onSearchChange } = props;

  useEffect(() => {
    fetchCompanyOption();
    fetchBuildingOption();
  }, [fetchCompanyOption, fetchBuildingOption]);

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递单号">
            <Search
              allowClear
              placeholder="请输入快递单号"
              defaultValue={get(query, 'orderNumber', '')}
              onSearch={(value: string) => onSearchChange({ orderNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ orderNumber: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="包裹号">
            <Search
              allowClear
              placeholder="请输入包裹号"
              defaultValue={get(query, 'shelfNumber', '')}
              onSearch={(value: string) => onSearchChange({ shelfNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ shelfNumber: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递公司">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择快递公司"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'companyId')}
              onChange={(value: string[]) => onSearchChange({ companyId: value.join() })}
            >
              {companyOptions.map((item: DataSourceItemObject, index: number) => (
                <Option value={item.value} key={index}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="订单状态">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择订单状态"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'orderState')}
              onChange={(value: string[]) => onSearchChange({ orderState: value.length > 0 ? value.join() : '' })}
            >
              {OrderStateOption.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="配送方式">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择配送方式"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'deliveryMethod')}
              onChange={(value: string[]) =>
                onSearchChange({ deliveryMethod: value.length > 0 ? value.join() : deliveryMethods.join() })
              }
            >
              {DeliveryMethodOption.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ common }: any) => ({
  buildingOptions: common.buildingOption,
  companyOptions: transformDataSource(common.companyOption),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompanyOption: (params?: object) => {
    dispatch({
      type: 'common/fetchCompanyOption',
      payload: params,
    });
  },
  fetchBuildingOption: (params?: object) => {
    dispatch({
      type: 'common/fetchBuildingOption',
      payload: params,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WarehousingOrderHeader);

import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const SendOrderReconciliation = Loadable.Map({
  loader: {
    SendOrderReconciliation: () => import('./SendOrderReconciliation'),
    model: () => import('./models/SendOrderReconciliation'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendOrderReconciliation = loaded.SendOrderReconciliation.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendOrderReconciliation {...props} />
      </Loading>
    );
  },
});

const OrderReconciliation = Loadable.Map({
  loader: {
    OrderReconciliation: () => import('./OrderReconciliation'),
    model: () => import('./models/OrderReconciliation'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const OrderReconciliation = loaded.OrderReconciliation.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <OrderReconciliation {...props} />
      </Loading>
    );
  },
});

export { SendOrderReconciliation, OrderReconciliation };

import { Col, Form, Input, Row, Select } from 'antd';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { useMedia } from 'react-use';

import { Dispatch } from '../../../models/dispatch';
import { filterOption, splitQuery } from '../../../utils/utils';

const { Item } = Form;
const { Search } = Input;
const { Option } = Select;

interface DvaProps {
  buildingOptions: any[];
  areaOptions: any[];
  fetchAreaOption: (params?: object) => void;
  fetchBuildingOption: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  query: any;
  onSearchChange: (params: any) => void;
}

export const ManualOrderHeader: FC<InternalProps> = (props) => {
  const { query, areaOptions, buildingOptions, fetchAreaOption, fetchBuildingOption, onSearchChange } = props;

  const isPC = useMedia('(min-width: 1920px)');

  useEffect(() => {
    fetchAreaOption();
    fetchBuildingOption();
  }, [fetchAreaOption, fetchBuildingOption]);

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="片区">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择片区"
              maxTagCount={isPC ? 4 : 2}
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'areaId')}
              onChange={(value: string[]) => onSearchChange({ areaId: value.join() })}
            >
              {areaOptions.map((item: any, index: number) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="楼栋">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择楼栋"
              maxTagCount={isPC ? 4 : 2}
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'buildingId')}
              onChange={(value: string[]) => onSearchChange({ buildingId: value.join() })}
            >
              {buildingOptions.map((item: any, index: number) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递员">
            <Search
              allowClear
              placeholder="请输入快递员姓名和电话"
              defaultValue={get(query, 'keyword', '')}
              onSearch={(value: string) => onSearchChange({ keyword: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ keyword: e.target.value })}
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ common }: any) => ({
  buildingOptions: common.buildingOption,
  areaOptions: common.areaOption,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAreaOption: (params?: object) => {
    dispatch({
      type: 'common/fetchAreaOption',
      payload: params,
    });
  },
  fetchBuildingOption: (params?: object) => {
    dispatch({
      type: 'common/fetchBuildingOption',
      payload: params,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualOrderHeader);

import { Form, Modal, Select } from 'antd';
import { connect } from 'dva';
import React, { FC, useEffect, useState } from 'react';
import { Dispatch } from '../../../models/dispatch';
import { RobotEquipmentOption } from '../../../utils/constants';

const { Option } = Select;

interface DvaProps {
  softwareList: any[];
}

interface InternalProps extends DvaProps {
  visible: boolean;
  record?: any;
  entityType?: string;
  onCancel: () => void;
  fetchSoftware: (params?: object) => void;
  onSubmit: (params?: object) => void;
}

export const RobotUpgradeModal: FC<InternalProps> = (props) => {
  const { record, visible, onCancel, onSubmit, entityType, softwareList, fetchSoftware } = props;
  const [softwareType, setSoftwareType] = useState<string>();
  const [form] = Form.useForm();
  const { resetFields, setFieldsValue, validateFields } = form;

  useEffect(() => {
    if (record) {
      setFieldsValue(record);
    }
  }, [setFieldsValue, record]);

  useEffect(() => {
    if (softwareType) {
      const params = { entityType, softwareType };
      fetchSoftware(params);
    }
  }, [entityType, fetchSoftware, softwareType]);

  const handleSubmit = async () => {
    const { softwareId } = await validateFields();
    const data = {
      softwareId,
      entityType,
    };

    onSubmit(data);
  };

  return (
    <Modal
      centered
      forceRender
      destroyOnClose={false}
      getContainer={false}
      title="选择升级版本"
      visible={visible}
      closable={false}
      onOk={handleSubmit}
      onCancel={onCancel}
      afterClose={resetFields}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item name="softwareType" label="固件类型" rules={[{ required: true, message: '请选择固件类型!' }]}>
          <Select
            showSearch
            placeholder="请选择固件类型"
            onChange={(value: string) => setSoftwareType(value)}
            filterOption={false}
          >
            {RobotEquipmentOption
              ? RobotEquipmentOption.map((item: any, index: number) => (
                  <Option value={item.value} key={index}>
                    {item.label}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
        <Form.Item name="softwareId" label="升级版本" rules={[{ required: true, message: '请选择版本!' }]}>
          <Select showSearch placeholder="请选择版本" filterOption={false}>
            {softwareList
              ? softwareList.map((item: any, index: number) => (
                  <Option value={item.id} key={index}>
                    {item.version}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ robotList }: any) => {
  return {
    softwareList: robotList && robotList.softwareList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchSoftware: (params?: object) => {
      dispatch({
        type: 'robotList/fetchSoftware',
        payload: { params },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RobotUpgradeModal);

import { ScenarioType } from '../utils/constants';
import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class MapService {
  public static fetchMapBlockList(params?: object): Promise<any> {
    return GET(ApiUrl.MAP_BLOCK, params);
  }

  public static fetchHubList(params?: object): Promise<any> {
    return GET(ApiUrl.MAP_HUB, params);
  }

  public static fetchHub(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.MAP_HUB}/${id}`, params);
  }

  public static fetchHubScenarioConfig(id: string, scenarioType: ScenarioType, params?: object): Promise<any> {
    return GET(`${ApiUrl.MAP_HUB}/${id}/${scenarioType}/configuration`, params);
  }

  public static fetchHubConfig(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.MAP_HUB}/${id}/configuration`, params);
  }

  public static patchHubConfig(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.MAP_HUB}/${id}/configuration`, params);
  }

  public static patchHubScenarioConfig(id: string, scenarioType: ScenarioType, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.MAP_HUB}/${id}/${scenarioType}/configuration`, params);
  }

  public static patchHub(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.MAP_HUB + '/' + id, params);
  }

  public static postHub(params?: object): Promise<any> {
    return POST(ApiUrl.MAP_HUB, params);
  }

  public static deleteHub(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.MAP_HUB + '/' + id, params);
  }

  public static fetchBuildingList(params?: object): Promise<any> {
    return GET(ApiUrl.MAP_BUILDING, params);
  }

  public static fetchBuilding(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.MAP_BUILDING}/${id}`, params);
  }

  public static patchBuilding(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.MAP_BUILDING + '/' + id, params);
  }

  public static postBuilding(params?: object): Promise<any> {
    return POST(ApiUrl.MAP_BUILDING, params);
  }

  public static deleteBuilding(id: string): Promise<any> {
    return DELETE(ApiUrl.MAP_BUILDING + '/' + id);
  }

  public static fetchFloorList(params?: object): Promise<any> {
    return GET(ApiUrl.MAP_FLOOR, params);
  }

  public static fetchFloor(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.MAP_FLOOR}/${id}`, params);
  }

  public static patchFloor(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.MAP_FLOOR + '/' + id, params);
  }

  public static postFloor(params?: object): Promise<any> {
    return POST(ApiUrl.MAP_FLOOR, params);
  }

  public static deleteFloor(id: string): Promise<any> {
    return DELETE(ApiUrl.MAP_FLOOR + '/' + id);
  }

  public static fetchWaypointList(params?: any): Promise<any> {
    return GET(ApiUrl.MAP_WAYPOINT, params);
  }

  public static fetchWaypoint(id: string, params?: any): Promise<any> {
    return GET(`${ApiUrl.MAP_WAYPOINT}/${id}`, params);
  }

  public static searchWaypoint(params?: any): Promise<any> {
    return GET(`${ApiUrl.MAP_WAYPOINT}/suggest`, params);
  }

  public static patchWaypoint(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.MAP_WAYPOINT + '/' + id, params);
  }

  public static postWaypoint(params?: object): Promise<any> {
    return POST(ApiUrl.MAP_WAYPOINT, params);
  }

  public static deleteWaypoint(id: string): Promise<any> {
    return DELETE(ApiUrl.MAP_WAYPOINT + '/' + id);
  }

  public static fetchStreetOptions(params?: object): Promise<any> {
    return GET(ApiUrl.CONNECTOR_GET_STREET_OPTIONS, params);
  }
}

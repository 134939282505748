import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const DeviceGateManage = Loadable.Map({
  loader: {
    DeviceGateManage: () => import('./DeviceGateManage'),
    model: () => import('./models/DeviceGateManage'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceGateManage = loaded.DeviceGateManage.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceGateManage {...props} />
      </Loading>
    );
  },
});

const DeviceGateManageDetail = Loadable.Map({
  loader: {
    DeviceGateManageDetail: () => import('./DeviceGateManageDetail'),
    model: () => import('./models/DeviceGateManage'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceGateManageDetail = loaded.DeviceGateManageDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceGateManageDetail {...props} />
      </Loading>
    );
  },
});

export { DeviceGateManage, DeviceGateManageDetail };

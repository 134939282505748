import { Layout } from 'antd';
import React, { FC, ReactNode } from 'react';

interface InternalProps {
  isMobile?: boolean;
  children?: ReactNode;
}

export const Content: FC<InternalProps> = (props) => {
  const { isMobile, children } = props;
  const styles = {
    minHeight: 'auto',
    paddingTop: isMobile ? 64 : 0,
  };

  return <Layout.Content style={styles}>{children}</Layout.Content>;
};

export default Content;

import { ApiUrl } from './ApiConfig';
import { DELETE, GET, POST, PUT } from './AxiosInstance';

export class FaceService {
  public static createFace(params: object): Promise<any> {
    return POST(ApiUrl.GUIDE_FACE, params);
  }

  public static deleteFace(id: string): Promise<any> {
    return DELETE(`${ApiUrl.GUIDE_FACE}/${id}`);
  }

  public static updateFace(id: string, params: object): Promise<any> {
    return PUT(`${ApiUrl.GUIDE_FACE}/${id}`, params);
  }

  public static fetchFaceList(params: object): Promise<any> {
    return GET(ApiUrl.GUIDE_FACE, params);
  }

  public static getFaceImgUrl(params: object): Promise<any> {
    return GET(ApiUrl.GUIDE_FACE_IMG_URL, params);
  }
}

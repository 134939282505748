import { Link } from 'dva/router';
import React, { FC } from 'react';

import styles from './Logo.module.scss';

interface InternalProps {
  collapsed?: boolean;
}

const Logo: FC<InternalProps> = (props) => {
  return (
    <div className={styles.logo}>
      <Link to="/app">
        <img src={`${process.env.PUBLIC_URL}/images/candela.png`} alt="Candela" />
        {!props.collapsed && <img src={`${process.env.PUBLIC_URL}/images/candela.svg`} alt="Candela" />}
      </Link>
    </div>
  );
};

export default Logo;

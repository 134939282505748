import { Col, Form, Input, Row } from 'antd';
import { get } from 'lodash';
import React, { ChangeEvent, FC } from 'react';

import { SendOrderParams } from '../models/typed';

const { Item } = Form;
const { Search } = Input;

interface InternalProps {
  query: SendOrderParams;
  onSearchChange: (params: SendOrderParams) => void;
}

export const ExceptionOrderHeader: FC<InternalProps> = (props) => {
  const { query, onSearchChange } = props;

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="寄件人">
            <Search
              allowClear
              placeholder="请输入寄件人姓名或电话"
              defaultValue={get(query, 'sender', '')}
              onSearch={(value: string) => onSearchChange({ sender: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ sender: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="收件人">
            <Search
              allowClear
              placeholder="请输入收件人姓名或电话"
              defaultValue={get(query, 'receiver', '')}
              onSearch={(value: string) => onSearchChange({ receiver: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ receiver: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递单号">
            <Search
              allowClear
              placeholder="请输入快递单号"
              defaultValue={get(query, 'orderNumber', '')}
              onSearch={(value: string) => onSearchChange({ orderNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ orderNumber: e.target.value })}
            />
          </Item>
        </Col>

        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递员">
            <Search
              allowClear
              placeholder="请输入快递员"
              defaultValue={get(query, 'courier', '')}
              onSearch={(value: string) => onSearchChange({ courier: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ courier: e.target.value })}
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ExceptionOrderHeader;

import { message } from 'antd';
import UUID from 'uuidjs';
import {
  AggregationService,
  DeliveryService,
  HiveService,
  MapService,
  RetailService,
  RobotService,
  UaaService,
  UserService,
} from '../services';
import { UploadConfig } from '../utils/bucketTool';
import { summary, TriggerType } from '../utils/constants';
import { Action, Effects } from './dispatch';
import { Summary } from './typed.d';

interface CommonParams {
  pageSize: number;
  deleted: boolean;
}

interface CommonState {
  notices: any[];
  hasMore: boolean;
  currentPath: string;
  floorOption: any[];
  waypointOption: any[];
  buildingOption: any[];
  mapBlockOption: any[];
  companyOption: any[];
  hubOption: any[];
  userOption: any[];
  addressOption: any[];
  merchantOption: any[];
  categoryOption: any[];
  itemOption: any[];
  hiveOption: any[];
  robotOption: any[];
  elevatorOption: any[];
  dockOption: any[];
  bayList: any[];
  lockerOption: any[];
  areaOption: any[];
  courierOption: any[];
  layoutOption: any[];
  roleTemplateOption: any[];
  commonParams: CommonParams;
  summary: Summary;
  mingleStatus: any;
  [otherProps: string]: any;
}

const initialState: CommonState = {
  notices: [],
  hasMore: false,
  currentPath: '',
  floorOption: [],
  layoutOption: [],
  waypointOption: [],
  buildingOption: [],
  mapBlockOption: [],
  companyOption: [],
  hubOption: [],
  userOption: [],
  dockOption: [],
  bayList: [],
  addressOption: [],
  merchantOption: [],
  categoryOption: [],
  itemOption: [],
  hiveOption: [],
  robotOption: [],
  elevatorOption: [],
  lockerOption: [],
  areaOption: [],
  courierOption: [],
  roleTemplateOption: [],
  mingleStatus: {
    [TriggerType.OFFLINE]: false,
    [TriggerType.LOCATION]: false,
    [TriggerType.BATTERY]: false,
    [TriggerType.EMERGENCY_STOP]: false,
    [TriggerType.TIMEOUT]: false,
    [TriggerType.OVER_FREQUENCY]: false,
    [TriggerType.CHARGING]: false,
    [TriggerType.LOW_LEVEL]: false,
  },
  commonParams: {
    pageSize: 200,
    deleted: false,
  },
  summary,
};

export default {
  namespace: 'common',

  state: initialState,

  effects: {
    *fetchMerchantList(action: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...action.payload,
        };
        const response = yield call(RetailService.fetchRetailMerchant, params);
        yield put({ type: 'loadCommonState', payload: { merchantOption: response.data } });
      } catch (error) {
        throw error;
      }
    },

    *fetchCategoryList(action: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...action.payload,
        };
        const response = yield call(RetailService.fetchRetailCategory, params);
        const { data } = response;

        if (Array.isArray(data)) {
          data.forEach((item: any, index: number) => {
            item.key = index + 1;
          });
          yield put({ type: 'loadCommonState', payload: { categoryOption: data } });
        }
      } catch (error) {
        throw error;
      }
    },

    *fetchRetailItemList({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...payload,
        };
        const response = yield call(RetailService.fetchRetailItem, params);
        const { data } = response;

        if (Array.isArray(data)) {
          data.forEach((item: any, index: number) => {
            item.key = index + 1;
          });
          yield put({
            type: 'loadCommonState',
            payload: { itemOption: data },
          });
        }
      } catch (error) {
        throw error;
      }
    },

    *fetchWaypointOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          selectable: true,
          sort: 'name,desc',
          ...commonParams,
          ...payload,
        };
        const response = yield call(MapService.fetchWaypointList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            waypointOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchMapBlockList({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...payload,
        };
        const response = yield call(MapService.fetchMapBlockList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            mapBlockOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchBuildingOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select(({ common }: any) => common);
        const { currentHub } = yield select(({ global }: any) => global);
        const params = {
          mapBlockId: currentHub.mapBlockId,
          sort: 'name,desc',
          ...commonParams,
          ...payload,
        };
        const response = yield call(MapService.fetchBuildingList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            buildingOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchFloorList({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          sort: 'name,desc',
          ...commonParams,
          ...payload,
        };
        const response = yield call(MapService.fetchFloorList, params);
        yield put({
          type: 'loadCommonState',
          payload: {
            floorOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchCompanyOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...payload,
        };
        const response = yield call(DeliveryService.fetchCompanyList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            companyOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchRoleTemplateOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...payload,
          deleted: false,
        };
        const response = yield call(UaaService.fetchRoleTemplate, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            roleTemplateOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchHubOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...payload,
          selectable: true,
          test: false,
        };

        const response = yield call(MapService.fetchHubList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            hubOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchUserOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const { currentHub } = yield select(({ global }: any) => global);
        const params = {
          hubId: currentHub.id,
          ...commonParams,
          ...payload,
        };
        const response = yield call(UserService.fetchUserList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            userOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchAddressOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...payload,
        };

        const response = yield call(UserService.fetchAddressList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            addressOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchHiveOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select(({ common }: any) => common);
        const { currentHub } = yield select(({ global }: any) => global);
        const params = {
          ...commonParams,
          ...payload,
          hubId: currentHub.id,
        };
        const response = yield call(HiveService.fetchHiveList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            hiveOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchRobotOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select(({ common }: any) => common);
        const { currentHub } = yield select(({ global }: any) => global);
        const params = {
          hubId: currentHub.id,
          ...commonParams,
          ...payload,
        };
        const response = yield call(RobotService.fetchRobotList, params);

        yield put({
          type: 'loadCommonState',
          payload: { robotOption: response.data },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchElevatorOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select(({ common }: any) => common);
        const { currentHub } = yield select(({ global }: any) => global);
        const params = {
          hubId: currentHub.id,
          ...commonParams,
          ...payload,
        };
        const response = yield call(RobotService.fetchElevatorList, params);

        yield put({
          type: 'loadCommonState',
          payload: { elevatorOption: response.data },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchAreaOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select(({ common }: any) => common);
        const { currentHub } = yield select(({ global }: any) => global);
        const params = {
          hubId: currentHub.id,
          ...commonParams,
          ...payload,
        };
        const response = yield call(DeliveryService.fetchAreaList, params);

        yield put({
          type: 'loadCommonState',
          payload: { areaOption: response.data },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchDockOption(action: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...action.payload,
        };

        const response = yield call(RobotService.fetchDockList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            dockOption: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchBayOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...payload,
        };
        const response = yield call(RobotService.fetchBayList, params);

        yield put({
          type: 'loadCommonState',
          payload: {
            bayList: response.data,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchOrderSummary({ payload }: Action, { put, select, call }: Effects) {
      try {
        const { currentHub } = yield select(({ global }: any) => global);
        const params = {
          ...payload,
          test: false,
          hubId: currentHub.id,
        };
        const response = yield call(DeliveryService.fetchOrderTotalSummary, params);
        const { data } = response;

        if (data) {
          yield put({ type: 'loadCommonState', payload: { summary: data } });
        }
      } catch (error) {
        throw error;
      }
    },

    *fetchCourierOption({ payload }: Action, { put, call, select }: Effects) {
      try {
        const { currentHub } = yield select(({ global }: any) => global);
        const { commonParams } = yield select((state: any) => state.common);
        const params = {
          ...commonParams,
          ...payload,
        };
        const response = yield call(UaaService.fetchRoles, {
          hubId: currentHub.id,
          roleIdentifier: 'COURIER',
        });

        if (response?.data?.length) {
          const { data } = response;
          const roleIds = data.map((d: any) => d.id);
          const res = yield call(UserService.fetchUserList, { roleId: roleIds + '', hubId: currentHub.id, ...params });

          if (res?.data?.length) {
            yield put({
              type: 'loadCommonState',
              payload: {
                courierOption: res.data,
              },
            });
          } else {
            message.error('该园区没有快递员');
          }
        } else {
          message.error('该园区没有快递员');
        }
      } catch (error) {
        throw error;
      }
    },

    *fetchNotifications({ payload }: Action, { call, put, select }: Effects) {
      try {
        const { page } = payload;
        const notices = yield select(({ global }: any) => global.notices);
        const response = yield call(DeliveryService.fetchDeliveryOrders, {
          page,
          pageSize: 10,
          requested: true,
          unapproved: true,
        });

        if (!response || !(Array.isArray(response.data) && response.data.length)) {
          return;
        }

        const { data } = response;
        const hasMore = data.length < 10 ? false : true;

        yield put({
          type: 'loadCommonState',
          payload: {
            notices: notices.concat(data),
            hasMore,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    *fetchExportExcel({ payload: { url, params, msg } }: Action, { call, select }: Effects) {
      try {
        if (!url) {
          throw new Error('param url must be required in payload');
        }

        if (!msg) {
          throw new Error('param msg must be required in payload');
        }

        const { currentHub } = yield select(({ global }: any) => global);
        const res = yield call(
          AggregationService.fetchExportExcel,
          url,
          { ...params, hubId: currentHub.id },
          'arraybuffer'
        );

        const el = document.createElement('a');
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });

        el.href = URL.createObjectURL(blob);
        el.setAttribute('download', `${msg}报表.xls`);
        el.click();
      } catch (error) {
        throw error;
      }
    },

    *uploadFile({ payload }: Action, { call, put }: Effects) {
      try {
        const { name } = payload;
        const key = UploadConfig.dir + UUID.genV4() + encodeURI(name);
        const response = yield call(UaaService.fetchOssInfoStsToken, {
          bucketName: UploadConfig.deviceOTA,
          type: 'OSS',
        });
        const { data: ossInfo } = response;

        if (ossInfo) {
          yield put({ type: 'fileUpload/uploadFile', payload: { key, ossInfo, file: payload } });
        }
      } catch (error) {
        throw error;
      }
    },

    *fetchHiveLayout({ payload }: Action, { put, call }: Effects) {
      try {
        const response = yield call(RobotService.fetchHiveLayout, payload);
        const { data } = response;

        if (data) {
          yield put({ type: 'loadCommonState', payload: { hiveLayout: data } });
        }
      } catch (error) {
        throw error;
      }
    },

    *fetchStreetOptions({ payload }: Action, { put, call }: Effects) {
      try {
        const response = yield call(MapService.fetchStreetOptions, payload);
        const { data } = response;
        return data;
      } catch (error) {
        throw error;
      }
    },
  },

  reducers: {
    loadCommonState(state: CommonState, { payload }: Action): CommonState {
      return { ...state, ...payload };
    },

    saveCurrentPath(state: CommonState, { payload }: Action): CommonState {
      return { ...state, currentPath: payload };
    },

    showOrHideModal(state: CommonState, { key, payload }: Action): CommonState {
      return { ...state, [key]: payload };
    },

    showOrHideAlarm(state: CommonState, { mingleStatus, key, payload }: Action): CommonState {
      return {
        ...state,
        mingleStatus: { ...mingleStatus, [key]: payload },
      };
    },

    initState(state: CommonState, { pathname, payload }: Action): CommonState {
      if (state.currentPath !== pathname) {
        return payload;
      }
      return state;
    },
  },
  // tslint:disable-next-line: max-file-line-count
};

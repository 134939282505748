import { notification } from 'antd';

// API 请求错误代码消息提示
export const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

export const errorHandle = (error: any, dispatch: any) => {
  const { response } = error;

  if (response) {
    const { status, data } = response;
    const errorText = data.error || codeMessage[status];

    if (status === 401) {
      notification.error({ message: errorText });
      dispatch({ type: 'global/logout' });
      return;
    }

    notification.error({
      message: `请求错误 ${status}`,
      description: errorText,
    });

    // if (status === 403) {
    //   dispatch(routerRedux.push('/app/exception/403'));
    //   return;
    // }

    // if (status <= 504 && status >= 500) {
    //   dispatch(routerRedux.push('/app/exception/500'));
    //   return;
    // }

    // if (status >= 404 && status < 422) {
    //   dispatch(routerRedux.push('/app/exception/404'));
    // }
  } else {
    notification.error({
      message: `请求错误`,
      description: '网络不佳！',
    });
  }
};

export default errorHandle;

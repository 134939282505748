import { Col, Form, Input, Row, Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { ChangeEvent, FC, useEffect } from 'react';

import { Dispatch } from '../../../models/dispatch';
import { filterOption, splitQuery, transformDataSource } from '../../../utils';
import { PayStatusOptions } from '../../../utils/constants';
import { SendOrderParams } from '../models/typed';

const { Item } = Form;
const { Search } = Input;
const { Option } = Select;

interface DvaProps {
  companyOptions: DataSourceItemObject[];
  fetchCompanyOption: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  query: SendOrderParams;
  onSearchChange: (params: SendOrderParams) => void;
}

export const ToSendOrderHeader: FC<InternalProps> = (props) => {
  const { query, fetchCompanyOption, companyOptions, onSearchChange } = props;

  useEffect(() => {
    fetchCompanyOption();
  }, [fetchCompanyOption]);

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="寄件人">
            <Search
              allowClear
              placeholder="请输入寄件人姓名和电话"
              defaultValue={get(query, 'sender')}
              onSearch={(value: string) => onSearchChange({ sender: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ sender: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="收件人">
            <Search
              allowClear
              placeholder="请输入收件人姓名和电话"
              defaultValue={get(query, 'receiver')}
              onSearch={(value: string) => onSearchChange({ receiver: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ receiver: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递单号">
            <Search
              allowClear
              placeholder="请输入快递单号"
              defaultValue={get(query, 'orderNumber')}
              onSearch={(value: string) => onSearchChange({ orderNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ orderNumber: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递公司">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择快递公司"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'companyId')}
              onChange={(value: string[]) => onSearchChange({ companyId: value.join() })}
            >
              {companyOptions.map((item: DataSourceItemObject, index: number) => (
                <Option value={item.value} key={index}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="支付状态">
            <Select
              allowClear
              placeholder="请选择支付状态"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={get(query, 'paid')}
              onChange={(value: string) => onSearchChange({ paid: value })}
            >
              {PayStatusOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ common }: any) => ({
  companyOptions: transformDataSource(common.companyOption),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompanyOption: (params?: object) => {
    dispatch({
      type: 'common/fetchCompanyOption',
      payload: params,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ToSendOrderHeader);

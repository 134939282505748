import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const RemoteMaintenanceScreen = Loadable.Map({
  loader: {
    RemoteMaintenanceScreen: () => import('./RemoteMaintenanceScreen'),
    model: () => import('./models/RemoteMaintenance'),
    deviceModel: () => import('../DeviceCenter/models/DeviceMonitor'),
    // monitorModel: () => import('../Robot/models/MonitorCenter'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RemoteMaintenanceScreen = loaded.RemoteMaintenanceScreen.default;
    const model = loaded.model.default;
    const deviceModel = loaded.deviceModel.default;

    registerModel(props.app, model);

    registerModel(props.app, deviceModel);

    return (
      <Loading>
        <RemoteMaintenanceScreen {...props} />
      </Loading>
    );
  },
});

export { RemoteMaintenanceScreen };

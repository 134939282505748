import { EditOutlined } from '@ant-design/icons';
import { Cascader, Form, Input, InputNumber, Select, Tooltip } from 'antd';
import { CascaderOptionType, CascaderValueType } from 'antd/lib/cascader';
import { SelectValue } from 'antd/lib/select';
import React, { Component, CSSProperties, FormEvent } from 'react';

import { diffData, transformValues } from '../../utils';
import { EditableContext } from './EditableForm';

import './EditableCell.scss';

const { Option } = Select;
const filter = (inputValue: string, path: any[]) => {
  return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};

export enum InputType {
  number = 'number',
  cascader = 'cascader',
  select = 'select',
  switch = 'switch',
}

interface InternalProps {
  record: any;
  index?: number;
  title?: string;
  options?: any;
  dataIndex: any;
  className?: string;
  editable: boolean;
  required?: boolean;
  pattern?: RegExp;
  style?: CSSProperties;
  selectOptions?: any[];
  cascaderOptions?: any[];
  inputType?: 'number' | 'cascader' | 'select' | 'switch';
  handleSave: (params: any) => void;
}

interface InternalState {
  editing: boolean;
}

class EditableCell extends Component<InternalProps, InternalState> {
  private input: any = null;
  private form: any | null = null;

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  public toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing && this.input) {
        // FIXME: 加下面一句一键清空无效
        this.input.focus();
      }
    });
  };

  public getInputType = () => {
    const { inputType, selectOptions, cascaderOptions, options } = this.props;
    const selectData = selectOptions || options;
    const cascaderData = cascaderOptions || options;

    if (inputType === InputType.number) {
      return <InputNumber onBlur={this.save} onPressEnter={this.save} min={0} ref={(node) => (this.input = node)} />;
    } else if (inputType === InputType.select && selectData) {
      return (
        <Select
          autoFocus
          defaultOpen
          onBlur={this.toggleEdit}
          style={{ minWidth: 120 }}
          onChange={this.handleSelect}
          ref={(node) => (this.input = node)}
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        >
          {selectData.map((item: any, index: number) => (
            <Option value={item.value} key={index} disabled={item.disabled}>
              {item.label}
            </Option>
          ))}
        </Select>
      );
    } else if (inputType === InputType.cascader && cascaderData) {
      return (
        <Cascader
          allowClear
          showSearch={{ filter }}
          options={cascaderData}
          onChange={this.onCascaderChange}
          ref={(node) => (this.input = node)}
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        />
      );
    }
    return (
      <Input
        ref={(node) => (this.input = node)}
        allowClear
        onPressEnter={this.save}
        onBlur={this.save}
        placeholder="请输入"
      />
    );
  };

  public save = async (e: FormEvent) => {
    const form = this.form;
    const { record, handleSave } = this.props;

    if (form) {
      const values = await form.validateFields();

      this.toggleEdit();

      if (diffData(record, values)) {
        const _val = transformValues(values);
        handleSave({ id: record ? record.id : null, ..._val });
      }
    }
  };

  public handleSelect = async (value: SelectValue) => {
    const form = this.form;
    const { record, dataIndex, handleSave } = this.props;

    if (form && !Array.isArray(dataIndex)) {
      await form.validateFields();
      const params = { [dataIndex]: value || null };

      if (diffData(record, params)) {
        handleSave({ id: record.id, ...params });
      }

      this.toggleEdit();
    }
  };

  public onCascaderChange = async (value: CascaderValueType, selectedOptions?: CascaderOptionType[]) => {
    const form = this.form;
    const { dataIndex, handleSave } = this.props;

    if (form && Array.isArray(dataIndex)) {
      await form.validateFields();

      let params: any;
      dataIndex.forEach((d: string, i: number) => {
        params = {
          ...params,
          [d]: value[i] || null,
        };
      });

      handleSave(params);
      this.toggleEdit();
    }
  };

  public getInitialValue = () => {
    const { dataIndex, record } = this.props;

    if (Array.isArray(dataIndex)) {
      return dataIndex.map((d: any) => record[d]);
    }

    return (record && record[dataIndex]) || null;
  };

  public getRules = () => {
    const { title, pattern, required, inputType } = this.props;
    const rules: any = [{ required, message: `${title}必填项！` }];

    if (required && inputType !== InputType.cascader) {
      rules.push({ pattern, message: `请输入正确的${title}` });
    }

    return rules;
  };

  public renderCell = (form: any) => {
    const { children, dataIndex } = this.props;
    const { editing } = this.state;
    const field = Array.isArray(dataIndex) ? dataIndex.join() : dataIndex;
    const initialValue = this.getInitialValue();

    this.form = form;
    form.setFieldsValue({ [field]: initialValue });

    return editing ? (
      <Form.Item name={field} style={{ margin: 0 }} rules={this.getRules()}>
        {this.getInputType()}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingLeft: 0, paddingRight: 0 }}>
        {children}
        <Tooltip placement="top" title="编辑" getPopupContainer={(triggerNode: HTMLElement) => triggerNode}>
          <EditOutlined className="editable-cell-value-wrap-i" onClick={this.toggleEdit} />
        </Tooltip>
      </div>
    );
  };

  public render() {
    const { editable, children, style, className } = this.props;

    return (
      <td style={style} className={className}>
        {editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}
      </td>
    );
  }
}

export default EditableCell;

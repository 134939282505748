import React, { PureComponent } from 'react';

import styles from './RadarMap.module.scss';

class RadarMap extends PureComponent {

  public render() {
    return (
      <div className={styles.radarMap}>
        <div className={styles.title}>雷达地图</div>
        <div className={styles.map}>监控地图</div>
      </div>
    );
  }
}

export default RadarMap;

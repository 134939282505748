import gcoord from 'gcoord';

export interface PolarCoordinate {
  angle: number;
  distance: number;
}

export interface Point {
  lng: number;
  lat: number;
}

export interface Coordinate {
  x: number;
  y: number;
}

export interface IdentifiedObject {
  coordinates: [number, number, number]; // [x, y, z] 障碍物体几何中心点的坐标
  dimensions: [number, number, number]; // [w, l, h] 障碍物体的宽、长、高尺寸信息
  polygon: [number, number, number][]; // 障碍物体的形状的边界坐标集合
  label: string; // 障碍物体类型标签
}

export const Shenzhen = { lng: 113.944183, lat: 22.527883 };

/**
 * 将激光雷达数据点，转换为极坐标点集合
 * @param {object} frame 激光雷达数据帧
 * @param {number} angle 机器人的角度
 * @return {PolarCoordinate} 激光数据的极坐标点集合
 */
export function toPolarCoordinates(frame: any, angle: number): PolarCoordinate[] {
  const coordinates: PolarCoordinate[] = [];

  if (!(frame && frame.measurements)) {
    return coordinates;
  }

  for (let i = 0; i < frame.measurements.length; i++) {
    const p: PolarCoordinate = {
      angle: 0,
      distance: 0,
    };

    p.angle = frame.minAngle + i * frame.angleIncrement + angle;
    p.distance = frame.measurements[i];

    if (p.distance > frame.maxRange) {
      p.distance = frame.maxRange;
    }

    if (p.distance < frame.minRange) {
      p.distance = 0;
    }

    coordinates.push(p);
  }

  return coordinates;
}

/**
 * 机器人极坐标点转笛卡尔坐标
 * @param {number} distance 极坐标距离
 * @param {number} angle 极坐标角度
 * @return {Coordinate} 笛卡尔坐标点
 */
export function p2d(distance: number, angle: number): Coordinate {
  return {
    x: Number((Math.cos(angle) * distance).toFixed(2)),
    y: Number((Math.sin(angle) * distance).toFixed(2)),
  };
}

/**
 * 转换slam坐标至地图像素坐标
 * @param {number} x slam坐标x
 * @param {number} y slam坐标y
 * @param {number} height 所在地图高度
 * @param {Coordinate} origin 地图原点信息
 * @param {number} resolution 地图分辨率
 * @return {Coordinate}
 */
export function slam2pixel(x: number, y: number, height: number, origin: Coordinate, resolution: number): Coordinate {
  try {
    if (resolution <= 0) {
      throw new Error('无效的地图分辨率');
    }

    return { x: (x - origin.x) / resolution, y: height - (y - origin.y) / resolution };
  } catch (error) {
    throw error;
  }
}

export function generateMockData(num: number): Coordinate[] {
  const coordinates = [];

  for (let index = 0; index < num; index++) {
    const element = {
      x: Math.ceil(Math.random() * 100),
      y: Math.ceil(Math.random() * 100),
    };
    coordinates.push(element);
  }

  return coordinates;
}

export function getObjectColor(identified: IdentifiedObject): string {
  let color: string = '';

  switch (identified.label) {
    case '0':
      color = '#7d7d7d';
      break;

    case '1':
      color = '#ed3b33';
      break;

    case '2':
      color = '#ed3b33';
      break;

    case '3':
      color = '#f7a946';
      break;

    case '4':
      color = '#0f9beb';
      break;

    default:
      break;
  }

  return color;
}

/**
 * wgs84转百度经纬度
 * @param {Point} point
 * @return {Point}
 */
export function convertToBaidu(point: Point): Point {
  const result = gcoord.transform([point.lng, point.lat], gcoord.WGS84, gcoord.BD09);
  return { lng: result[0], lat: result[1] };
}

/**
 * 百度经纬度转wgs84
 * @param {Point} point
 * @return {Point}
 */
export function convertToWGS84(point: Point): Point {
  const result = gcoord.transform([point.lng, point.lat], gcoord.BD09, gcoord.WGS84);
  return { lng: result[0], lat: result[1] };
}

interface ConvertResult {
  status: number;
  points: Point[];
}

/**
 * from 参数和 to 参数说明 https://lbsyun.baidu.com/index.php?title=webapi/guide/changeposition
 * @param {Point[]} points
 * @param {number} from
 * @param {number} to
 * @return {Promise<ConvertResult>}
 */
export function convertPointAsync(points: Point[], from: number, to: number): Promise<ConvertResult> {
  return new Promise<ConvertResult>((resolve: (value: any) => void, reject: (reason?: any) => void) => {
    const BMap = window.BMap;

    if (BMap) {
      const convertor = new BMap.Convertor();
      convertor?.translate(points, from, to, (result: ConvertResult) => resolve(result));
    } else {
      reject('Please make sure BMap is in window');
    }
  });
}

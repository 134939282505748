import { Col, Form, Input, Row, Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { get } from 'lodash';
import React, { ChangeEvent, FC } from 'react';

import { ChinaCity } from '../../../utils/cityCode';
import {
  DeliveryMethod,
  GoodsTypeOption,
  PaymentMethodOptions,
  PayStatusOptions,
  SendOrderStatusStr,
} from '../../../utils/constants';
import { filterOption, splitQuery } from '../../../utils/utils';
import { SendOrderParams } from '../models/typed';

const { Item } = Form;
const { Search } = Input;
const { Option } = Select;

interface InternalProps {
  query: SendOrderParams;
  companyOptions: DataSourceItemObject[];
  onSearchChange: (params: SendOrderParams) => void;
}

export const SendOrderHeader: FC<InternalProps> = (props) => {
  const { query, companyOptions, onSearchChange } = props;

  const showFlag = query.deliveryMethod !== DeliveryMethod.MANUAL;
  const showHiveFlag = query.deliveryMethod !== DeliveryMethod.HIVE;

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递运单号">
            <Search
              allowClear
              placeholder="请输入快递运单号"
              defaultValue={get(query, 'shipperNumber', '')}
              onSearch={(value: string) => onSearchChange({ shipperNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ shipperNumber: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="快递公司">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择快递公司"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'companyId')}
              onChange={(value: string[]) => onSearchChange({ companyId: value + '' })}
            >
              {companyOptions.map((item: DataSourceItemObject, index: number) => (
                <Option value={item.value} key={index}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="快递状态">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择快递状态"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'orderState')}
              onChange={(value: string[]) => onSearchChange({ orderState: value + '' })}
            >
              {Object.keys(SendOrderStatusStr).map((item: any, index: number) => (
                <Option value={item} key={index}>
                  {SendOrderStatusStr[item]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="支付状态">
            <Select
              allowClear
              placeholder="请选择支付状态"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={get(query, 'paid')}
              onChange={(value: string) => onSearchChange({ paid: value })}
            >
              {PayStatusOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="支付方式">
            <Select
              allowClear
              placeholder="请选择支付方式"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={get(query, 'deliveryFeeType')}
              onChange={(value: string) => onSearchChange({ deliveryFeeType: value })}
            >
              {PaymentMethodOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="收件人">
            <Search
              allowClear
              placeholder="请输入收件人"
              defaultValue={get(query, 'receiver')}
              onSearch={(value: string) => onSearchChange({ receiver: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ receiver: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="寄件人">
            <Search
              allowClear
              placeholder="请输入寄件人"
              defaultValue={get(query, 'sender')}
              onSearch={(value: string) => onSearchChange({ sender: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ sender: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="物品类型">
            <Select
              allowClear
              placeholder="请选择物品类型"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={get(query, 'goodsType')}
              onChange={(value: string) => onSearchChange({ goodsType: value })}
            >
              {GoodsTypeOption.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="目的省份">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择目的省份"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'destination')}
              onChange={(value: string[]) => onSearchChange({ destination: value + '' })}
            >
              {ChinaCity.map((item: any, index: number) => (
                <Option value={item} key={index}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24} style={{ display: showFlag ? 'block' : 'none' }}>
          <Item label="快递柜名称">
            <Search
              allowClear
              placeholder="请输入快递柜名称"
              defaultValue={get(query, 'hiveName')}
              onSearch={(value: string) => onSearchChange({ hiveName: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ hiveName: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24} style={{ display: showHiveFlag ? 'block' : 'none' }}>
          <Item label="快递员">
            <Search
              allowClear
              placeholder="快递员姓名、电话"
              defaultValue={get(query, 'courier')}
              onSearch={(value: string) => onSearchChange({ courier: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ courier: e.target.value })}
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SendOrderHeader;

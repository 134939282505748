import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class UtilityService {

  public static fetchRetailPicture(params?: object): Promise<any> {
    return GET(ApiUrl.UTILITY_IMAGE, params);
  }

  public static patchRetailPicture(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.UTILITY_IMAGE + '/' + id, params);
  }

  public static postRetailPicture(params?: object): Promise<any> {
    return POST(ApiUrl.UTILITY_IMAGE, params);
  }

  public static deleteRetailPicture(id: string): Promise<any> {
    return DELETE(ApiUrl.UTILITY_IMAGE + '/' + id);
  }

  public static postRobotTransfer(id: string, params: object): Promise<any> {
    return POST(`${ApiUrl.UTILITY_ROBOT}/${id}/transfer`, params);
  }

  public static postHiveTransfer(id: string, params: object): Promise<any> {
    return POST(`${ApiUrl.UTILITY_HIVE}/${id}/transfer`, params);
  }
}

import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class RetailService {

  public static fetchRetailRetail(params?: any): Promise<any> {
    return GET(ApiUrl.RETAIL_ORDER, params);
  }

  public static fetchRetailItem(params?: any): Promise<any> {
    return GET(ApiUrl.RETAIL_ITEM, params);
  }

  public static fetchRetailMerchant(params?: any): Promise<any> {
    return GET(ApiUrl.RETAIL_MERCHANT, params);
  }

  public static patchMerchantItem(id: string, params?: any): Promise<any> {
    return PATCH(ApiUrl.RETAIL_MERCHANT + '/' + id, params);
  }

  public static postMerchantItem(params?: any): Promise<any> {
    return POST(ApiUrl.RETAIL_MERCHANT, params);
  }

  public static fetchRetailCategory(params?: any): Promise<any> {
    return GET(ApiUrl.RETAIL_CATEGORY, params);
  }

  public static patchRetailCategory(id: string, params?: any): Promise<any> {
    return PATCH(ApiUrl.RETAIL_CATEGORY + '/' + id, params);
  }

  public static postRetailCategory(params?: any): Promise<any> {
    return POST(ApiUrl.RETAIL_CATEGORY, params);
  }

  public static deleteRetailCategory(id: string): Promise<any> {
    return DELETE(ApiUrl.RETAIL_CATEGORY + '/' + id);
  }

  public static patchRetailItem(id: string, params?: any): Promise<any> {
    return PATCH(ApiUrl.RETAIL_ITEM + '/' + id, params);
  }

  public static postRetailItem(params?: any): Promise<any> {
    return POST(ApiUrl.RETAIL_ITEM, params);
  }

  public static fetchRetailAdvertise(params?: any): Promise<any> {
    return GET(ApiUrl.RETAIL_ADVERTISE, params);
  }

  public static patchRetailAdvertise(id: string, params?: any): Promise<any> {
    return PATCH(ApiUrl.RETAIL_ADVERTISE + '/' + id, params);
  }

  public static postRetailAdvertise(params?: any): Promise<any> {
    return POST(ApiUrl.RETAIL_ADVERTISE, params);
  }

  public static deleteRetailAdvertise(id: string): Promise<any> {
    return DELETE(ApiUrl.RETAIL_ADVERTISE + '/' + id);
  }

  public static fetchRetailInventory(params?: any): Promise<any> {
    return GET(ApiUrl.RETAIL_INVENTORY, params);
  }

  public static postRetailInventory(params?: any): Promise<any> {
    return POST(ApiUrl.RETAIL_INVENTORY, params);
  }

  public static patchRetailInventory(id: string, params?: any): Promise<any> {
    return PATCH(ApiUrl.RETAIL_INVENTORY + '/' + id, params);
  }

  public static fetchRetailInventoryHistory(params?: any): Promise<any> {
    return GET(ApiUrl.AGG_RETAIL_INVENTORY_HISTORY, params);
  }

  public static fetchRetailInventoryQuantity(params?: any): Promise<any> {
    return GET(ApiUrl.RETAIL_INVENTORY_SUMMARY, params);
  }

}

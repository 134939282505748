import { Form, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { get } from 'lodash';
import React, { CSSProperties, FC, useCallback, useState } from 'react';

import { diffData, filterOption, transformValues } from '../../utils';
import EditableWrapper from './EditableWrapper';

const { Item } = Form;
const { Option } = Select;

interface InternalProps extends SelectProps<any> {
  record: any;
  title?: string;
  rules?: any[];
  options: any[];
  editable?: boolean;
  dataIndex: string;
  required?: boolean;
  wrapperStyle?: CSSProperties;
  onSubmit?: (params: object) => void;
}

export const EditableSelect: FC<InternalProps> = (props) => {
  let formContext: any;
  const {
    record,
    title,
    rules,
    editable,
    options,
    dataIndex,
    onSubmit,
    required,
    children,
    wrapperStyle,
    ...otherProps
  } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const validationRules = [{ required, message: `请输入${title}!` }].concat(rules || []);

  const getPopupContainer = useCallback(() => {
    const element = document.getElementById(`select-${dataIndex}`);

    if (element) {
      return element;
    }

    return document.body;
  }, [dataIndex]);

  const handleEdit = (editing: boolean) => {
    setEditing(editing);
  };

  const onSave = async () => {
    if (formContext) {
      const values = await formContext.validateFields();

      if (diffData(record, values)) {
        const _val = transformValues(values);
        onSubmit && onSubmit({ id: record.id, ..._val });
        setEditing(false);
      }
    }
  };

  const renderInput = (form: any) => {
    formContext = form;
    form.setFieldsValue({ [dataIndex]: get(record, dataIndex) });

    return (
      <Item style={{ margin: 0 }} name={dataIndex} rules={validationRules}>
        <Select
          autoFocus
          allowClear
          showSearch
          defaultOpen
          style={{ minWidth: 160 }}
          placeholder={`请选择${title}`}
          filterOption={filterOption}
          getPopupContainer={getPopupContainer}
          {...otherProps}
        >
          {options.map((item: any, index: number) => (
            <Option value={item.value} key={index} disabled={item.disabled}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Item>
    );
  };

  return (
    <EditableWrapper
      editing={editing}
      onSave={onSave}
      editable={editable}
      onEdit={handleEdit}
      renderArea={renderInput}
      wrapperStyle={wrapperStyle}
      id={`select-${dataIndex}`}
    >
      {children}
    </EditableWrapper>
  );
};

export default EditableSelect;

import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class StatusService {
  public static fetchTriggerList(params?: object): Promise<any> {
    return GET(ApiUrl.STATUS_TRIGGER, params);
  }

  public static postTrigger(params?: object): Promise<any> {
    return POST(ApiUrl.STATUS_TRIGGER, params);
  }

  public static patchTrigger(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.STATUS_TRIGGER + '/' + id, params);
  }

  public static deleteTrigger(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.STATUS_TRIGGER + '/' + id, params);
  }

  public static fetchAlertList(params?: object): Promise<any> {
    return GET(ApiUrl.STATUS_ALERT, params);
  }

  public static postAlert(params?: object): Promise<any> {
    return POST(ApiUrl.STATUS_ALERT, params);
  }

  public static patchAlert(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.STATUS_ALERT + '/' + id, params);
  }

  public static deleteAlert(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.STATUS_ALERT + '/' + id, params);
  }

  public static fetchRobotStatus(id: string): Promise<any> {
    return GET(`${ApiUrl.STATUS_ROBOT}/${id}`);
  }

  public static fetchStatusList(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.AGG_STATUS_LIST}/${id}/snapshot`, params);
  }

  public static fetchAlertConfig(params?: object): Promise<any> {
    return GET(ApiUrl.STATUS_ALERT_CONFIG, params);
  }

  public static patchAlertConfig(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.STATUS_ALERT_CONFIG}/${id}`, params);
  }

  public static deleteAlertConfig(id?: string): Promise<any> {
    return DELETE(`${ApiUrl.STATUS_ALERT_CONFIG}/${id}`);
  }

  public static uploadAlertConfig(params: object, force: boolean = true): Promise<any> {
    return POST(`${ApiUrl.STATUS_ALERT_CONFIG_RESOLVE}/${force}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

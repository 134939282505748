import { Col, Form, Input, Modal, Row } from 'antd';
import { get } from 'lodash';
import React, { FC, useEffect } from 'react';

import { IdCard } from '../../../utils/regTool';

interface InternalProps {
  visible: boolean;
  orderInfo: any;
  onCancel: () => void;
  afterClose: () => void;
  onSubmit: (values: any) => void;
}

const ModifyRealNameModal: FC<InternalProps> = (props) => {
  const { visible, onCancel, onSubmit, orderInfo, afterClose } = props;
  const [form] = Form.useForm();
  const { resetFields, validateFields, setFieldsValue } = form;

  useEffect(() => {
    setFieldsValue(get(orderInfo, 'user'));
  }, [setFieldsValue, orderInfo]);

  const handleSubmit = async () => {
    const values = await validateFields();
    const { userId } = orderInfo;

    onSubmit({ ...values, userId });
  };

  const handleAfterClose = () => {
    resetFields();
    afterClose();
  };

  return (
    <Modal
      centered
      forceRender
      destroyOnClose={false}
      getContainer={false}
      title={`修改实名认证信息`}
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      afterClose={handleAfterClose}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="name" label="姓名" rules={[{ required: true, message: '请输入姓名' }]}>
              <Input placeholder="姓名" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="idCardNumber"
              label="身份证号码"
              rules={[
                { required: true, message: '请输入身份证号码' },
                { pattern: IdCard, message: '请输入正确的身份证号码！' },
              ]}
            >
              <Input placeholder="身份证号码" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModifyRealNameModal;

import { HistoryType, HistoryTypeStr } from './constants';

// Change Field enum
export enum ChangeField {
  name = 'name',
  mobile = 'mobile',
  labels = 'labels',
  deliveryDays = 'deliveryDays',
  deliveryMethod = 'deliveryMethod',
  deliveryStartTime = 'deliveryStartTime',
  deliveryEndTime = 'deliveryEndTime',
}

export const ChangeFieldStr = {
  [ChangeField.name]: '姓名',
  [ChangeField.mobile]: '手机号',
  [ChangeField.labels]: '标签',
  [ChangeField.deliveryMethod]: '配送方式',
  [ChangeField.deliveryDays]: '配送方式',
  [ChangeField.deliveryStartTime]: '配送开始时间',
  [ChangeField.deliveryEndTime]: '配送结束时间',
};

export interface Change {
  after: string | any[];
  before: string | any[];
  field: ChangeField;
}

/**
 * 格式化用户日志
 * @param [HistoryType] type
 * @param [Change[]] changes
 * @return [string[]]
 */
export function formatLog(type: HistoryType, changes: Change[]): string[] {
  return changes.map((item: Change, index: number) => {
    if (item.before && Array.isArray(item.before) && Array.isArray(item.after)) {
      return `${ChangeFieldStr[item.field]}由${item.before.length > 0 ? item.before.join() : '空'}${
        HistoryTypeStr[type]
      }为${item.after.length > 0 ? item.after.join() : '空'}`;
    }

    return `${ChangeFieldStr[item.field]}${HistoryTypeStr[type]}为${item.after}${
      index !== changes.length - 1 ? '，' : '。'
    }`;
  });
}

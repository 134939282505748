import { Tag } from 'antd';
import React, { FC } from 'react';

import { DeviceStatus, DeviceStatusStr } from '../../../utils/constants';

interface InternalProps {
  status: DeviceStatus;
}

const OperationStatusTag: FC<InternalProps> = (props) => {
  let color;
  const { status } = props;

  switch (status) {
    case DeviceStatus.OFFLINE:
      color = '#868FA0';
      break;
    case DeviceStatus.ACTIVE:
      color = '#07bd13';
      break;
    case DeviceStatus.INACTIVE:
      color = '#CEDBF2';
      break;
    case DeviceStatus.IDLE:
      color = '#0EC1FF';
      break;
    case DeviceStatus.MAINTENANCE:
      color = '#5D6A7E';
      break;
    case DeviceStatus.CHARGING:
      color = '#39B481';
      break;
    case DeviceStatus.ERROR:
      color = '#f5222d';
      break;
    default:
      color = '#868FA0';
      break;
  }

  return <Tag color={color}>{DeviceStatusStr[status]}</Tag>;
};

export default OperationStatusTag;

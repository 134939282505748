import * as React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const MessageList = Loadable.Map({
  loader: {
    MessageList: () => import('./MessageList'),
    model: () => import('./models/MessageList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const MessageList = loaded.MessageList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <MessageList {...props} />
      </Loading>
    );
  },
});

export { MessageList };

import { pathToRegexp } from 'path-to-regexp';

import { RouteConfig, SysMenu } from '../routes';
import { urlToList } from './utils';

/**
 * Recursively flatten the data
 * Example:
 *  [{path: string}, {path: string}] => {path1, path2}
 *
 * @param {array} menu
 * @returns {string[]}
 */
export function getFlatMenuKeys(menu: any[]): string[] {
  let keys: any[] = [];
  menu?.forEach((item: any) => {
    keys.push(item.path);
    if (item.children) {
      keys = keys.concat(getFlatMenuKeys(item.children));
    }
  });
  return keys;
}

/**
 * Get menu matches
 * @param {array} flatMenuKeys
 * @param {string[]}
 * @returns {string[]}
 */
export function getMenuMatches(flatMenuKeys: string[], path: string): string[] {
  const menus = flatMenuKeys.filter((item: string) => {
    if (!item) {
      return null;
    }
    return pathToRegexp(item).test(path);
  });
  return menus;
}

/**
 * Get default collapsed sub menus
 *
 * @param {string} pathname
 * @param {string[]} flatMenuKeys
 * @returns {string[]}
 */
export function getDefaultCollapsedSubMenus(pathname: string, flatMenuKeys: string[]): string[] {
  const subMenus = urlToList(pathname)
    .map((item: string) => getMenuMatches(flatMenuKeys, item)[0])
    .filter((item: string) => item)
    .reduce((acc: any, curr: any) => [...acc, curr], ['/']);

  return subMenus;
}

/**
 * 根据服务端返回的路径数据，过滤用户权限路径
 * @param {RouteConfig[]} menu
 * @param {string[]} path
 * @returns {RouteConfig[]}
 */
export function filterAuthPath(menu: RouteConfig[], path: string[]): RouteConfig[] {
  return menu
    .filter((item: RouteConfig) => path.includes(item.path) || item.ignoreAuth)
    .map((item: RouteConfig) => {
      if (item.children) {
        return { ...item, children: filterAuthPath(item.children, path) };
      }
      return item;
    });
}

/**
 * 根据菜单配置，配置用户菜单权限
 * @param {object} menu
 * @returns SysMenu
 */
export function mapSysMenu(config: SysMenu, path: string[]): SysMenu {
  let sysMenu: SysMenu = {};

  Object.entries(config).map((item: [string, RouteConfig[]]) => {
    const filteredPath = filterAuthPath(item[1], path);
    sysMenu = {
      ...sysMenu,
      [item[0]]: filteredPath,
    };

    return item;
  });

  return sysMenu;
}

import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const RoleList = Loadable.Map({
  loader: {
    Role: () => import('./Role'),
    model: () => import('./models/Role'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const Role = loaded.Role.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <Role {...props} />
      </Loading>
    );
  },
});

const AuthUserList = Loadable.Map({
  loader: {
    AuthUserList: () => import('./User'),
    model: () => import('./models/User'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const AuthUserList = loaded.AuthUserList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <AuthUserList {...props} />
      </Loading>
    );
  },
});

const AuthUserDetail = Loadable.Map({
  loader: {
    AuthUserDetail: () => import('./AuthUserDetail'),
    userDetail: () => import('../UserCenter/models/UserDetail'),
    model: () => import('./models/User'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const AuthUserDetail = loaded.AuthUserDetail.default;
    const userDetail = loaded.userDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, userDetail);
    registerModel(props.app, model);

    return (
      <Loading>
        <AuthUserDetail {...props} />
      </Loading>
    );
  },
});

const DownloadContent = Loadable.Map({
  loader: {
    DownloadContent: () => import('./DownloadContent'),
    model: () => import('./models/DownloadContent'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DownloadContent = loaded.DownloadContent.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DownloadContent {...props} />
      </Loading>
    );
  },
});

export { AuthUserList, AuthUserDetail, RoleList, DownloadContent };

import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST, PUT } from './AxiosInstance';

export class DeviceRegister {
  // 设备注册接口API
  public static fetchRobotProductManageList(params: object): Promise<any> {
    return GET(ApiUrl.PRODUCTION_MANAGE, params);
  }

  public static fetchRobotProductManageAdd(params: object): Promise<any> {
    return POST(ApiUrl.PRODUCTION_MANAGE, params);
  }

  public static fetchRobotProductManageUpdate(params: any): Promise<any> {
    const { id } = params;
    return PUT(`${ApiUrl.PRODUCTION_MANAGE}/${id}`, params);
  }

  public static fetchRobotProductManageDelete(id: string): Promise<any> {
    return DELETE(`${ApiUrl.PRODUCTION_MANAGE}/${id}`);
  }

  public static getProductManageDetail(id: string): Promise<any> {
    return GET(`${ApiUrl.PRODUCTION_MANAGE}/${id}`);
  }

  public static fetchSpecificationSizeList(params?: any): Promise<any> {
    return GET(ApiUrl.SPECIFICATION_SIZE, params);
  }

  public static createSpecificationSize(params?: any): Promise<any> {
    return POST(ApiUrl.SPECIFICATION_SIZE, params);
  }

  public static updateSpecificationSize(id: string, params?: any): Promise<any> {
    return PATCH(`${ApiUrl.SPECIFICATION_SIZE}/${id}`, params);
  }

  public static deleteSpecificationSize(id: string, params?: any): Promise<any> {
    return DELETE(`${ApiUrl.SPECIFICATION_SIZE}/${id}`, params);
  }

  public static fetchRobotCandlelightList(params: object): Promise<any> {
    return GET(ApiUrl.DEVICE_CANDLELIGHT, params);
  }

  public static fetchRobotCandlelightAdd(params: object): Promise<any> {
    return POST(ApiUrl.DEVICE_CANDLELIGHT, params);
  }

  public static fetchRobotCandlelightUpdate(params: any): Promise<any> {
    const { id } = params;
    return PUT(`${ApiUrl.DEVICE_CANDLELIGHT}/${id}`, params);
  }

  public static fetchRobotCandlelightDelete(id: string): Promise<any> {
    return DELETE(`${ApiUrl.DEVICE_CANDLELIGHT}/${id}`);
  }

  public static fetchSunRobotList(params?: any): Promise<any> {
    return GET(ApiUrl.DEVICE_REGISTER_ROBOT, params);
  }

  public static getSunRobotDetail(id: string, params?: any): Promise<any> {
    return GET(`${ApiUrl.DEVICE_REGISTER_ROBOT}/${id}`, params);
  }

  public static createSunRobot(params?: any): Promise<any> {
    return POST(ApiUrl.DEVICE_REGISTER_ROBOT, params);
  }

  public static updateSunRobot(id: string, params?: any): Promise<any> {
    return PATCH(`${ApiUrl.DEVICE_REGISTER_ROBOT}/${id}`, params);
  }

  public static deleteSunRobot(id: string, robotSeries: string): Promise<any> {
    return DELETE(`${ApiUrl.DEVICE_REGISTER_ROBOT}/${id}?robotSeries=${robotSeries}`);
  }

  public static fetchTachographList(params?: any): Promise<any> {
    return GET(`${ApiUrl.MEDIA_LIVE_DEVICE}`, params);
  }

  public static createTachograph(params?: any): Promise<any> {
    return POST(ApiUrl.MEDIA_LIVE_DEVICE, params);
  }

  public static updateTachograph(id: string, params?: any): Promise<any> {
    return PATCH(`${ApiUrl.MEDIA_LIVE_DEVICE}/${id}`, params);
  }

  public static deleteTachograph(id: string, params?: any): Promise<any> {
    return DELETE(`${ApiUrl.MEDIA_LIVE_DEVICE}/${id}`, params);
  }

  public static getProductManageOptions(params?: any): Promise<any> {
    return GET(ApiUrl.PRODUCTION_MANAGE, params);
  }

  public static getProductModelOptions(params?: any): Promise<any> {
    return GET(ApiUrl.PRODUCTION_MODEL, params);
  }

  public static fetchFunctionBoxList(params: object): Promise<any> {
    return GET(ApiUrl.FUNCTION_BOX, params);
  }

  public static fetchFunctionBoxAdd(params: object): Promise<any> {
    return POST(ApiUrl.FUNCTION_BOX, params);
  }

  public static fetchFunctionBoxUpdate(id: string, params: any): Promise<any> {
    return PATCH(`${ApiUrl.FUNCTION_BOX}/${id}`, params);
  }

  public static fetchFunctionBoxDelete(id: string): Promise<any> {
    return DELETE(`${ApiUrl.FUNCTION_BOX}/${id}`);
  }

  public static getFunctionBoxDetail(id: string): Promise<any> {
    return GET(`${ApiUrl.FUNCTION_BOX}/${id}`);
  }

  public static fetchRobotSpecificationList(params: object): Promise<any> {
    return GET(ApiUrl.DEVICE_SPECIFICATION, params);
  }

  public static fetchRobotAddSpecification(params: object): Promise<any> {
    return POST(ApiUrl.DEVICE_SPECIFICATION, params);
  }

  public static fetchRobotUpdateSpecification(params: any): Promise<any> {
    const { id } = params;
    return PUT(`${ApiUrl.DEVICE_SPECIFICATION}/${id}`, params);
  }

  public static fetchRobotDeleteSpecification(id: string): Promise<any> {
    return DELETE(`${ApiUrl.DEVICE_SPECIFICATION}/${id}`);
  }

  public static getProductSpecificationOptions(params?: any): Promise<any> {
    return GET(ApiUrl.PRODUCTION_SPECIFICATION, params);
  }

  public static fetchHiveSizeList(params?: any): Promise<any> {
    return GET(ApiUrl.HIVE_SIZE, params);
  }

  public static getHiveSizeDetail(id: string, params?: any): Promise<any> {
    return GET(`${ApiUrl.HIVE_SIZE}/${id}`, params);
  }

  public static createHiveSize(params?: any): Promise<any> {
    return POST(ApiUrl.HIVE_SIZE, params);
  }

  public static updateHiveSize(id: string, params?: any): Promise<any> {
    return PATCH(`${ApiUrl.HIVE_SIZE}/${id}`, params);
  }

  public static deleteHiveSize(id: string, params?: any): Promise<any> {
    return DELETE(`${ApiUrl.HIVE_SIZE}/${id}`, params);
  }
}

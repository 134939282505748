import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { TransferItem } from 'antd/lib/transfer';
import { get } from 'lodash';

import { RouteConfig } from '../routes';
import {
  ChangeableOrderStateOptions,
  DeliveryMethodOption,
  OrderStateOption,
  PackageSize,
  PackageSizeOption,
  TableColumnProps,
} from './constants';

/**
 * Transfer url to url list
 * Example:
 *  '/userinfo/2019/id' => ['/userinfo', '/useinfo/2019, '/userindo/2019/id']
 *
 * @param {string} url
 * @return {string[]}
 */
export function urlToList(url: string): string[] {
  const urlList = url.split('/').filter((i: string) => i);
  return urlList.map((item: string, index: number) => `/${urlList.slice(0, index + 1).join('/')}`);
}

/**
 * 获取面包屑路径映射
 * @param RouteConfig[] menuData 菜单配置
 */
export function getBreadcrumbNameMap(menuData: RouteConfig[]): { [key: string]: RouteConfig } {
  const routerMap: { [key: string]: RouteConfig } = {};
  const flattenMenuData: (data: RouteConfig[]) => void = (data) => {
    data.forEach((menuItem: RouteConfig) => {
      if (!menuItem) {
        return;
      }
      if (menuItem && menuItem.children) {
        flattenMenuData(menuItem.children);
      }
      routerMap[menuItem.path] = menuItem;
    });
  };
  flattenMenuData(menuData);
  return routerMap;
}

/**
 * Transfer number to `x小时 y分 z秒`
 * @param {number} num 秒单位
 * @return {string}
 */
export function formatSeconds(num: number, flag?: boolean): string {
  if (!num) {
    return '00:00:00';
  }

  const day = ~~(num / (60 * 60 * 24));
  const minutes = ~~((num / 60) % 60);
  const hours = ~~((num / 60 / 60) % 24);
  const seconds = ~~Math.ceil(num % 60);

  if (flag) {
    return `${day > 0 ? day + '天' : ''}${hours > 0 ? hours + '小时' : ''}${minutes > 0 ? minutes + '分' : ''}${
      seconds > 0 ? seconds + '秒' : ''
    }`;
  } else {
    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
  }
}

/**
 * string去除收尾空格符
 * @param {string} params
 */
export function strTrim(str: string) {
  return str && str.replace(/^(\s|\xA0)+|(\s|\xA0)+$/g, '');
}

/**
 * 判断对象或者数组是否为空
 * @param {array | object} obj
 * @return {boolean}
 */
export function isEmpty(obj: any): boolean {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
}

/**
 * 格式化uuid
 * @param {string} uuid
 * @return {string}
 */
export function formatUuid(uuid: string): string {
  if (!uuid) {
    return '';
  }
  return uuid.replace(/^(.{0,16}).{20}$/, '$1...');
}

/**
 * 转换数据格式为下拉列表的数组格式
 * @param {array[]} data
 * @return {DataSourceItemObject[]} []
 */
export function transformDataSource(data: any[], key?: string): DataSourceItemObject[] {
  if (!data || data.length === 0) {
    return [];
  }
  return data.map((item) => ({
    value: item.id,
    text: key ? item[key] : item.name,
    ...item,
  }));
}

/**
 * 转换数据格式为手机号下拉列表的数组格式
 * @param {array[]} data
 * @return {DataSourceItemObject[]} []
 */
export function transformMobile(data: any[]): DataSourceItemObject[] {
  if (!data || data.length === 0) {
    return [];
  }
  return data
    .filter((item: any) => item.mobile !== undefined)
    .map((item: any) => ({
      key: item.id,
      value: item.mobile,
      text: item.mobile,
      ...item,
    }));
}

/**
 * 转换百度地图数组为下拉列表的数组格式
 * @param {array[]} data
 * @return {DataSourceItemObject[]} []
 */
export function transformMap(data: any[]): DataSourceItemObject[] {
  if (!data || data.length === 0) {
    return [];
  }
  return data
    .filter((item: any) => item.point !== undefined)
    .map((item: any) => ({
      key: JSON.stringify(item.point),
      value: item.title,
      text: item.title,
      address: item.address,
      // ...item,
    }));
}

/**
 * 转换数据格式为地址下拉列表的数组格式
 * @param {array[]} data
 * @return {DataSourceItemObject[]} []
 */
export function transformAddress(data: any[]): DataSourceItemObject[] {
  if (!data || data.length === 0) {
    return [];
  }
  return data
    .filter((item: any) => item.address !== undefined)
    .map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.address,
      ...item,
    }));
}

/**
 * 转换数据格式为穿梭框数据格式
 * @param {array[]} data
 * @return {TransferItem[]} []
 */
export function transformTransfer(data: any[]): TransferItem[] {
  if (!data || data.length === 0) {
    return [];
  }
  return data
    .filter((item: any) => item.name !== undefined)
    .map((item: any) => ({
      key: item.id,
      title: item.name,
      ...item,
    }));
}

/**
 * 格式化 packageSize 数据
 * @param {PackageSize[]} packageSizes
 * @return PackageSizeOption
 */
export function transformPackageSizes(packageSizes: PackageSize[]) {
  if (!packageSizes || packageSizes.length === 0) {
    return PackageSizeOption;
  }

  return packageSizes
    .map((item) => {
      return PackageSizeOption.find((option) => option.value === item);
    })
    .filter((item) => item && item);
}

/**
 * 转换数据格式为穿梭框数据格式
 * @param {array[]} data
 * @return {[]} []
 */
export function dataTransOptions(data: any[]) {
  if (!data || data.length === 0) {
    return [];
  }
  return data
    .filter((item: any) => item.name !== undefined)
    .map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
}

/**
 * 数字格式化为 1 或 01
 * @param {number} n
 */
export function formatNumber(n: number): string {
  const num = n.toString();
  return num[1] ? num : '0' + num;
}

/**
 * 生成A-Z大写字母
 * @return {string[]} [A, ..., Z]
 */
export function generateAToZ(): string[] {
  const chars = [];

  for (let charCode = 65; charCode <= 90; charCode++) {
    chars.push(String.fromCharCode(charCode));
  }

  return chars;
}

/**
 * 转换时间为整点整分
 * @param {number} time
 */
export function filterTime(time: number): any {
  const min = Math.floor(Math.floor(time % 3600) / 60) || '00';
  return formatNumber(Math.ceil(Math.floor(time / 3600))) + ':' + min;
}

/**
 * 匹配宽度和高度
 * @param {string} str
 * @param {RegExp} reg
 */
export function regWidthOrHeight(str: string, reg: RegExp) {
  const regArr = str.match(reg);
  if (regArr && regArr.length) {
    return regArr[0];
  } else {
    return '未知';
  }
}

/**
 * 转换 deliveryMethod 数组为下拉列表数组格式
 * Example:
 *  ['HIVE'] => [{ label: '机器人配送', value: 'HIVE' }]
 * @param {string[]} deliveryMethods
 */
export function transformDeliveryMethods(deliveryMethods: string[]) {
  if (!deliveryMethods || deliveryMethods.length === 0) {
    return DeliveryMethodOption;
  }

  return deliveryMethods
    .map((item: string) => {
      const option = DeliveryMethodOption.find((option) => option.value === item);
      return option;
    })
    .filter((item) => item && item);
}

/**
 * 过滤对象中键值为undefined的键
 * @param {object} obj
 */
export function filterEmpty(obj: object) {
  for (const o in obj) {
    if (typeof obj[o] === 'undefined') {
      delete obj[o];
    }
  }
  return obj;
}

/**
 * 给定一个 from 和 to, 移动数组中的 from 项到 to这个位置
 * Example:
 * arrayMove([1, 2, 3], 0, 1) => [2, 1, 3]
 * @param {any[]} array
 * @param {number} from
 * @param {number} to
 */
export function arrayMove(array: any[], from: number, to: number): any[] {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

/**
 * 给定一个数组,累加计算数值
 * Example:
 * arrayReduce([1, 2, 3]) => 6
 * @param {any[]} array
 */
export function arrayReduce(array: any[]): string {
  let a = 0;
  array.forEach((item: any) => {
    a += item;
  });
  return a.toFixed(2);
}

/**
 * 给定一个数组和一个需要判断的量还有需要变形后的单个数组的长度，将数组变换成多个二维数组
 * Example:
 * arrayTrans([1, 2, 3, 0, 1],4,3) => [[1,2,3],[0,1]]
 * @param {any[]} array
 * @param {number} more
 * @param {number} many
 */
export function arrayTrans(array: any[], more: number, many: number): any[] {
  const newAry: any = [];
  const lineNum = array.length % many === 0 ? array.length / many : Math.floor(array.length / many + 1);

  if (array.length > more) {
    for (let i = 0; i < lineNum; i++) {
      const twelveList = array.slice(i * many, i * many + many);
      newAry.push(twelveList);
    }
    return newAry;
  } else {
    return array;
  }
}

/**
 * Antd Select 下拉组件搜索过滤工具函数
 * @param [string] inputValue
 * @param [ReactElement<OptionProps>] option
 */
export function filterOption(inputValue: string, option: any): boolean {
  if (typeof option.children !== 'string') {
    return false;
  }

  return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
}

/**
 * 转换 Antd Table 表格单元格为可编辑单元格数据
 * @param {TableColumnProps<any>[]} columns
 * @param {Function} handleSave
 * @returns {TableColumnProps<any>[]} columns
 */
export function transformColumns(columns: TableColumnProps<any>[], handleSave: () => void): TableColumnProps<any>[] {
  return columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        handleSave,
        editable: col.editable,
        dataIndex: col.dataIndex,
        inputType: col.inputType,
        pattern: col.pattern,
        required: col.required,
        title: col.title as string,
      }),
    };
  });
}

/**
 * 转换 1,2,3,4,5为[1,2,3,4,5]
 * @param {string} str
 */
export function transformString(str: string): any[] {
  return Array.from(new Set(str.split(/[ ,，]+/))).filter((d: string) => d && d !== '0');
}

/**
 * 格式化手机号 13798241234 -> 137****1234
 * @param mobile
 */
export function formatMobile(mobile: string): string {
  if (!mobile) {
    return '';
  }
  return mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

/**
 * 数组中对象里的key值的累加求和
 * @param {string} str
 * @param {any[]} arr
 */
export function arraySum(arr: any[], str: string) {
  let num = 0;
  for (const i of arr) {
    const val = get(i, `deliveryMethodFiltrationCount.${str}`, 0);
    num += val;
  }
  return num;
}

/**
 * 返回可修改订单状态列表
 * @param {any[]} arr
 */
export function formatOrderStateOptions() {
  return OrderStateOption.filter((d: any) => d.value !== '').map((d: any) => ({
    ...d,
    disabled: !ChangeableOrderStateOptions.includes(d.value),
  }));
}

/**
 * 转换 [1,2,-1~5] 为[-1,1,2,3,4,5]
 * @param {any[]} arr
 */
export function transformArr(arr: any[]): any[] {
  const pureArr = arr.filter((item: any) => !/[~～]/.test(item));
  const tempArr: any[] = [];

  arr.forEach((item) => {
    if (/[~～]/.test(item)) {
      const momentArr = item.split(/[～~]+/);
      for (let i = momentArr[0] - 1; i < momentArr[1]; i++) {
        tempArr.push(Number(i) + 1 + '');
      }
    }
  });

  return Array.from(new Set(tempArr.concat(pureArr))).filter((d: string) => d && d !== '0');
}

/**
 * 阿拉伯数字转汉字字符
 * 例如: 转换 1234 为 一千二百三十四
 *
 * @param {number} section
 * @return {string} chnStr
 */
export function SectionToChinese(section: number): string {
  const chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const chnUnitChar = ['', '十', '百', '千', '万', '亿', '万亿', '亿亿'];

  let strIns = '';
  let chnStr = '';
  let unitPos = 0;
  let zero = true;

  while (section > 0) {
    const v = section % 10;
    if (v === 0) {
      if (!zero) {
        zero = true;
        chnStr = chnNumChar[v] + chnStr;
      }
    } else {
      zero = false;
      strIns = chnNumChar[v];
      strIns += chnUnitChar[unitPos];
      chnStr = strIns + chnStr;
    }
    unitPos++;
    section = Math.floor(section / 10);
  }

  return chnStr;
}

/**
 * diff form 表单字段修改前后是否变化
 * @param {object} record
 * @param {object} values
 * @return {boolean}
 */
export function diffData(record: object, values: object): boolean {
  if (!record) {
    return true;
  }

  let diff;

  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      const value = values[key];
      diff = record[key] !== value;
    }
  }

  return !!diff;
}

export function sortByArr(name: any, minor?: any, sort: any = 'desc') {
  return (o: any, p: any) => {
    let a;
    let b;

    if (o && p && typeof o === 'object' && typeof p === 'object') {
      a = o[name];
      b = p[name];

      if (a === b) {
        return typeof minor === 'function' ? minor(o, p) : 0;
      }
      return sort === 'asc' ? (a < b ? -1 : 1) : a < b ? 1 : -1;
    } else {
      return 0;
    }
  };
}

/**
 * 生成一个从 start 到 end 的连续数组
 * @param start start 大于等于0的整数
 * @param end end 大于等于0的整数
 * @return {number[]} 返回数字数组
 */
export function generateArray(start: number, end: number): number[] {
  if (isNaN(start) || isNaN(end)) {
    return [];
  }
  return Array.from(new Array(end + 1).keys()).slice(start);
}

/**
 * 页面刷新后获取搜索框的默认值
 * @param query url携带的参数
 * @param key 参数的对应键
 */
export function splitQuery(query: any, key: string): string[] | undefined {
  const params = get(query, key);
  if (params) {
    return params.split(',');
  } else {
    return undefined;
  }
}

/**
 * 根据数组中对象的某一个属性去重
 * @param arr url携带的参数
 * @param key 属性键值
 */
export function removeDuplicate(arr: any[], key: string): any[] {
  const obj = {};
  return arr.reduce((item: any[], next: any) => {
    if (!obj[next[key]]) {
      item.push(next);
      obj[next[key]] = true;
    }
    return item;
  }, []);
}

/**
 * 限定输入字符长度
 * @param {number} num 长度
 */
export function limitLength(num: number): RegExp {
  return new RegExp(`^[\u4e00-\u9fffa-zA-Z]{0,${num}}$`);
}

/**
 * 空值转为null
 * @param obj 提交的数据
 */
export function transformValues(obj: object): object {
  for (const o in obj) {
    if (typeof obj[o] === 'undefined' || obj[o] === '') {
      obj[o] = null;
    }
  }
  return obj;
}

/**
 * 一维数组转二维数组
 * @param {number} num 数组长度
 * @param {any[]} arr 数组
 */
export function arrTrans(num: number, arr: any[]) {
  const newArr = [];
  while (arr.length > 0) {
    newArr.push(arr.splice(0, num));
  }
  return newArr;
}

// tslint:disable-next-line: max-file-line-count

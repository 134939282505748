import { Col, Layout, Row } from 'antd';
import { connect } from 'dva';
import React, { PureComponent } from 'react';

import styles from './index.module.scss';

const { Footer } = Layout;

interface InternalProps {
  robotStatus: any;
}

class MonitorDashboard extends PureComponent<InternalProps> {
  public render() {
    const { robotStatus } = this.props;

    return (
      <Footer className={styles.dashboard}>
        <Row gutter={8} style={{ height: '100%' }}>
          <Col span={9}>
            <h1>机器人</h1>
            电池电量: {robotStatus && robotStatus.battery && robotStatus.battery.percentage}%
          </Col>
          <Col span={9}>
            <h1>导航模式</h1>
          </Col>
          <Col span={6}>
            <h1>日志</h1>
          </Col>
        </Row>
      </Footer>
    );
  }
}

const mapStateToProps = ({ monitorCenter }: any) => {
  return {
    robotStatus: monitorCenter.robotStatus,
  };
};

export default connect(mapStateToProps)(MonitorDashboard);

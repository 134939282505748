import { Drawer } from 'antd';
import React, { CSSProperties } from 'react';

import { getFlatMenuKeys } from '../../utils';
import BaseMenu, { MenuItemProps } from './BaseMenu';
import SideMenu from './SideMenu';

const style: CSSProperties = {
  padding: 0,
  height: '100vh',
};

interface InternalProps {
  loading: boolean;
  location: Location;
  isMobile?: boolean;
  collapsed?: boolean;
  menu: MenuItemProps[];
  width?: number | string;
  onCollapse?: (collapsed: boolean) => void;
}

const SideBar: React.FC<InternalProps> = (props) => {
  const { isMobile, menu, width, collapsed, onCollapse } = props;
  const flatMenuKeys = getFlatMenuKeys(menu);

  return isMobile ? (
    <Drawer
      placement="left"
      width={width || 240}
      bodyStyle={style}
      closable={false}
      visible={!collapsed}
      onClose={() => onCollapse && onCollapse(true)}
    >
      <SideMenu {...props} flatMenuKeys={flatMenuKeys} collapsed={isMobile ? false : collapsed} />
    </Drawer>
  ) : (
    <SideMenu {...props} flatMenuKeys={flatMenuKeys} />
  );
};

export { BaseMenu };

export default SideBar;

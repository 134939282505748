import { ApiUrl } from './ApiConfig';
import { GET } from './AxiosInstance';

export class ReportService {
  public static fetchReportList(params?: any): Promise<any> {
    return GET(ApiUrl.DEL_OPERATION, params);
  }

  public static fetchReportTotalSummary(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_ORDER_SUMMARY, params);
  }

  public static fetchCollectTodaySummary(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_COLLECT_TODAY_SUMMARY, params);
  }

  public static fetchBuildingSummary(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_BUILDING_SUMMARY, params);
  }

  public static fetchUnKnownBuildingSummary(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_UNKNOWN_BUILDING_SUMMARY, params);
  }

  public static fetchOrderDeliverySummary(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_ORDER_SUMMARIES_SUMMARY, params);
  }

  public static fetchDeliveryCompanyData(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_COMPANY_SUMMARY, params);
  }

  public static fetchOrderCost(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_ORDER_COST, params);
  }

  public static fetchDispatchOrderSum(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_DISPATCH_SUMMARY, params);
  }

  public static fetchExportRecordList(params: any): Promise<any> {
    return GET(ApiUrl.REPORT_EXPORT_RECORD, params);
  }
}

import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class WarehouseService {

  public static fetchWindows(params?: object): Promise<any> {
    return GET(ApiUrl.WAREHOUSE_WINDOW, params);
  }

  public static fetchWarehouses(params?: object): Promise<any> {
    return GET(ApiUrl.WAREHOUSE_WAREHOUSE, params);
  }

  public static postSynchronize(params?: object): Promise<any> {
    return POST(ApiUrl.WAREHOUSE_SYNCHRONIZE, params);
  }

  public static patchWindow(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.WAREHOUSE_WINDOW}/${id}`, params);
  }

  public static postWarehouse(params?: object): Promise<any> {
    return PATCH(ApiUrl.WAREHOUSE_WAREHOUSE, params);
  }

  public static patchWarehouse(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.WAREHOUSE_WAREHOUSE}/${id}`, params);
  }

  public static deleteWarehouse(id: string): Promise<any> {
    return DELETE(`${ApiUrl.WAREHOUSE_WAREHOUSE}/${id}`);
  }

  public static postSorterTake(params?: object): Promise<any> {
    return POST(ApiUrl.WAREHOUSE_SORTER, params);
  }
}

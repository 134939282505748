import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, message, Popconfirm, Row, Select, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { FC, useState } from 'react';

import { ExpressType, ExpressTypeStr } from '../../../utils/constants';
import { filterOption } from '../../../utils/utils';
import { SectionToChinese } from './../../../utils/utils';
import CityModal from './CityModal';

const { Item } = Form;
const { Option } = Select;

interface InternalProps {
  postageList: any[];
  setFieldsValue: (values: any) => void;
  handlePostageList: (values: any[]) => void;
}

const BatchAddArea: FC<InternalProps> = (props) => {
  const { postageList, setFieldsValue, handlePostageList } = props;
  const [tableKey, setTableKey] = useState<number>(0);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [showCityModal, setShowCityModal] = useState<boolean>(false);
  const [inaccessibleArea, setInaccessibleArea] = useState<any[]>([]);

  const handleChangeInputValue = (value: any, record: any, key: string) => {
    const newData = [...postageList];
    newData[record.key - 1][key] = Number(value);
    handlePostageList(newData);
  };

  const handleChangeExpressType = (value: string, record: any, key: string) => {
    const newData = [...postageList];
    newData[record.key - 1][key] = value;
    handlePostageList(newData);
  };

  const addAreaName = () => {
    const newData = [...postageList];
    const keyNum = newData.length + 1;

    newData.push({
      key: keyNum,
      name: SectionToChinese(keyNum) + '区',
      destinations: [],
      initialWeightCost: null,
      additionalWeightCost: null,
      estimatedDeliveryDays: null,
      volumeWeightCoefficient: null,
      expressType: null,
    });

    handlePostageList(newData);
  };

  const deleteAreaName = async (record: any) => {
    const newData = [...postageList];
    const target = newData.filter((item) => record.key !== item.key);
    initAreaNumber(target);
  };

  const initAreaNumber = (list: any[]) => {
    list = list.map((item: any, index: number) => ({
      ...item,
      key: index + 1,
      name: SectionToChinese(index + 1) + '区',
    }));
    handlePostageList(list);
  };

  const handleCityModal = (record: any) => {
    if (!record.expressType) {
      message.error('请优先选择快递类型!');
      return;
    }
    const { receiveList } = record;
    const data =
      receiveList && receiveList.length
        ? receiveList.map((item: any) => (item.province ? JSON.stringify(item) : item))
        : [];

    setTableKey(record.key);
    setCheckedKeys(data);

    const newData = [...postageList];
    const tempArr: any = { LAND: [], AIR_TRANSPORT: [], SAME_CITY_SAME_DAY: [], SAME_CITY_TODAY: [] };

    newData.forEach((item: any) => {
      if (item.receiveList && item.expressType === ExpressType.LAND) {
        tempArr.LAND = tempArr.LAND.concat(item.receiveList);
      } else if (item.receiveList && item.expressType === ExpressType.AIR_TRANSPORT) {
        tempArr.AIR_TRANSPORT = tempArr.AIR_TRANSPORT.concat(item.receiveList);
      } else if (item.receiveList && item.expressType === ExpressType.SAME_CITY_SAME_DAY) {
        tempArr.SAME_CITY_SAME_DAY = tempArr.SAME_CITY_SAME_DAY.concat(item.receiveList);
      } else if (item.receiveList && item.expressType === ExpressType.SAME_CITY_TODAY) {
        tempArr.SAME_CITY_TODAY = tempArr.SAME_CITY_TODAY.concat(item.receiveList);
      }
    });

    const disabledList = tempArr[record.expressType]
      .map((item: any) => (item.province ? JSON.stringify(item) : item))
      .filter((v: any) => data.indexOf(v) === -1);

    setShowCityModal(true);
    setInaccessibleArea(disabledList);
  };

  const fillProvinceList = (values: any[]) => {
    const newData = [...postageList];
    newData[tableKey - 1].receiveList = values;
    handlePostageList(newData);
  };

  const columns: ColumnProps<any>[] = [
    {
      title: '快递类型',
      dataIndex: 'expressType',
      fixed: true,
      align: 'center',
      render: (text: any, record: any) => {
        const field = `expressType${record.key}`;
        setFieldsValue({ [field]: text });

        return (
          <Item required name={field} style={{ margin: 0 }} rules={[{ required: true, message: `请输入快递类型!` }]}>
            <Select
              showSearch
              placeholder="快递类型"
              optionFilterProp="children"
              filterOption={filterOption}
              onChange={(value: string) => handleChangeExpressType(value, record, 'expressType')}
            >
              {Object.keys(ExpressTypeStr).map((item: any, index: number) => {
                return (
                  <Option value={item} key={index}>
                    {ExpressTypeStr[item]}
                  </Option>
                );
              })}
            </Select>
          </Item>
        );
      },
    },
    {
      title: '区域',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '详细地区',
      dataIndex: 'receiveList',
      render: (text: any, record: any) => (
        <>
          {text && text.length ? Array.from(new Set(text.map((item: any) => item.province || item))).join('/') : ''}
          <Button type="link" onClick={() => handleCityModal(record)}>
            编辑地区
          </Button>
        </>
      ),
    },
    {
      title: '预计到达天数（天）',
      dataIndex: 'estimatedDeliveryDays',
      render: (text: any, record: any) => {
        const field = `estimatedDeliveryDays${record.key}`;
        setFieldsValue({ [field]: text });

        return (
          <Item
            required
            style={{ margin: 0 }}
            name={field}
            rules={[{ required: true, message: `请输入预计到达天数（天）!` }]}
          >
            <InputNumber
              min={0}
              type="number"
              placeholder="天数"
              style={{ width: '100%' }}
              onChange={(value?: number | string) => handleChangeInputValue(value, record, 'estimatedDeliveryDays')}
            />
          </Item>
        );
      },
    },
    {
      title: '首重价格（kg,元）',
      dataIndex: 'initialWeightCost',
      render: (text: any, record: any) => {
        const field = `initialWeightCost${record.key}`;
        setFieldsValue({ [field]: text });

        return (
          <Item
            required
            name={field}
            style={{ margin: 0 }}
            rules={[{ required: true, message: `请输入首重价格（kg,元）!` }]}
          >
            <InputNumber
              min={0}
              type="number"
              placeholder="首重价格"
              style={{ width: '100%' }}
              onChange={(value?: number | string) => handleChangeInputValue(value, record, 'initialWeightCost')}
            />
          </Item>
        );
      },
    },
    {
      title: '续重价格（kg,元）',
      dataIndex: 'additionalWeightCost',
      render: (text: any, record: any) => {
        const field = `additionalWeightCost${record.key}`;
        setFieldsValue({ [field]: text });

        return (
          <Item
            required
            name={field}
            style={{ margin: 0 }}
            rules={[{ required: true, message: `请输入续重价格（kg,元）!` }]}
          >
            <InputNumber
              min={0}
              type="number"
              placeholder="续重价格"
              style={{ width: '80px' }}
              onChange={(value?: number | string) => handleChangeInputValue(value, record, 'additionalWeightCost')}
            />
          </Item>
        );
      },
    },
    {
      title: '体积重量系数',
      dataIndex: 'volumeWeightCoefficient',
      render: (text: any, record: any) => {
        const field = `volumeWeightCoefficient${record.key}`;
        setFieldsValue({ [field]: text });

        return (
          <Item
            required
            name={field}
            style={{ margin: 0 }}
            rules={[{ required: true, message: `请输入体积重量系数!` }]}
          >
            <InputNumber
              min={0}
              type="number"
              style={{ width: '100%' }}
              placeholder="体积重量系数"
              onChange={(value?: number | string) => handleChangeInputValue(value, record, 'volumeWeightCoefficient')}
            />
          </Item>
        );
      },
    },
    {
      title: '协议首重价格(元)',
      dataIndex: 'agreementInitialWeightCost',
      render: (text: any, record: any) => {
        const field = `agreementInitialWeightCost${record.key}`;
        setFieldsValue({ [field]: text });

        return (
          <Item
            required
            name={field}
            style={{ margin: 0 }}
            rules={[{ required: true, message: `请输入协议首重价格!` }]}
          >
            <InputNumber
              min={0}
              type="number"
              style={{ width: '100%' }}
              placeholder="协议首重价格"
              onChange={(value?: number | string) =>
                handleChangeInputValue(value, record, 'agreementInitialWeightCost')
              }
            />
          </Item>
        );
      },
    },
    {
      title: '协议续重价格(元)',
      dataIndex: 'agreementAdditionalWeightCost',
      render: (text: any, record: any) => {
        const field = `agreementAdditionalWeightCost${record.key}`;
        setFieldsValue({ [field]: text });

        return (
          <Item
            required
            name={field}
            style={{ margin: 0 }}
            rules={[{ required: true, message: `请输入协议续重价格!` }]}
          >
            <InputNumber
              min={0}
              type="number"
              style={{ width: '100%' }}
              placeholder="协议续重价格"
              onChange={(value?: number | string) =>
                handleChangeInputValue(value, record, 'agreementAdditionalWeightCost')
              }
            />
          </Item>
        );
      },
    },
    {
      title: '协议体积重量系数',
      dataIndex: 'agreementVolumeWeightCoefficient',
      render: (text: any, record: any) => {
        const field = `agreementVolumeWeightCoefficient${record.key}`;
        setFieldsValue({ [field]: text });

        return (
          <Item
            required
            name={field}
            style={{ margin: 0 }}
            rules={[{ required: true, message: `请输入协议体积重量系数!` }]}
          >
            <InputNumber
              min={0}
              type="number"
              style={{ width: '100%' }}
              placeholder="协议体积重量系数"
              onChange={(value?: number | string) =>
                handleChangeInputValue(value, record, 'agreementVolumeWeightCoefficient')
              }
            />
          </Item>
        );
      },
    },
    {
      title: '操作',
      key: 'operation',
      width: 70,
      fixed: 'right',
      align: 'center',
      render: (record: any) => (
        <Popconfirm title="确定要删除?" onConfirm={() => deleteAreaName(record)}>
          <Tooltip title="删除">
            <Button danger icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Table bordered dataSource={postageList} pagination={false} columns={columns} scroll={{ x: 1800 }} />
      <Row style={{ padding: '10px 0' }}>
        <Button type="dashed" onClick={addAreaName} block>
          <PlusOutlined /> 添加区域
        </Button>
      </Row>
      <CityModal
        visible={showCityModal}
        checkedKeys={checkedKeys}
        onSubmit={fillProvinceList}
        inaccessibleArea={inaccessibleArea}
        onCancel={() => setShowCityModal(false)}
      />
    </>
  );
};

export default BatchAddArea;

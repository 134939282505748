import { Col, DatePicker, Radio, Row } from 'antd';
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';
import {
  differenceInCalendarDays,
  differenceInMonths,
  endOfDay,
  endOfToday,
  endOfYesterday,
  getTime,
  isSameDay,
  isSameMonth,
  isSameYear,
  isToday,
  isYesterday,
  startOfToday,
  startOfYesterday,
  subDays,
  subMonths,
} from 'date-fns';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';

const { Group, Button } = Radio;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const midnight = getTime(startOfToday());
const endToday = getTime(endOfToday());
const sevenDays = getTime(subDays(midnight, 6));
const thirtyDays = getTime(subDays(midnight, 29));
const threeMonthsAgo = getTime(subMonths(midnight, 3));
const thisWeek: any[] = [moment(new Date(sevenDays), dateFormat), moment(endOfToday(), dateFormat)];
const yesterday: any[] = [moment(startOfYesterday(), dateFormat), moment(endOfYesterday(), dateFormat)];
const threeMonth: any[] = [moment(new Date(threeMonthsAgo), dateFormat), moment(endOfToday(), dateFormat)];
export const all: any[] = [moment(new Date(thirtyDays), dateFormat), moment(endOfToday(), dateFormat)];
export const today: any[] = [moment(startOfToday(), dateFormat), moment(endOfToday(), dateFormat)];

export enum SegmentOption {
  all = 'all',
  today = 'today',
  yesterday = 'yesterday',
  thisWeek = 'thisWeek',
  threeMonth = 'threeMonth',
}

interface InternalProps extends RadioGroupProps {
  defaultDate?: any;
  ifFinancial?: boolean;
  onDateChange: (date: any) => void;
}

export const DateSegment: FC<InternalProps> = (props) => {
  const { style, className, onDateChange, defaultDate, ifFinancial, ...restProps } = props;
  const [value, setValue] = useState<SegmentOption | null>(SegmentOption.today);
  const [dateValue, setDateValue] = useState<any>(defaultDate || today);

  const handleSegmentValue = useCallback(() => {
    const allStart = all[0].toDate();
    const start = dateValue && dateValue.length ? dateValue[0].toDate() : midnight;
    const end = dateValue && dateValue.length ? dateValue[1]?.toDate() : endToday;

    if (start && end) {
      if (isToday(start) && isToday(end)) {
        setValue(SegmentOption.today);
      } else if (isYesterday(start) && isYesterday(end)) {
        setValue(SegmentOption.yesterday);
      } else if (differenceInCalendarDays(end, start) === 6 && end.getDate() === endOfToday().getDate()) {
        setValue(SegmentOption.thisWeek);
      } else if (
        differenceInMonths(end, start) === 3 &&
        end.getDate() === endOfToday().getDate() &&
        start.getDate() === startOfToday().getDate()
      ) {
        setValue(SegmentOption.threeMonth);
      } else if (
        isSameYear(allStart, start) &&
        isSameMonth(allStart, start) &&
        isSameDay(allStart, start) &&
        isToday(end)
      ) {
        setValue(SegmentOption.all);
      } else {
        setValue(null);
      }
    } else {
      setValue(SegmentOption.today);
    }
  }, [dateValue]);

  useEffect(() => {
    if (defaultDate) {
      setDateValue(defaultDate);
    }
    handleSegmentValue();
  }, [handleSegmentValue, defaultDate]);

  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value === SegmentOption.today) {
      setDateValue(today);
      onDateChange(today);
    } else if (e.target.value === SegmentOption.yesterday) {
      setDateValue(yesterday);
      onDateChange(yesterday);
    } else if (e.target.value === SegmentOption.thisWeek) {
      setDateValue(thisWeek);
      onDateChange(thisWeek);
    } else if (e.target.value === SegmentOption.threeMonth) {
      setDateValue(threeMonth);
      onDateChange(threeMonth);
    } else {
      setDateValue(all);
      onDateChange(all);
    }

    setValue(e.target.value);
  };

  const handleDateChange = (date: any, dateString: [string, string], key?: string) => {
    handleSegmentValue();
    // onDateChange(date);
    // setDateValue(date);
    const secondTime = date && date.length && endOfDay(moment(date[1]).valueOf()).valueOf();
    const newDate = date && date.length ? [date[0], moment(secondTime)] : date;
    onDateChange(newDate);
    setDateValue(newDate);
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  return (
    <Row gutter={12} style={style} className={className}>
      <Col flex={1}>
        <Group value={value} onChange={onChange} {...restProps}>
          <Button value={SegmentOption.today}>今日</Button>
          {!ifFinancial && <Button value={SegmentOption.yesterday}>昨日</Button>}
          <Button value={SegmentOption.thisWeek}>近7日</Button>
          <Button value={SegmentOption.all}>近30日</Button>
          {ifFinancial && <Button value={SegmentOption.threeMonth}>近3个月</Button>}
        </Group>
      </Col>
      <Col>
        <RangePicker
          value={dateValue}
          format={dateFormat}
          disabledDate={disabledDate}
          onChange={handleDateChange}
          placeholder={['开始日期', '结束日期']}
        />
      </Col>
    </Row>
  );
};

export default DateSegment;

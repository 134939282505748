import { UploadFile } from 'antd/lib/upload/interface';

import { FileService } from '../services';
import { Action, Effects } from './dispatch';
const fileService = new FileService();

function* uploadFileSaga({ payload }: Action, { call, take, put, cancelled }: Effects) {
  // Upload the specified file
  const { key, file, ossInfo } = payload;
  const { name } = file;
  const url = ossInfo.ossPrefix + '/' + key;
  const uploadChannel = fileService.createUploadChannel(key, file, ossInfo);

  try {
    while (true) {
      const response = yield take(uploadChannel);
      const { percent, error, etag } = response;

      if (percent) {
        yield put({
          type: 'uploadStatus',
          payload: { ...file, name, percent, status: 'uploading' },
        });
      }

      if (error) {
        yield put({
          type: 'uploadStatus',
          payload: { ...file, name, error, status: 'error' },
        });
      }

      if (etag) {
        yield put({
          type: 'uploadStatus',
          payload: { ...file, name, url, status: 'done' },
        });
      }
    }
  } catch (error) {
    throw error;
  } finally {
    if (yield cancelled()) {
      // Close Channel
      uploadChannel.close();
      yield put({
        type: 'uploadStatus',
        payload: { ...file, name, status: 'error' },
      });
    }
  }
}

export interface FileUploadState {
  files: UploadFile[];
}

const initialState: FileUploadState = {
  files: [],
};

export default {
  namespace: 'fileUpload',

  state: initialState,

  effects: {
    // upload file action
    *uploadFile({ payload }: Action, { call, cancel, fork, put, take, cancelled }: Effects) {
      try {
        const { key, file, ossInfo } = payload;

        if (!key) {
          throw new Error('Payload missing applicationId param');
        }

        if (!file) {
          throw new Error('Payload missing file param');
        }

        if (!ossInfo) {
          throw new Error('Payload missing ossInfo param');
        }

        const uploadTask = yield fork(uploadFileSaga, { payload }, { call, put, take, cancelled });

        if (yield take('cancelUpload')) {
          yield cancel(uploadTask);
        }
      } catch (error) {
        throw error;
      }
    },

    // cancel upload file action
    *cancelUpload(action: Action, effects: Effects) {},

    *uploadStatus({ payload }: Action, { put }: Effects) {
      try {
        yield put({ type: 'updateUploadStatus', payload });
      } catch (error) {
        throw error;
      }
    },
  },

  reducers: {
    loadFileList(state: FileUploadState, { payload }: Action): FileUploadState {
      return { files: payload };
    },

    clearFileList(state: FileUploadState, { payload }: Action): FileUploadState {
      return { files: [] };
    },

    updateUploadStatus(state: FileUploadState, { payload }: Action): FileUploadState {
      const { uid } = payload as UploadFile;
      const files = state.files.map((item: UploadFile) => {
        if (item.uid === uid) {
          return payload;
        }
        return item;
      });

      return { files };
    },

    removeFile(state: FileUploadState, { payload }: Action): FileUploadState {
      const { uid } = payload as UploadFile;
      const files = state.files.filter((item: UploadFile) => item.uid !== uid);

      return { files };
    },
  },

  subscriptions: {
    setup({ dispatch, history }: any) {
      return history.listen(({ pathname }: Location) => {
        // When location changes, clear file list
        dispatch({ type: 'clearFileList' });
      });
    },
  },
};

import * as React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const Cruise = Loadable.Map({
  loader: {
    Cruise: () => import('./Cruise'),
    model: () => import('./models/Cruise'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const Cruise = loaded.Cruise.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <Cruise {...props} />;
  },
});

export { Cruise };

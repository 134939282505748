import { Card, Col, Modal, Row } from 'antd';
import { connect } from 'dva';
import React, { FC, useEffect, useState } from 'react';

import { Dispatch } from '../../../models/dispatch';
import styles from './CopyPriceModal.module.scss';

const checkedIcon = `${process.env.PUBLIC_URL}/images/bottom-check@3x.png`;

interface DvaProps {
  pricingManageList: any[];
  PricePagination: any;
  fetchAggPricingManage: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  visible: boolean;
  onCancel: () => void;
  handleSubmitCompanyId: (id: string) => void;
}

const CopyPriceModal: FC<InternalProps> = (props) => {
  const { visible, onCancel, fetchAggPricingManage, pricingManageList, handleSubmitCompanyId } = props;
  const [checkId, setCheckId] = useState<string>('');

  useEffect(() => {
    fetchAggPricingManage();
  }, [fetchAggPricingManage]);

  const handleChecked = (item: any) => {
    setCheckId(item.id);
  };

  const handleCompanyId = () => {
    handleSubmitCompanyId(checkId);
    onCancel();
  };

  return (
    <Modal
      width={800}
      title="复制价格表"
      visible={visible}
      onCancel={onCancel}
      onOk={handleCompanyId}
      className={styles.copyCard}
    >
      <Row gutter={24} style={{ padding: '16px 0' }}>
        {pricingManageList.map((item: any, index: number) => {
          return (
            <Col key={index} xl={8} lg={8} md={12} xs={24} style={{ marginBottom: 12 }}>
              <Card className={styles.active} onClick={() => handleChecked(item)}>
                <Row className={styles.cardTitle} align="middle">
                  <img src={item.company && item.company.logoUrl} alt="" />
                  <span className={styles.text}>{item.company && item.company.name}</span>
                </Row>
                <Row className={styles.cardBody}>
                  <img
                    alt="checked"
                    src={checkedIcon}
                    className={styles.checkImg}
                    style={{ display: checkId === item.id ? 'block' : 'none' }}
                  />
                  {item.postageSummaries && item.postageSummaries.length
                    ? ` 首重${item.postageSummaries[0].lowestInitialWeightCost}元，续重${item.postageSummaries[0].lowestAdditionalWeightCost}元`
                    : '暂无首重续重信息'}
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Modal>
  );
};

const mapStateToProps = ({ loading, settingPrice }: any) => {
  return {
    loading: loading.effects['settingPrice/fetchSettingPriceList'],
    pricingManageList: settingPrice.pricingManageList,
    PricePagination: settingPrice.PricePagination,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchAggPricingManage: (params: object) => {
      dispatch({
        type: 'settingPrice/fetchAggPricingManage',
        payload: params,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyPriceModal);

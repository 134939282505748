import { END, eventChannel, EventChannel } from 'redux-saga';

import { UploadConfig } from '../utils/bucketTool';
import { source } from './AxiosInstance';
// tslint:disable-next-line: no-var-requires
const OSS = require('ali-oss');

export interface OSSInfo {
  host: string;
  policy: string;
  accessId: string;
  accessKeyId: string;
  signature: string;
  securityToken: string;
  accessKeySecret: string;
  region: string;
}

export class FileService {
  private ossClient: any;

  private getOssClient(ossInfo: OSSInfo) {
    return new OSS({
      region: `oss-${ossInfo.region}`,
      accessKeyId: ossInfo.accessKeyId,
      accessKeySecret: ossInfo.accessKeySecret,
      bucket: UploadConfig && UploadConfig.deviceOTA,
      stsToken: ossInfo.securityToken,
    });
  }

  public createUploadChannel(key: string, file: File, ossInfo: OSSInfo): EventChannel<any> {
    this.ossClient = this.getOssClient(ossInfo);

    return eventChannel((emitter: (params?: any) => void) => {
      const config = {
        parallel: 3,
        partSize: 1024 * 1024,
        progress: (progress: any, checkpoint: any) => {
          emitter({ percent: progress * 100 });
        },
      };

      this.ossClient
        .multipartUpload(key, file, config)
        .then((res: any) => {
          if (res) {
            emitter(res);
            emitter(END);
          }
        })
        .catch((err: Error) => {
          emitter(err);
          emitter(END);
        });

      return () => {
        source.cancel();
      };
    });
  }
}

import { Col, Form, Input, Row } from 'antd';
import { get } from 'lodash';
import React, { FC } from 'react';

import { ValidatedParams } from '../models/PendingUserList';

const { Search } = Input;

interface InternalProps {
  query: ValidatedParams;
  onSearchChange: (params: object) => any;
}

const ValidatedHeader: FC<InternalProps> = (props) => {
  const { query, onSearchChange } = props;
  return (
    <Form className="base-form">
      <Row gutter={24}>
        {query.validated ? (
          <Col xl={6} lg={8} md={12} xs={24}>
            <Form.Item label="操作人">
              <Search
                allowClear
                placeholder="请输入姓名或手机号"
                value={get(query, 'operator')}
                onSearch={(value) => onSearchChange({ operator: value })}
                onChange={(e: any) => onSearchChange({ operator: e.target.value })}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col xl={6} lg={8} md={12} xs={24}>
            <Form.Item label="入库员">
              <Search
                allowClear
                placeholder="请输入姓名或手机号"
                value={get(query, 'createdBy')}
                onSearch={(value) => onSearchChange({ createdBy: value })}
                onChange={(e: any) => onSearchChange({ createdBy: e.target.value })}
              />
            </Form.Item>
          </Col>
        )}
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="订单号">
            <Search
              allowClear
              placeholder="请输入"
              value={get(query, 'orderNumber')}
              onSearch={(value) => onSearchChange({ orderNumber: value })}
              onChange={(e: any) => onSearchChange({ orderNumber: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="包裹号">
            <Search
              allowClear
              placeholder="请输入包裹号"
              value={get(query, 'shelfNumber')}
              onSearch={(value) => onSearchChange({ shelfNumber: value })}
              onChange={(e: any) => onSearchChange({ shelfNumber: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ValidatedHeader;

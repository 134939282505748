import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST, PUT } from './AxiosInstance';

export class RobotService {
  public static fetchRobotList(params?: object): Promise<any> {
    return GET(ApiUrl.ROBOT, params);
  }

  public static fetchRobotSettingList(params?: object): Promise<any> {
    return GET(ApiUrl.ROBOT_CONFIGURATION, params);
  }

  public static fetchRobotSettingDetail(id: string, params?: object): Promise<any> {
    return GET(ApiUrl.ROBOT_CONFIGURATION + '/' + id, params);
  }

  public static fetchRobotHubSetting(params?: object): Promise<any> {
    return GET(ApiUrl.ROBOT_ACTIVATED, params);
  }

  public static createConfiguration(params?: object): Promise<any> {
    return POST(ApiUrl.ROBOT_CONFIGURATION, params);
  }

  public static deleteConfiguration(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.ROBOT_CONFIGURATION + '/' + id, params);
  }

  public static patchConfiguration(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.ROBOT_CONFIGURATION + '/' + id, params);
  }

  public static activeConfiguration(id: string, params?: object): Promise<any> {
    return POST(ApiUrl.ROBOT_CONFIGURATION + '/' + id + '/activate', params);
  }

  public static defaultConfiguration(params?: object): Promise<any> {
    return POST(ApiUrl.ROBOT_CONFIGURATION + '/default/activate', params);
  }

  public static patchCandlelightSetting(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.ROBOT_CONFIGURATION + '/' + id + '/candlelight', params);
  }

  public static fetchDockList(params?: object): Promise<any> {
    return GET(ApiUrl.DOCK, params);
  }

  public static fetchBayList(params?: object): Promise<any> {
    return GET(ApiUrl.BAY, params);
  }

  public static postDock(params?: object): Promise<any> {
    return POST(ApiUrl.DOCK, params);
  }

  public static patchDock(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.DOCK + '/' + id, params);
  }

  public static deleteDock(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.DOCK + '/' + id, params);
  }

  public static fetchRobot(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.ROBOT}/${id}`, params);
  }

  public static postRobot(params?: object): Promise<any> {
    return POST(ApiUrl.ROBOT, params);
  }

  public static postSoftware(params?: object): Promise<any> {
    return POST(ApiUrl.SOFTWARE, params);
  }

  public static postSoftwareCommand(params?: object): Promise<any> {
    return POST(ApiUrl.SOFTWARE_COMMAND, params);
  }

  public static patchRobot(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.ROBOT}/${id}`, params);
  }

  public static deleteRobot(id: string, params?: object): Promise<any> {
    return DELETE(`${ApiUrl.ROBOT}/${id}`, params);
  }

  public static fetchElevatorList(params?: object): Promise<any> {
    return GET(ApiUrl.AGG_ROBOT_ELEVATOR, params);
  }

  public static postElevatorList(params?: object): Promise<any> {
    return POST(ApiUrl.ROBOT_ELEVATOR, params);
  }

  public static patchElevatorList(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.ROBOT_ELEVATOR + '/' + id, params);
  }

  public static deleteElevatorList(id: string): Promise<any> {
    return DELETE(ApiUrl.ROBOT_ELEVATOR + '/' + id);
  }

  public static fetchHive(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.HIVE}/${id}`, params);
  }

  public static postHive(params?: object): Promise<any> {
    return POST(ApiUrl.HIVE, params);
  }

  public static patchHive(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.HIVE}/${id}`, params);
  }

  public static patchSoftware(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.SOFTWARE}/${id}`, params);
  }

  public static deleteHive(id: string, params?: object): Promise<any> {
    return DELETE(`${ApiUrl.HIVE}/${id}`, params);
  }

  public static fetchReportSummary(params?: object): Promise<any> {
    return GET(ApiUrl.REPORT_DEVICE_SUMMARY, params);
  }

  public static fetchSoftwareHistory(params?: object): Promise<any> {
    return GET(ApiUrl.SOFTWARE_HISTORY, params);
  }

  public static fetchSoftware(params?: object): Promise<any> {
    return GET(ApiUrl.SOFTWARE, params);
  }

  public static deleteSoftware(id: string, params?: object): Promise<any> {
    return DELETE(`${ApiUrl.SOFTWARE}/${id}`, params);
  }

  public static fetchComments(params?: object): Promise<any> {
    return GET(ApiUrl.ROBOT_COMMENT, params);
  }

  public static postComment(params?: object): Promise<any> {
    return POST(ApiUrl.ROBOT_COMMENT, params);
  }

  public static patchComment(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.ROBOT_COMMENT}/${id}`, params);
  }

  public static deleteComment(id: string, params?: object): Promise<any> {
    return DELETE(`${ApiUrl.ROBOT_COMMENT}/${id}`, params);
  }

  public static fetchHiveLayout(params?: object): Promise<any> {
    return GET(`${ApiUrl.HIVE}/layout`, params);
  }

  public static fetchModelManageList(params?: object): Promise<any> {
    return GET(`${ApiUrl.ROBOT_MODEL}`, params);
  }

  public static postModelManageList(params?: object): Promise<any> {
    return POST(`${ApiUrl.ROBOT_MODEL}`, params);
  }

  public static removeModelManageList(id?: number): Promise<any> {
    return DELETE(`${ApiUrl.ROBOT_MODEL}/${id}`);
  }

  public static updateModelManageList(id?: number, params?: object): Promise<any> {
    return PUT(`${ApiUrl.ROBOT_MODEL}/${id}`, params);
  }

  public static fetchAggProductManageList(params?: object): Promise<any> {
    return GET(`${ApiUrl.ROBOT_MANAGEMENT}`, params);
  }
}

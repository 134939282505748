import { ApiUrl } from './ApiConfig';
import { GET } from './AxiosInstance';

export class HiveService {

  public static fetchHiveList(params?: any): Promise<any> {
    return GET(ApiUrl.HIVE, params);
  }

}

import { ApiUrl } from './ApiConfig';
import { GET } from './AxiosInstance';

export class LogisticService {
  public static fetchDispatchHiveList(params?: any): Promise<any> {
    return GET(ApiUrl.LOGISTIC_HIVE, params);
  }

  public static fetchDispatchHiveCount(params?: any): Promise<any> {
    return GET(ApiUrl.LOGISTIC_HIVE_STATE_COUNT, params);
  }

  public static fetchDispatchUserList(params?: any): Promise<any> {
    return GET(ApiUrl.LOGISTIC_USER, params);
  }

  public static fetchDispatchOrderList(params?: any): Promise<any> {
    return GET(ApiUrl.LOGISTIC_DISPATCH, params);
  }

  public static fetchDispatchOrderSharedList(params?: any): Promise<any> {
    return GET(ApiUrl.LOGISTIC_DISPATCH_ORDER_SHARED, params);
  }

  public static fetchTransportSharedOrderList(params?: any): Promise<any> {
    return GET(ApiUrl.LOGISTIC_DISPATCH_TRANSPORT_SHARED, params);
  }

  public static fetchDispatchUserCount(params?: any): Promise<any> {
    return GET(ApiUrl.LOGISTIC_HIVE_STATE_COUNT, params);
  }
}

import { Stomp } from '@stomp/stompjs';
import { message } from 'antd';
import { eventChannel, EventChannel } from 'redux-saga';
import SockJS from 'sockjs-client';

import { ServerEnv, __PROD__ } from '../env';
import { localStorageKey } from '../utils/constants';

const serverEnv = ServerEnv();
const api = serverEnv.api;

export class SocketService {
  public static stompClient: any;
  public static subscription: any[] = [];

  public static instance(url: string) {
    if (!SocketService.stompClient) {
      const sockJS = new SockJS(`${api}${url}?Authorization=${SocketService.token}`);
      SocketService.stompClient = Stomp.over(sockJS);

      if (__PROD__) {
        // 生产环境关闭debug模式
        SocketService.stompClient.debug = () => {};
      }
    }

    return new SocketService();
  }

  public static get token(): string {
    const appKeys = JSON.parse(localStorage.getItem(localStorageKey.APP_KEY_STORE) || '{}');
    return appKeys.token_type + ' ' + appKeys.access_token || '';
  }

  public onConnect(id?: string): Promise<any> {
    const headers = {
      'client-id': id,
      login: 'login',
      passcode: 'passcode',
      Authorization: SocketService.token,
    };

    return new Promise((resolve, reject) => {
      SocketService.stompClient?.connect(
        headers,
        (frame: any) => {
          resolve();
        },
        (err: Error) => {
          message.info(`Socket 连接错误! ${err.message}`);
          reject(err);
        }
      );
    });
  }

  public onSubscribe(event: string, subId?: string): EventChannel<any> {
    return eventChannel((emit: (params: any) => void) => {
      if (!SocketService?.stompClient?.connected) {
        return () => {};
      }

      const sub = SocketService.stompClient?.subscribe(
        event,
        (response: any) => {
          if (response.body) {
            const data = JSON.parse(response.body);
            emit(data);
          }
        },
        { id: subId }
      );

      SocketService.subscription.push(sub);

      return () => {
        sub.unsubscribe();
      };
    });
  }

  public onUnsubscribe(subId?: string) {
    // 如果有subId, 就取消订阅subId所对应的订阅，否则取消所有订阅
    if (subId) {
      SocketService.subscription
        .map((item: any) => {
          if (item.id === subId) {
            item.unsubscribe();
          }
          return item;
        })
        .filter((d: any) => d.id !== subId);
    } else {
      SocketService.subscription.map((item: any) => item.unsubscribe());
      SocketService.subscription = [];
    }
  }

  public onDisconnect() {
    SocketService.stompClient?.deactivate();
    SocketService.stompClient = null;
  }
}

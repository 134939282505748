import * as React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';
import { OperationStatusTag, TransferModal } from './components';

const RobotList = Loadable.Map({
  loader: {
    Robot: () => import('./RobotList'),
    model: () => import('./models/RobotList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const Robot = loaded.Robot.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <Robot {...props} />
      </Loading>
    );
  },
});

const RobotDetail = Loadable.Map({
  loader: {
    RobotDetail: () => import('./RobotDetail'),
    model: () => import('./models/RobotList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RobotDetail = loaded.RobotDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <RobotDetail {...props} />
      </Loading>
    );
  },
});

const MonitorCenter = Loadable.Map({
  loader: {
    MonitorCenter: () => import('./MonitorCenter'),
    model: () => import('./models/MonitorCenter'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const MonitorCenter = loaded.MonitorCenter.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <MonitorCenter {...props} />;
  },
});

const DeliveryHistory = Loadable.Map({
  loader: {
    DeliveryHistoryList: () => import('./DeliveryHistory'),
    model: () => import('./models/DeliveryHistory'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeliveryHistoryList = loaded.DeliveryHistoryList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <DeliveryHistoryList {...props} />;
  },
});

const DeliveryDetail = Loadable.Map({
  loader: {
    DeliveryDetailList: () => import('./DeliveryDetail'),
    model: () => import('./models/DeliveryDetail'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeliveryDetailList = loaded.DeliveryDetailList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <DeliveryDetailList {...props} />;
  },
});

const RobotMileage = Loadable.Map({
  loader: {
    RobotMileageList: () => import('./RobotMileage'),
    model: () => import('./models/RobotMileage'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RobotMileageList = loaded.RobotMileageList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <RobotMileageList {...props} />;
  },
});

const CommandHistory = Loadable.Map({
  loader: {
    CommandHistory: () => import('./CommandHistory'),
    model: () => import('./models/CommandHistory'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CommandHistory = loaded.CommandHistory.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <CommandHistory {...props} />;
  },
});

const ScheduleRequest = Loadable.Map({
  loader: {
    ScheduleRequest: () => import('./ScheduleRequest'),
    model: () => import('./models/Schedule'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const ScheduleRequest = loaded.ScheduleRequest.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <ScheduleRequest {...props} />;
  },
});

const ScheduleHistory = Loadable.Map({
  loader: {
    ScheduleHistory: () => import('./ScheduleHistory'),
    model: () => import('./models/Schedule'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const ScheduleHistory = loaded.ScheduleHistory.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <ScheduleHistory {...props} />;
  },
});

export {
  RobotList,
  RobotDetail,
  MonitorCenter,
  TransferModal,
  DeliveryHistory,
  DeliveryDetail,
  RobotMileage,
  CommandHistory,
  ScheduleRequest,
  OperationStatusTag,
  ScheduleHistory,
};

import { Form, message, Modal, Select } from 'antd';
import { connect } from 'dva';
import React, { FC, useEffect, useState } from 'react';

import { Dispatch } from '../../../models/dispatch';
import { filterOption } from '../../../utils';
import { DeliveryMethod, OrderState } from '../../../utils/constants';

const { Option } = Select;

interface DvaProps {
  globalHub: any;
  loading: boolean;
  courierOption: any;
  uploadCancel: (status?: string) => void;
  fetchCourierOption: (params: object) => void;
}

interface InternalProps extends DvaProps {
  record?: any;
  visible?: boolean;
  onCancel?: () => void;
  onSubmit: (id: string, params: object) => void;
}

export const RobotDispatchingOrderModal: FC<InternalProps> = (props) => {
  const { record, courierOption, loading, visible, onCancel, onSubmit, fetchCourierOption } = props;
  const [form] = Form.useForm();
  const { resetFields, validateFields, setFieldsValue } = form;
  const [deliveryMethod, setDeliveryMethod] = useState('');

  useEffect(() => {
    fetchCourierOption({ pageSize: 200 });
  }, [fetchCourierOption]);

  useEffect(() => {
    if (record) {
      setFieldsValue(record);
    }
  }, [setFieldsValue, record]);

  const handleSubmit = async () => {
    const values = await validateFields();

    if (record && record.length === 0) {
      message.warning('请选中一个或多个订单后进行本操作!');
      handleCancel();
      return;
    }

    const { deliveryMethod, courier } = values;
    const ids = record && record.map((item: any) => item.id);
    const deliveryMethodParams = {
      deliveryMethod: DeliveryMethod.MANUAL,
      orderState: OrderState.PENDING,
    };

    if (deliveryMethod === DeliveryMethod.MANUAL) {
      if (courierOption && courierOption.length === 0) {
        message.warning('当前园区没有可分配快递员');
        return;
      }
      onSubmit(ids, deliveryMethodParams);
    } else {
      onSubmit(ids, { ...deliveryMethodParams, assignedUserId: courier });
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setDeliveryMethod('');
  };

  return (
    <Modal
      centered
      forceRender
      okText="确定"
      title="转人工配送"
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      confirmLoading={loading}
      afterClose={resetFields}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item name="deliveryMethod" label="分配方式" rules={[{ required: true, message: '请选择分配方式!' }]}>
          <Select
            filterOption={false}
            placeholder="请选择分配方式"
            onChange={(value: string) => setDeliveryMethod(value)}
          >
            <Option value="MANUAL">自动分配</Option>
            <Option value="COURIER">快递员</Option>
          </Select>
        </Form.Item>
        {deliveryMethod === 'COURIER' && (
          <Form.Item name="courier" label="请选择快递员" rules={[{ required: true, message: '请选择快递员!' }]}>
            <Select placeholder="请选择快递员" showSearch optionFilterProp="children" filterOption={filterOption}>
              {courierOption &&
                courierOption.map((item: any, index: number) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ global, common, loading }: any) => {
  return {
    globalHub: global.userInfo.hub,
    courierOption: common.courierOption,
    loading: loading.effects['userList/createUser'] || loading.effects['userList/updateUser'],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchCourierOption: (params: object) => {
      dispatch({
        type: 'common/fetchCourierOption',
        payload: { params },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RobotDispatchingOrderModal);

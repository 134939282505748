import { EditOutlined } from '@ant-design/icons';
import { Descriptions, Divider, Typography } from 'antd';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { CSSProperties, FC, useEffect, useState } from 'react';

import { EditableForm, EditableInput, EditableNumber, EditableSelect } from '../../../components';
import { Dispatch } from '../../../models/dispatch';
import {
  GoodsTypeOption,
  GoodsTypeStr,
  OrderType,
  PaymentMethodStr,
  ValuationMethodStr,
} from '../../../utils/constants';
import { PhoneReg } from '../../../utils/regTool';
import ModifyAddressModal from './ModifyAddressModal';
import ModifyRealNameModal from './ModifyRealNameModal';

const { Text } = Typography;
const wrapperStyle: CSSProperties = { paddingLeft: 0 };

interface DvaProps {
  companyOption: any;
  showModifyAddressModal: boolean;
  showModifyRealNameModal: boolean;
  fetchCompanyOption: (params?: object) => void;
  updateUserInfo: (id: string, params?: object) => void;
  updateOrderInfo: (id: string, params?: object) => void;
  patchUserInfo: (id: string, params?: object) => void;
  postCompanyEstimate: (orderId: string, params?: object) => void;
  toShowModal: (key: string, show: boolean) => void;
  updateAddressInfo: (orderId: string, orderType: OrderType, params?: object) => void;
}

interface InternalProps extends DvaProps {
  orderInfo: any;
  updating: boolean;
}

export const ShippingOrderInfo: FC<InternalProps> = (props) => {
  const {
    orderInfo,
    updating,
    toShowModal,
    patchUserInfo,
    updateOrderInfo,
    updateAddressInfo,
    fetchCompanyOption,
    postCompanyEstimate,
    showModifyAddressModal,
    showModifyRealNameModal,
  } = props;
  const [modifyMsg, setModifyMsg] = useState<string>('');
  const [subOrderInfo, setSubOrderInfo] = useState<any>(orderInfo);

  useEffect(() => {
    fetchCompanyOption();
  }, [fetchCompanyOption, updating]);

  const handleSave = (params: any) => {
    const { id, ...resetPops } = params;
    updateOrderInfo(id, { ...resetPops });
  };

  const handleSubmitData = (params?: any) => {
    const { id, ...others } = params;
    const { id: orderId, userId } = orderInfo;
    const orderType = modifyMsg === 'addressEntity' ? OrderType.COLLECT : OrderType.SEND;
    updateAddressInfo(orderId, orderType, { userId, ...others });
  };

  const handleSaveValuation = (params?: any) => {
    const { id, ...others } = params;
    const {
      id: orderId,
      packageWeight = 1,
      packageLength = null,
      packageWidth = null,
      packageHeight = null,
      postageId = null,
      hubId,
      companyId,
      receiverAddressEntity,
    } = orderInfo;
    const valuationParams = {
      packageWeight,
      packageLength,
      packageWidth,
      packageHeight,
      ...others,
    };
    const estimateParams = {
      weight: valuationParams.packageWeight,
      length: valuationParams.packageLength,
      width: valuationParams.packageWidth,
      height: valuationParams.packageHeight,
      province: receiverAddressEntity.province || null,
      hubId,
      companyId,
      postageId,
    };

    postCompanyEstimate(orderId, { estimateParams, orderParams: others });
  };

  const handleModifyAddress = (key: string) => {
    setModifyMsg(key);
    setSubOrderInfo({ ...subOrderInfo, [key]: orderInfo[key] });
    toShowModal('showModifyAddressModal', true);
  };

  const handleSubmitValues = (values?: any) => {
    const { userId, ...others } = values;
    patchUserInfo(userId, others);
  };

  return (
    <>
      <EditableForm index={0} type="normal">
        <Descriptions title="支付信息">
          <Descriptions.Item label="总价(元)">
            <Text strong>{get(orderInfo, 'totalCost', '暂无')}元</Text>
          </Descriptions.Item>
          <Descriptions.Item label="运费(元)" className="editable-row">
            <EditableNumber
              editable={!get(orderInfo, 'paid')}
              title="运费"
              record={orderInfo}
              dataIndex="cost"
              wrapperStyle={wrapperStyle}
              min={0}
              step={0.01}
              onSubmit={handleSave}
            >
              <Text strong>{get(orderInfo, 'cost', '暂无')}元</Text>
            </EditableNumber>
          </Descriptions.Item>
          <Descriptions.Item label="包装费(元)" className="editable-row">
            <EditableNumber
              editable={!get(orderInfo, 'paid')}
              title="包装费"
              record={orderInfo}
              dataIndex="packagingCost"
              wrapperStyle={wrapperStyle}
              min={0}
              step={0.01}
              onSubmit={handleSave}
            >
              <Text strong>{get(orderInfo, 'packagingCost', '暂无')}元</Text>
            </EditableNumber>
          </Descriptions.Item>
          <Descriptions.Item label="支付方式">
            <Text strong>{PaymentMethodStr[get(orderInfo, 'deliveryFeeType')]}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="支付状态">
            <Text strong>{get(orderInfo, 'paid') ? '已支付' : '未支付'}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="计价方式">
            <Text strong>{ValuationMethodStr[get(orderInfo, 'valuationMethod')] || '暂无'}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="物品类型" className="editable-row">
            <EditableSelect
              editable
              title="物品类型"
              record={orderInfo}
              dataIndex="goodsType"
              options={GoodsTypeOption}
              wrapperStyle={wrapperStyle}
              onSubmit={handleSave}
            >
              <Text strong>{GoodsTypeStr[get(orderInfo, 'goodsType')]}</Text>
            </EditableSelect>
          </Descriptions.Item>
          <Descriptions.Item label="物品备注" className="editable-row">
            <EditableInput
              editable
              title="备注"
              record={orderInfo}
              dataIndex="remark"
              wrapperStyle={wrapperStyle}
              onSubmit={handleSave}
            >
              <Text strong>{get(orderInfo, 'remark', '暂无')}</Text>
            </EditableInput>
          </Descriptions.Item>
          <Descriptions.Item label="物品重量(千克)" className="editable-row">
            <EditableNumber
              editable
              title="重量"
              record={orderInfo}
              dataIndex="packageWeight"
              wrapperStyle={wrapperStyle}
              onSubmit={handleSaveValuation}
            >
              <Text strong>{get(orderInfo, 'packageWeight', '暂无')}</Text>
            </EditableNumber>
          </Descriptions.Item>
          <Descriptions.Item label="物品尺寸长(厘米)" className="editable-row">
            <EditableNumber
              editable
              title="长度"
              record={orderInfo}
              dataIndex="packageLength"
              wrapperStyle={wrapperStyle}
              onSubmit={handleSaveValuation}
            >
              <Text strong>{get(orderInfo, 'packageLength', '暂无')}</Text>
            </EditableNumber>
          </Descriptions.Item>
          <Descriptions.Item label="物品尺寸宽(厘米)" className="editable-row">
            <EditableNumber
              editable
              record={orderInfo}
              title="宽度"
              dataIndex="packageWidth"
              wrapperStyle={wrapperStyle}
              onSubmit={handleSaveValuation}
            >
              <Text strong>{get(orderInfo, 'packageWidth', '暂无')}</Text>
            </EditableNumber>
          </Descriptions.Item>
          <Descriptions.Item label="物品尺寸高(厘米)" className="editable-row">
            <EditableNumber
              editable
              title="高度"
              record={orderInfo}
              dataIndex="packageHeight"
              wrapperStyle={wrapperStyle}
              onSubmit={handleSaveValuation}
            >
              <Text strong>{get(orderInfo, 'packageHeight', '暂无')}</Text>
            </EditableNumber>
          </Descriptions.Item>
          <Descriptions.Item label="物品数量">
            <Text strong>{get(orderInfo, 'quantity', '暂无')}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="物品包装">
            <Text strong>{get(orderInfo, 'packagingRequired') ? '有' : '无'}</Text>
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Descriptions title="寄件信息">
          <Descriptions.Item label="寄件人姓名">
            <Text strong>
              {get(orderInfo, 'addressEntity.name')}
              <EditOutlined
                type="edit"
                onClick={() => {
                  handleModifyAddress('addressEntity');
                }}
              />
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="寄件人手机号">
            <Text strong>{get(orderInfo, 'addressEntity.mobile')}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="寄件人地址">
            <Text strong>{get(orderInfo, 'addressEntity.address')}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="收件人姓名">
            <Text strong>
              {get(orderInfo, 'receiverAddressEntity.name')}
              <EditOutlined
                onClick={() => {
                  handleModifyAddress('receiverAddressEntity');
                }}
              />
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="收件人手机号" className="editable-row">
            <EditableInput
              required
              editable
              title="手机号"
              dataIndex="mobile"
              onSubmit={handleSave}
              wrapperStyle={wrapperStyle}
              record={orderInfo.receiverAddressEntity}
              rules={[{ pattern: PhoneReg, message: '请输入正确的手机号!' }]}
            >
              <Text strong>{get(orderInfo, 'receiverAddressEntity.mobile', '暂无')}</Text>
            </EditableInput>
          </Descriptions.Item>
          <Descriptions.Item label="收件人地址" className="editable-row">
            <Text strong>{get(orderInfo, 'receiverAddressEntity.address', '暂无')}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="是否实名认证" className="editable-row">
            <Text strong>
              {get(orderInfo, 'user.realName') ? '已实名' : '未实名'}
              <EditOutlined
                onClick={() => {
                  setSubOrderInfo({ ...subOrderInfo, user: orderInfo.user });
                  toShowModal('showModifyRealNameModal', true);
                }}
              />
            </Text>
          </Descriptions.Item>
        </Descriptions>
      </EditableForm>
      <ModifyAddressModal
        orderInfo={subOrderInfo}
        modifyMsg={modifyMsg}
        onSubmit={handleSubmitData}
        visible={showModifyAddressModal}
        afterClose={() => setSubOrderInfo({ ...subOrderInfo, [modifyMsg]: {} })}
        onCancel={() => toShowModal('showModifyAddressModal', false)}
      />
      <ModifyRealNameModal
        orderInfo={subOrderInfo}
        onSubmit={handleSubmitValues}
        visible={showModifyRealNameModal}
        afterClose={() => setSubOrderInfo({ ...subOrderInfo, user: {} })}
        onCancel={() => toShowModal('showModifyRealNameModal', false)}
      />
    </>
  );
};

const mapStateToProps = ({ common, pieceOrder, loading }: any) => {
  const companyList: any = [];

  common.companyOption.map((item: any) => {
    companyList.push({ label: item.name, value: item.id });
    return companyList;
  });

  return {
    companyOption: companyList,
    orderInfo: pieceOrder.orderInfo,
    showModifyAddressModal: common.showModifyAddressModal,
    showModifyRealNameModal: common.showModifyRealNameModal,
    loading: loading.effects['pieceOrder/fetchOrderInfo'],
    updating: loading.effects['pieceOrder/updateOrderInfo'],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompanyOption: (params?: object) => {
    dispatch({
      type: 'common/fetchCompanyOption',
      payload: params,
    });
  },
  fetchOrderInfo: (id: string) => {
    dispatch({
      type: 'pieceOrder/fetchOrderInfo',
      payload: id,
    });
  },
  updateAddressInfo: (orderId: string, orderType: OrderType, params?: object) => {
    dispatch({
      type: 'pieceOrder/postAddressInfo',
      payload: { orderId, orderType, params },
    });
  },
  updateOrderInfo: (id: string, params?: object) => {
    dispatch({
      type: 'pieceOrder/patchOrderInfo',
      payload: { id, params },
    });
  },
  patchUserInfo: (id: string, params?: object) => {
    dispatch({
      type: 'pieceOrder/patchOrderInfo',
      payload: { id, params },
    });
  },
  postCompanyEstimate: (orderId: string, params?: object) => {
    dispatch({
      type: 'pieceOrder/postCompanyEstimate',
      payload: { orderId, params },
    });
  },
  toShowModal: (key: string, show: boolean) => {
    dispatch({
      type: 'common/showOrHideModal',
      key,
      payload: show,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingOrderInfo);

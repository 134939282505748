import { notification } from 'antd';
import CryptoJS from 'crypto-js';
import { routerRedux } from 'dva/router';
import JSEncrypt from 'jsencrypt';
import queryString from 'query-string';

import { rsaPublicKey } from '../env';
import { Dispatch } from '../models/dispatch';
import { Routes } from '../routes';
import { AxiosInstance } from '../services';
import { localStorageKey } from './constants';
import { getStorageKey } from './localStorage';
import { UrlQueryReg } from './regTool';

/**
 * Use window.atob to decode jwt token
 * @param token
 */
export function parseJWT(token: string) {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (error) {
    return null;
  }
}

/**
 * Override AxiosInstance token value
 * @param token
 */
export function overrideToken(token: string, tokenType: string = 'Bearer') {
  // 重写 axios headers authorization token 配置
  AxiosInstance.defaults.headers = {
    Authorization: `${tokenType} ${token}`,
  };
}

/**
 * 获取加密密码之后的字符串
 * @param {string} password
 */
export function getEncryptedPwd(password: string): string {
  const jsEncrypt = new JSEncrypt();
  const SHA1 = CryptoJS.SHA1(password);
  jsEncrypt.setPublicKey(rsaPublicKey);
  return jsEncrypt.encrypt(CryptoJS.enc.Hex.stringify(SHA1));
}

export function handleAuthorize(pathname: string, dispatch: Dispatch) {
  try {
    const appData = getStorageKey(localStorageKey.APP_KEY_STORE);
    const hubData = getStorageKey(localStorageKey.HUB_KEY_STORE);
    const appView = getStorageKey(localStorageKey.APP_VIEW_STORE);

    if (appData) {
      const accessToken = appData.access_token;

      overrideToken(accessToken);

      // 解码 jwt token
      const decoded = parseJWT(accessToken);
      const userId = decoded.user_name;
      const expireTime = decoded.exp;
      const now = new Date().getTime();

      // 判断Token是否过期，如果过期就退出登录
      if (expireTime * 1000 <= now) {
        dispatch({ type: 'logout' });
        notification.error({ message: '令牌已过期，请重新登录系统！' });
        return;
      }

      dispatch({ type: 'authorize', payload: { isAuthenticated: true, permissions: [] } });
      dispatch({ type: 'changeLayoutCollapsed', payload: appView ? appView.collapsed : false });

      if (pathname === Routes.Login) {
        dispatch(routerRedux.push(Routes.App));
      }

      dispatch({ type: 'loadUserId', payload: userId });
      dispatch({ type: 'loadCurrentHub', payload: hubData });
    } else {
      if ([Routes.Login, Routes.ForgetPassword].includes(pathname as Routes)) {
        return;
      }

      const queryParams = window.location.hash.match(UrlQueryReg);
      const params = queryParams ? queryString.parse(queryParams[0]) : null;

      if (params?.accessToken) {
        // 如果第三方有accessToken参数，可以直接的登录
        const authData = { token_type: 'Bearer', access_token: params?.accessToken };
        localStorage.setItem(localStorageKey.APP_KEY_STORE, JSON.stringify(authData));

        overrideToken(params?.accessToken as string);
        dispatch({ type: 'fetchUserByToken', payload: params.accessToken });
      } else {
        const redirectUrl = window.location.href.includes('login') ? null : window.location.href || null;
        dispatch({ type: 'logout', payload: redirectUrl });
      }
    }
  } catch (error) {
    throw error;
  }
}

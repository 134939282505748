import { Form, Modal, Select } from 'antd';
import React, { FC } from 'react';

import { PaymentMethodStr } from '../../../utils/constants';
import { filterOption } from '../../../utils/utils';

const Option = Select.Option;

interface InternalProps {
  record: any;
  visible: boolean;
  companyOption: any[];
  onCancel: () => void;
  onSubmit: (values: any) => void;
}
const CreateHandOverListModal: FC<InternalProps> = (props) => {
  const { visible, onCancel, record, onSubmit, companyOption } = props;
  const [form] = Form.useForm();
  const { validateFields } = form;

  const handleSubmitData = async () => {
    const values = await validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      centered
      forceRender
      destroyOnClose={false}
      getContainer={false}
      title="创建清单"
      onOk={handleSubmitData}
      visible={visible}
      onCancel={onCancel}
      afterClose={form.resetFields}
    >
      <Form layout="vertical" onFinish={handleSubmitData} initialValues={record} form={form}>
        <Form.Item label="公司" name="companyId" rules={[{ required: true, message: '请选择公司' }]}>
          <Select
            showSearch
            placeholder="请选择公司"
            optionFilterProp="children"
            filterOption={filterOption}
            onChange={(value: string) => { }}
          >
            {companyOption.map((item: any, index) => {
              return (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="支付方式" name="deliveryFeeType" rules={[{ required: true, message: '请选择支付方式' }]}>
          <Select placeholder="请选择" showSearch optionFilterProp="children" filterOption={filterOption}>
            {Object.keys(PaymentMethodStr).map((item, index) => {
              return (
                <Option value={item} key={index}>
                  {PaymentMethodStr[item]}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateHandOverListModal;

import { Col, Form, Modal, Row, Select } from 'antd';
import React, { FC } from 'react';
import { filterOption } from '../../../utils/utils';

interface InternalProps {
  visible: boolean;
  courierOption: any[];
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const { Option } = Select;

const AssignCourierModal: FC<InternalProps> = (props) => {
  const { visible, onCancel, courierOption, onSubmit } = props;
  const [form] = Form.useForm();
  const { resetFields, validateFields } = form;

  const handleSubmit = async () => {
    const values = await validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      centered
      forceRender
      destroyOnClose={false}
      getContainer={false}
      title="分配快递员"
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      afterClose={resetFields}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="userIds" label="快递员" rules={[{ required: true, message: '请选择快递员' }]}>
              <Select
                showSearch
                mode="multiple"
                placeholder="请选择快递员"
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {courierOption.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AssignCourierModal;

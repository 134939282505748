import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class TaskService {
  public static fetchTask(params?: object): Promise<any> {
    return GET(ApiUrl.TASK_TASK, params);
  }

  public static fetchCommand(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.TASK_COMMAND}/${id}`, params);
  }

  public static postTask(params?: any): Promise<any> {
    return POST(ApiUrl.TASK_TASK, params);
  }

  public static activateTask(
    id: string,
    params?: any,
    recursive: boolean = false,
    activate: boolean = false
  ): Promise<any> {
    return POST(`${ApiUrl.TASK_TASK}/${id}/activate?recursive=${recursive}&activate=${activate}`, params);
  }

  public static abortTask(id: string, params?: any): Promise<any> {
    return POST(`${ApiUrl.TASK_TASK}/${id}/abort`, params);
  }

  public static skipTask(id: string, commandId: string, interrupt: boolean = false): Promise<any> {
    return POST(`${ApiUrl.TASK_TASK}/${id}/skip/${commandId}?interrupt=${interrupt}`);
  }

  public static postTemplate(params?: any): Promise<any> {
    return POST(ApiUrl.TASK_TEMPLATE, params);
  }

  public static postCommand(params?: any): Promise<any> {
    return POST(ApiUrl.TASK_COMMAND, params);
  }

  public static patchTemplate(id: string, params?: any): Promise<any> {
    return PATCH(`${ApiUrl.TASK_TEMPLATE}/${id}`, params);
  }

  public static deleteTask(id: string): Promise<any> {
    return DELETE(`${ApiUrl.TASK_TASK}/${id}`);
  }

  public static deleteTemplate(id: string): Promise<any> {
    return DELETE(`${ApiUrl.TASK_TEMPLATE}/${id}`);
  }
}

import { Cascader, Col, Form, Input, Row, Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { ChangeEvent, FC, useEffect } from 'react';

import { Dispatch } from '../../../models/dispatch';
import { CascaderBuildingOption, CascaderFloorOption } from '../../../utils/constants';
import { filterOption, splitQuery, transformDataSource } from '../../../utils/utils';
import { OrderParams } from '../models/typed.d';

const { Item } = Form;
const { Search } = Input;
const { Option } = Select;

interface DvaProps {
  cascaderOptions: any[];
  companyOptions: DataSourceItemObject[];
  fetchCompanyOption: (params?: object) => void;
  fetchBuildingOption: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  query: OrderParams;
  activeKey: string;
  onSearchChange: (params: OrderParams) => void;
}

export const ManualOrderDetailHeader: FC<InternalProps> = (props) => {
  const { query, companyOptions, cascaderOptions, fetchCompanyOption, fetchBuildingOption, onSearchChange } = props;

  useEffect(() => {
    fetchCompanyOption();
    fetchBuildingOption({ resolveFloors: true, selectable: true });
  }, [fetchCompanyOption, fetchBuildingOption]);

  const defaultValue: any[] = query.floorId
    ? [query.buildingId, query.floorId]
    : query.buildingId
    ? [query.buildingId]
    : [];

  const onChange = (value: any, selectedOptions: any) => {
    if (value.length === 1) {
      onSearchChange({ buildingId: value[0], floorId: '' });
    } else if (value.length === 2) {
      onSearchChange({ floorId: value[1], buildingId: value[0] });
    } else {
      onSearchChange({ floorId: '', buildingId: '' });
    }
  };

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递单号">
            <Search
              allowClear
              placeholder="请输入快递单号"
              defaultValue={get(query, 'orderNumber', '')}
              onSearch={(value: string) => onSearchChange({ orderNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ orderNumber: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="包裹号">
            <Search
              allowClear
              placeholder="请输入包裹号"
              defaultValue={get(query, 'shelfNumber', '')}
              onSearch={(value: string) => onSearchChange({ shelfNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ shelfNumber: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="收件人">
            <Search
              allowClear
              placeholder="请输入收件人姓名或电话"
              defaultValue={get(query, 'keyword', '')}
              onSearch={(value: string) => onSearchChange({ keyword: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ keyword: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递公司">
            <Select
              allowClear
              mode="multiple"
              maxTagCount={1}
              placeholder="请选择快递公司"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'companyId')}
              onChange={(value: string[]) => onSearchChange({ companyId: value.join() })}
            >
              {companyOptions.map((item: DataSourceItemObject, index: number) => (
                <Option value={item.value} key={index}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="派件楼栋楼层">
            <Cascader
              options={cascaderOptions}
              placeholder="请选择派件楼栋或楼层"
              defaultValue={defaultValue}
              onChange={onChange}
              changeOnSelect
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ common }: any) => {
  const cascaderOptions = common.buildingOption.map((item: any) => {
    const floorOptions = item.floors
      ? item.floors.map((item: any) => {
          return {
            value: item.id,
            label: item.forbidden ? item.name + '(人工)' : item.name,
          };
        })
      : [];

    return {
      label: item.name,
      value: item.id,
      isLeaf: false,
      children: CascaderFloorOption.concat(floorOptions),
    };
  });

  return {
    companyOptions: transformDataSource(common.companyOption),
    cascaderOptions: CascaderBuildingOption.concat(cascaderOptions),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompanyOption: (params?: object) => {
    dispatch({
      type: 'common/fetchCompanyOption',
      payload: params,
    });
  },
  fetchBuildingOption: (params?: object) => {
    dispatch({
      type: 'common/fetchBuildingOption',
      payload: params,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualOrderDetailHeader);

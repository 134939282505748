import { EChartOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import React from 'react';

const EchartCheckOrders = (props: any) => {
  const { normalCount, checkInRequiredCount, checkedOutCount, checkOutRequiredCount } = props;

  const option: EChartOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    series: [
      {
        label: {
          normal: {
            position: 'outer',
            formatter: '{a} {b} : {c}单 ({d}%)',
          },
        },
        name: '',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          { value: normalCount, name: '在库数', selected: true },
          { value: checkInRequiredCount, name: '未入库' },
          { value: checkedOutCount, name: '未出库' },
          { value: checkOutRequiredCount, name: '不在库' },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  return <ReactEcharts option={option} style={{ height: '350px', width: '100%', marginTop: '-100px' }} />;
};

export default EchartCheckOrders;

import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const ElectricFence = Loadable.Map({
  loader: {
    ElectricFence: () => import('./ElectricFence'),
    model: () => import('./models/ElectricFence'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const User = loaded.ElectricFence.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <User {...props} />
      </Loading>
    );
  },
});

const CabinetOrder = Loadable.Map({
  loader: {
    CabinetOrder: () => import('./CabinetOrder'),
    model: () => import('./models/CabinetOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CabinetOrder = loaded.CabinetOrder.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <CabinetOrder {...props} />
      </Loading>
    );
  },
});

const CabinetHiveList = Loadable.Map({
  loader: {
    CabinetHiveList: () => import('./CabinetHiveList'),
    model: () => import('./models/CabinetHiveList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CabinetHiveList = loaded.CabinetHiveList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <CabinetHiveList {...props} />
      </Loading>
    );
  },
});

const CabinetUserList = Loadable.Map({
  loader: {
    CabinetUserList: () => import('./CabinetUserList'),
    model: () => import('./models/CabinetUserList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CabinetUserList = loaded.CabinetUserList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <CabinetUserList {...props} />
      </Loading>
    );
  },
});

const CabinetHiveDetail = Loadable.Map({
  loader: {
    CabinetHiveDetail: () => import('./CabinetHiveDetail'),
    model: () => import('./models/CabinetHiveDetail'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CabinetHiveDetail = loaded.CabinetHiveDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <CabinetHiveDetail {...props} />
      </Loading>
    );
  },
});

const CabinetUserDetail = Loadable.Map({
  loader: {
    CabinetUserDetail: () => import('./CabinetUserDetail'),
    model: () => import('./models/CabinetUserDetail'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CabinetUserDetail = loaded.CabinetUserDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <CabinetUserDetail {...props} />
      </Loading>
    );
  },
});

const CabinetOrderDetail = Loadable.Map({
  loader: {
    CabinetOrderDetail: () => import('./CabinetOrderDetail'),
    historyModel: () => import('../Order/models/OrderHistory'),
    model: () => import('./models/CabinetOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CabinetOrderDetail = loaded.CabinetOrderDetail.default;
    const historyModel = loaded.historyModel.default;
    const model = loaded.model.default;

    registerModel(props.app, model);
    registerModel(props.app, historyModel);

    return (
      <Loading>
        <CabinetOrderDetail {...props} />
      </Loading>
    );
  },
});

export {
  ElectricFence,
  CabinetOrder,
  CabinetOrderDetail,
  CabinetHiveList,
  CabinetUserList,
  CabinetHiveDetail,
  CabinetUserDetail,
};

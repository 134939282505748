import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const FaultStrategy = Loadable.Map({
  loader: {
    FaultStrategy: () => import('./FaultStrategy'),
    model: () => import('./models/FaultStrategy'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const FaultStrategy = loaded.FaultStrategy.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <FaultStrategy {...props} />
      </Loading>
    );
  },
});

const FaultNotifier = Loadable.Map({
  loader: {
    FaultNotifier: () => import('./FaultNotifier'),
    model: () => import('./models/FaultNotifier'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const FaultNotifier = loaded.FaultNotifier.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <FaultNotifier {...props} />
      </Loading>
    );
  },
});

const FaultHistory = Loadable.Map({
  loader: {
    FaultHistory: () => import('./FaultHistory'),
    model: () => import('./models/FaultHistory'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const FaultHistory = loaded.FaultHistory.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <FaultHistory {...props} />
      </Loading>
    );
  },
});

const FaultHistoryDetail = Loadable.Map({
  loader: {
    FaultHistoryDetail: () => import('./FaultHistoryDetail'),
    model: () => import('./models/FaultHistory'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const FaultHistoryDetail = loaded.FaultHistoryDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <FaultHistoryDetail {...props} />
      </Loading>
    );
  },
});

const FaultLog = Loadable.Map({
  loader: {
    FaultLog: () => import('./FaultLog'),
    model: () => import('./models/FaultLog'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const FaultLog = loaded.FaultLog.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <FaultLog {...props} />
      </Loading>
    );
  },
});

const FaultConfig = Loadable.Map({
  loader: {
    FaultConfig: () => import('./FaultConfig'),
    model: () => import('./models/FaultConfig'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const FaultConfig = loaded.FaultConfig.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <FaultConfig {...props} />
      </Loading>
    );
  },
});

export { FaultStrategy, FaultNotifier, FaultHistory, FaultHistoryDetail, FaultLog, FaultConfig };

import { format } from 'date-fns';
import { get } from 'lodash';

import {
  CollectionMethodStr,
  DeliveryMethodStr,
  HistoryType,
  HistoryTypeStr,
  KeyTypeStr,
  OrderType,
  OrderTypeStr,
  PackageSizeStr,
} from './constants';

// Change Field enum
export enum ChangeField {
  hiveId = 'hiveId',
  lockerId = 'lockerId',
  companyId = 'companyId',
  buildingId = 'buildingId',
  robotId = 'robotId',
  floorId = 'floorId',
  waypointId = 'waypointId',
  validated = 'validated',
  validatedBy = 'validatedBy',
  validatedTime = 'validatedTime',
  orderState = 'orderState',
  failReason = 'failReason',
  approvedBy = 'approvedBy',
  packageSize = 'packageSize',
  rejectReason = 'rejectReason',
  cancelReason = 'cancelReason',
  confirmedBy = 'confirmedBy',
  confirmedTime = 'confirmedTime',
  collectionTime = 'collectionTime',
  deliveryMethod = 'deliveryMethod',
  deliveryStartTime = 'deliveryStartTime',
  collectInPerson = 'collectInPerson',
  collectionMethod = 'collectionMethod',
  requestedDeliveryMethod = 'requestedDeliveryMethod',
  stockCheckedBy = 'stockCheckedBy',
  stranded = 'stranded',
  strandedTime = 'strandedTime',
  strandedBy = 'strandedBy',
  closedBy = 'closedBy',
  returnedKeyType = 'returnedKeyType',
  returnedBy = 'returnedBy',
  orderNumber = 'orderNumber',
  returnedReasonDetail = 'returnedReasonDetail',
  mobile = 'mobile',
}

export const ChangeFieldStr = {
  [ChangeField.hiveId]: '快递柜',
  [ChangeField.lockerId]: '储物箱',
  [ChangeField.companyId]: '快递公司',
  [ChangeField.buildingId]: '楼栋',
  [ChangeField.floorId]: '楼层',
  [ChangeField.robotId]: '机器人',
  [ChangeField.waypointId]: '取件点',
  [ChangeField.validated]: '待完善信息',
  [ChangeField.validatedBy]: '操作人',
  [ChangeField.validatedTime]: '操作时间',
  [ChangeField.approvedBy]: '审批人',
  [ChangeField.orderState]: '订单状态',
  [ChangeField.failReason]: '失败原因',
  [ChangeField.packageSize]: '包裹',
  [ChangeField.confirmedBy]: '确认人',
  [ChangeField.confirmedTime]: '确认时间',
  [ChangeField.collectionTime]: '取件时间',
  [ChangeField.rejectReason]: '拒绝原因',
  [ChangeField.cancelReason]: '取消原因',
  [ChangeField.deliveryMethod]: '配送方式',
  [ChangeField.collectInPerson]: '本人取件',
  [ChangeField.collectionMethod]: '取件方式',
  [ChangeField.deliveryStartTime]: '配送开始时间',
  [ChangeField.requestedDeliveryMethod]: '用户申请取件方式',
  [ChangeField.stranded]: '是否是滞留件',
  [ChangeField.strandedTime]: '滞留时间',
  [ChangeField.strandedBy]: '滞留件操作人',
  [ChangeField.closedBy]: '退回快递公司操作人',
  [ChangeField.returnedBy]: '回库人',
  [ChangeField.returnedKeyType]: '操作平台',
  [ChangeField.returnedReasonDetail]: '退库理由',
  [ChangeField.orderNumber]: '订单号',
  [ChangeField.mobile]: '收件人号码',
};

export interface Change {
  after: string;
  before: string;
  field: ChangeField;
  description: string;
  beforeEntity?: any;
  afterEntity?: any;
}

export function formatChange(change: Change, orderType: OrderType) {
  let after;
  let before;
  const orderStateDesc = OrderTypeStr[orderType];

  switch (change.field) {
    case ChangeField.orderState:
      before = orderStateDesc[change.before];
      after = orderStateDesc[change.after];
      break;
    case ChangeField.deliveryMethod:
      before = DeliveryMethodStr[change.before];
      after = DeliveryMethodStr[change.after];
      break;
    case ChangeField.collectionMethod:
      before = CollectionMethodStr[change.before];
      after = CollectionMethodStr[change.after];
      break;
    case ChangeField.packageSize:
      before = PackageSizeStr[change.before];
      after = PackageSizeStr[change.after];
      break;
    default:
      before = change.before;
      after = change.after;
      break;
  }
  return { ...change, after, before };
}

export function actionType(type: HistoryType | string): string {
  return HistoryTypeStr[type];
}

export function changeField(field: ChangeField): string {
  return ChangeFieldStr[field];
}

export function formatDescription(text: string): string {
  return text ? '【' + text + '】' : '';
}

export function modifiedBy({ modifiedByEntity }: any): string {
  if (modifiedByEntity) {
    return modifiedByEntity.name;
  }
  return '';
}

export function _some(arr: any[], key: string): boolean {
  return arr.some((item: any) => item.field === key);
}

/**
 * 格式化订单历史
 * @param [HistoryType] type
 * @param [Change[]] changes
 * @return [string[]]
 */
export function formatHistory(type: HistoryType, changes: Change[], orderType: OrderType): any {
  const flag = _some(changes, ChangeField.stockCheckedBy);
  const item = changes.find((item: any) => item.field === ChangeField.stockCheckedBy);

  if (flag) {
    if (_some(changes, 'orderState')) {
      return `盘库处理：${get(item, 'afterEntity.name')}更新订单状态为已入库`;
    }
    if (_some(changes, 'failReason')) {
      return `${get(item, 'afterEntity.name')}更新异常内容为盘库处理：订单标记为不在库`;
    } else {
      return `盘库处理：${get(item, 'afterEntity.name')}创建订单`;
    }
  }

  return changes.map((item: Change, index: number) => {
    const { after, before, beforeEntity, afterEntity } = formatChange(item, orderType);
    const symbol = `${index !== changes.length - 1 ? '，' : '。'}`;

    if (item.field === ChangeField.failReason) {
      return `${item.description || changeField(item.field)}${after ? '：' + after : '：' + before}${symbol}`;
    }

    if (item.field === ChangeField.returnedKeyType) {
      return `操作平台：由${before ? KeyTypeStr[before] : '空'}为【${after ? KeyTypeStr[after] : '空'}】${symbol}`;
    }

    if ([ChangeField.stranded].includes(item.field)) {
      return `将${item.description || changeField(item.field)}【${
        String(before) ? (before ? '是' : '否') : '空'
      }】${actionType(type)}为【${String(after) ? (after ? '是' : '否') : '空'}】${symbol}`;
    }

    if ([ChangeField.validated].includes(item.field)) {
      return `将${item.description || changeField(item.field)}【${
        String(before) ? (before ? '否' : '是') : '空'
      }】${actionType(type)}为【${String(after) ? (after ? '否' : '是') : '空'}】${symbol}`;
    }

    if (item.field === ChangeField.packageSize) {
      return `将${formatDescription(before)}${item.description || changeField(item.field)}${actionType(
        type
      )}为${formatDescription(after)}${changeField(item.field)}${symbol}`;
    }

    if (
      [
        ChangeField.validatedBy,
        ChangeField.confirmedBy,
        ChangeField.approvedBy,
        ChangeField.closedBy,
        ChangeField.strandedBy,
      ].includes(item.field)
    ) {
      return `${item.description || changeField(item.field)}${'：' +
        (afterEntity ? afterEntity.name : after)}${symbol}`;
    }

    if (
      [
        ChangeField.validatedTime,
        ChangeField.strandedTime,
        ChangeField.confirmedTime,
        ChangeField.collectionTime,
        ChangeField.deliveryStartTime,
      ].includes(item.field)
    ) {
      return `${actionType(type)}${item.description || changeField(item.field)}${'为：' +
        format(new Date(after || before), 'yyyy-MM-dd HH:mm:ss')}${symbol}`;
    }

    return `将${item.description || changeField(item.field)}${
      beforeEntity ? formatDescription(beforeEntity.name) : formatDescription(before) || ''
    }${actionType(type)}为${
      afterEntity ? formatDescription(afterEntity.name) : formatDescription(after) || '空'
    }${symbol}`;
  });
}

import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const SharedOrderList = Loadable.Map({
  loader: {
    SharedOrderList: () => import('./SharedOrderList'),
    model: () => import('./models/SharedOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SharedOrderList = loaded.SharedOrderList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SharedOrderList {...props} />
      </Loading>
    );
  },
});

const SharedOrderDetail = Loadable.Map({
  loader: {
    SharedOrderDetail: () => import('./SharedOrderDetail'),
    historyModel: () => import('../Order/models/OrderHistory'),
    model: () => import('./models/SharedOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SharedOrderDetail = loaded.SharedOrderDetail.default;
    const historyModel = loaded.historyModel.default;
    const model = loaded.model.default;

    registerModel(props.app, model);
    registerModel(props.app, historyModel);

    return (
      <Loading>
        <SharedOrderDetail {...props} />
      </Loading>
    );
  },
});

export { SharedOrderList, SharedOrderDetail };

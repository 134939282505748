import { DeviceRoutes, Routes } from './routes';
import { RouteConfig, SysMenu } from './typed';

// 驿站设置页面
export const HubSettingRoutes: RouteConfig[] = [
  {
    name: '基本设置',
    path: Routes.BasicSetting,
    component: 'BasicSetting',
  },
  {
    name: '派件规则设置',
    path: Routes.RulesSetting,
    component: 'RulesSetting',
  },
  {
    name: '订单分配规则设置',
    path: Routes.SendRulesSetting,
    component: 'SendRulesSetting',
  },
  {
    name: '共享配送设置',
    path: Routes.ShareSetting,
    component: 'ShareSetting',
  },
  {
    name: '外卖配送设置',
    path: Routes.TakeOutSetting,
    component: 'TakeOutSetting',
  },
  {
    name: '调度设置',
    path: Routes.SchedulingDisabledSetting,
    component: 'SchedulingDisabledSetting',
  },
  {
    name: '参数配置管理',
    path: Routes.RobotSetting,
    component: 'RobotSetting',
  },
  {
    name: '参数配置详情',
    path: `${Routes.RobotSettingDetail}/:id`,
    component: 'RobotSettingDetail',
    ignoreAuth: true,
    hideInMenu: true,
  },
  {
    name: '垃圾清理设置',
    path: Routes.GarbageClearSetting,
    component: 'GarbageClearSetting',
  },
];

// 综合业务管理平台路由配置
export const AppRoutes: RouteConfig[] = [
  {
    name: '首页',
    icon: 'dashboard',
    path: Routes.App,
    ignoreAuth: true,
    hideInMenu: true,
    component: 'Dashboard',
  },
  {
    name: '首页',
    ignoreAuth: true,
    icon: 'dashboard',
    path: Routes.Dashboard,
    component: 'Dashboard',
  },
  {
    name: '驿站管理',
    icon: 'bank',
    path: Routes.HubCenter,
    children: [
      {
        name: '楼宇管理',
        path: Routes.HubBuilding,
        icon: 'build',
        component: 'HubDetail',
      },
      {
        name: '片区管理',
        path: Routes.HubAreaList,
        icon: 'profile',
        component: 'AreaList',
      },
      {
        name: '电梯管理',
        path: Routes.HubLiftList,
        icon: 'database',
        component: 'LiftList',
      },
      {
        name: '驿站设置',
        icon: 'setting',
        hideChildren: true,
        path: Routes.HubSettings,
        component: 'HubSettings',
        children: HubSettingRoutes,
      },
      {
        name: '片区管理编辑',
        path: Routes.HubAreaEdit,
        icon: 'layout',
        component: 'AreaEditOrAdd',
        hideInMenu: true,
      },
    ],
  },
  {
    name: '快递派件管理',
    path: Routes.Order,
    icon: 'profile',
    hideInMenu: true,
    children: [
      {
        name: '机器人派件管理',
        path: Routes.PieceRobotOrder,
        icon: 'robot',
        component: 'PieceRobotOrder',
      },
      {
        name: '快递员派件管理',
        path: Routes.PieceManualOrder,
        icon: 'solution',
        component: 'PieceManualOrder',
      },
      {
        name: '派件订单管理',
        path: Routes.PieceOrder,
        icon: 'profile',
        component: 'PieceOrder',
      },
      {
        name: '已入库订单管理',
        path: Routes.PieceWarehousedOrder,
        icon: 'file-protect',
        component: 'PieceWarehousedOrder',
      },
      {
        name: '待完善订单信息',
        path: Routes.PendingClientInfo,
        icon: 'usergroup-add',
        component: 'PendingUserList',
      },
      {
        name: '机器待分配订单',
        path: Routes.PieceRobotDeliveredOrder,
        icon: 'schedule',
        component: 'PieceRobotDeliveredOrder',
      },
      {
        name: '异常订单管理',
        path: Routes.PieceExceptionOrder,
        icon: 'exclamation-circle',
        component: 'PieceExceptionOrder',
      },
      {
        name: '驿站取件管理',
        path: Routes.PieceHubOrder,
        icon: 'hdd',
        component: 'PieceHubOrder',
      },
      {
        name: '退回快递公司',
        path: Routes.PieceToCompanyOrder,
        icon: 'interaction',
        component: 'PieceToCompanyOrder',
      },
      {
        name: '盘库管理',
        path: Routes.CheckInventory,
        icon: 'usergroup-add',
        component: 'CheckInventory',
      },
      {
        name: '派件定价管理',
        path: Routes.DeliveryCompanyPrice,
        icon: 'hdd',
        component: 'DeliveryCompanyPrice',
      },
      {
        name: '派件订单同步',
        path: Routes.PieceOrderSync,
        icon: 'hdd',
        component: 'PieceOrderSync',
      },
      {
        name: '盘库详情',
        path: `${Routes.CheckInventory}/:id`,
        icon: 'usergroup-add',
        component: 'CheckInventoryDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '订单详情',
        path: `${Routes.PieceOrder}/:id`,
        component: 'OrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '机器人订单详情',
        path: `${Routes.PieceRobotOrder}/:id`,
        component: 'RobotOrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '人工派件详情',
        path: `${Routes.PieceManualOrder}/:id`,
        icon: 'profile',
        component: 'PieceManualOrderDetail',
        hideInMenu: true,
        ignoreAuth: true,
      },
      {
        name: '驿站订单详情',
        path: `${Routes.PieceHubOrder}/:id`,
        component: 'OrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '异常订单详情',
        path: `${Routes.PieceExceptionOrder}/:id`,
        component: 'OrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '退回快递公司详情',
        path: `${Routes.PieceToCompanyOrder}/:id`,
        component: 'OrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '已入库订单详情',
        path: `${Routes.PieceWarehousedOrder}/:id`,
        component: 'OrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '机器人待分配订单详情',
        path: `${Routes.PieceRobotDeliveredOrder}/:id`,
        component: 'OrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '录单',
        path: `${Routes.PendingClientInfo}/:id`,
        icon: 'usergroup-add',
        component: 'PendingUserEdit',
        hideInMenu: true,
        ignoreAuth: true,
      },
    ],
  },
  {
    name: '快递寄件管理',
    icon: 'inbox',
    hideInMenu: true,
    path: Routes.SendOrderCenter,
    children: [
      {
        name: '机器人寄件管理',
        path: Routes.SendRobotOrder,
        icon: 'robot',
        component: 'SendRobotOrder',
      },
      {
        name: '快递员寄件管理',
        path: Routes.SendManualOrder,
        icon: 'solution',
        component: 'SendManualOrder',
      },
      {
        name: '寄件订单管理',
        path: Routes.SendOrderList,
        icon: 'profile',
        component: 'SendOrderList',
      },
      {
        name: '驿站寄件管理',
        path: Routes.SendHubOrder,
        icon: 'hdd',
        component: 'SendHubOrder',
      },
      {
        name: '待寄出管理',
        path: Routes.ToSendOrder,
        icon: 'schedule',
        component: 'ToSendOrder',
      },
      {
        name: '已寄出管理',
        path: Routes.BeSentOrder,
        icon: 'interaction',
        component: 'BeSentOrder',
      },
      {
        name: '快递公司交接',
        path: Routes.HandOverCompany,
        icon: 'container',
        component: 'HandOverCompany',
      },
      {
        name: '异常订单管理',
        path: Routes.SendExceptionOrder,
        icon: 'exclamation-circle',
        component: 'SendExceptionOrder',
      },
      {
        name: '寄件定价管理',
        path: Routes.PricingManage,
        icon: 'credit-card',
        component: 'PricingManage',
      },
      {
        name: '人工上门详情',
        path: `${Routes.SendManualOrder}/:id`,
        icon: 'profile',
        component: 'SendManualOrderDetail',
        hideInMenu: true,
        ignoreAuth: true,
      },
      {
        name: '机器人上门详情',
        path: `${Routes.SendRobotOrder}/:id`,
        icon: 'profile',
        component: 'SendRobotOrderDetail',
        hideInMenu: true,
        ignoreAuth: true,
      },
      {
        name: '寄件订单详情',
        path: `${Routes.SendOrderList}/:id`,
        icon: 'profile',
        component: 'SendOrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '人工上门订单详情',
        path: `${Routes.SendManualOrder}/detail/:id`,
        icon: 'solution',
        component: 'SendOrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '机器人上门订单详情',
        path: `${Routes.SendRobotOrder}/detail/:id`,
        icon: 'robot',
        component: 'SendOrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '驿站寄件订单详情',
        path: `${Routes.SendHubOrder}/:id`,
        icon: 'profile',
        component: 'SendOrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '异常订单订单详情',
        path: `${Routes.SendExceptionOrder}/:id`,
        icon: 'profile',
        component: 'SendOrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '待寄出订单详情',
        path: `${Routes.ToSendOrder}/:id`,
        icon: 'profile',
        component: 'SendOrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '已寄出订单详情',
        path: `${Routes.BeSentOrder}/:id`,
        icon: 'profile',
        component: 'SendOrderDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '清单详情',
        path: `${Routes.HandOverCompany}/:id`,
        icon: 'profile',
        component: 'HandOverCompanyDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '预览定价',
        path: `${Routes.PreviewPrice}/:id`,
        icon: 'profile',
        component: 'PreviewPrice',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '编辑定价',
        path: `${Routes.SettingPrice}`,
        icon: 'profile',
        component: 'SettingPrice',
        hideInMenu: true,
      },
    ],
  },
  {
    name: '外卖配送管理',
    icon: 'hdd',
    hideInMenu: true,
    path: Routes.SharedCabinet,
    children: [
      {
        name: '订单管理',
        path: Routes.SharedCabinetOrder,
        icon: 'profile',
        component: 'CabinetOrder',
      },
      {
        name: '订单详情',
        path: `${Routes.SharedCabinetOrder}/:id`,
        icon: 'profile',
        hideInMenu: true,
        ignoreAuth: true,
        component: 'CabinetOrderDetail',
      },
      {
        name: '机器人配送',
        path: Routes.CabinetHiveList,
        icon: 'profile',
        component: 'CabinetHiveList',
      },
      {
        name: '外卖柜详情',
        path: `${Routes.CabinetHiveList}/:id`,
        icon: 'profile',
        hideInMenu: true,
        ignoreAuth: true,
        component: 'CabinetHiveDetail',
      },
      {
        name: '快递员详情',
        path: `${Routes.CabinetUserList}/:id`,
        icon: 'profile',
        hideInMenu: true,
        ignoreAuth: true,
        component: 'CabinetUserDetail',
      },
      {
        name: '外卖员投柜',
        path: Routes.CabinetUserList,
        icon: 'profile',
        component: 'CabinetUserList',
      },
    ],
  },
  {
    name: '设备管控',
    icon: 'cloud-server',
    path: Routes.Device,
    children: [
      {
        name: '设备管理',
        icon: 'cluster',
        path: Routes.DeviceCenter,
        children: [
          {
            name: '机器人管理',
            path: Routes.DeviceRobot,
            icon: 'robot',
            component: 'RobotList',
          },
          {
            name: '单元柜管理',
            path: Routes.DeviceHiveCell,
            icon: 'table',
            component: 'HiveCellList',
          },
          {
            name: '货架管理',
            path: Routes.DeviceShelf,
            icon: 'table',
            component: 'ShelfList',
          },
          {
            name: '机器人详情',
            path: `${Routes.DeviceRobot}/:id`,
            icon: 'robot',
            component: 'RobotDetail',
            hideInMenu: true,
            ignoreAuth: true,
          },
          {
            name: '单元柜详情',
            path: `${Routes.DeviceHiveCell}/:id`,
            icon: 'robot',
            component: 'HiveCellDetail',
            hideInMenu: true,
            ignoreAuth: true,
          },
        ],
      },
      {
        name: '固件升级',
        icon: 'coffee',
        path: Routes.OTA,
        children: [
          {
            name: '升级情况管理',
            path: Routes.OTAList,
            icon: 'history',
            component: 'OTAList',
          },
          {
            name: 'OTA版本列表',
            path: Routes.SoftwareList,
            icon: 'table',
            component: 'SoftwareList',
          },
        ],
      },
      {
        name: '阳光监控',
        icon: 'desktop',
        path: Routes.DeviceMonitor,
        children: [
          {
            name: '实时监控',
            path: Routes.RobotMonitor,
            icon: 'robot',
            component: 'DeviceMonitor',
          },
          {
            name: '回放中心',
            path: Routes.ReplayCenter,
            icon: 'play-square',
            component: 'ReplayList',
          },
          {
            name: '异常记录',
            path: Routes.AnomalyRecord,
            icon: 'audit',
            component: 'DeviceCommentList',
          },
        ],
      },
      {
        name: '异常告警',
        icon: 'alert',
        path: Routes.FaultWarning,
        children: [
          {
            name: '告警定制',
            path: Routes.FaultStrategy,
            icon: 'deployment-unit',
            component: 'FaultStrategy',
          },
          {
            name: '告警历史',
            path: Routes.FaultHistory,
            icon: 'history',
            component: 'FaultHistory',
          },
          {
            name: '异常告警配置',
            path: Routes.FaultConfig,
            icon: 'tool',
            component: 'FaultConfig',
          },
          {
            name: '告警历史详情',
            path: `${Routes.FaultHistory}/:id`,
            icon: 'history',
            component: 'FaultHistoryDetail',
            ignoreAuth: true,
            hideInMenu: true,
          },
        ],
      },
      {
        name: '烛光监控',
        icon: 'control',
        path: Routes.RemoteMaintenance,
        children: [
          {
            name: '远程维护',
            path: Routes.RemoteMaintenanceScreen,
            icon: 'deployment-unit',
            component: 'RemoteMaintenanceScreen',
          },
        ],
      },
      {
        name: '调度',
        icon: 'control',
        path: Routes.Schedule,
        children: [
          {
            name: '调度历史',
            path: Routes.ScheduleHistory,
            icon: 'history',
            component: 'ScheduleHistory',
          },
          {
            name: '调度请求',
            path: Routes.ScheduleRequest,
            icon: 'deployment-unit',
            component: 'ScheduleRequest',
          },
          {
            name: '巡游模板',
            path: Routes.ScheduleTask,
            icon: 'menu-unfold',
            component: 'Cruise',
          },
        ],
      },
    ],
  },
  {
    name: '对账管理',
    icon: 'pay-circle',
    hideInMenu: true,
    path: Routes.Financial,
    children: [
      {
        name: '派件流水对账',
        path: Routes.CollectOrderReconciliation,
        icon: 'money-collect',
        component: 'OrderReconciliation',
      },
      {
        name: '寄件流水对账',
        path: Routes.SendOrderReconciliation,
        icon: 'red-envelope',
        component: 'SendOrderReconciliation',
      },
    ],
  },
  {
    name: '用户管理',
    icon: 'team',
    path: Routes.ClientCenter,
    children: [
      {
        name: '前台代收管理',
        path: Routes.ClientCollection,
        icon: 'shopping',
        component: 'UserCollection',
      },
      {
        name: '用户列表',
        path: Routes.ClientList,
        icon: 'user',
        component: 'UserList',
      },
      {
        name: '用户反馈',
        path: Routes.ClientFeedback,
        icon: 'info-circle',
        component: 'FeedbackList',
      },
      {
        name: '代收点详情',
        path: `${Routes.ClientCollection}/:id`,
        icon: 'shopping',
        component: 'CollectionDetail',
        hideInMenu: true,
        ignoreAuth: true,
      },
      {
        name: '个人用户详情',
        path: `${Routes.ClientList}/:id`,
        component: 'UserDetail',
        hideInMenu: true,
        ignoreAuth: true,
      },
    ],
  },
  {
    name: '账号管理',
    icon: 'idcard',
    path: Routes.AppAuth,
    children: [
      {
        name: '角色管理',
        path: Routes.AuthRole,
        icon: 'solution',
        component: 'RoleList',
      },
      {
        name: '账号管理',
        path: Routes.AuthUser,
        icon: 'user',
        component: 'AuthUserList',
      },
      {
        name: '用户详情',
        path: `${Routes.AuthUser}/:id`,
        component: 'AuthUserDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '下载中心',
        path: Routes.Download,
        hideInMenu: true,
        component: 'DownloadContent',
      },
    ],
  },
  {
    name: '消息中心',
    icon: 'message',
    path: Routes.MessageCenter,
    children: [
      {
        name: '消息记录',
        path: Routes.MessageList,
        icon: 'solution',
        component: 'MessageList',
      },
    ],
  },
  {
    name: '任务执行记录',
    icon: 'audit',
    path: Routes.TaskRecord,
    children: [
      {
        name: '医疗消毒执行记录',
        path: Routes.DisinfectList,
        icon: 'solution',
        component: 'DisinfectList',
      },
      {
        name: '垃圾转运执行记录',
        path: Routes.RubbishList,
        icon: 'solution',
        component: 'RubbishList',
      },
      {
        name: '物料转运任务记录',
        path: Routes.MaterialTransportList,
        icon: 'solution',
        component: 'MaterialTransportList',
      },
      {
        name: '消毒记录详情',
        path: `${Routes.DisinfectList}/:id`,
        component: 'DisinfectDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '物料转运任务详情',
        path: `${Routes.MaterialTransportList}/:id`,
        component: 'MaterialTransportDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '任务报告',
        path: `${Routes.RubbishList}/:id`,
        component: 'RubbishDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
    ],
  },
  {
    name: '来访接待',
    icon: 'audit',
    path: Routes.VisitReception,
    children: [
      {
        name: '人脸录入',
        path: Routes.VisitFaceEntry,
        icon: 'solution',
        component: 'FaceEntry',
      },
    ],
  },
];

// 设备管控平台路由配置
export const DeviceManageRoutes: RouteConfig[] = [
  {
    name: '设备注册',
    icon: 'cloud-server',
    path: DeviceRoutes.DeviceRegister,
    children: [
      {
        name: '产品管理',
        path: DeviceRoutes.DeviceProductManage,
        icon: 'project',
        component: 'DeviceProductManage',
      },
      {
        name: '产品型号管理',
        path: DeviceRoutes.DeviceModelManage,
        icon: 'desktop',
        component: 'DeviceModelManage',
      },
      {
        name: '产品规格管理',
        path: DeviceRoutes.DeviceSpecification,
        icon: 'block',
        component: 'DeviceSpecification',
      },
      {
        name: '单元柜尺寸管理',
        path: DeviceRoutes.DeviceHiveSize,
        icon: 'table',
        component: 'DeviceHiveSize',
      },
      {
        name: '阳光机器人注册',
        path: DeviceRoutes.DeviceSunlight,
        icon: 'robot',
        component: 'DeviceSunlight',
      },
      {
        name: '烛光机器人注册',
        path: DeviceRoutes.DeviceCandlelight,
        icon: 'robot',
        component: 'DeviceCandlelight',
      },
      {
        name: '多功能柜注册',
        path: DeviceRoutes.DeviceFunctionBox,
        icon: 'inbox',
        component: 'DeviceFunctionBox',
      },
      {
        name: '产品类型详情',
        path: `${DeviceRoutes.DeviceProductManage}/:id`,
        icon: 'project',
        component: 'DeviceProductManageDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '烛光机器人详情',
        path: `${DeviceRoutes.DeviceCandlelight}/:id`,
        component: 'DeviceCandlelightDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '阳光机器人详情',
        path: `${DeviceRoutes.DeviceSunlight}/:id`,
        component: 'DeviceSunlightDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '单元柜尺寸详情',
        path: `${DeviceRoutes.DeviceHiveSize}/:id`,
        component: 'DeviceHiveSizeDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
      {
        name: '多功能柜详情',
        path: `${DeviceRoutes.DeviceFunctionBox}/:id`,
        icon: 'inbox',
        component: 'DeviceFunctionBoxDetail',
        ignoreAuth: true,
        hideInMenu: true,
      },
    ],
  },
  // {
  //   name: '设备管理',
  //   icon: 'cloud-server',
  //   path: DeviceRoutes.DeviceManage,
  //   children: [
  //     {
  //       name: '闸机管理',
  //       path: DeviceRoutes.DeviceGateManage,
  //       icon: 'project',
  //       component: 'DeviceGateManage',
  //     },
  //     {
  //       name: '闸机管理详情',
  //       path: `${DeviceRoutes.DeviceGateManage}/:id`,
  //       icon: 'project',
  //       component: 'DeviceGateManageDetail',
  //       ignoreAuth: true,
  //       hideInMenu: true,
  //     },
  //   ],
  // },
];

const sysMenuConfig: SysMenu = {
  app: AppRoutes,
  device: DeviceManageRoutes,
};

export default sysMenuConfig;
// tslint:disable-next-line: max-file-line-count

import { ColumnProps } from 'antd/lib/table';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import { Summary } from '../models/typed.d';
import { CommandType } from './commandType';

const date = new Date();

/************** 全局声明 **************/

export enum AppName {
  SHANZHU = 'SHANZHU_OFFICIAL_ACCOUNT',
  OFFICIAL = 'OFFICIAL_ACCOUNT_WEIXIN',
  SHANZHU_MINI_PROGRAM = 'SHANZHU_MINI_PROGRAM',
  DEVICE_CONTROL_WEB = 'CANDELA_DEVICE_CONTROL_WEB',
}

// 全局分页接口
export interface Pagination {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
}

export const summary: Summary = {
  count: {
    total: 0,
    robot: 0,
    manual: 0,
    hub: 0,
    hive: 0,
  },
  hubFiltrationCount: {
    processed: 0,
    unfinished: 0,
    pendingCount: 0,
    orderCount: 0,
  },
  validatedFiltrationCount: {
    unvalidatedCount: 0,
    validatedCount: 0,
  },
  collectedFiltrationCount: {
    manualCollected: 0,
    robotCollected: 0,
    hubCollected: 0,
    sendManualCollected: 0,
    sendRobotCollected: 0,
    sendHubCollected: 0,
  },
  deliveryMethodFiltrationCount: {
    manualPending: 0,
    robotPending: 0,
    robotProcessed: 0,
    sendManualProcessed: 0,
    sendRobotProcessed: 0,
    sendHubPending: 0,
    sendHubConfirmed: 0,
    sendHubRejected: 0,
  },
};

// 全局可编辑表格接口
export interface TableColumnProps<T> extends ColumnProps<T> {
  record?: any;
  editable?: boolean;
  inputType?: string;
  required?: boolean;
  selectOptions?: any;
  pattern?: RegExp;
  cascaderOptions?: any[];
  rules?: any[];
}

export const MILLISECOND_IN_A_DAY = 1000 * 60 * 60 * 24;
export const MILLISECOND_IN_EIGHT_HOURS = 1000 * 60 * 60 * 8;

// 全局权限枚举
export enum Permissions {
  Send = 'SEND',
  Operate = 'OPERATE',
  Process = 'PROCESS',
  Receive = 'RECEIVE',
  Admin = 'ROLE-ADMIN',
  Deliver = 'DELIVER',
}

// localStorage 本地存储 key 值
export const localStorageKey = {
  APP_KEY_STORE: 'CTI-CENTRAL-CONSOLE',
  HUB_KEY_STORE: 'CTI-CENTRAL-CONSOLE-HUB',
  APP_VIEW_STORE: 'CTI-CENTRAL-CONSOLE-VIEW',
  APP_SCAN_USER: 'CTI-CENTRAL-SCAN-USER',
  APP_DEVICE_MONITOR: 'CTI-CENTRAL-DEVICE-MONITOR',
};

export const OpeningDays = [
  { label: '周一', value: 1 },
  { label: '周二', value: 2 },
  { label: '周三', value: 3 },
  { label: '周四', value: 4 },
  { label: '周五', value: 5 },
  { label: '周六', value: 6 },
  { label: '周日', value: 7 },
];

export const BooleanOptions = [
  { label: '是', value: 'true' },
  { label: '否', value: 'false' },
];

export const PayStatusOptions = [
  { label: '已支付', value: 'true' },
  { label: '未支付', value: 'false' },
];

export enum RobotSeriesState {
  SUNLIGHT = 'SUNLIGHT',
  CANDLELIGHT = 'CANDLELIGHT',
  DELIVERY = 'DELIVERY',
  COLLECTION = 'COLLECTION',
}

/************** 订单中心常量声明 **************/

export enum OrderState {
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
  ARRIVED = 'ARRIVED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  UNCLAIMED = 'UNCLAIMED',
  ON_ROUTE = 'ON_ROUTE',
  CLOSED = 'CLOSED',
  COLLECTED = 'COLLECTED',
  CONFIRMED = 'CONFIRMED',
  PAID = 'PAID',
}

export const MixedOrderState = [
  OrderState.PENDING,
  OrderState.UNCLAIMED,
  OrderState.PROCESSED,
  OrderState.ON_ROUTE,
  OrderState.ARRIVED,
];

export const OrderStateStr = {
  [OrderState.PENDING]: '已入库',
  [OrderState.CONFIRMED]: '已确认',
  [OrderState.PROCESSED]: '已装箱',
  [OrderState.ARRIVED]: '等待取件',
  [OrderState.FAILED]: '配送失败',
  [OrderState.REJECTED]: '已拒收',
  [OrderState.CANCELLED]: '取消配送',
  [OrderState.UNCLAIMED]: '超时未取件',
  [OrderState.ON_ROUTE]: '正在配送',
  [OrderState.CLOSED]: '已退回',
  [OrderState.COLLECTED]: '已取件',
};

export const SendOrderStatusStr = {
  [OrderState.PENDING]: '待上门',
  [OrderState.PROCESSED]: '已分配',
  [OrderState.ON_ROUTE]: '揽件中',
  [OrderState.ARRIVED]: '待投柜',
  [OrderState.CONFIRMED]: '待寄出',
  [OrderState.COLLECTED]: '已揽件',
  [OrderState.REJECTED]: '已取消',
  [OrderState.UNCLAIMED]: '超时未投柜',
  [OrderState.CLOSED]: '已寄出',
  [OrderState.CANCELLED]: '已取消',
  [OrderState.FAILED]: '揽件失败',
};

export const DispatchOrderStatusStr = {
  [OrderState.PENDING]: '待投柜',
  [OrderState.PROCESSED]: '待配送',
  [OrderState.ON_ROUTE]: '配送中',
  [OrderState.ARRIVED]: '待取件',
  [OrderState.COLLECTED]: '已签收',
  [OrderState.REJECTED]: '拒收',
  [OrderState.UNCLAIMED]: '滞留中',
  [OrderState.CANCELLED]: '已取消',
  [OrderState.FAILED]: '配送失败',
  [OrderState.CLOSED]: '已滞留',
};

export const RubbishOrderStateStr = {
  [OrderState.PENDING]: '预约成功',
  [OrderState.PROCESSED]: '已出发',
  [OrderState.ON_ROUTE]: '已出发',
  [OrderState.ARRIVED]: '已到达',
  [OrderState.COLLECTED]: '已结束',
  [OrderState.CANCELLED]: '已取消',
  [OrderState.FAILED]: '已结束',
  [OrderState.UNCLAIMED]: '已完成',
};

export const RubbishOrderState = {
  [OrderState.PENDING]: '预约成功',
  [`${OrderState.PROCESSED}^${OrderState.ON_ROUTE}`]: '已出发',
  [OrderState.ARRIVED]: '已到达',
  [`${OrderState.COLLECTED}^${OrderState.FAILED}`]: '已结束',
  [OrderState.CANCELLED]: '已取消',
  [OrderState.UNCLAIMED]: '已完成',
};

export const TransportOrderStateStr = {
  [OrderState.PENDING]: '待出发',
  [OrderState.PROCESSED]: '已出发',
  [OrderState.ON_ROUTE]: '已出发',
  [OrderState.ARRIVED]: '已到达',
  [OrderState.COLLECTED]: '已完成',
  [OrderState.CANCELLED]: '已取消',
  [OrderState.FAILED]: '已失败',
};

export const OrderStateOption = [
  { label: OrderStateStr[OrderState.PENDING], value: OrderState.PENDING },
  { label: OrderStateStr[OrderState.CONFIRMED], value: OrderState.CONFIRMED },
  { label: OrderStateStr[OrderState.PROCESSED], value: OrderState.PROCESSED },
  { label: OrderStateStr[OrderState.ARRIVED], value: OrderState.ARRIVED },
  { label: OrderStateStr[OrderState.FAILED], value: OrderState.FAILED },
  { label: OrderStateStr[OrderState.REJECTED], value: OrderState.REJECTED },
  { label: OrderStateStr[OrderState.CANCELLED], value: OrderState.CANCELLED },
  { label: OrderStateStr[OrderState.UNCLAIMED], value: OrderState.UNCLAIMED },
  { label: OrderStateStr[OrderState.ON_ROUTE], value: OrderState.ON_ROUTE },
  { label: OrderStateStr[OrderState.CLOSED], value: OrderState.CLOSED },
  { label: OrderStateStr[OrderState.COLLECTED], value: OrderState.COLLECTED },
];

export const DispatchOrderStatusOption = [
  { label: DispatchOrderStatusStr[OrderState.PROCESSED], value: OrderState.PROCESSED },
  { label: DispatchOrderStatusStr[OrderState.ON_ROUTE], value: OrderState.ON_ROUTE },
  { label: DispatchOrderStatusStr[OrderState.ARRIVED], value: OrderState.ARRIVED },
  { label: DispatchOrderStatusStr[OrderState.COLLECTED], value: OrderState.COLLECTED },
  { label: DispatchOrderStatusStr[OrderState.UNCLAIMED], value: OrderState.UNCLAIMED },
  { label: DispatchOrderStatusStr[OrderState.CLOSED], value: OrderState.CLOSED },
  { label: DispatchOrderStatusStr[OrderState.CANCELLED], value: OrderState.CANCELLED },
  { label: DispatchOrderStatusStr[OrderState.FAILED], value: OrderState.FAILED },
];

export const TransportOrderStateOption = [
  { label: TransportOrderStateStr[OrderState.PENDING], value: OrderState.PENDING },
  { label: TransportOrderStateStr[OrderState.PROCESSED], value: `${OrderState.PROCESSED}&${OrderState.ON_ROUTE}` },
  { label: TransportOrderStateStr[OrderState.ARRIVED], value: OrderState.ARRIVED },
  { label: TransportOrderStateStr[OrderState.COLLECTED], value: OrderState.COLLECTED },
  { label: TransportOrderStateStr[OrderState.CANCELLED], value: OrderState.CANCELLED },
  { label: TransportOrderStateStr[OrderState.FAILED], value: OrderState.FAILED },
];

export const ChangeableOrderStateOptions = [OrderState.PENDING, OrderState.COLLECTED, OrderState.CANCELLED];

export enum PackageSize {
  OVERSIZE = 'OVERSIZE',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  ANY = 'ANY',
}

export const PackageSizeStr = {
  [PackageSize.OVERSIZE]: '超大',
  [PackageSize.LARGE]: '大',
  [PackageSize.MEDIUM]: '中',
  [PackageSize.SMALL]: '小',
  [PackageSize.ANY]: '任意',
};

export const PackageSizeOption = [
  { label: PackageSizeStr[PackageSize.OVERSIZE], value: PackageSize.OVERSIZE },
  { label: PackageSizeStr[PackageSize.LARGE], value: PackageSize.LARGE },
  { label: PackageSizeStr[PackageSize.MEDIUM], value: PackageSize.MEDIUM },
  { label: PackageSizeStr[PackageSize.SMALL], value: PackageSize.SMALL },
  { label: PackageSizeStr[PackageSize.ANY], value: PackageSize.ANY },
];

export enum DeliveryMethod {
  ROBOT = 'ROBOT',
  HUB = 'HUB',
  MANUAL = 'MANUAL',
  HIVE = 'HIVE',
  STATIONARY = 'STATIONARY',
  RETURN = 'RETURN',
}

export const SendDeliveryMethodStr = {
  [DeliveryMethod.HIVE]: '机器人上门',
  [DeliveryMethod.MANUAL]: '人工上门',
  [DeliveryMethod.HUB]: '驿站寄件',
};

export const SendDeliveryMethodOption = [
  { label: SendDeliveryMethodStr[DeliveryMethod.HIVE], value: DeliveryMethod.HIVE },
  { label: SendDeliveryMethodStr[DeliveryMethod.MANUAL], value: DeliveryMethod.MANUAL },
];

export const DeliveryMethodStr = {
  [DeliveryMethod.ROBOT]: '机器人配送', // 暂时不需要该配送方式
  [DeliveryMethod.HUB]: '驿站取件',
  [DeliveryMethod.RETURN]: '拒收',
  [DeliveryMethod.MANUAL]: '人工配送',
  [DeliveryMethod.HIVE]: '机器人配送', // 特殊原因，ROBOT和 HIVE都叫机器人配送
  [DeliveryMethod.STATIONARY]: '固定快递柜',
};

export const DeliveryMethodOption = [
  { label: DeliveryMethodStr[DeliveryMethod.HUB], value: DeliveryMethod.HUB },
  { label: DeliveryMethodStr[DeliveryMethod.MANUAL], value: DeliveryMethod.MANUAL },
  { label: DeliveryMethodStr[DeliveryMethod.HIVE], value: DeliveryMethod.HIVE },
];

export const SendMethodOption = [
  { label: '驿站寄件', value: DeliveryMethod.HUB },
  { label: '人工揽件', value: DeliveryMethod.MANUAL },
  { label: '机器揽件', value: DeliveryMethod.HIVE },
];

export const OtherBusinessType = [
  { label: '人工接送', value: DeliveryMethod.MANUAL },
  { label: '机器接送', value: DeliveryMethod.HIVE },
];

export const PieceBusinessType = [
  { label: '驿站取件', value: DeliveryMethod.HUB },
  { label: '人工配送', value: DeliveryMethod.MANUAL },
  { label: '机器配送', value: DeliveryMethod.HIVE },
];

export const WareHouseType = [
  { label: '普通仓', value: 'ORDINARY' },
  { label: '智能仓', value: 'INTELLIGENT' },
];

export enum HubAddressType {
  ADDRESS_CODE = 'ADDRESS_CODE',
  WAYPOINT = 'WAYPOINT',
  ADDRESS = 'ADDRESS',
}

export const HubAddressTypeStr = {
  [HubAddressType.ADDRESS_CODE]: '配送码配送',
  [HubAddressType.WAYPOINT]: '电话优先',
  [HubAddressType.ADDRESS]: '地址配送',
};

export const HubAddressTypeOption = [
  { label: HubAddressTypeStr[HubAddressType.WAYPOINT], value: HubAddressType.WAYPOINT },
  { label: HubAddressTypeStr[HubAddressType.ADDRESS_CODE], value: HubAddressType.ADDRESS_CODE },
  { label: HubAddressTypeStr[HubAddressType.ADDRESS], value: HubAddressType.ADDRESS },
];

export enum CollectionMethod {
  CODE = 'CODE',
  SCAN = 'SCAN',
  HIVE = 'HIVE',
  IN_PERSON = 'IN_PERSON',
  FRONT_DESK = 'FRONT_DESK',
}

export const CollectionMethodStr = {
  [CollectionMethod.CODE]: '验证码取件',
  [CollectionMethod.SCAN]: '扫码取件',
  [CollectionMethod.IN_PERSON]: '	本人签收',
  [CollectionMethod.FRONT_DESK]: '前台代收',
  [CollectionMethod.HIVE]: '快递柜代收',
};

// 路点类型 waypoint type
export enum WaypointType {
  BAY = 'BAY',
  HUB = 'HUB',
  BASE = 'BASE',
  DOCK = 'DOCK',
  LIFT = 'LIFT',
  HOME = 'HOME',
  HOLD = 'HOLD',
  NORMAL = 'NORMAL',
  BUFFER = 'BUFFER',
  CHARGER = 'CHARGER',
  CLUSTER = 'CLUSTER',
  PARKING = 'PARKING',
  OUTDOOR = 'OUTDOOR',
  BUILDING = 'BUILDING',
  INTERCHANGE = 'INTERCHANGE',
}

export const WaypointTypeStr = {
  [WaypointType.NORMAL]: '普通',
  [WaypointType.BAY]: '车位',
  [WaypointType.HUB]: '驿站',
  [WaypointType.DOCK]: '桩',
  [WaypointType.BASE]: '基地',
  [WaypointType.OUTDOOR]: '户外',
  [WaypointType.BUILDING]: '楼宇',
  [WaypointType.INTERCHANGE]: '接驳',
  [WaypointType.LIFT]: '电梯',
  [WaypointType.PARKING]: '停车场',
  [WaypointType.HOME]: '初始点',
  [WaypointType.CHARGER]: '充电桩',
  [WaypointType.HOLD]: '等待',
  [WaypointType.CLUSTER]: '公共取件点',
};

// 路点类型 waypoint options
export const WaypointOption = [
  { label: WaypointTypeStr[WaypointType.NORMAL], value: WaypointType.NORMAL },
  { label: WaypointTypeStr[WaypointType.BAY], value: WaypointType.BAY },
  { label: WaypointTypeStr[WaypointType.HUB], value: WaypointType.HUB },
  { label: WaypointTypeStr[WaypointType.DOCK], value: WaypointType.DOCK },
  { label: WaypointTypeStr[WaypointType.OUTDOOR], value: WaypointType.OUTDOOR },
  { label: WaypointTypeStr[WaypointType.BUILDING], value: WaypointType.BUILDING },
  { label: WaypointTypeStr[WaypointType.INTERCHANGE], value: WaypointType.INTERCHANGE },
  { label: WaypointTypeStr[WaypointType.LIFT], value: WaypointType.LIFT },
  { label: WaypointTypeStr[WaypointType.PARKING], value: WaypointType.PARKING },
  { label: WaypointTypeStr[WaypointType.HOME], value: WaypointType.HOME },
  { label: WaypointTypeStr[WaypointType.CHARGER], value: WaypointType.CHARGER },
  { label: WaypointTypeStr[WaypointType.HOLD], value: WaypointType.HOLD },
  { label: WaypointTypeStr[WaypointType.CLUSTER], value: WaypointType.CLUSTER },
];

export enum NewWaypointType {
  NORMAL = 'NORMAL',
  CLUSTER = 'CLUSTER',
}

export const NewWaypointTypeStr = {
  [NewWaypointType.NORMAL]: '私有取件点',
  [NewWaypointType.CLUSTER]: '公共取件点',
};

export const CascaderWaypointOption = [{ label: '选择取件点', value: null, disabled: true }];
export const CascaderDockOption = [{ label: '选择桩', value: null, disabled: true }];
export const CascaderBayOption = [{ label: '选择停车位', value: null, isLeaf: false, disabled: true }];
export const CascaderFloorOption = [{ label: '选择楼层', value: null, disabled: true }];
export const CascaderBuildingOption = [{ label: '选择大楼', value: null, isLeaf: false, disabled: true }];

/************** 新零售中心常量声明 **************/

export enum RetailState {
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  PROCESSED = 'PROCESSED',
  PICKING_UP = 'PICKING_UP',
  LOADED = 'LOADED',
  DROPPING_OFF = 'DROPPING_OFF',
  AWAIT_WITHDRAWAL = 'AWAIT_WITHDRAWAL',
  COMPLETED = 'COMPLETED',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
  REFUNDED_CONFIRMED = 'REFUNDED_CONFIRMED',
  REFUNDED_FAILED = 'REFUNDED_FAILED',
}

export const RetailStateStr = {
  [RetailState.CREATED]: '已创建',
  [RetailState.CONFIRMED]: '已确认',
  [RetailState.PROCESSED]: '已处理',
  [RetailState.REFUNDED]: '已退款',
  [RetailState.CANCELLED]: '已取消',
  [RetailState.PICKING_UP]: '取货中',
  [RetailState.DROPPING_OFF]: '送货中',
  [RetailState.COMPLETED]: '已完成',
  [RetailState.REFUNDED_CONFIRMED]: '退款中',
  [RetailState.REFUNDED_FAILED]: '退款失败',
};

export const RetailStateOption = [
  { label: RetailStateStr[RetailState.CREATED], value: RetailState.CREATED },
  { label: RetailStateStr[RetailState.CONFIRMED], value: RetailState.CONFIRMED },
  { label: RetailStateStr[RetailState.PROCESSED], value: RetailState.PROCESSED },
  { label: RetailStateStr[RetailState.REFUNDED], value: RetailState.REFUNDED },
  { label: RetailStateStr[RetailState.CANCELLED], value: RetailState.CANCELLED },
  { label: RetailStateStr[RetailState.PICKING_UP], value: RetailState.PICKING_UP },
  { label: RetailStateStr[RetailState.DROPPING_OFF], value: RetailState.DROPPING_OFF },
  { label: RetailStateStr[RetailState.COMPLETED], value: RetailState.COMPLETED },
];

export enum ThemeType {
  MAP_BLOCK = 'MAP_BLOCK',
  GENERIC = 'GENERIC',
}

export const ThemeTypeStr = {
  [ThemeType.MAP_BLOCK]: '园区',
  [ThemeType.GENERIC]: '通用',
};

export enum AppType {
  APP = 'APP',
  WEB = 'WEB',
  TABLET = 'TABLET',
}

export const AppTypeStr = {
  [AppType.APP]: '手机端',
  [AppType.WEB]: 'web端',
  [AppType.TABLET]: '平板端',
};

export enum PlacementType {
  LOGISTIC_APP = 'LOGISTIC_APP',
  LOGISTIC_TABLET = 'LOGISTIC_TABLET',
  PORTAL_TABLET = 'PORTAL_TABLET',
  PORTAL_APP = 'PORTAL_APP',
}

export const PlacementTypeStr = {
  [PlacementType.LOGISTIC_APP]: '物流app',
  [PlacementType.LOGISTIC_TABLET]: '物流平板',
  [PlacementType.PORTAL_TABLET]: '运营平板',
  [PlacementType.PORTAL_APP]: '运营app',
};

export enum PlatformType {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  JIEGUI = 'JIEGUI',
}

export const PlatformTypeStr = {
  [PlatformType.MOBILE]: '手机端',
  [PlatformType.TABLET]: '平板',
  [PlatformType.JIEGUI]: '街柜',
};

export const entityTypes = {
  BACKGROUND: 'BACKGROUND',
  WAYPOINT: 'WAYPOINT',
};

export const entityTypesStr = {
  [entityTypes.BACKGROUND]: '背景图',
  [entityTypes.WAYPOINT]: '路点图',
};

export enum StockState {
  NORMAL = 'NORMAL',
  CHECK_IN_REQUIRED = 'CHECK_IN_REQUIRED',
  CHECKED_OUT = 'CHECKED_OUT',
  CHECK_OUT_REQUIRED = 'CHECK_OUT_REQUIRED',
}

export const StockStateStr = {
  [StockState.NORMAL]: '在库',
  [StockState.CHECK_IN_REQUIRED]: '未入库',
  [StockState.CHECKED_OUT]: '未出库',
  [StockState.CHECK_OUT_REQUIRED]: '不在库',
};

export enum StockCheckRole {
  CREATOR = 'CREATOR',
  PARTICIPANT = 'PARTICIPANT',
}

export const ProcessStateOptions = [
  { label: '全部', value: '' },
  { label: '已处理', value: 'true' },
  { label: '未处理', value: 'false' },
];

export enum InventoryDetailTab {
  SALE = 'sale',
  ADD_ITEMS = 'addItems',
}

export enum BuildField {
  BUILDING_ID = 'buildingId',
  FLOOR_ID = 'floorId',
}

export enum LogisticTrackState {
  ON_ROUTE = 'ON_ROUTE',
  COLLECTION = 'COLLECTION',
  DIFFICULT = 'DIFFICULT',
  SIGNING = 'SIGNING',
  REJECTED = 'REJECTED',
  PIECES = 'PIECES',
  RETURNED = 'RETURNED',
}

export const LogisticTrackStateStr = {
  [LogisticTrackState.ON_ROUTE]: '在途',
  [LogisticTrackState.COLLECTION]: '揽收',
  [LogisticTrackState.DIFFICULT]: '疑难',
  [LogisticTrackState.SIGNING]: '签收',
  [LogisticTrackState.REJECTED]: '退签',
  [LogisticTrackState.PIECES]: '派件',
  [LogisticTrackState.RETURNED]: '退回',
};

// 驿站窗口类型 enum
export enum WindowType {
  ARTIFICIAL = 'ARTIFICIAL',
  ROBOT_LOAD = 'ROBOT_LOAD',
  ENTRANCE = 'ENTRANCE',
}

// 驿站窗口类型
export const WindowTypeStr = {
  [WindowType.ARTIFICIAL]: '手动装箱',
  [WindowType.ROBOT_LOAD]: '自动装箱',
  [WindowType.ENTRANCE]: '取件',
};

// 驿站窗口类型下拉列表
export const WindowTypeOptions = [
  { label: '全部', value: '' },
  { label: WindowTypeStr[WindowType.ARTIFICIAL], value: WindowType.ARTIFICIAL },
  { label: WindowTypeStr[WindowType.ROBOT_LOAD], value: WindowType.ROBOT_LOAD },
  { label: WindowTypeStr[WindowType.ENTRANCE], value: WindowType.ENTRANCE },
];

// 驿站窗口状态 enum
export enum WindowState {
  ERROR = 'ERROR',
  EMPLOY = 'EMPLOY',
  IDLE = 'IDLE',
}

// 驿站窗口状态
export const WindowStateStr = {
  [WindowState.ERROR]: '故障',
  [WindowState.EMPLOY]: '占用',
  [WindowState.IDLE]: '空闲',
};

// 驿站窗口状态下拉列表
export const WindowStateOptions = [
  { label: '全部', value: '' },
  { label: WindowStateStr[WindowState.EMPLOY], value: WindowState.EMPLOY },
  { label: WindowStateStr[WindowState.IDLE], value: WindowState.IDLE },
  { label: WindowStateStr[WindowState.ERROR], value: WindowState.ERROR },
];

/************** 机器人常量声明 **************/

export enum RobotConfiguration {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const RobotConfigurationUrl = {
  [RobotConfiguration.DEVELOPMENT]: 'https://development.api.ctirobot.com/api/config/robot-configuration.json',
  [RobotConfiguration.STAGING]: 'https://staging.api.ctirobot.com/api/config/robot-configuration.json',
  [RobotConfiguration.PRODUCTION]: 'https://api.ctirobot.com/api/config/robot-configuration.json',
};

export enum DeviceStatus {
  OFFLINE = 'OFFLINE',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  IDLE = 'IDLE',
  MAINTENANCE = 'MAINTENANCE',
  CHARGING = 'CHARGING',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
  BUSY = 'BUSY',
  IN_USE = 'IN_USE',
}

export enum NavigationState {
  UNDEFINED = 'UNDEFINED',
  BOOTING = 'BOOTING',
  INITIALIZING = 'INITIALIZING',
  LOCATIONING = 'LOCATIONING',
  RELOCATIONING = 'RELOCATIONING',
  IDLE = 'IDLE',
  MOVING = 'MOVING',
  MOVING_TO_LIFT = 'MOVING_TO_LIFT',
  PAUSED = 'PAUSED',
  ENTERING_LIFT = 'ENTERING_LIFT',
  LEAVING_LIFT = 'LEAVING_LIFT',
  WAITING = 'WAITING',
  WAITING_LIFT_OUTSIDE = 'WAITING_LIFT_OUTSIDE',
  WAITING_LIFT_INSIDE = 'WAITING_LIFT_INSIDE',
  ENTERING_CHARGER = 'ENTERING_CHARGER',
  LEAVING_CHARGER = 'LEAVING_CHARGER',
  MOVING_TO_GATE = 'MOVING_TO_GATE',
  WAITING_GATE = 'WAITING_GATE',
  CROSSING_GATE = 'CROSSING_GATE',
  CHARGING = 'CHARGING',
  STRAIGHT = 'STRAIGHT',
  MOUNTING = 'MOUNTING',
  UNMOUNTING = 'UNMOUNTING',
  MISSION_COMPLETE = 'MISSION_COMPLETE',
  CONGESTION = 'CONGESTION',
  LOST = 'LOST',
  PAUSE = 'PAUSE',
  ERROR = 'ERROR',
}

export const DeviceStatusStr = {
  [DeviceStatus.OFFLINE]: '离线',
  [DeviceStatus.ACTIVE]: '活跃',
  [DeviceStatus.INACTIVE]: '失联',
  [DeviceStatus.IDLE]: '空闲',
  [DeviceStatus.BUSY]: '忙',
  [DeviceStatus.MAINTENANCE]: '维护',
  [DeviceStatus.CHARGING]: '充电',
  [DeviceStatus.ERROR]: '故障',
  [DeviceStatus.UNKNOWN]: '未知',
  [DeviceStatus.IN_USE]: '使用',
};

export enum LoadingState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  UNLOADING = 'UNLOADING',
  ERROR = 'ERROR',
}

export const LoadingStateStr = {
  LOADING: '正在装箱',
  LOADED: '已装箱',
  UNLOADING: '正在卸箱',
  ERROR: '故障',
};

export const NavigationStateStr = {
  [NavigationState.UNDEFINED]: '未定义',
  [NavigationState.BOOTING]: '启动中',
  [NavigationState.INITIALIZING]: '初始化',
  [NavigationState.LOCATIONING]: '定位中',
  [NavigationState.RELOCATIONING]: '重定位中',
  [NavigationState.IDLE]: '空闲中',
  [NavigationState.MOVING]: '行驶中',
  [NavigationState.MOVING_TO_LIFT]: '向电梯行驶中',
  [NavigationState.PAUSED]: '暂停中',
  [NavigationState.ENTERING_LIFT]: '进入电梯',
  [NavigationState.LEAVING_LIFT]: '离开电梯',
  [NavigationState.WAITING]: '等待中',
  [NavigationState.WAITING_LIFT_OUTSIDE]: '在电梯外等待',
  [NavigationState.WAITING_LIFT_INSIDE]: '在电梯内等待',
  [NavigationState.ENTERING_CHARGER]: '对接充电桩',
  [NavigationState.LEAVING_CHARGER]: '离开充电桩',
  [NavigationState.MOVING_TO_GATE]: '像闸机行驶中',
  [NavigationState.WAITING_GATE]: '等待闸机开启',
  [NavigationState.CROSSING_GATE]: '通过闸机中',
  [NavigationState.CHARGING]: '充电中',
  [NavigationState.STRAIGHT]: '路径规划',
  [NavigationState.MOUNTING]: '装箱中',
  [NavigationState.UNMOUNTING]: '卸箱中',
  [NavigationState.MISSION_COMPLETE]: '任务完成',
  [NavigationState.CONGESTION]: '前方堵塞',
  [NavigationState.LOST]: '定位丢失',
  [NavigationState.PAUSED]: '暂停',
  [NavigationState.ERROR]: '错误',
};

export const DeviceStatusOption = [
  { label: DeviceStatusStr[DeviceStatus.OFFLINE], value: DeviceStatus.OFFLINE },
  { label: DeviceStatusStr[DeviceStatus.BUSY], value: DeviceStatus.BUSY },
  { label: DeviceStatusStr[DeviceStatus.ACTIVE], value: DeviceStatus.ACTIVE },
  { label: DeviceStatusStr[DeviceStatus.INACTIVE], value: DeviceStatus.INACTIVE },
  { label: DeviceStatusStr[DeviceStatus.IDLE], value: DeviceStatus.IDLE },
  { label: DeviceStatusStr[DeviceStatus.MAINTENANCE], value: DeviceStatus.MAINTENANCE },
  { label: DeviceStatusStr[DeviceStatus.CHARGING], value: DeviceStatus.CHARGING },
  { label: DeviceStatusStr[DeviceStatus.ERROR], value: DeviceStatus.ERROR },
  { label: DeviceStatusStr[DeviceStatus.UNKNOWN], value: DeviceStatus.UNKNOWN },
];

export enum RobotType {
  UNDEFINED = 'UNDEFINED',
  BR201 = 'BR201',
  BR202 = 'BR202',
  BR203 = 'BR203',
  BR204 = 'BR204',
  BR205 = 'BR205',
  HR201 = 'HR201',
  LR201 = 'LR201',
  LR202 = 'LR202',
  LR203 = 'LR203',
  LR204 = 'LR204',
  LR401 = 'LR401',
  LR402 = 'LR402',
  LR403 = 'LR403',
  LR404 = 'LR404',
  LR405 = 'LR405',
  LR406 = 'LR406',
  TR101 = 'TR101',
  TR201 = 'TR201',
  TR401 = 'TR401',
}

export enum RobotSeries {
  UNDEFINED = 'UNDEFINED',
  SUNLIGHT = 'SUNLIGHT',
  CANDLELIGHT = 'CANDLELIGHT',
  STARLIGHT = 'STARLIGHT',
}

export const RobotTypeStr = {
  [RobotType.UNDEFINED]: '未定义',
  [RobotType.BR201]: '两轮楼宇物流1.0',
  [RobotType.BR202]: '两轮楼宇物流2.0',
  [RobotType.BR203]: '两轮楼宇物流3.0',
  [RobotType.BR204]: '两轮楼宇物流4.0',
  [RobotType.BR205]: '两轮楼宇物流5.0',
  [RobotType.HR201]: '两轮酒店1.0',
  [RobotType.LR201]: '两轮球形物流',
  [RobotType.LR202]: '两轮人形物流',
  [RobotType.LR203]: '两轮箱式物流',
  [RobotType.LR204]: '两轮摩托物流',
  [RobotType.LR401]: '四轮羊驼物流',
  [RobotType.LR402]: '四轮四驱物流1.0',
  [RobotType.LR403]: '四轮四驱物流1.1',
  [RobotType.LR404]: '四轮四驱物流1.2',
  [RobotType.LR405]: '四轮四驱物流1.2.1',
  [RobotType.LR406]: '四轮四驱物流1.3',
  [RobotType.TR101]: '球形宠物',
  [RobotType.TR201]: '两轮球形宠物',
  [RobotType.TR401]: '四轮车型宠物',
};

export const SunlightRobotTypeOption = [
  { value: RobotType.LR402, label: RobotTypeStr[RobotType.LR402] },
  { value: RobotType.LR403, label: RobotTypeStr[RobotType.LR403] },
  { value: RobotType.LR404, label: RobotTypeStr[RobotType.LR404] },
  { value: RobotType.LR405, label: RobotTypeStr[RobotType.LR405] },
  { value: RobotType.LR406, label: RobotTypeStr[RobotType.LR406] },
];

export const CandlelightRobotTypeOption = [
  { value: RobotType.BR201, label: RobotTypeStr[RobotType.BR201] },
  { value: RobotType.BR202, label: RobotTypeStr[RobotType.BR202] },
  { value: RobotType.BR203, label: RobotTypeStr[RobotType.BR203] },
  { value: RobotType.BR205, label: RobotTypeStr[RobotType.BR205] },
  { value: RobotType.BR204, label: RobotTypeStr[RobotType.BR204] },
];

export const RobotSeriesStr = {
  [RobotSeries.UNDEFINED]: '未定义',
  [RobotSeries.SUNLIGHT]: '阳光',
  [RobotSeries.CANDLELIGHT]: '烛光',
  [RobotSeries.STARLIGHT]: '星光',
};

export enum HiveLayoutType {
  UNDEFINED = 'UNDEFINED',
  BR201 = 'BR201',
  BR202 = 'BR202',
  BR203 = 'BR203',
  BR204 = 'BR204',
  BR205 = 'BR205',
  CM201 = 'CM201',
  CC3H10 = 'CC3H10',
  CCGT10 = 'CCGT10',
  CCGT20 = 'CCGT20',
  CCM410 = 'CCM410',
  CCM411 = 'CCM411',
  CCST10 = 'CCST10',
  CCST20 = 'CCST20',
  CCTG10 = 'CCTG10',
  SCGT10 = 'SCGT10',
  SCST10 = 'SCST10',
  CCGC10 = 'CCGC10',
  SCSW20 = 'SCSW20',
  CR202M1 = 'CR202M1',
  CR202M2 = 'CR202M2',
  CR202M3 = 'CR202M3',
  CR202M4 = 'CR202M4',
  CR202M5 = 'CR202M5',
  CR202M6 = 'CR202M6',
  CR202M7 = 'CR202M7',
  HR201 = 'HR201',
  LR203 = 'LR203',
  LR204 = 'LR204',
  LR401 = 'LR401',
  LR402 = 'LR402',
  LR403 = 'LR403',
  LR404 = 'LR404',
  LR405 = 'LR405',
  LR406 = 'LR406',
}

export enum HiveSeries {
  UNDEFINED = 'UNDEFINED',
  DELIVERY = 'DELIVERY',
  COLLECTION = 'COLLECTION',
  STERILIZATION = 'STERILIZATION',
  VENDING = 'VENDING',
  GARBAGE = 'GARBAGE',
}

export const HiveLayoutTypeStr = {
  [HiveLayoutType.UNDEFINED]: '未定义',
  [HiveLayoutType.BR201]: '两轮楼宇物流1.0',
  [HiveLayoutType.BR202]: '两轮楼宇物流2.0',
  [HiveLayoutType.BR203]: '两轮楼宇物流3.0',
  [HiveLayoutType.BR204]: '两轮楼宇物流4.0',
  [HiveLayoutType.BR205]: '伪六格口寄件箱',
  [HiveLayoutType.CM201]: '寄件箱2.0（邮筒)',
  [HiveLayoutType.CC3H10]: '多功能三格箱红绒1.0',
  [HiveLayoutType.CCGT10]: '垃圾转运箱1.0',
  [HiveLayoutType.CCGT20]: '垃圾转运箱2.0',
  [HiveLayoutType.CCM410]: '医疗四格一体式配送箱',
  [HiveLayoutType.CCM411]: '医疗四格分体式配送箱',
  [HiveLayoutType.CCST10]: '智能垃圾转运箱1.0',
  [HiveLayoutType.CCST20]: '智能垃圾转运箱2.0',
  [HiveLayoutType.SCGT10]: '阳光垃圾转运箱1.0',
  [HiveLayoutType.SCST10]: '阳光智能垃圾转运箱1.0',
  [HiveLayoutType.CCGC10]: '智能地面清洁箱1.0',
  [HiveLayoutType.SCSW20]: '阳光智能清扫箱2.0',
  [HiveLayoutType.CR202M1]: '派件箱3.0-三宫格',
  [HiveLayoutType.CR202M2]: '派件箱3.0-四宫格',
  [HiveLayoutType.CR202M3]: '派件箱3.0-五宫格',
  [HiveLayoutType.CR202M4]: '派件箱3.0-六宫格',
  [HiveLayoutType.CR202M5]: '派件箱3.0-两格开门',
  [HiveLayoutType.CR202M6]: '派件箱3.0-三格开门',
  [HiveLayoutType.CR202M7]: '派件箱3.0-新四宫格',
  [HiveLayoutType.HR201]: '两轮酒店1.0',
  [HiveLayoutType.LR203]: '两轮箱式物流',
  [HiveLayoutType.LR204]: '两轮摩托物流',
  [HiveLayoutType.LR401]: '四轮羊驼物流',
  [HiveLayoutType.LR402]: '四轮四驱物流1.0',
  [HiveLayoutType.LR403]: '四轮四驱物流1.1',
  [HiveLayoutType.LR404]: '四轮四驱物流1.2',
  [HiveLayoutType.LR405]: '阳光智能多格箱1.4',
  [HiveLayoutType.LR406]: '阳光智能多格箱1.3.1',
  [HiveLayoutType.CCTG10]: '消毒一体机器人',
};

export const HiveSeriesStr = {
  [HiveSeries.UNDEFINED]: '未定义',
  [HiveSeries.DELIVERY]: '派件',
  [HiveSeries.COLLECTION]: '寄件',
  [HiveSeries.STERILIZATION]: '消毒',
  [HiveSeries.VENDING]: '零售',
  [HiveSeries.GARBAGE]: '垃圾',
};

export enum OperationMode {
  DELIVERY = 'DELIVERY',
  COLLECTION = 'COLLECTION',
  VENDING = 'VENDING',
  DISPATCH = 'DISPATCH',
  SHARED = 'SHARED',
  STERILIZATION = 'STERILIZATION',
  LOGISTICS = 'LOGISTICS',
  TRANSPORT = 'TRANSPORT',
  GARBAGE = 'GARBAGE',
  PRIVATE_SHARED = 'PRIVATE_SHARED',
  CLEANING = 'CLEANING',
  GUIDE = 'GUIDE',
}

export const OperationModeStr = {
  [OperationMode.DELIVERY]: '派件模式',
  // [OperationMode.COLLECTION]: '寄件模式',
  [OperationMode.VENDING]: '零售模式',
  // [OperationMode.DISPATCH]: '外卖模式',
  [OperationMode.GARBAGE]: '垃圾模式',
  [OperationMode.SHARED]: '对外共享模式',
  [OperationMode.TRANSPORT]: '转运模式',
  [OperationMode.LOGISTICS]: '物流模式',
  [OperationMode.STERILIZATION]: '消毒模式',
  [OperationMode.PRIVATE_SHARED]: '对内共享模式',
  [OperationMode.GUIDE]: '引导模式',
  [OperationMode.CLEANING]: '清洁模式',
};

export const CommandStateStr = {
  PENDING: { title: '待执行', color: '#FF9933' },
  IN_PROGRESS: { title: '执行中', color: '#108ee9' },
  COMPLETED: { title: '已完成', color: '#87d068' },
  FAILED: { title: '已失败', color: '#f50' },
  CANCELLED: { title: '已取消', color: '#979797' },
  QUEUED: { title: '排队中', color: '#FF9933' },
};

export const TransitStateStr = {
  PENDING: { text: '初始化', color: '#2db7f5' },
  BLOCKED: { text: '待处理', color: '#FF9933' },
  PICKING_UP: { text: '取柜', color: 'magenta' },
  MOUNTING: { text: '装载', color: 'purple' },
  DROPPING_OFF: { text: '送柜', color: 'magenta' },
  DISMOUNTING: { text: '卸载', color: 'purple' },
  COMPLETED: { text: '已完成', color: '#87d068' },
  CANCELLED: { text: '已取消', color: '#f50' },
  EXPIRED: { text: '已超时', color: '#f50' },
  QUEUED: { text: '排队中', color: '#FF9933' },
};

export const TransitModeStr = {
  COLLECT: '取货',
  DISPATCH: '送货',
  RETURN: '返航',
  CHARGE: '充电',
  DEPLOY: '部署',
  RECALL: '召回',
  INTEGRATE: '整合',
};

export enum FloorZone {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const FloorZoneOption = [
  { label: '低档', value: FloorZone.LOW },
  { label: '中档', value: FloorZone.MEDIUM },
  { label: '高档', value: FloorZone.HIGH },
];

export const DialStatusOptions = [
  { label: '成功', value: 'true' },
  { label: '失败', value: 'false' },
];

export const deliveryDaysArr = [
  { label: '周一至周五', value: JSON.stringify([1, 2, 3, 4, 5]) },
  { label: '周一至周六', value: JSON.stringify([1, 2, 3, 4, 5, 6]) },
  { label: '周一至周天', value: JSON.stringify([1, 2, 3, 4, 5, 6, 7]) },
];

export enum HistoryType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const HistoryTypeStr = {
  [HistoryType.CREATE]: '创建',
  [HistoryType.UPDATE]: '更新',
  [HistoryType.DELETE]: '删除',
};

export enum AddressType {
  CONTACT = 'CONTACT',
  USER = 'USER',
  WAYPOINT = 'WAYPOINT',
  PRIVATE_WAYPOINT = 'PRIVATE_WAYPOINT',
}

export enum OrderType {
  SEND = 'SEND',
  COLLECT = 'COLLECT',
  TRANSPORT = 'TRANSPORT',
  DISPATCH = 'DISPATCH',
}

export const OrderTypeStr = {
  [OrderType.COLLECT]: OrderStateStr,
  [OrderType.SEND]: SendOrderStatusStr,
  [OrderType.TRANSPORT]: TransportOrderStateStr,
  [OrderType.DISPATCH]: DispatchOrderStatusStr,
};

/************** 用户权限常量声明 **************/

export const RoleTypes = {
  USER: '普通用户',
  ADMIN: '管理员',
  COURIER: '快递员',
  HUB_ADMIN: '驿站管理员',
  SUPER_ADMIN: '超级管理员',
};

export enum ElevatorType {
  PASSENGER = 'PASSENGER',
  EMERGENCY = 'EMERGENCY',
  FREIGHT = 'FREIGHT',
}

export const ElevatorTypeStr = {
  [ElevatorType.PASSENGER]: '客梯',
  [ElevatorType.EMERGENCY]: '消防梯',
  [ElevatorType.FREIGHT]: '货梯',
};

export enum ElevatorPlatformType {
  DUOAO = 'DUOAO',
  WANGLONG = 'WANGLONG',
  CANDELA = 'CANDELA',
}

export const ElevatorPlatformTypeStr = {
  [ElevatorPlatformType.DUOAO]: '多奥',
  [ElevatorPlatformType.WANGLONG]: '旺龙',
  [ElevatorPlatformType.CANDELA]: '坎德拉',
};

export enum DockType {
  CHARGER = 'CHARGER',
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
  EXCLUSIVE = 'EXCLUSIVE',
  INTERCHANGE = 'INTERCHANGE',
}

export const DockTypeStr = {
  [DockType.CHARGER]: '充电',
  [DockType.INDOOR]: '室内',
  [DockType.OUTDOOR]: '室外',
  [DockType.EXCLUSIVE]: '专用',
  [DockType.INTERCHANGE]: '接驳',
};

export enum ValuationMethod {
  ACTUAL_WEIGHT = 'ACTUAL_WEIGHT',
  VOLUME_WEIGHT = 'VOLUME_WEIGHT',
  MIX = 'MIX',
}

export const ValuationMethodStr = {
  [ValuationMethod.ACTUAL_WEIGHT]: '实际重量',
  [ValuationMethod.VOLUME_WEIGHT]: '体积重量',
  [ValuationMethod.MIX]: '混合',
};

/************** 寄件订单常量声明 **************/

export enum GoodsType {
  BOOK = 'BOOK',
  DIGITAL_PRODUCT = 'DIGITAL_PRODUCT',
  COMMODITY = 'COMMODITY',
  CLOTHING = 'CLOTHING',
  FRESH = 'FRESH',
  OTHER = 'OTHER',
}

export const GoodsTypeStr = {
  [GoodsType.BOOK]: '文件',
  [GoodsType.DIGITAL_PRODUCT]: '数码产品',
  [GoodsType.COMMODITY]: '日用品',
  [GoodsType.CLOTHING]: '服饰',
  [GoodsType.FRESH]: '生鲜食品',
  [GoodsType.OTHER]: '其他',
};

export const GoodsTypeOption = [
  { label: '文件', value: GoodsType.BOOK },
  { label: '数码产品', value: GoodsType.DIGITAL_PRODUCT },
  { label: '日用品', value: GoodsType.COMMODITY },
  { label: '服饰', value: GoodsType.CLOTHING },
  { label: '生鲜食品', value: GoodsType.FRESH },
  { label: '其他', value: GoodsType.OTHER },
];

export enum PaymentMethod {
  PREPAID = 'PREPAID',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
}

export const PaymentMethodStr = {
  [PaymentMethod.PREPAID]: '寄付',
  [PaymentMethod.CASH_ON_DELIVERY]: '到付',
};

export const PaymentMethodOptions = [
  { label: '寄付', value: PaymentMethod.PREPAID },
  { label: '到付', value: PaymentMethod.CASH_ON_DELIVERY },
];

export const ChangeSendOrderState = [OrderState.PENDING, OrderState.PROCESSED, OrderState.ON_ROUTE, OrderState.ARRIVED];

export enum ExpressType {
  LAND = 'LAND',
  AIR_TRANSPORT = 'AIR_TRANSPORT',
  SAME_CITY_TODAY = 'SAME_CITY_TODAY',
  SAME_CITY_SAME_DAY = 'SAME_CITY_SAME_DAY',
}

export const ExpressTypeStr = {
  [ExpressType.LAND]: '陆运',
  [ExpressType.AIR_TRANSPORT]: '空运',
  [ExpressType.SAME_CITY_TODAY]: '同城即日',
  [ExpressType.SAME_CITY_SAME_DAY]: '同城当日达',
};

/************** 前置仓常量声明 **************/

export enum SorterState {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  ASSIGNED = 'ASSIGNED',
  STORAGE = 'STORAGE',
  WAREHOUSING = 'WAREHOUSING',
  CHECKED_OUT = 'CHECKED_OUT',
  OUT_OF_WAREHOUSE = 'OUT_OF_WAREHOUSE',
}

export const SorterStateStr = {
  [SorterState.PENDING]: '已入库',
  [SorterState.ASSIGNED]: '已分配',
  [SorterState.STORAGE]: '待入仓',
  [SorterState.OUT_OF_WAREHOUSE]: '待出仓',
  [SorterState.WAREHOUSING]: '已出仓',
  [SorterState.CHECKED_OUT]: '已出库',
  [SorterState.ERROR]: '异常',
};

/************** 消息模板常量声明 **************/

export enum MiddlewareType {
  SMS = 'SMS',
  WEIXIN = 'WEIXIN',
  OFFICIAL_ACCOUNT = 'OFFICIAL_ACCOUNT',
  VOICE = 'VOICE',
}

export const MiddlewareTypeStr = {
  [MiddlewareType.SMS]: '短信',
  [MiddlewareType.WEIXIN]: '小程序',
  [MiddlewareType.OFFICIAL_ACCOUNT]: '公众号',
  [MiddlewareType.VOICE]: '电话',
};

export const providerType = {
  TENCENT: '腾讯云',
  ALIYUN: '阿里云',
};

/************** OTA **************/

export enum EquipmentType {
  ROBOT = 'ROBOT',
  SUNLIGHT = 'SUNLIGHT',
  CANDLELIGHT = 'CANDLELIGHT',
  HIVE = 'HIVE',
  ELEVATOR = 'ELEVATOR',
  SIGNAL = 'SIGNAL',
  DELIVERY = 'DELIVERY',
  COLLECTION = 'COLLECTION',
  GARBAGE = 'GARBAGE',
  STERILIZATION = 'STERILIZATION',
}

export const EquipmentStr = {
  [EquipmentType.ROBOT]: '机器人',
  [EquipmentType.SUNLIGHT]: '阳光机器人',
  [EquipmentType.CANDLELIGHT]: '烛光机器人',
  [EquipmentType.HIVE]: '单元柜',
  [EquipmentType.ELEVATOR]: '梯控',
  [EquipmentType.SIGNAL]: '交通灯',
  [EquipmentType.DELIVERY]: '派件柜',
  [EquipmentType.COLLECTION]: '寄件柜',
  [EquipmentType.GARBAGE]: '垃圾箱',
  [EquipmentType.STERILIZATION]: '消毒柜',
};

export const EquipmentOption = [
  {
    title: EquipmentStr[EquipmentType.ROBOT],
    value: EquipmentType.ROBOT,
    children: [
      { title: EquipmentStr[EquipmentType.SUNLIGHT], value: EquipmentType.SUNLIGHT },
      { title: EquipmentStr[EquipmentType.CANDLELIGHT], value: EquipmentType.CANDLELIGHT },
    ],
  },
  {
    title: EquipmentStr[EquipmentType.HIVE],
    value: EquipmentType.HIVE,
    children: [
      { title: EquipmentStr[EquipmentType.DELIVERY], value: EquipmentType.DELIVERY },
      { title: EquipmentStr[EquipmentType.COLLECTION], value: EquipmentType.COLLECTION },
      { title: EquipmentStr[EquipmentType.GARBAGE], value: EquipmentType.GARBAGE },
      { title: EquipmentStr[EquipmentType.STERILIZATION], value: EquipmentType.STERILIZATION },
    ],
  },
  { title: EquipmentStr[EquipmentType.ELEVATOR], value: EquipmentType.ELEVATOR },
  { title: EquipmentStr[EquipmentType.SIGNAL], value: EquipmentType.SIGNAL },
];

export enum RobotEquipmentType {
  NAVIGATION = 'NAVIGATION',
  EMBEDDED = 'EMBEDDED',
  TABLET = 'TABLET',
}

export const RobotEquipmentStr = {
  [RobotEquipmentType.NAVIGATION]: '导航',
  [RobotEquipmentType.EMBEDDED]: '嵌入式',
  [RobotEquipmentType.TABLET]: '平板',
};

export const RobotEquipmentOption = [
  { label: RobotEquipmentStr[RobotEquipmentType.NAVIGATION], value: RobotEquipmentType.NAVIGATION },
  { label: RobotEquipmentStr[RobotEquipmentType.EMBEDDED], value: RobotEquipmentType.EMBEDDED },
  { label: RobotEquipmentStr[RobotEquipmentType.TABLET], value: RobotEquipmentType.TABLET },
];

export enum OTAState {
  PENDING = 'PENDING',
  DOWNLOADING = 'DOWNLOADING',
  UPGRADING = 'UPGRADING',
  COMPLETED = 'COMPLETED',
  FAIL = 'FAIL',
}

export const OTAStateStr = {
  [OTAState.PENDING]: '待升级', // 暂时不需要该配送方式
  [OTAState.DOWNLOADING]: '下载中',
  [OTAState.UPGRADING]: '升级中',
  [OTAState.COMPLETED]: '已完成',
  [OTAState.FAIL]: '失败', // 特殊原因，ROBOT和 HIVE都叫机器人配送
};

/************** 故障告警常量声明 **************/

export enum TriggerType {
  OFFLINE = 'OFFLINE',
  LOCATION = 'LOCATION',
  BATTERY = 'BATTERY',
  EMERGENCY_STOP = 'EMERGENCY_STOP',
  TIMEOUT = 'TIMEOUT',
  OVER_FREQUENCY = 'OVER_FREQUENCY',
  CHARGING = 'CHARGING',
  LOW_LEVEL = 'LOW_LEVEL',
}

export enum AlertLevel {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
}

export const AlertLevelStr = {
  [AlertLevel.INFO]: '提示',
  [AlertLevel.WARN]: '警告',
  [AlertLevel.ERROR]: '严重',
  [AlertLevel.CRITICAL]: '致命',
};

export const TriggerTypeStr = {
  [TriggerType.OFFLINE]: '离线告警',
  [TriggerType.LOCATION]: '定位告警',
  [TriggerType.BATTERY]: '电量告警',
  [TriggerType.EMERGENCY_STOP]: '急停告警',
  [TriggerType.TIMEOUT]: '超时告警',
  [TriggerType.OVER_FREQUENCY]: '超频告警',
  [TriggerType.CHARGING]: '充电异常告警',
  [TriggerType.LOW_LEVEL]: '底层故障告警',
};

export enum FaultStatus {
  PAUSED = 'PAUSED',
  MOUNTING = 'MOUNTING',
  UNMOUNTING = 'UNMOUNTING',
  STRAIGHT = 'STRAIGHT',
  ENTERING_LIFT = 'ENTERING_LIFT',
  LEAVING_LIFT = 'LEAVING_LIFT',
  WAITING_LIFT_INSIDE = 'WAITING_LIFT_INSIDE',
  WAITING_LIFT_OUTSIDE = 'WAITING_LIFT_OUTSIDE',
}

export const FaultStatusStr = {
  [FaultStatus.ENTERING_LIFT]: '进入电梯',
  [FaultStatus.WAITING_LIFT_INSIDE]: '在电梯中',
  [FaultStatus.WAITING_LIFT_OUTSIDE]: '呼叫电梯',
  [FaultStatus.LEAVING_LIFT]: '离开电梯',
  [FaultStatus.PAUSED]: '多机避让',
  [FaultStatus.STRAIGHT]: '堵塞状态',
  [FaultStatus.MOUNTING]: '正在装箱',
  [FaultStatus.UNMOUNTING]: '正在卸箱',
};

export enum StateActions {
  MOUNTING = 'MOUNTING',
  UNMOUNTING = 'UNMOUNTING',
}

export const TaskOption = [
  { label: '装箱', value: StateActions.MOUNTING },
  { label: '卸箱', value: StateActions.UNMOUNTING },
  { label: '到目标点', value: CommandType.MOVE },
];

export enum CreateTimeArea {
  ALL = 'ALL',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THREE_DAYS_BEFORE = 'THREE_DAYS_BEFORE',
}

export const CreateTimeAreaStr = {
  [CreateTimeArea.ALL]: '全部',
  [CreateTimeArea.TODAY]: '今日',
  [CreateTimeArea.YESTERDAY]: '昨日',
  [CreateTimeArea.THREE_DAYS_BEFORE]: '最近三天',
};

export const CreateTimeAreaOption = [
  {
    label: CreateTimeArea.ALL,
    value: {
      start: new Date(2016, 1, 1).getTime(),
      end: endOfDay(date).getTime(),
    },
  },
  {
    label: CreateTimeArea.TODAY,
    value: {
      start: startOfDay(date).getTime(),
      end: endOfDay(date).getTime(),
    },
  },
  {
    label: CreateTimeArea.YESTERDAY,
    value: {
      start: startOfDay(subDays(date, 1)).getTime(),
      end: endOfDay(subDays(date, 1)).getTime(),
    },
  },
  {
    label: CreateTimeArea.THREE_DAYS_BEFORE,
    value: {
      start: startOfDay(subDays(date, 3)).getTime(),
      end: endOfDay(date).getTime(),
    },
  },
];

export const ComponentsStr = {
  DRIVER: '驱动模块',
  JACK: '顶升功能',
  MOTOR: '运动状态',
  BATTERY: '电源管理',
  SENSOR: '传感器数据',
  COMMUNICATION: '通讯模块',
};

export const MiddlewareOption = [
  { label: '邮箱', value: 'EMAIL' },
  { label: '短信(收费)', value: 'SMS' },
  { label: '企业微信', value: 'WECHAT_WORK' },
];

export enum PaidMethodState {
  WEIXIN_TOP_UP = 'WEIXIN_TOP_UP',
  ALIPAY_TOP_UP = 'ALIPAY_TOP_UP',
  ORDER_PAYMENT = 'ORDER_PAYMENT',
  WEIXIN_RED_PACK = 'WEIXIN_RED_PACK',
}

export const PaidMethodStateStr = {
  [PaidMethodState.WEIXIN_TOP_UP]: '微信支付',
  [PaidMethodState.ALIPAY_TOP_UP]: '支付宝支付',
  [PaidMethodState.ORDER_PAYMENT]: '支付宝退款',
  [PaidMethodState.WEIXIN_RED_PACK]: '微信红包',
};

export enum ScenarioType {
  SEND = 'SEND',
  GUIDE = 'GUIDE',
  SHARED = 'SHARED',
  SWEEP = 'SWEEP',
  DEPOSIT = 'DEPOSIT',
  GARBAGE = 'GARBAGE',
  TRANSPORT = 'TRANSPORT',
  DISPATCH = 'DISPATCH',
  CLEANING = 'CLEANING',
  LOGISTICS = 'LOGISTICS',
  STERILIZATION = 'STERILIZATION',
  PRIVATE_SHARED = 'PRIVATE_SHARED',
}

export const ScenarioTypesStr = {
  [ScenarioType.SEND]: '寄件',
  [ScenarioType.GUIDE]: '引导',
  [ScenarioType.LOGISTICS]: '快递取件',
  [ScenarioType.DEPOSIT]: '寄件揽收',
  [ScenarioType.STERILIZATION]: '医疗消毒',
  [ScenarioType.TRANSPORT]: '物资转运',
  [ScenarioType.DISPATCH]: '外卖取件',
  [ScenarioType.SHARED]: '对外共享取件',
  [ScenarioType.GARBAGE]: '垃圾转运',
  [ScenarioType.CLEANING]: '地面清洁',
  [ScenarioType.PRIVATE_SHARED]: '对内共享取件',
};

export enum KeyType {
  USER = 'USER',
  ADMIN = 'ADMIN',
  MASTER = 'MASTER',
  TOUCHSCREEN = 'TOUCHSCREEN',
}

export const KeyTypeStr = {
  [KeyType.USER]: '用户',
  [KeyType.ADMIN]: '管理员',
  [KeyType.MASTER]: '系统',
  [KeyType.TOUCHSCREEN]: '平板',
};

export const RobotSettingOptions = [
  { label: '启用', value: true },
  { label: '停用', value: false },
];

export const RobotSettingHiveOptions = [
  { label: 'WIFI', value: 'WIFI' },
  { label: 'LORA', value: 'LORA' },
];

export enum AlertHandleType {
  SITE = 'SITE',
  REMOTE = 'REMOTE',
  IGNORE = 'IGNORE',
}

export const AlertHandleTypeStr = {
  [AlertHandleType.SITE]: '现场处理',
  [AlertHandleType.REMOTE]: '远程处理',
  [AlertHandleType.IGNORE]: '无需处理',
};

// 实体类型
export enum EntityType {
  ROBOT = 'ROBOT',
  HIVE = 'HIVE',
  SIGNAL = 'SIGNAL',
  ELEVATOR = 'ELEVATOR',
}

export const EntityTypeStr = {
  [EntityType.ROBOT]: '机器人',
  [EntityType.HIVE]: '柜子',
  [EntityType.SIGNAL]: '交通灯',
  [EntityType.ELEVATOR]: '电梯',
};

// 故障告警类别
export enum AlertGroup {
  EMBEDDED = 'EMBEDDED',
  BUSINESS = 'BUSINESS',
  NAVIGATION = 'NAVIGATION',
}

export const AlertGroupStr = {
  [AlertGroup.EMBEDDED]: '嵌入式',
  [AlertGroup.BUSINESS]: '业务层',
  [AlertGroup.NAVIGATION]: '导航层',
};

export const AlertGroupOption = [
  { label: AlertGroupStr[AlertGroup.EMBEDDED], value: AlertGroup.EMBEDDED },
  { label: AlertGroupStr[AlertGroup.BUSINESS], value: AlertGroup.BUSINESS },
  { label: AlertGroupStr[AlertGroup.NAVIGATION], value: AlertGroup.NAVIGATION },
];

export enum TaskMode {
  DELIVERY = 'DELIVERY',
  SHARED = 'SHARED',
  COLLECTION = 'COLLECTION',
  DISPATCH = 'DISPATCH',
  TRANSPORT = 'TRANSPORT',
  STERILIZATION = 'STERILIZATION',
  VENDING = 'VENDING',
  LOGISTICS = 'LOGISTICS',
  GARBAGE = 'GARBAGE',
  SWEEP = 'SWEEP',
}

export const TaskModeStr = {
  [TaskMode.DELIVERY]: '派件',
  [TaskMode.COLLECTION]: '寄件',
  [TaskMode.SHARED]: '共享',
  [TaskMode.DISPATCH]: '外卖',
  [TaskMode.TRANSPORT]: '转运',
  [TaskMode.STERILIZATION]: '消毒',
  [TaskMode.VENDING]: '零售',
  [TaskMode.LOGISTICS]: '物流',
  [TaskMode.GARBAGE]: '垃圾',
  [TaskMode.SWEEP]: '清扫',
};

export enum TaskState {
  DORMANT = 'DORMANT',
  QUEUED = 'QUEUED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export const TaskStateStr = {
  [TaskState.DORMANT]: '待执行',
  [TaskState.QUEUED]: '待执行',
  [TaskState.PENDING]: '待执行',
  [TaskState.IN_PROGRESS]: '执行中',
  [TaskState.PAUSED]: '已暂停',
  [TaskState.COMPLETED]: '已完成',
  [TaskState.FAILED]: '已失败',
  [TaskState.CANCELLED]: '已取消',
};

export const TaskStateHeaderFilter = [
  { label: TaskStateStr[TaskState.DORMANT], value: 'DORMANT, QUEUED, PENDING' },
  { label: TaskStateStr[TaskState.IN_PROGRESS], value: 'IN_PROGRESS' },
  { label: TaskStateStr[TaskState.PAUSED], value: 'PAUSED' },
  { label: TaskStateStr[TaskState.COMPLETED], value: 'COMPLETED' },
  { label: TaskStateStr[TaskState.FAILED], value: 'FAILED' },
  { label: TaskStateStr[TaskState.CANCELLED], value: 'CANCELLED' },
];

/************** 财务对账常量声明 **************/

export const FinancialSendOrderStr = [
  { label: '全部', value: [OrderState.CLOSED, OrderState.CONFIRMED].join() },
  { label: '待寄出', value: [OrderState.CONFIRMED].join() },
  { label: '已寄出', value: [OrderState.CLOSED].join() },
];

/************** 设备注册常量声明  **************/

export enum GridLayoutType {
  SMALL_LAYOUT = 'SMALL_LAYOUT',
  MIDDLE_LAYOUT = 'MIDDLE_LAYOUT',
  LARGE_LAYOUT = 'LARGE_LAYOUT',
}

export const GridLayoutTypeOptions = {
  [GridLayoutType.SMALL_LAYOUT]: '小格口',
  [GridLayoutType.MIDDLE_LAYOUT]: '中格口',
  [GridLayoutType.LARGE_LAYOUT]: '大格口',
};

export enum CameraBrand {
  LECHANGE = 'LECHANGE',
  EZVIZ = 'EZVIZ',
  HD_CAMERA = 'HD_CAMERA',
}

export const CameraBrandOptions = {
  [CameraBrand.LECHANGE]: '乐橙',
  [CameraBrand.EZVIZ]: '荧石',
  [CameraBrand.HD_CAMERA]: '鱼眼摄像头',
};

export enum CameraType {
  NVR = 'NVR',
  DVR = 'DVR',
  HCVR = 'HCVR',
  IPC = 'IPC',
  SD = 'SD',
  ARC = 'ARC',
}

export const CameraTypeOptions = {
  [CameraType.NVR]: '网络数字硬盘录像机',
  [CameraType.DVR]: '数字视频录像机',
  [CameraType.HCVR]: '高清数字硬盘录像机',
  [CameraType.IPC]: '网络摄像机',
  [CameraType.SD]: 'SD卡录像机',
  [CameraType.ARC]: '音频录像机',
};

export const NotificationMiddlewareType = [
  { label: '电话通知', value: 'VOICE' },
  { label: '短信通知', value: 'SMS' },
];

export const DisabledOptions = [
  { label: '启用', value: true },
  { label: '禁用', value: false },
];

export enum ProductType {
  ROBOT = 'ROBOT',
  HIVE = 'HIVE',
  DOCK = 'DOCK',
}

export const ProductTypeStr = {
  [ProductType.ROBOT]: '机器人',
  [ProductType.HIVE]: '智能柜',
  [ProductType.DOCK]: '硬件桩',
};

export const ProductTypeOptions = [
  { label: '机器人', value: ProductType.ROBOT },
  { label: '智能柜', value: ProductType.HIVE },
  { label: '硬件桩', value: ProductType.DOCK },
];

// tslint:disable-next-line: max-file-line-count

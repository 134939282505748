import { Form, InputNumber, Modal, Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { connect } from 'dva';
import React, { FC, useEffect } from 'react';
import { transformDataSource } from '../../../utils';

import { Dispatch } from '../../../models/dispatch';

const { Option } = Select;

interface DvaProps {
  companyOptions: DataSourceItemObject[];
  fetchCompanyOption: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  visible?: boolean;
  onCancel: () => void;
  onSubmit: (record: object) => void;
}

export const DeliveryCompanyPriceModal: FC<InternalProps> = (props) => {
  const [form] = Form.useForm();
  const { validateFields, resetFields } = form;
  const { visible, onCancel, onSubmit, companyOptions, fetchCompanyOption } = props;

  useEffect(() => {
    fetchCompanyOption();
  }, [fetchCompanyOption]);

  const handleSubmit = async () => {
    const values = await validateFields();
    const data = {
      ...values,
      postageType: 'COLLECT',
    };
    onSubmit(data);
  };

  return (
    <Modal
      centered
      forceRender
      title="添加定价"
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      afterClose={resetFields}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item name="companyId" label="快递公司" rules={[{ required: true, message: '请选择快递公司!' }]}>
          <Select showSearch placeholder="请选择快递公司" filterOption={false}>
            {companyOptions.map((item: any, index: number) => (
              <Option value={item.id} key={index}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="agreementPrice" label="协议价格(元)" rules={[{ required: true, message: '请输入金额!' }]}>
          <InputNumber
            step={0.1}
            min={0}
            autoFocus
            type="number"
            precision={2}
            placeholder="请输入金额"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ global, common, loading }: any) => {
  return {
    companyOptions: transformDataSource(common.companyOption),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchCompanyOption: (params?: object) => {
      dispatch({
        type: 'common/fetchCompanyOption',
        payload: params,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCompanyPriceModal);

import { AutoComplete, Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { FC } from 'react';

const Option = Select.Option;
const Search = Input.Search;
const { RangePicker } = DatePicker;

interface InternalProps {
  reqParams: any;
  userOption: any[];
  companyOption: any[];
  onPickDate: (dates: any, dateStrings: [string, string]) => void;
  handleChange: (key: string, value: any) => void;
  handleSearchUser: (value: any) => any;
}
const HandOverHistoryTitle: FC<InternalProps> = (props) => {
  const { reqParams, handleChange, onPickDate, companyOption, userOption, handleSearchUser } = props;

  return (
    <Form>
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="快递公司">
            <Select defaultValue={reqParams.companyId} placeholder="请输入" onChange={(value: string) => handleChange('companyId', value)}>
              {[{ name: '全部', id: '' }].concat(companyOption).map((item: any, index: number) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="快递运单号">
            <Search
              placeholder="运单号"
              defaultValue={reqParams.sender}
              onChange={(e: any) => handleChange('shipperNumber', e.target.value)}
              onSearch={(value: string) => handleChange('shipperNumber', value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="清单编号">
            <Search
              placeholder="清单编号"
              defaultValue={reqParams.inventoryNumber}
              onChange={(e: any) => handleChange('inventoryNumber', e.target.value)}
              onSearch={(value: string) => handleChange('inventoryNumber', value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="操作账号">
            <AutoComplete
              options={userOption}
              placeholder="企业名称"
              defaultValue={reqParams.name}
              onSelect={(value: any, option: any) => handleChange('modifiedBy', value)}
              onSearch={(value: any) => handleSearchUser(value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="交接时间区间">
            <RangePicker
              style={{ width: '100%' }}
              onChange={(dates: any, dateStrings: [string, string]) => onPickDate(dates, dateStrings)}
              placeholder={['开始日期', '结束日期']}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Button type="primary" style={{ margin: '0 10px  0 0' }}>
          导出记录
        </Button>
      </Row>
    </Form>
  );
};

export default HandOverHistoryTitle;

import React, { PureComponent } from 'react';

import styles from './RadarStatus.module.scss';

class RadarStatus extends PureComponent {

  public render() {

    return (
      <div className={styles.radarStatus}>
        <div className={styles.radarTop} />
        <div className={styles.radarBottom} />
        雷达状态
      </div>
    );
  }
}

export default RadarStatus;

import { Cascader, Form } from 'antd';
import { CascaderOptionType, CascaderProps, CascaderValueType } from 'antd/lib/cascader';
import { get } from 'lodash';
import React, { CSSProperties, FC, useCallback, useState } from 'react';

import { diffData } from '../../utils';
import EditableWrapper from './EditableWrapper';

const { Item } = Form;
const filter = (inputValue: string, path: any[]) => {
  return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};

interface InternalProps extends CascaderProps {
  record: any;
  title?: string;
  rules?: any[];
  editable?: boolean;
  dataIndex: string[];
  required?: boolean;
  wrapperStyle?: CSSProperties;
  options: CascaderOptionType[];
  onSubmit?: (params: object) => void;
}

export const EditableCascader: FC<InternalProps> = (props) => {
  let formContext: any;
  const {
    record,
    rules,
    title,
    editable,
    options,
    dataIndex,
    onSubmit,
    required,
    children,
    wrapperStyle,
    ...otherProps
  } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const validationRules = [{ required, message: `请输入${title}!` }].concat(rules || []);

  const getPopupContainer = useCallback(() => {
    const element = document.getElementById(`cascader-${dataIndex[0]}`);

    if (element) {
      return element;
    }

    return document.body;
  }, [dataIndex]);

  const handleEdit = (editing: boolean) => {
    setEditing(editing);
  };

  const onChange = async (value: CascaderValueType, selectedOptions?: CascaderOptionType[]) => {
    if (!value.length) {
      return;
    }

    if (formContext) {
      const values = await formContext.validateFields();

      if (diffData(record, values)) {
        let params: any;
        dataIndex.forEach((d: string, i: number) => {
          params = {
            ...params,
            [d]: value[i] || null,
          };
        });

        onSubmit && onSubmit(params);
        setEditing(false);
      }
    }
  };

  const renderInput = (form: any) => {
    formContext = form;
    form.setFieldsValue({ [dataIndex.join()]: dataIndex.map((d: any) => get(record, d)) });

    return (
      <Item style={{ margin: 0 }} name={dataIndex.join()} rules={validationRules}>
        <Cascader
          allowClear
          options={options}
          onChange={onChange}
          popupVisible={editing}
          showSearch={{ filter }}
          getPopupContainer={getPopupContainer}
          {...otherProps}
        />
      </Item>
    );
  };

  return (
    <EditableWrapper
      editing={editing}
      editable={editable}
      onEdit={handleEdit}
      renderArea={renderInput}
      wrapperStyle={wrapperStyle}
      id={`cascader-${dataIndex[0]}`}
    >
      {children}
    </EditableWrapper>
  );
};

export default EditableCascader;

import { Layout } from 'antd';
import { getYear } from 'date-fns';
import React, { FC } from 'react';

const Footer: FC<any> = (props) => {
  const color = '#fff';

  return (
    <Layout.Footer style={{ textAlign: 'center', backgroundColor: color }} {...props}>
      Copyright ©{getYear(new Date())} Created by Candela
    </Layout.Footer>
  );
};

export default Footer;

import * as React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const Dashboard = Loadable.Map({
  loader: {
    Dashboard: () => import('./Dashboard'),
    model: () => import('./models/Dashboard'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const Dashboard = loaded.Dashboard.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <Dashboard {...props} />;
  },
});

const TaskReminder = Loadable.Map({
  loader: {
    TaskReminder: () => import('./TaskReminder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const TaskReminder = loaded.TaskReminder.default;

    return <TaskReminder {...props} />;
  },
});

const SwitchHub = Loadable.Map({
  loader: {
    SwitchHub: () => import('./SwitchHub'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SwitchHub = loaded.SwitchHub.default;

    return (
      <Loading>
        <SwitchHub {...props} />
      </Loading>
    );
  },
});

const HubReport = Loadable.Map({
  loader: {
    HubReport: () => import('./HubReport'),
    model: () => import('./models/HubReport'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const HubReport = loaded.HubReport.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <HubReport {...props} />
      </Loading>
    );
  },
});

const DashRoleList = Loadable.Map({
  loader: {
    DashRoleList: () => import('./RoleList'),
    model: () => import('./models/RoleList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DashRoleList = loaded.DashRoleList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DashRoleList {...props} />
      </Loading>
    );
  },
});

export { Dashboard, SwitchHub, HubReport, DashRoleList, TaskReminder };

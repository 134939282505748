import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class MediaService {
  public static fetchMediaDevice(params?: object): Promise<any> {
    return GET(ApiUrl.MEDIA_DEVICE, params);
  }

  public static fetchDeviceInfo(id: string): Promise<any> {
    return GET(`${ApiUrl.MEDIA_DEVICE}/${id}/info`);
  }

  public static fetchDeviceLive(id: string, params: object): Promise<any> {
    return GET(`${ApiUrl.MEDIA_DEVICE}/${id}/live`, params);
  }

  public static fetchDeviceToken(id: string): Promise<any> {
    return GET(`${ApiUrl.MEDIA_DEVICE}/${id}/token`);
  }

  public static fetchMediaRecord(params?: object): Promise<any> {
    return GET(ApiUrl.MEDIA_RECORD, params);
  }

  public static postMediaRecord(params: object): Promise<any> {
    return POST(ApiUrl.MEDIA_RECORD, params);
  }

  public static postMediaDevice(params: object): Promise<any> {
    return POST(ApiUrl.MEDIA_DEVICE, params);
  }

  public static patchMediaDevice(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.MEDIA_DEVICE}/${id}`, params);
  }

  public static deleteMediaDevice(id: string): Promise<any> {
    return DELETE(`${ApiUrl.MEDIA_DEVICE}/${id}`);
  }
}

import { Empty, Modal, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';

interface InternalProps {
  src?: string;
  visible?: boolean;
  onCancel?: () => void;
}

export const PreviewImage: FC<InternalProps> = (props) => {
  const { visible, src, onCancel } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.src = src;
      img.onload = () => setLoading(false);
    }
  }, [src]);

  const renderImg = () => {
    if (loading) {
      return (
        <Row align="middle" justify="center" style={{ height: 320 }}>
          <Spin spinning={loading} />
        </Row>
      );
    } else {
      return <img alt="待完善信息" style={{ width: '100%' }} src={src} />;
    }
  };

  return (
    <Modal centered footer={null} closable={false} visible={visible} onCancel={onCancel} bodyStyle={{ padding: 0 }}>
      {src ? renderImg() : <Empty style={{ padding: 16 }} />}
    </Modal>
  );
};

export default PreviewImage;

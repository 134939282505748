import { message, notification } from 'antd';

/**
 * form表单提交错误处理提示
 * @param error
 */
export function formErrorHandler(error: any) {
  const { response } = error;

  if (response?.data) {
    const { error, details } = response.data;

    if (details?.length) {
      details.forEach((item: any) => {
        notification.error({
          message: '提交数据错误!',
          description: `${item.fieldName}${item.error}`,
        });
      });
    } else {
      notification.error({
        message: '提交数据错误!',
        description: error,
      });
    }
  } else {
    message.error('提交数据错误!');
  }
}

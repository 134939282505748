import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';
import {
  BasicSetting,
  GarbageClearSetting,
  HubSettings,
  RobotSetting,
  RobotSettingDetail,
  RulesSetting,
  SchedulingDisabledSetting,
  SendRulesSetting,
  ServiceTypeSetting,
  ShareSetting,
  TakeOutSetting,
} from './Settings';

const HubDetail = Loadable.Map({
  loader: {
    HubDetail: () => import('./containers/connectedHubDetail'),
    model: () => import('./models/HubDetail'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const HubDetail = loaded.HubDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <HubDetail {...props} />
      </Loading>
    );
  },
});

const AreaList = Loadable.Map({
  loader: {
    AreaList: () => import('./AreaList'),
    model: () => import('./models/AreaList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const AreaList = loaded.AreaList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <AreaList {...props} />
      </Loading>
    );
  },
});

const AreaEditOrAdd = Loadable.Map({
  loader: {
    AreaEditOrAdd: () => import('./AreaEditOrAdd'),
    model: () => import('./models/AreaList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const AreaEditOrAdd = loaded.AreaEditOrAdd.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <AreaEditOrAdd {...props} />
      </Loading>
    );
  },
});

const LiftList = Loadable.Map({
  loader: {
    LiftList: () => import('./LiftList'),
    model: () => import('./models/LiftList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const LiftList = loaded.LiftList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <LiftList {...props} />
      </Loading>
    );
  },
});

const RobotAreaList = Loadable.Map({
  loader: {
    RobotAreaList: () => import('./RobotAreaList'),
    model: () => import('./models/RobotArea'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RobotAreaList = loaded.RobotAreaList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <RobotAreaList {...props} />
      </Loading>
    );
  },
});

export {
  HubDetail,
  LiftList,
  RobotAreaList,
  AreaList,
  AreaEditOrAdd,
  HubSettings,
  BasicSetting,
  ShareSetting,
  RulesSetting,
  SendRulesSetting,
  TakeOutSetting,
  ServiceTypeSetting,
  RobotSetting,
  RobotSettingDetail,
  SchedulingDisabledSetting,
  GarbageClearSetting,
};

import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const HiveCellList = Loadable.Map({
  loader: {
    HiveCellList: () => import('./HiveCellList'),
    model: () => import('./models/HiveCell'),
    robotModel: () => import('../Robot/models/RobotList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const HiveCellList = loaded.HiveCellList.default;
    const robotModel = loaded.robotModel.default;
    const model = loaded.model.default;

    registerModel(props.app, robotModel);
    registerModel(props.app, model);

    return (
      <Loading>
        <HiveCellList {...props} />
      </Loading>
    );
  },
});

const HiveCellDetail = Loadable.Map({
  loader: {
    HiveCellDetail: () => import('./HiveCellDetail'),
    model: () => import('./models/HiveCell'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const HiveCellDetail = loaded.HiveCellDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <HiveCellDetail {...props} />
      </Loading>
    );
  },
});

const DeviceMonitor = Loadable.Map({
  loader: {
    DeviceMonitor: () => import('./DeviceMonitor'),
    model: () => import('./models/DeviceMonitor'),
    monitorCenter: () => import('../Robot/models/MonitorCenter'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceMonitor = loaded.DeviceMonitor.default;
    const monitorCenter = loaded.monitorCenter.default;
    const model = loaded.model.default;

    registerModel(props.app, monitorCenter);
    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceMonitor {...props} />
      </Loading>
    );
  },
});

const ShelfList = Loadable.Map({
  loader: {
    model: () => import('./models/ShelfList'),
    ShelfList: () => import('./ShelfList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const ShelfList = loaded.ShelfList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <ShelfList {...props} />
      </Loading>
    );
  },
});

const DeviceCommentList = Loadable.Map({
  loader: {
    DeviceCommentList: () => import('./DeviceCommentList'),
    model: () => import('./models/DeviceMonitor'),
    robotModel: () => import('../Robot/models/RobotList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceCommentList = loaded.DeviceCommentList.default;
    const robotModel = loaded.robotModel.default;
    const model = loaded.model.default;

    registerModel(props.app, robotModel);
    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceCommentList {...props} />
      </Loading>
    );
  },
});

const ReplayList = Loadable.Map({
  loader: {
    ReplayList: () => import('./ReplayList'),
    model: () => import('./models/DeviceMonitor'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const ReplayList = loaded.ReplayList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <ReplayList {...props} />
      </Loading>
    );
  },
});

export { HiveCellList, HiveCellDetail, DeviceMonitor, DeviceCommentList, ReplayList, ShelfList };

import { Col, Form, Input, Modal, Radio, Row, Select, Switch } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { FC, useEffect, useState } from 'react';

import {
  CandlelightRobotTypeOption,
  OperationMode,
  OperationModeStr,
  SunlightRobotTypeOption,
} from '../../../utils/constants';
import { MacAddressReg } from '../../../utils/regTool';

const { Option } = Select;
const { Item } = Form;

interface InternalProps {
  record?: any;
  visible?: boolean;
  loading?: boolean;
  robotType?: string;
  onCancel?: () => void;
  afterClose?: () => void;
  onSubmit: (params?: object) => void;
}

const RobotModal: FC<InternalProps> = (props) => {
  const { onCancel, robotType, loading, afterClose, visible, record, onSubmit } = props;
  const [form] = Form.useForm();
  const title = record ? '编辑机器人' : '添加机器人';
  const { resetFields, setFieldsValue, validateFields } = form;

  const [robotTypeOptions, setRobotTypeOptions] = useState<any[]>([]);

  useEffect(() => {
    const { disabled, indoor } = record || {};
    const initialValues = {
      ...record,
      disabled: disabled + '' ? !disabled : false,
      indoor: robotType === 'indoor',
    };

    setRobotTypeOptions(indoor ? CandlelightRobotTypeOption : SunlightRobotTypeOption);
    setFieldsValue(initialValues);
  }, [setFieldsValue, record, robotType]);

  const handleAfterClose = () => {
    resetFields();
    afterClose && afterClose();
  };

  const handleTypeChange = (e: RadioChangeEvent) => {
    setRobotTypeOptions(e.target.value ? CandlelightRobotTypeOption : SunlightRobotTypeOption);
  };

  const handleSubmit = async () => {
    const values = await validateFields();
    const {
      disabled,
      enableIfLoad,
      operationMode,
      disableMapUpload,
      entityVersionConfirm,
      internalRideLiftFlow,
    } = values;
    const configurations = {
      disableMapUpload: disableMapUpload !== undefined ? disableMapUpload : null,
      entityVersionConfirm: entityVersionConfirm !== undefined ? entityVersionConfirm : null,
      internalRideLiftFlow: internalRideLiftFlow !== undefined ? internalRideLiftFlow : null,
      hiveDockingOffsetAdjust: {
        enableIfLoad: enableIfLoad !== undefined ? enableIfLoad : null,
      },
    };
    const params = {
      id: record ? record.id : null,
      ...values,
      disabled: !disabled,
      configurations,
      operationMode: operationMode !== undefined ? operationMode : null,
    };

    onSubmit(params);
  };

  return (
    <Modal
      centered
      forceRender
      title={title}
      visible={visible}
      getContainer={false}
      onOk={handleSubmit}
      onCancel={onCancel}
      destroyOnClose={false}
      confirmLoading={loading}
      afterClose={handleAfterClose}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={24}>
          {!record && (
            <Col>
              <Item name="indoor">
                <Radio.Group buttonStyle="solid" onChange={handleTypeChange}>
                  <Radio.Button value={false}>阳光无人车（户外）</Radio.Button>
                  <Radio.Button value={true}>烛光机器人（楼宇）</Radio.Button>
                </Radio.Group>
              </Item>
            </Col>
          )}
          <Col span={24}>
            <Item label="机器人类型" name="robotType" rules={[{ required: true, message: '请选择机器人类型！' }]}>
              <Select allowClear showSearch optionFilterProp="children" placeholder="请选择">
                {robotTypeOptions.map((item: any, index: number) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item label="机器人名称" name="name" rules={[{ required: true, message: '请输入机器人名称!' }]}>
              <Input placeholder="请输入" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name="macAddress"
              label="机器人MAC地址"
              rules={[
                { required: true, message: '请输入机器人MAC地址!' },
                { pattern: MacAddressReg, message: '请输入正确格式的MAC地址!' },
              ]}
            >
              <Input placeholder="请输入" />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="运行模式" name="operationMode">
              <Select allowClear showSearch optionFilterProp="children" placeholder="请选择">
                {Object.entries(OperationModeStr).map((item: [string, string], index: number) => (
                  <Option
                    key={index}
                    value={item[0]}
                    disabled={item[0] === OperationMode.LOGISTICS || item[0] === OperationMode.VENDING}
                  >
                    {item[1]}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Item name="activated" label="激活" valuePropName="checked">
              <Switch checkedChildren="是" unCheckedChildren="否" />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="selectable" label="可选" valuePropName="checked">
              <Switch checkedChildren="是" unCheckedChildren="否" />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="disabled" label="调度开关" valuePropName="checked">
              <Switch checkedChildren="是" unCheckedChildren="否" />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RobotModal;

import { Col, Form, Input, Modal, Radio, Row } from 'antd';
import React, { useState } from 'react';

const { TextArea } = Input;

const cancelReason = {
  WRONG_ORDER: '客户下错单，或重复下单',
  OUT_SPACE: '订单超出我的揽件范围',
  CANNOT_TO_DESTINATION: '寄件目的地不能派送',
  DISPUTED_TIME: '客户对揽件时间/价格有异议',
  CANNOT_CONTACT: '电话联系不上客户',
  OTHER: '其他',
};

interface InternalProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (params: any) => void;
}

const CancelOrderModal = (props: InternalProps) => {
  const { visible, onCancel, onSubmit } = props;
  const [form] = Form.useForm();
  const { resetFields, validateFields } = form;
  const [showOther, setShowOther] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const selectedValue = e.target.value;
    setShowOther(selectedValue === 'OTHER');
  };

  const handleSubmit = async () => {
    const values = await validateFields();
    const { other, cancelReason: reason } = values;
    const params = {
      cancelReason: showOther ? other : cancelReason[reason],
    };

    onSubmit(params);
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Modal
      centered
      forceRender
      title="取消订单"
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      afterClose={resetFields}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="取消原因" name="cancelReason" rules={[{ required: true, message: '请选择取消原因!' }]}>
              <Radio.Group onChange={handleChange}>
                {Object.keys(cancelReason).map((item: string, index: number) => (
                  <Radio style={radioStyle} value={item} key={index}>
                    {cancelReason[item]}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            {showOther && (
              <Form.Item name="other" rules={[{ required: showOther, message: '请说点什么......' }]}>
                <TextArea placeholder="说点什么......" autoSize={{ minRows: 3, maxRows: 5 }} />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CancelOrderModal;

import { Button, Modal, Space, Table } from 'antd';
import { format } from 'date-fns';
import { connect } from 'dva';
import { Link } from 'dva/router';
import React, { FC, useEffect } from 'react';

import { Pagination as PaginationView } from '../../../components';
import { Dispatch } from '../../../models/dispatch';
import { Routes } from '../../../routes/routes';
import { ApiUrl } from '../../../services/ApiConfig';
import { TableColumnProps } from '../../../utils/constants';
import CreateHandOverListModal from './CreateHandOverListModal';

const { confirm } = Modal;

interface InternalProps {
  userList: any[];
  userParams: any;
  showCreateModal: boolean;
  userInfoPagination: any;
  userInfoLoading: boolean;
  companyOption: any[];
  fetchExportExcel: (params: object) => void;
  handleUserParams: (params: object) => any;
  fetchCompanyOption: (params?: object) => void;
  postInventoryList: (params?: object) => void;
  deleteInventoryList: (params?: object) => void;
  toShowModal: (key: string, show: boolean) => void;
}

const WaitHandOverList: FC<InternalProps> = (props) => {
  const {
    userList,
    companyOption,
    toShowModal,
    fetchCompanyOption,
    userInfoPagination,
    userInfoLoading,
    handleUserParams,
    fetchExportExcel,
    showCreateModal,
    deleteInventoryList,
    postInventoryList,
  } = props;

  const onPageChange = (page: number, pageSize: number | undefined) => {
    const tempObj = {
      page: page - 1,
      pageSize,
    };
    handleUserParams(tempObj);
  };

  const handleDeleteItem = (record: any) => {
    confirm({
      centered: true,
      title: `确认删除 ${record.inventoryNumber} 清单吗`,
      onOk: () => {
        deleteInventoryList({ record });
      },
    });
  };

  const handleSubmitData = (values: any) => {
    postInventoryList({ values });
  };

  const handleCreateList = () => {
    toShowModal('showCreateModal', true);
  };

  const handleExportExcel = (record: any) => {
    fetchExportExcel({ params: { inventoryId: record.id }, msg: '订单列表', url: ApiUrl.AGG_CHECK_ORDER_EXPORT });
  };

  useEffect(() => {
    fetchCompanyOption({ pageSize: 30 });
  }, [fetchCompanyOption]);

  const TableHeader = (
    <Button type="primary" onClick={handleCreateList}>
      创建清单
    </Button>
  );

  const columns: TableColumnProps<any>[] = [
    {
      title: '清单编号',
      dataIndex: 'inventoryNumber',
    },
    {
      title: '交接数量',
      dataIndex: 'handoverCount',
    },
    {
      title: '快递公司',
      dataIndex: 'company',
      render: (record: any) => record && record.name,
    },
    {
      title: '创建时间',
      dataIndex: 'createdTime',
      render: (record: any) => format(new Date(record), 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      title: '创建人',
      dataIndex: 'createdByUser',
      render: (record: any) => record && (record.name || record.mobile),
    },
    {
      title: '操作',
      key: 'action',
      width: 240,
      fixed: 'right',
      align: 'center',
      render: (text: any, record: any) => (
        <Space className="button-group">
          <Link to={`${Routes.HandOverCompany}/${record.id}`}>详情</Link>
          <Button type="link" onClick={() => handleExportExcel(record)}>
            导出清单
          </Button>
          <Button type="link" style={{ color: 'red' }} onClick={() => handleDeleteItem(record)}>
            删除清单
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={userList}
        title={() => TableHeader}
        loading={userInfoLoading}
        pagination={PaginationView(userInfoPagination, onPageChange)}
      />
      <CreateHandOverListModal
        visible={showCreateModal}
        record={{}}
        onSubmit={handleSubmitData}
        companyOption={companyOption}
        onCancel={() => toShowModal('showCreateModal', false)}
      />
    </>
  );
};

export const mapStateToProps = ({ common }: any) => {
  return {
    showCreateModal: common.showCreateModal,
    companyOption: common.companyOption,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    toShowModal: (key: string, show: boolean) => {
      dispatch({
        type: 'common/showOrHideModal',
        key,
        payload: show,
      });
    },
    fetchCompanyOption: (params?: object) => {
      dispatch({
        type: 'common/fetchCompanyOption',
        payload: params,
      });
    },
    fetchExportExcel: (params?: object) => {
      dispatch({
        type: 'common/fetchExportExcel',
        payload: params,
      });
    },
    postInventoryList: (params?: object) => {
      dispatch({
        type: 'handOverCompany/postInventoryList',
        payload: params,
      });
    },
    deleteInventoryList: (params?: object) => {
      dispatch({
        type: 'handOverCompany/deleteInventoryList',
        payload: params,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitHandOverList);

export enum CommandType {
  PARK = 'PARK',
  PEEK = 'PEEK',
  MOVE = 'MOVE',
  LOAD = 'LOAD',
  WAIT = 'WAIT',
  AVOID = 'AVOID',
  STOP = 'STOP',
  JACK = 'JACK',
  SHELL = 'SHELL',
  CLEAN = 'CLEAN',
  ABORT = 'ABORT',
  PAUSE = 'PAUSE',
  CLUTCH = 'CLUTCH',
  UNLOCK = 'UNLOCK',
  UNLOAD = 'UNLOAD',
  RESUME = 'RESUME',
  CHARGE = 'CHARGE',
  HOTFIX = 'HOTFIX',
  CONTROL = 'CONTROL',
  RESTART = 'RESTART',
  NOTIFY = 'NOTIFY',
  DEPLOY = 'DEPLOY',
  RECALL = 'RECALL',
  VOLUME = 'VOLUME',
  PICK_UP = 'PICK_UP',
  HOTSPOT = 'HOTSPOT',
  CONNECT = 'CONNECT',
  DROP_OFF = 'DROP_OFF',
  ELEVATOR = 'ELEVATOR',
  RELOCATE = 'RELOCATE',
  SCAN_CODE = 'SCAN_CODE',
  LIVE_FEED = 'LIVE_FEED',
  MAP_UPLOAD = 'MAP_UPLOAD',
  MAP_UPDATE = 'MAP_UPDATE',
  PLAY_SOUND = 'PLAY_SOUND',
  LOAD_STACK = 'LOAD_STACK',
  CHANGE_MAP = 'CHANGE_MAP',
  RECORD_BAG = 'RECORD_BAG',
  INTEGRATE = 'INTEGRATE',
  STERILIZE = 'STERILIZE',
  SWITCH_ROBOT = 'SWITCH_ROBOT',
  UNLOAD_STACK = 'UNLOAD_STACK',
  ELEVATOR_DOOR = 'ELEVATOR_DOOR',
  PICK_UP_STACK = 'PICK_UP_STACK',
  DROP_OFF_STACK = 'DROP_OFF_STACK',
  EMERGENCY_STOP = 'EMERGENCY_STOP',
  ELEVATOR_FLOOR = 'ELEVATOR_FLOOR',
  SOFTWARE_UPDATE = 'SOFTWARE_UPDATE',
  SIGNAL_ALL_STOP = 'SIGNAL_ALL_STOP',
  SOFTWARE_RESTART = 'SOFTWARE_RESTART',
  SWITCH_ENVIRONMENT = 'SWITCH_ENVIRONMENT',
  CONFIGURATION_UPDATE = 'CONFIGURATION_UPDATE',
}

export const CommandTypeStr = {
  [CommandType.JACK]: '顶升',
  [CommandType.PEEK]: '检测',
  [CommandType.MOVE]: '移动',
  [CommandType.WAIT]: '等待',
  [CommandType.AVOID]: '避让',
  [CommandType.VOLUME]: '音量',
  [CommandType.RECALL]: '召回',
  [CommandType.CLUTCH]: '离合',
  [CommandType.CHARGE]: '充电',
  [CommandType.NOTIFY]: '通知',
  [CommandType.CLEAN]: '清扫',
  [CommandType.PARK]: '停车',
  [CommandType.STOP]: '停止',
  [CommandType.PAUSE]: '暂停',
  [CommandType.ABORT]: '放弃',
  [CommandType.CONNECT]: '连接',
  [CommandType.RESUME]: '继续',
  [CommandType.DEPLOY]: '部署',
  [CommandType.RESTART]: '重启',
  [CommandType.HOTSPOT]: '热点',
  [CommandType.HOTFIX]: '热修复',
  [CommandType.UNLOCK]: '开锁',
  [CommandType.INTEGRATE]: '整合',
  [CommandType.RECORD_BAG]: '录包',
  [CommandType.LOAD]: '装载快递柜',
  [CommandType.SHELL]: '命令行指令',
  [CommandType.CONTROL]: '摇杆控制',
  [CommandType.SCAN_CODE]: '扫码',
  [CommandType.RELOCATE]: '重定位',
  [CommandType.LIVE_FEED]: '图传',
  [CommandType.ELEVATOR]: ' 电梯',
  [CommandType.CHANGE_MAP]: '更改地图',
  [CommandType.PICK_UP]: '装载快递柜',
  [CommandType.UNLOAD]: '卸载快递柜',
  [CommandType.DROP_OFF]: '卸载快递柜',
  [CommandType.MAP_UPDATE]: '地图更新',
  [CommandType.MAP_UPLOAD]: '地图上传',
  [CommandType.EMERGENCY_STOP]: '急停',
  [CommandType.PICK_UP_STACK]: '装载快递栈',
  [CommandType.DROP_OFF_STACK]: '卸载快递栈',
  [CommandType.LOAD_STACK]: '装载快递栈',
  [CommandType.UNLOAD_STACK]: '卸载快递栈',
  [CommandType.SWITCH_ROBOT]: '更改机器人',
  [CommandType.SOFTWARE_RESTART]: '重启软件',
  [CommandType.SOFTWARE_UPDATE]: '更新软件',
  [CommandType.CONFIGURATION_UPDATE]: '刷新设置',
  [CommandType.PLAY_SOUND]: '播放音频',
  [CommandType.ELEVATOR_DOOR]: '梯控选门',
  [CommandType.SIGNAL_ALL_STOP]: '控灯',
  [CommandType.STERILIZE]: '消毒',
  [CommandType.ELEVATOR_FLOOR]: '梯控选楼层',
  [CommandType.SWITCH_ENVIRONMENT]: '切换环境',
};

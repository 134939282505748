import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { endOfDay, startOfDay } from 'date-fns';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { ChangeEvent, FC, useEffect } from 'react';

import { Dispatch } from '../../../models/dispatch';
import { BooleanOptions, CollectionMethodStr, OrderStateOption } from '../../../utils/constants';
import { filterOption, splitQuery, transformDataSource } from '../../../utils/utils';
import { OrderParams } from '../models/typed.d';

const { Item } = Form;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface DvaProps {
  buildingOptions: any[];
  companyOptions: DataSourceItemObject[];
  fetchCompanyOption: (params?: object) => void;
  fetchBuildingOption: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  query: OrderParams;
  onSearchChange: (params: OrderParams) => void;
}

export const PieceOrderHeader: FC<InternalProps> = (props) => {
  const { query, companyOptions, buildingOptions, fetchCompanyOption, fetchBuildingOption, onSearchChange } = props;

  useEffect(() => {
    fetchCompanyOption();
    fetchBuildingOption();
  }, [fetchCompanyOption, fetchBuildingOption]);

  const onChangePickDate = (date: any, dateString: [string, string], key?: string) => {
    const start = dateString[0] !== '' ? startOfDay(new Date(dateString[0])).getTime() : '';
    const end = dateString[1] !== '' ? endOfDay(new Date(dateString[1])).getTime() : '';

    if (key && key === 'start') {
      onSearchChange({ start, end });
    } else {
      onSearchChange({ collectionTimeStart: start, collectionTimeEnd: end });
    }
  };

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递单号">
            <Search
              allowClear
              placeholder="请输入快递单号"
              defaultValue={get(query, 'orderNumber', '')}
              onSearch={(value: string) => onSearchChange({ orderNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onSearchChange({ orderNumber: e.target.value.length > 5 ? e.target.value : '' })
              }
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="包裹号">
            <Search
              allowClear
              placeholder="请输入包裹号"
              defaultValue={get(query, 'shelfNumber', '')}
              onSearch={(value: string) => onSearchChange({ shelfNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ shelfNumber: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="收件人姓名电话">
            <Search
              allowClear
              placeholder="请输入收件人姓名或电话"
              defaultValue={get(query, 'receiverKeyword', '')}
              onSearch={(value: string) => onSearchChange({ receiverKeyword: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ receiverKeyword: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递公司">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择快递公司"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'companyId')}
              onChange={(value: string[]) => onSearchChange({ companyId: value.join() })}
            >
              {companyOptions.map((item: DataSourceItemObject, index: number) => (
                <Option value={item.value} key={index}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="订单状态">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择订单状态"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'orderState')}
              onChange={(value: string[]) => onSearchChange({ orderState: value.join() })}
            >
              {OrderStateOption.filter((item: any) => !['CONFIRMED', 'PAID'].includes(item.value)).map(
                (item: any, index: number) => (
                  <Option value={item.value} key={index}>
                    {item.label}
                  </Option>
                )
              )}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="楼栋">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择楼栋"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'buildingId')}
              onChange={(value: string[]) => onSearchChange({ buildingId: value.join() })}
            >
              {buildingOptions.map((item: any, index: number) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="是否滞留">
            <Select
              allowClear
              placeholder="请选择"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={get(query, 'stranded')}
              onChange={(value: string) => onSearchChange({ stranded: value })}
            >
              {BooleanOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="入库时间">
            <RangePicker
              style={{ width: '100%' }}
              onChange={(dates: any, dateStrings: [string, string]) => onChangePickDate(dates, dateStrings, 'start')}
              placeholder={['开始日期', '结束日期']}
            />
          </Item>
        </Col> */}
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="出库时间">
            <RangePicker
              style={{ width: '100%' }}
              onChange={(dates: any, dateStrings: [string, string]) => onChangePickDate(dates, dateStrings)}
              placeholder={['开始日期', '结束日期']}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="妥投方式" style={{ marginBottom: 0 }}>
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择妥投方式"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'collectionMethod')}
              onChange={(value: string[]) => onSearchChange({ collectionMethod: value.join() })}
            >
              {Object.entries(CollectionMethodStr).map((item: [string, string], index: number) => (
                <Option value={item[0]} key={index}>
                  {item[1]}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ common }: any) => ({
  buildingOptions: common.buildingOption,
  companyOptions: transformDataSource(common.companyOption),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompanyOption: (params?: object) => {
    dispatch({
      type: 'common/fetchCompanyOption',
      payload: params,
    });
  },
  fetchBuildingOption: (params?: object) => {
    dispatch({
      type: 'common/fetchBuildingOption',
      payload: params,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PieceOrderHeader);

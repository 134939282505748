import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../../components';
import { registerModel } from '../../../utils';

const HubSettings = Loadable.Map({
  loader: {
    Settings: () => import('./Settings'),
    model: () => import('./models/Settings'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const Settings = loaded.Settings.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <Settings {...props} />
      </Loading>
    );
  },
});

const SendRulesSetting = Loadable.Map({
  loader: {
    SendRulesSetting: () => import('./SendRulesSetting'),
    model: () => import('./models/SendRulesSetting'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SendRulesSetting = loaded.SendRulesSetting.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SendRulesSetting {...props} />
      </Loading>
    );
  },
});

const RobotSetting = Loadable.Map({
  loader: {
    RobotSetting: () => import('./RobotSetting'),
    model: () => import('./models/RobotSetting'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RobotSetting = loaded.RobotSetting.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <RobotSetting {...props} />
      </Loading>
    );
  },
});

const RobotSettingDetail = Loadable.Map({
  loader: {
    RobotSettingDetail: () => import('./RobotSettingDetail'),
    model: () => import('./models/RobotSettingDetail'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RobotSettingDetail = loaded.RobotSettingDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <RobotSettingDetail {...props} />
      </Loading>
    );
  },
});

const RulesSetting = Loadable.Map({
  loader: {
    RulesSetting: () => import('./RulesSetting'),
    model: () => import('./models/RulesSetting'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RulesSetting = loaded.RulesSetting.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <RulesSetting {...props} />
      </Loading>
    );
  },
});

const SchedulingDisabledSetting = Loadable.Map({
  loader: {
    SchedulingDisabledSetting: () => import('./SchedulingDisabledSetting'),
    model: () => import('./models/SchedulingDisabledSetting'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const SchedulingDisabledSetting = loaded.SchedulingDisabledSetting.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <SchedulingDisabledSetting {...props} />
      </Loading>
    );
  },
});

const BasicSetting = Loadable.Map({
  loader: {
    BasicSetting: () => import('./BasicSetting'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const BasicSetting = loaded.BasicSetting.default;

    return (
      <Loading>
        <BasicSetting {...props} />
      </Loading>
    );
  },
});

const TakeOutSetting = Loadable.Map({
  loader: {
    TakeOutSetting: () => import('./TakeOutSetting'),
    model: () => import('./models/TakeOutSetting'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const TakeOutSetting = loaded.TakeOutSetting.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <TakeOutSetting {...props} />
      </Loading>
    );
  },
});

const ShareSetting = Loadable.Map({
  loader: {
    ShareSetting: () => import('./ShareSetting'),
    model: () => import('./models/Settings'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const ShareSetting = loaded.ShareSetting.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <ShareSetting {...props} />
      </Loading>
    );
  },
});

const ServiceTypeSetting = Loadable.Map({
  loader: {
    ServiceTypeSetting: () => import('./ServiceTypeSetting'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const ServiceTypeSetting = loaded.ServiceTypeSetting.default;

    return (
      <Loading>
        <ServiceTypeSetting {...props} />
      </Loading>
    );
  },
});

const GarbageClearSetting = Loadable.Map({
  loader: {
    GarbageClearSetting: () => import('./GarbageClearSetting'),
    model: () => import('./models/GarbageClearSetting'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const GarbageClearSetting = loaded.GarbageClearSetting.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <GarbageClearSetting {...props} />
      </Loading>
    );
  },
});

export {
  HubSettings,
  BasicSetting,
  ShareSetting,
  RulesSetting,
  RobotSetting,
  TakeOutSetting,
  SendRulesSetting,
  ServiceTypeSetting,
  RobotSettingDetail,
  SchedulingDisabledSetting,
  GarbageClearSetting,
};

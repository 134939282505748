import { Col, Form, Input, Row, Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { connect } from 'dva';
import { get } from 'lodash';
import React, { ChangeEvent, FC, useEffect } from 'react';

import { Dispatch } from '../../../models/dispatch';
import { filterOption, splitQuery, transformDataSource } from '../../../utils';
import { BooleanOptions } from '../../../utils/constants';
import { OrderParams } from '../models/typed';

const { Item } = Form;
const { Search } = Input;
const { Option } = Select;

interface DvaProps {
  buildingOptions: any[];
  lastModifiedBysList: any[];
  companyOptions: DataSourceItemObject[];
  fetchCompanyOption: (params?: object) => void;
  fetchBuildingOption: (params?: object) => void;
  fetchLastModifiedBys: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  query: OrderParams;
  onSearchChange: (params: OrderParams) => void;
}

export const HubOrderHeader: FC<InternalProps> = (props) => {
  const {
    query,
    companyOptions,
    fetchCompanyOption,
    fetchBuildingOption,
    fetchLastModifiedBys,
    lastModifiedBysList,
    onSearchChange,
  } = props;

  useEffect(() => {
    fetchCompanyOption();
    fetchBuildingOption();
    fetchLastModifiedBys();
  }, [fetchCompanyOption, fetchBuildingOption, fetchLastModifiedBys]);

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递单号">
            <Search
              allowClear
              placeholder="请输入快递单号"
              defaultValue={get(query, 'orderNumber', '')}
              onSearch={(value: string) => onSearchChange({ orderNumber: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ orderNumber: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="快递公司">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择快递公司"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={splitQuery(query, 'companyId')}
              onChange={(value: string[]) => onSearchChange({ companyId: value.join() })}
            >
              {companyOptions.map((item: DataSourceItemObject, index: number) => (
                <Option value={item.value} key={index}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="收件人">
            <Search
              allowClear
              placeholder="请输入收件人姓名或电话"
              defaultValue={get(query, 'keyword', '')}
              onSearch={(value: string) => onSearchChange({ keyword: value })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange({ keyword: e.target.value })}
            />
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="取件方式">
            <Select
              allowClear
              placeholder="请选择"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={get(query, 'collectInPerson')}
              onChange={(value: string) => onSearchChange({ collectInPerson: value })}
            >
              {BooleanOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.value === 'true' ? '自取' : '代取'}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="出库管理员">
            <Select
              allowClear
              placeholder="请输入管理员姓名"
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={get(query, 'lastModifiedBy')}
              onChange={(value: string) => onSearchChange({ lastModifiedBy: value })}
            >
              {lastModifiedBysList &&
                lastModifiedBysList.map((item: any, index: number) => (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ common, pieceOrder }: any) => ({
  buildingOptions: common.buildingOption,
  companyOptions: transformDataSource(common.companyOption),
  lastModifiedBysList: pieceOrder.lastModifiedBysList,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompanyOption: (params?: object) => {
    dispatch({
      type: 'common/fetchCompanyOption',
      payload: params,
    });
  },
  fetchBuildingOption: (params?: object) => {
    dispatch({
      type: 'common/fetchBuildingOption',
      payload: params,
    });
  },
  fetchLastModifiedBys: (params?: object) => {
    dispatch({
      type: 'pieceOrder/fetchLastModifiedBys',
      payload: params,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HubOrderHeader);

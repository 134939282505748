import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';
import { OrderHistory } from './components';

const CreateOrder = Loadable.Map({
  loader: {
    CreateOrder: () => import('./CreateOrder'),
    model: () => import('./models/CreateOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CreateOrder = loaded.CreateOrder.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <CreateOrder {...props} />;
  },
});

const CheckInventory = Loadable.Map({
  loader: {
    CheckInventory: () => import('./CheckInventory'),
    model: () => import('./models/CheckInventory'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CheckInventory = loaded.CheckInventory.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <CheckInventory {...props} />;
  },
});

const CheckInventoryDetail = Loadable.Map({
  loader: {
    CheckInventoryDetail: () => import('./CheckInventoryDetail'),
    model: () => import('./models/CheckInventoryDetail'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CheckInventoryDetail = loaded.CheckInventoryDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return <CheckInventoryDetail {...props} />;
  },
});

const PieceOrder = Loadable.Map({
  loader: {
    PieceOrder: () => import('./containers'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceOrder = loaded.PieceOrder.PieceOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceOrder {...props} />
      </Loading>
    );
  },
});

const PieceRobotOrder = Loadable.Map({
  loader: {
    PieceOrder: () => import('./containers'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceRobotOrder = loaded.PieceOrder.PieceRobotOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceRobotOrder {...props} />
      </Loading>
    );
  },
});

const PieceManualOrder = Loadable.Map({
  loader: {
    PieceManualOrder: () => import('./PieceManualOrder'),
    model: () => import('./models/PieceManualOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceManualOrder = loaded.PieceManualOrder.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceManualOrder {...props} />
      </Loading>
    );
  },
});

const PieceOrderSync = Loadable.Map({
  loader: {
    PieceOrderSync: () => import('./PieceOrderSync'),
    model: () => import('./models/OrderHistory'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceOrderSync = loaded.PieceOrderSync.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceOrderSync {...props} />
      </Loading>
    );
  },
});

const PieceManualOrderDetail = Loadable.Map({
  loader: {
    PieceManualOrderDetail: () => import('./PieceManualOrderDetail'),
    model: () => import('./models/PieceManualOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceManualOrderDetail = loaded.PieceManualOrderDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceManualOrderDetail {...props} />
      </Loading>
    );
  },
});

const PieceHubOrder = Loadable.Map({
  loader: {
    PieceOrder: () => import('./containers'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceHubOrder = loaded.PieceOrder.PieceHubOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceHubOrder {...props} />
      </Loading>
    );
  },
});

const PieceExceptionOrder = Loadable.Map({
  loader: {
    PieceOrder: () => import('./containers'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceExceptionOrder = loaded.PieceOrder.PieceExceptionOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceExceptionOrder {...props} />
      </Loading>
    );
  },
});

const PieceToCompanyOrder = Loadable.Map({
  loader: {
    PieceOrder: () => import('./containers'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceToCompanyOrder = loaded.PieceOrder.PieceToCompanyOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceToCompanyOrder {...props} />
      </Loading>
    );
  },
});

const PieceWarehousedOrder = Loadable.Map({
  loader: {
    PieceOrder: () => import('./containers'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceWarehousedOrder = loaded.PieceOrder.PieceWarehousedOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PieceWarehousedOrder {...props} />
      </Loading>
    );
  },
});

const PieceRobotDeliveredOrder = Loadable.Map({
  loader: {
    PieceOrder: () => import('./containers'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeliveredOrder = loaded.PieceOrder.PieceRobotDeliveredOrder;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeliveredOrder {...props} />
      </Loading>
    );
  },
});

const OrderDetail = Loadable.Map({
  loader: {
    PieceOrderDetail: () => import('./OrderDetail'),
    historyModel: () => import('./models/OrderHistory'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PieceOrderDetail = loaded.PieceOrderDetail.default;
    const historyModel = loaded.historyModel.default;
    const model = loaded.model.default;

    registerModel(props.app, model);
    registerModel(props.app, historyModel);

    return (
      <Loading>
        <PieceOrderDetail {...props} />
      </Loading>
    );
  },
});

const RobotOrderDetail = Loadable.Map({
  loader: {
    RobotOrderDetail: () => import('./RobotOrderDetail'),
    model: () => import('./models/PieceOrder'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RobotOrderDetail = loaded.RobotOrderDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <RobotOrderDetail {...props} />
      </Loading>
    );
  },
});

const PendingUserList = Loadable.Map({
  loader: {
    PendingUserList: () => import('./PendingUserList'),
    model: () => import('./models/PendingUserList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PendingUserList = loaded.PendingUserList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PendingUserList {...props} />
      </Loading>
    );
  },
});

const PendingUserEdit = Loadable.Map({
  loader: {
    PendingUserEdit: () => import('./containers/connectedPendingUserEdit'),
    model: () => import('./models/PendingUserList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const PendingUserEdit = loaded.PendingUserEdit.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <PendingUserEdit {...props} />
      </Loading>
    );
  },
});

const DeliveryCompanyPrice = Loadable.Map({
  loader: {
    DeliveryCompanyPrice: () => import('./DeliveryCompanyPrice'),
    model: () => import('./models/DeliveryCompanyPrice'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeliveryCompanyPrice = loaded.DeliveryCompanyPrice.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeliveryCompanyPrice {...props} />
      </Loading>
    );
  },
});

export {
  PieceOrder,
  PieceRobotOrder,
  PieceManualOrder,
  PieceHubOrder,
  PieceExceptionOrder,
  OrderDetail,
  RobotOrderDetail,
  PieceOrderSync,
  CreateOrder,
  CheckInventory,
  CheckInventoryDetail,
  PendingUserList,
  PendingUserEdit,
  PieceManualOrderDetail,
  PieceWarehousedOrder,
  PieceRobotDeliveredOrder,
  DeliveryCompanyPrice,
  PieceToCompanyOrder,
  OrderHistory,
};

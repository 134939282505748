import { Layout } from 'antd';
import { connect } from 'dva';
import { Link } from 'dva/router';
import React, { PureComponent } from 'react';

import { Dispatch } from '../../../../models/dispatch';
import styles from './index.module.scss';
import MonitorVideo from './MonitorVideo';
import RadarMap from './RadarMap';
import RadarStatus from './RadarStatus';
import StatusBar from './StatusBar';

const { Content } = Layout;

interface InternalProps {
  robotList: any;
  robotData: any;
  unsubscribe: () => void;
  fetchRobotData: (id: string) => void;
  fetchRobotList: (params?: object) => void;
}

interface InternalState {
  selected: any;
}

class MonitorContent extends PureComponent<InternalProps, InternalState> {
  public readonly state: InternalState = {
    selected: null,
  };

  private static getDerivedStateFromProps(props: InternalProps, state: InternalState) {
    const { robotData } = props;

    if (robotData !== null) {
      return {
        selected: robotData,
      };
    }
    return null;
  }

  public componentDidMount() {
    const { fetchRobotList } = this.props;
    fetchRobotList();
  }

  public handleSelect = (item: any) => {
    const { fetchRobotData, unsubscribe } = this.props;

    if (this.state.selected.id === item.id) {
      return;
    }

    unsubscribe();
    this.setState({ selected: item });
    fetchRobotData(item.id);
  };

  public renderRobotList() {
    const { selected } = this.state;
    const { robotList } = this.props;

    return (
      <div className={styles.robotList}>
        <ul>
          {robotList.map((item: any) => {
            const classNames = [styles.item, selected && selected.id === item.id ? styles.active : ''].join(' ');

            return (
              <li key={item.id} onClick={() => this.handleSelect(item)}>
                <Link to={`/app/robot/monitor-center/${item.id}`} className={classNames}>
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  public render() {
    return (
      <Content className={styles.monitorContent}>
        <div>
          <RadarStatus />
          {this.renderRobotList()}
        </div>
        <div className={styles.baiduMap}>
          <StatusBar />
        </div>
        <div>
          <RadarMap />
          <MonitorVideo />
        </div>
      </Content>
    );
  }
}

const mapStateToProps = ({ monitorCenter }: any) => {
  return {
    robotList: monitorCenter.robotList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchRobotList: (params?: object) => {
      dispatch({
        type: 'monitorCenter/fetchRobotList',
        payload: params,
      });
    },
    unsubscribe: () => {
      dispatch({
        type: 'monitorCenter/unsubscribe',
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonitorContent);

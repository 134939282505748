import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const FaceEntry = Loadable.Map({
  loader: {
    FaceEntry: () => import('./FaceEntry'),
    model: () => import('./models/FaceEntry'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const FaceEntry = loaded.FaceEntry.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <FaceEntry {...props} />
      </Loading>
    );
  },
});

export { FaceEntry };

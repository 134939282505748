import axios from 'axios';

import { localStorageKey } from '../utils/constants';
import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class UaaService {
  public static getCaptcha(mobile: string): Promise<any> {
    return POST(ApiUrl.UAA_CAPTCHA, { phoneNumber: mobile });
  }

  public static verifyCaptcha(params: object): Promise<any> {
    return POST(ApiUrl.UAA_CAPTCHA_VERIFY, params);
  }

  public static auth(params: object): Promise<any> {
    return POST(ApiUrl.UAA_AUTH, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  public static authByPwd(params: object): Promise<any> {
    return POST(ApiUrl.UAA_AUTH, params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  public static fetchOssInfo(params: object): Promise<any> {
    return POST(ApiUrl.UAA_BUCKET_TOKEN, params);
  }

  public static fetchOssInfoStsToken(params: object): Promise<any> {
    return GET(ApiUrl.UAA_STS_TOKEN, params);
  }

  public static ossUploadImg(host: string, params: object, onProgress?: any): Promise<any> {
    const appData = JSON.parse(localStorage.getItem(localStorageKey.APP_KEY_STORE) || '{}');

    return axios.post(host, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${appData.token_type} ${appData.access_token}`,
      },
      onUploadProgress: onProgress,
    });
  }

  public static fetchRoles(params?: object): Promise<any> {
    return GET(ApiUrl.UAA_ROLE, params);
  }

  public static postPassword(id: string, params?: object): Promise<any> {
    return POST(ApiUrl.UAA_PASSWORD, { ...params, entityId: id });
  }

  public static resetPassword(id: string, params?: object): Promise<any> {
    return POST(ApiUrl.UAA_PASSWORD_RESET, { ...params, entityId: id });
  }

  public static forgetPassword(params?: object): Promise<any> {
    return POST(ApiUrl.UAA_PASSWORD_FORGET, params);
  }

  public static checkExisting(username: string): Promise<any> {
    return POST(`${ApiUrl.UAA_USERNAME_EXIST}?username=${username}`);
  }

  public static fetchRoleTemplate(params?: any): Promise<any> {
    return GET(ApiUrl.UAA_ROLE_TEMPLATE, params);
  }

  public static postRole(params?: any): Promise<any> {
    return POST(ApiUrl.UAA_ROLE, params);
  }

  public static postRoleTemplate(params?: any): Promise<any> {
    return POST(ApiUrl.UAA_ROLE_TEMPLATE, params);
  }

  public static postRoleTmp(params?: any): Promise<any> {
    return POST(ApiUrl.UAA_ROLE_TMP, params);
  }

  public static patchRole(id: string, params?: any): Promise<any> {
    return PATCH(ApiUrl.UAA_ROLE + '/' + id, params);
  }

  public static patchRoleTemplate(id: string, params?: any): Promise<any> {
    return PATCH(ApiUrl.UAA_ROLE_TEMPLATE + '/' + id, params);
  }

  public static deleteRole(id: string): Promise<any> {
    return DELETE(ApiUrl.UAA_ROLE + '/' + id);
  }

  public static deleteRoleTemplate(id: string): Promise<any> {
    return DELETE(ApiUrl.UAA_ROLE_TEMPLATE + '/' + id);
  }

  public static getRoleIdentifier(params?: any): Promise<any> {
    return GET(ApiUrl.UAA_ROLE_IDENTIFIER, params);
  }

  public static fetchAuthMetadata(params?: object): Promise<any> {
    return GET(ApiUrl.UAA_AUTH_METADATA, params);
  }

  public static fetchAuthPath(params?: object): Promise<any> {
    return GET(ApiUrl.UAA_AUTH_PATH, params);
  }

  public static fetchPermission(params?: object): Promise<any> {
    return GET(ApiUrl.UAA_PERMISSION, params);
  }
}

// API url config

const API_VERSION = 'v1';

export const ApiUrl = {
  // 聚合服务接口
  AGG_ADDRESS: `/aggregation/${API_VERSION}/address`,
  AGG_DOCK: `/aggregation/${API_VERSION}/dock`,
  AGG_DOCK_EXCEL: `/aggregation/${API_VERSION}/dock/export`,
  AGG_HIVE: `/aggregation/${API_VERSION}/hive`,
  AGG_HUB: `/aggregation/${API_VERSION}/hub`,
  AGG_HISTORY: `/aggregation/${API_VERSION}/history`,
  AGG_LOCKER: `/aggregation/${API_VERSION}/locker`,
  AGG_ROBOT: `/aggregation/${API_VERSION}/robot`,
  AGG_RETAIL: `/aggregation/${API_VERSION}/retail`,
  AGG_RETAIL_ITEM: `/aggregation/${API_VERSION}/item`,
  AGG_ORDER: `/aggregation/${API_VERSION}/order`,
  AGG_ORDER_EXCEL: `/aggregation/${API_VERSION}/order/export`,
  AGG_TRIP: `/aggregation/${API_VERSION}/trip`,
  AGG_USER: `/aggregation/${API_VERSION}/user`,
  AGG_COMMAND: `/aggregation/${API_VERSION}/command`,
  AGG_TEMPLATE: `/aggregation/${API_VERSION}/template`,
  AGG_NOTIFICATION: `/aggregation/${API_VERSION}/notification`,
  AGG_ADVERTISEMENT: `/aggregation/${API_VERSION}/advertisement`,
  AGG_NOTIFICATION_EXPORT: `/aggregation/${API_VERSION}/notification/export`,
  AGG_SCHEDULING_TRANSIT: `/aggregation/${API_VERSION}/transit`,
  AGG_STATUS_LIST: `/status/${API_VERSION}/status/robot`,
  AGG_SCHEDULING_TRANSIT_HISTORY: `/aggregation/${API_VERSION}/transit-history`,
  AGG_RETAIL_INVENTORY_HISTORY: `/aggregation/${API_VERSION}/inventory-history`,
  AGG_CHECK_ORDER: `/aggregation/${API_VERSION}/check`,
  AGG_PARTICIPANT: `/aggregation/${API_VERSION}/participant`,
  AGG_CHECK_ORDER_EXPORT: `/aggregation/${API_VERSION}/order/export`,
  AGG_ROBOT_ELEVATOR: `/aggregation/${API_VERSION}/elevator`,
  AGG_AREA: `/aggregation/${API_VERSION}/area`,
  AGG_FEEDBACK: `/aggregation/${API_VERSION}/feedback`,
  AGG_WAYPOINT: `aggregation/${API_VERSION}/waypoint`,
  AGG_CACHE_WAYPOINT: `aggregation/${API_VERSION}/cache/waypoint`,
  AGG_BUILDING: `aggregation/${API_VERSION}/building`,
  AGG_WAYPOINT_EXPORT: `aggregation/${API_VERSION}/waypoint/export`,
  AGG_COMPANY_POSTAGE: `aggregation/${API_VERSION}/company-postage`,
  AGG_ORDER_INVENTORY: `aggregation/${API_VERSION}/order-inventory`,
  AGG_DELIVERY_COLLECTION: `aggregation/${API_VERSION}/collection-point`,
  AGG_STATUS_TRIGGER: `aggregation/status/${API_VERSION}/trigger`,
  AGG_STATUS_ALERT: `aggregation/status/${API_VERSION}/alert`,
  AGG_STATUS_ALERT_EXPORT: `aggregation/status/${API_VERSION}/alert/export`,
  AGG_ROBOT_COMMENT: `aggregation/robot/${API_VERSION}/comment`,
  AGG_MEDIA_RECORD: `aggregation/${API_VERSION}/recording`,
  AGG_CHECK_ORDER_RECORD: `/aggregation/${API_VERSION}/record`,
  AGG_TASK_HISTORY: `/aggregation/${API_VERSION}/task-history`,
  AGG_TASK: `/aggregation/${API_VERSION}/task`,
  AGG_TASK_HISTORY_EXPORT: `/aggregation/${API_VERSION}/task-history/export`,

  // 快递中心服务接口
  DEL_COMMENT: `/delivery/${API_VERSION}/comment`,
  DEL_COMPANY: `/delivery/${API_VERSION}/company`,
  DEL_HELP: `/delivery/${API_VERSION}/help`,
  DEL_HIVE: `/delivery/${API_VERSION}/hive`,
  DEL_HISTORY: `/delivery/${API_VERSION}/history`,
  DEL_HUB: `/delivery/${API_VERSION}/hub`,
  DEL_LOCKER: `/delivery/${API_VERSION}/locker`,
  DEL_OPERATION: `/delivery/${API_VERSION}/operation`,
  DEL_ORDER: `/delivery/${API_VERSION}/order`,
  DEL_ORDER_SUMMARY: `/delivery/${API_VERSION}/order/summary`,
  DEL_ORDER_SUMMARY_TOTAL: `/delivery/${API_VERSION}/order/summary/total`,
  DEL_CHECK_ORDER_RECORD: `/delivery/${API_VERSION}/record`,
  DEL_AREA: `/delivery/${API_VERSION}/area`,
  DEL_ORDER_ASSIGNMENT: `/delivery/${API_VERSION}/order/assignment`,
  DEL_POSTAGE: `/delivery/${API_VERSION}/postage`,
  DEL_POSTAGE_SUMMARY: `/delivery/${API_VERSION}/postage/summary`,
  DEL_INVENTORY: `/delivery/${API_VERSION}/inventory`,
  DEL_COMPANY_POSTAGE: `/delivery/${API_VERSION}/company-postage`,
  DEL_PRINT: `/delivery/${API_VERSION}/print`,
  DEL_COMPANY_ESTIMATE: `/delivery/${API_VERSION}/company-postage/estimate`,
  DEL_COLLECTION: `/delivery/${API_VERSION}/collection-point`,
  DEL_ORDER_DELIVERY_SUMMARY: `/delivery/${API_VERSION}/order/delivery/summary`,
  DEL_RULES: `/delivery/${API_VERSION}/delivery-rule`,
  DEL_POSTAGE_UPLOAD: `/delivery/${API_VERSION}/postage/excelData`,
  DEL_SHELF: `/delivery/${API_VERSION}/shelf`,
  DEL_SHELF_AVAILABLE: `/delivery/${API_VERSION}/shelf/availableShelfName`,
  DEL_ORDER_SYNC: `/delivery/diyibox/sync/delivery`,

  // 新零售中心服务接口
  RETAIL_ORDER: `/retail/${API_VERSION}/retail`,
  RETAIL_ITEM: `/retail/${API_VERSION}/item`,
  RETAIL_MERCHANT: `/retail/${API_VERSION}/merchant`,
  RETAIL_INVENTORY: `/retail/${API_VERSION}/inventory`,
  RETAIL_CATEGORY: `/retail/${API_VERSION}/category`,
  RETAIL_ADVERTISE: `/retail/${API_VERSION}/advertisement`,
  RETAIL_INVENTORY_HISTORY: `retail/${API_VERSION}/inventory-history`,
  RETAIL_INVENTORY_SUMMARY: `retail/${API_VERSION}/inventory-history/summary/quantity`,

  // 地图服务接口
  MAP_BUILDING: `/map/${API_VERSION}/building`,
  MAP_FLOOR: `/map/${API_VERSION}/floor`,
  MAP_GRAPH: `/map/${API_VERSION}/graph`,
  MAP_LINK: `/map/${API_VERSION}/link`,
  MAP_BLOCK: `/map/${API_VERSION}/map-block`,
  MAP_WAYPOINT: `/map/${API_VERSION}/waypoint`,
  MAP_PATH: `/map/${API_VERSION}/path`,
  MAP_HUB: `/map/${API_VERSION}/hub`,
  MAP_THEME: `/map/${API_VERSION}/theme`,

  // 用户服务接口
  USER: `/user/${API_VERSION}/user`,
  USER_INFO: `/user/${API_VERSION}/user-info`,
  USER_ADDRESS: `/user/${API_VERSION}/address`,
  USER_COMMENT: `/user/${API_VERSION}/comment`,
  USER_CUSTOMER: `/user/${API_VERSION}/customer`,
  USER_FEEDBACK: `/user/${API_VERSION}/feedback`,
  USER_HISTORY: `/user/${API_VERSION}/history`,
  USER_TRANSACTION: `/user/${API_VERSION}/transaction`,
  USER_DEPARTMENT: `/user/${API_VERSION}/department`,

  // 微信服务接口
  WECHAT_QRCODE: `/wechat/${API_VERSION}/wechat/qrcode/scene`,
  WECHAT_SCANCODE: `/wechat/${API_VERSION}/wechat/scancode-url`,
  WECHAT_SHORT: `/wechat/${API_VERSION}/wechat/short-url`,
  WECHAT_BIND: `/wechat/${API_VERSION}/wechat/user/bind`,
  WECHAT_OPEN_ID: `/wechat/${API_VERSION}/wechat/user/openId`,
  WECHAT_HUB_PICKUP: `/wechat/${API_VERSION}/qr-code/hub-pickup`,

  // 模拟器服务接口
  SIM_HIVE: `/simulation/${API_VERSION}/hive`,
  SIM_ORDER: `/simulation/${API_VERSION}/order`,
  SIM_ROBOT: `/simulation/${API_VERSION}/robot`,
  SIM_USER: `/simulation/${API_VERSION}/user`,

  // 工具类接口
  UTILITY_IMAGE: `/utility/${API_VERSION}/image`,
  UTILITY_ROBOT: `/utility/${API_VERSION}/robot`,
  UTILITY_HIVE: `/utility/${API_VERSION}/hive`,

  // 机器人服务接口
  ROBOT: `/robot/${API_VERSION}/robot`,
  HIVE: `/robot/${API_VERSION}/hive`,
  DOCK: `/robot/${API_VERSION}/dock`,
  BAY: `/robot/${API_VERSION}/bay`,
  LOCKER: `/robot/${API_VERSION}/locker`,
  SOFTWARE: `/robot/${API_VERSION}/software`,
  ROBOT_COMMENT: `/robot/${API_VERSION}/comment`,
  ROBOT_ELEVATOR: `/robot/${API_VERSION}/elevator`,
  SOFTWARE_HISTORY: `/robot/${API_VERSION}/history`,
  ROBOT_MODEL: `/robot/${API_VERSION}/model`,
  SOFTWARE_COMMAND: `/robot/${API_VERSION}/software/command`,
  ROBOT_CONFIGURATION: `/robot/${API_VERSION}/configuration`,
  ROBOT_ACTIVATED: `/robot/${API_VERSION}/configuration/activated`,
  ROBOT_ACTIVATE: `/robot/${API_VERSION}/configuration/default/activate`,
  ROBOT_MANAGEMENT: `/robot/${API_VERSION}/management`,

  // UAA服务接口
  UAA_AUTH: `/uaa/oauth/token`,
  UAA_ROLE: `/uaa/${API_VERSION}/role`,
  UAA_AUTH_PATH: `/uaa/${API_VERSION}/role/authority/path`,
  UAA_ROLE_IDENTIFIER: `/uaa/${API_VERSION}/role/identifier`,
  UAA_ROLE_AUTH_METADATA: `/uaa/${API_VERSION}/role/authority/metadata`,
  UAA_AUTH_METADATA: `/uaa/${API_VERSION}/authority/metadata`,
  UAA_PERMISSION: `/uaa/${API_VERSION}/role/permission`,
  UAA_PASSWORD: `/uaa/${API_VERSION}/auth/password`,
  UAA_PASSWORD_RESET: `/uaa/${API_VERSION}/auth/password/reset`,
  UAA_PASSWORD_FORGET: `/uaa/${API_VERSION}/auth/password/forgot`,
  UAA_PASSWORD_EXIST: `/uaa/${API_VERSION}/auth/password/exists`,
  UAA_USERNAME_EXIST: `/uaa/${API_VERSION}/check/username`,
  UAA_ROLE_TEMPLATE: `/uaa/${API_VERSION}/role-template`,
  UAA_ROLE_TMP: `/uaa/${API_VERSION}/role/template`,
  UAA_CAPTCHA: `/uaa/${API_VERSION}/verification/send`,
  UAA_CAPTCHA_VERIFY: `/uaa/${API_VERSION}/verification/verify`,
  UAA_BUCKET_TOKEN: `/uaa/${API_VERSION}/bucket-token/bucket-token`,
  UAA_STS_TOKEN: `/uaa/${API_VERSION}/bucket-token/sts-token`,

  // 任务服务接口
  TASK_TASK: `/task/${API_VERSION}/task`,
  TASK_TEMPLATE: `/task/${API_VERSION}/template`,
  TASK_COMMAND: `/task/${API_VERSION}/command`,

  //  仓库服务接口
  WAREHOUSE_SORTER: `/warehouse/${API_VERSION}/sorter/take`,
  WAREHOUSE_WINDOW: `/warehouse/${API_VERSION}/window`,
  WAREHOUSE_WAREHOUSE: `/warehouse/${API_VERSION}/warehouse`,
  WAREHOUSE_SYNCHRONIZE: `/warehouse/${API_VERSION}/window/synchronize`,

  // 多媒体服务接口
  MEDIA_DEVICE: `/media/${API_VERSION}/device`,
  MEDIA_RECORD: `/media/${API_VERSION}/recording`,

  // 状态服务接口
  STATUS_ALERT: `/status/${API_VERSION}/alert`,
  STATUS_TRIGGER: `/status/${API_VERSION}/trigger`,
  STATUS_ROBOT: `/status/${API_VERSION}/status`,
  STATUS_ALERT_CONFIG: `/status/${API_VERSION}/alert-config`,
  STATUS_ALERT_CONFIG_RESOLVE: `/status/${API_VERSION}/alert-config/resolve`,

  // 报表统计服务接口
  REPORT_ORDER_COST: `/report/${API_VERSION}/order/cost`,
  REPORT_DEVICE_SUMMARY: `/report/${API_VERSION}/device/summary`,
  REPORT_ORDER_SUMMARY: `/report/${API_VERSION}/order/summary`,
  REPORT_COMPANY_SUMMARY: `/report/${API_VERSION}/company/order/summary`,
  REPORT_COLLECT_TODAY_SUMMARY: `/report/${API_VERSION}/order/collect/todaySummary`,
  REPORT_BUILDING_SUMMARY: `/report/${API_VERSION}/building/summary`,
  REPORT_UNKNOWN_BUILDING_SUMMARY: `/report/${API_VERSION}/order/count/unKnownBuilding`,
  REPORT_ORDER_SUMMARIES_SUMMARY: `/report/${API_VERSION}/order/summaries`,
  REPORT_DISPATCH_SUMMARY: `/report/${API_VERSION}/order/dispatch/count`,
  REPORT_EXPORT_RECORD: `/report/${API_VERSION}/async-export-record`,

  // 物流服务接口
  LOGISTIC_HIVE: `/logistic/${API_VERSION}/hive/dispatch`,
  LOGISTIC_USER: `/logistic/${API_VERSION}/user/dispatch`,
  LOGISTIC_DISPATCH: `/logistic/${API_VERSION}/order/dispatch`,
  LOGISTIC_DISPATCH_EXPORT: `/logistic/${API_VERSION}/dispatch-order/export`,
  LOGISTIC_TRANSPORT_EXPORT: `/logistic/${API_VERSION}/transport-order/export`,
  LOGISTIC_DISPATCH_ORDER_SHARED: `/logistic/${API_VERSION}/dispatch-order/shared/list`,
  LOGISTIC_DISPATCH_TRANSPORT_SHARED: `/logistic/${API_VERSION}/transport-order/shared`,
  LOGISTIC_HIVE_STATE_COUNT: `/logistic/${API_VERSION}/hive/dispatch/order/stateCount`,
  LOGISTIC_USER_STATE_COUNT: `/logistic/${API_VERSION}/user/dispatch/order/stateCount`,

  // 设备注册
  PRODUCTION_MANAGE: `/robot/${API_VERSION}/management`,
  PRODUCTION_MODEL: `/robot/${API_VERSION}/model`,
  PRODUCTION_SPECIFICATION: `/robot/${API_VERSION}/specification`,
  DEVICE_CANDLELIGHT: `/robot`,
  FUNCTION_BOX: `/robot/${API_VERSION}/factory/hive`,
  DEVICE_SPECIFICATION: `/robot/${API_VERSION}/specification`,
  DEVICE_REGISTER_ROBOT: `/robot/${API_VERSION}/factory/robot`,
  MEDIA_LIVE_DEVICE: `/media/${API_VERSION}/live-device`,
  HIVE_SIZE: `/robot/${API_VERSION}/hive-size`,
  SPECIFICATION_SIZE: `/robot/${API_VERSION}/specification-size`,

  // 省市区 查询街道
  CONNECTOR_GET_STREET_OPTIONS: `/connector/${API_VERSION}/synchronization/town`,

  // 人脸录入接口
  GUIDE_FACE: `/guide/${API_VERSION}/face`,
  GUIDE_FACE_IMG_URL: `/uaa/${API_VERSION}/bucket-token/build-url`,
};

export const SocketUrl = {
  STATUS: '/ws/status',
  MONITOR: '/ws/monitoring',
  DELIVERY: '/ws/delivery',
  MEDIA: '/ws/media',
  NOTIFICATION: '/ws/notification',
  UPLOAD: '/ws/upload',
};

export const SocketEvent = {
  SCHEDULE: '/topic/scheduling',
  CHANGE: '/topic/change',
  STATUS: '/topic/status',
  USER: '/topic/user',
  HUB: '/topic/hub',
  ORDER: '/topic/order',
  USER_SCAN: '/topic/user-scan',
  UPLOAD: '/topic/upload',
};

export const baiduMapAppKey = 'P3jmxb42OdZnM6QQMEQK99sWr8AtdwGC';

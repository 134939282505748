import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { getBrowserUA, ServerEnv } from '../env';
import { Pagination } from '../utils/constants';

const serverEnv = ServerEnv();
const userAgent = getBrowserUA(serverEnv.appName, '2.7.0');

export const CancelToken = axios.CancelToken;

export const source = CancelToken.source();

interface CustomizeConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

// axios config options
const options: CustomizeConfig = {
  baseURL: serverEnv.api + '/api',
  timeout: 10000,
  retry: 0,
  retryDelay: 1000,
  headers: {
    Authorization: serverEnv.token,
  },
  // 查询对象序列化函数
  paramsSerializer: (params: any) => qs.stringify(params),
};

const AxiosInstance = axios.create(options);

// 无法直接设置浏览器 user-agent, axios 会报 warning
// https://github.com/axios/axios/issues/1231
AxiosInstance.defaults.headers.common['X-CTI-UA'] = userAgent;

// 设置请求重试机制
AxiosInstance.interceptors.response.use(undefined, async (err: any) => {
  const config = err.config;

  if (!config || !config.retry) {
    return Promise.reject(err);
  }

  config.__retryCount = config.__retryCount || 0;
  if (config.__retryCount >= config.retry) {
    return Promise.reject(err);
  }

  config.__retryCount += 1;

  return new Promise((resolve) => {
    setTimeout(() => resolve(), config.retryDelay);
  }).then(() => axios(config));
});

// GET 获取数据
export const GET = (url: string, params?: any, config?: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(url, { params, ...config })
      .then((res: AxiosResponse) => {
        let pagination: Pagination;
        if (res.headers && res.headers['x-pagination-total-count']) {
          const {
            'x-pagination-total-count': totalCount,
            'x-pagination-page-count': totalPageCount,
            'x-pagination-page-number': page,
            'x-pagination-page-size': pageSize,
          } = res.headers;

          pagination = {
            page: Number(page),
            pageSize: Number(pageSize),
            totalCount: Number(totalCount),
            totalPageCount: Number(totalPageCount),
          };

          resolve({ ...res, pagination });
        }

        resolve({ ...res });
      })
      .catch((error: Error) => reject(error));
  });
};

// DELETE 删除数据
export const DELETE = (url: string, config?: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.delete(url, config)
      .then((res: AxiosResponse) => resolve(res))
      .catch((error: Error) => reject(error));
  });
};

// POST 提交数据
export const POST = (url: string, data?: any, config?: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(url, data, config)
      .then((res: AxiosResponse) => resolve(res))
      .catch((error: Error) => reject(error));
  });
};

// PUT 修改数据
export const PUT = (url: string, data?: any, config?: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.put(url, data, config)
      .then((res: AxiosResponse) => resolve(res))
      .catch((error: Error) => reject(error));
  });
};

// PATCH 修改数据
export const PATCH = (url: string, data?: any, config?: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    AxiosInstance.patch(url, data, config)
      .then((res: AxiosResponse) => resolve(res))
      .catch((error: Error) => reject(error));
  });
};

export default AxiosInstance;

import { AutoComplete, Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { ChangeEvent, FC, FocusEvent, useCallback, useEffect, useState } from 'react';

import { PackageSize, PackageSizeOption, Permissions } from '../../../utils/constants';
import { PhoneReg } from '../../../utils/regTool';
import { filterOption, transformDeliveryMethods } from '../../../utils/utils';

let delayFlag: any = null;
const { Option } = Select;

interface InternalProps {
  user: any;
  globalHub: any;
  userAddress: any;
  loading: boolean;
  hubOption: any[];
  courierOption: any[];
  companyOption: any[];
  waypointOption: any[];
  addressOption: any[];
  packageSize: PackageSize;
  predictedCompany: string;
  userMobileOption: any[];
  courierMobileOption: any[];
  submitOrder: (params: object) => void;
  fetchOrder: (params?: object) => void;
  predictCompany: (params?: object) => void;
  fetchUserOption: (params?: object) => void;
  fetchHubOption: (params?: object) => void;
  fetchUserByMobile: (mobile?: string) => void;
  fetchWaypointOption: (params?: object) => void;
  fetchCompanyOption: (params?: object) => void;
  fetchAddressOption: (params?: object) => void;
  fetchCourierOption: (params?: object) => void;
  fetchUserMobileOption: (params?: object) => void;
  fetchCourierMobileOption: (params?: object) => void;
}

const CreateOrderHeader: FC<InternalProps> = (props) => {
  const {
    user,
    loading,
    globalHub,
    fetchOrder,
    userAddress,
    submitOrder,
    packageSize,
    courierOption,
    companyOption,
    waypointOption,
    addressOption,
    predictCompany,
    fetchUserOption,
    predictedCompany,
    userMobileOption,
    fetchUserByMobile,
    courierMobileOption,
    fetchCourierOption,
    fetchWaypointOption,
    fetchCompanyOption,
    fetchAddressOption,
    fetchUserMobileOption,
    fetchCourierMobileOption,
  } = props;
  const [form] = Form.useForm();
  const deliveryMethodOption = transformDeliveryMethods(globalHub ? globalHub.deliveryMethods : null);
  const [deliveryMethods, setDeliveryMethods] = useState(deliveryMethodOption);
  const { resetFields, setFieldsValue, getFieldValue } = form;

  const fetchWaypoint = useCallback(
    (params?: any) => {
      const mapBlockId = globalHub ? globalHub.mapBlockId : null;
      fetchWaypointOption({ mapBlockId, waypointType: 'NORMAL', ...params });
    },
    [globalHub, fetchWaypointOption]
  );

  useEffect(() => {
    fetchWaypoint();
    fetchUserMobileOption();
    fetchCourierOption();
    fetchCourierMobileOption();
    fetchCompanyOption();
    fetchAddressOption();
    fetchUserOption({ mobile: '', permission: Permissions.Receive });
  }, [
    fetchWaypoint,
    fetchUserOption,
    fetchCourierOption,
    fetchCompanyOption,
    fetchAddressOption,
    fetchUserMobileOption,
    fetchCourierMobileOption,
  ]);

  const handleSearch = (key: string, value: string) => {
    const companyId = getFieldValue('companyId');
    window.clearTimeout(delayFlag);

    delayFlag = setTimeout(() => {
      switch (key) {
        case 'waypoint':
          fetchWaypoint({ name: value });
          break;
        case 'userMobile':
          fetchUserMobileOption({ mobile: value });
          break;
        case 'courierMobile':
          fetchCourierMobileOption({ companyId, mobile: value });
          break;
        case 'address':
          fetchAddressOption({ address: value });
          break;
        case 'courier':
          fetchCourierOption({ companyId, name: value });
          break;
        default:
          break;
      }
    }, 500);
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;

    window.clearTimeout(delayFlag);

    delayFlag = setTimeout(async () => {
      fetchOrder({ orderNumber: value });
    }, 500);
  };

  const handleBlur = async (evt: FocusEvent<HTMLInputElement>) => {
    if (evt.target.value.trim() === '') {
      return;
    }
    predictCompany({ orderNumber: evt.target.value });
  };

  const handleCompanyChange = (value: string) => {
    fetchCourierOption({ companyId: value });
    fetchCourierMobileOption({ companyId: value });
  };

  const handleUserMobileChange = (value: SelectValue) => {
    window.clearTimeout(delayFlag);

    delayFlag = setTimeout(async () => {
      fetchUserByMobile(value as string);

      if (PhoneReg.test(form.getFieldValue('userMobile'))) {
        fetchOrder({ mobile: value, pageSize: 50 });
      }
    }, 500);
  };

  const handleCourierChange = (value: SelectValue) => {
    const options = courierOption;
    const user = options.find((item: any) => item.id === value);
    setFieldsValue({ mobile: user && user.mobile });
  };

  const handleCourierMobileChange = (value: SelectValue) => {
    const options = courierMobileOption;
    const user = options.find((item: any) => item.mobile === value);
    setFieldsValue({ courierId: user && user.id });
  };

  const handlePackageChange = (value: string) => {
    if (PackageSize.OVERSIZE === value) {
      const deliveryMethods = transformDeliveryMethods(['HUB', 'MANUAL']);
      setDeliveryMethods(deliveryMethods);
    }
  };

  const handleAddressSelect = (value: SelectValue) => {
    const address = addressOption.find((item) => item.id === value);
    if (address) {
      setFieldsValue({ waypointId: address.waypointId ? address.waypointId : '' });
    }
  };

  const onSubmit = (values: any) => {
    submitOrder({
      ...values,
      userId: user.id,
      orderType: 'COLLECT',
      orderState: 'PENDING',
      hubId: globalHub.id,
    });

    resetFields(['orderNumber', 'userMobile']);
  };

  const initialValues = {
    companyId: predictedCompany,
    deliveryMethod: packageSize,
    packageSize: PackageSizeOption[0].value,
    waypointId: userAddress ? userAddress.waypointId : '',
    address: userAddress && userAddress.address ? userAddress.address : '',
  };

  return (
    <Spin spinning={!!loading}>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
        style={{ maxWidth: 1200, padding: '0 16px' }}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item name="orderNumber" label="快递单号" rules={[{ required: true, message: '请输入快递单号！' }]}>
              <Input autoFocus allowClear placeholder="快递单号" onChange={handleChange} onBlur={handleBlur} />
            </Form.Item>
            <Form.Item name="companyId" label="快递公司" rules={[{ required: true, message: '请选择快递公司！' }]}>
              <Select
                showSearch
                placeholder="请选择快递公司"
                style={{ width: '100%' }}
                filterOption={filterOption}
                onChange={handleCompanyChange}
              >
                {companyOption &&
                  companyOption.map((item: any) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="userMobile"
              label="客户手机号码"
              rules={[
                { required: true, message: '请输入客户手机号码！' },
                { pattern: PhoneReg, message: '请输入正确的手机号码！' },
              ]}
            >
              <AutoComplete
                allowClear
                placeholder="手机号"
                options={userMobileOption}
                filterOption={filterOption}
                onChange={handleUserMobileChange}
                onSearch={(value) => handleSearch('userMobile', value)}
              />
            </Form.Item>
            <Form.Item name="waypointId" label="路点" rules={[{ required: true, message: '请选择路点！' }]}>
              <Select
                showSearch
                placeholder="请选择路点"
                style={{ width: '100%' }}
                filterOption={filterOption}
                onSearch={(value) => handleSearch('waypoint', value)}
              >
                {waypointOption &&
                  waypointOption.map((item: any) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="packageSize" label="包裹大小">
                  <Select onChange={handlePackageChange}>
                    {PackageSizeOption.map((item, index) => (
                      <Option value={item.value} key={index}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="deliveryMethod" label="配送方式">
                  <Select placeholder="请选择配送方式">
                    {deliveryMethods.map((item: any, index: number) => (
                      <Option value={item.value} key={index}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <p style={{ marginTop: '1em', marginBottom: 0 }}>(选填区域)</p>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="courierId" label="快递员姓名">
                  <AutoComplete
                    allowClear
                    options={courierOption}
                    placeholder="快递员姓名"
                    filterOption={filterOption}
                    onChange={handleCourierChange}
                    onSearch={(value) => handleSearch('courier', value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="mobile" label="快递员电话">
                  <AutoComplete
                    allowClear
                    placeholder="手机号"
                    filterOption={filterOption}
                    options={courierMobileOption}
                    onChange={handleCourierMobileChange}
                    onSearch={(value) => handleSearch('courierMobile', value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="customPickUpCode"
                  label="设置取件码"
                  rules={[{ pattern: /^\d{6}$/, message: '请输入六位数字' }]}
                >
                  <AutoComplete allowClear placeholder="请输入六位取件码" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="address" label="收件详址">
                  <AutoComplete
                    allowClear
                    placeholder="收件地址"
                    options={addressOption}
                    filterOption={filterOption}
                    onSelect={handleAddressSelect}
                    onSearch={(value) => handleSearch('address', value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
              <Button type="primary" shape="round" htmlType="submit">
                确认录入
              </Button>
              <Button type="ghost" shape="round" style={{ marginLeft: 20 }} onClick={() => resetFields()}>
                清空
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CreateOrderHeader;

import { Col, Row } from 'antd';
import React, { FC } from 'react';

import styles from './StatusBar.module.scss';

interface InternalProps {
  robotData?: any;
}

const StatusBar: FC<InternalProps> = (props) => {
  return (
    <Row gutter={8} align="middle" justify="center" className={styles.statusBar}>
      <Col span={6}>
        <span className={styles.statusIcon}>
          <span className="icon left-light" />
          <span className="status-light green" />
        </span>
        <span className={styles.statusIcon}>
          <span className="icon motion" />
          <span className="status-light red" />
        </span>
        <span className={styles.statusIcon}>
          <span className="icon right-light" />
          <span className="status-light" />
        </span>
      </Col>
      <Col span={4}>
        <span className={styles.statusIcon}>
          <span className="icon camera-light" />
          <span className="status-light" />
        </span>
        <span className={styles.statusIcon}>
          <span className="icon camera-user" />
          <span className="status-light" />
        </span>
      </Col>
      <Col span={7}>
        <span>
          前超声
          <span className="status-light green" />
          <span className="status-light green" />
          <span className="status-light red" />
        </span>
        <span>
          前超声
          <span className="status-light" />
          <span className="status-light" />
          <span className="status-light" />
        </span>
      </Col>
      <Col span={7}>
        <span>
          GPS
          <span className="status-light" />
        </span>
        <span>
          IMU
          <span className="status-light" />
        </span>
        <span>
          温度计
          <span className="status-light" />
        </span>
      </Col>
    </Row>
  );
};

export default StatusBar;

import {
  AppstoreOutlined,
  DownloadOutlined,
  EnvironmentOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SwapOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Layout, Menu, Tooltip, Typography } from 'antd';
import { connect } from 'dva';
import { Link, routerRedux } from 'dva/router';
import { debounce, get } from 'lodash';
import React, { FC, useEffect } from 'react';

import { Dispatch } from '../../models/dispatch';
import { UserInfo } from '../../models/typed.d';
import { DeviceRoutes, Routes } from '../../routes';
import { isEmpty } from '../../utils';
import Avatar from '../Avatar/Avatar';
import styles from './Header.module.scss';

const { Text } = Typography;

interface InternalProps {
  notices?: any[];
  location: Location;
  userInfo: UserInfo;
  collapsed?: boolean;
  hasMore?: boolean;
  isMobile?: boolean;
  fetchingNotices?: boolean;
  logout: () => void;
  redirect: (url: string) => void;
  loadMoreNotices?: (params?: any) => void;
  onCollapse?: (collapsed: boolean) => void;
}

export const Header: FC<InternalProps> = (props) => {
  const { location, logout, redirect, collapsed, isMobile, userInfo, onCollapse } = props;
  const { hub, hubs, superAdmin, id, menu } = userInfo;
  const hasDevice = !isEmpty(menu?.device);
  const isApp = location.pathname.includes(Routes.App);
  const classNames = [styles.header, isMobile ? styles.fixedHeader : ''].join(' ');

  const triggerResizeEvent = debounce(() => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }, 500);

  useEffect(() => {
    document.addEventListener('resize', triggerResizeEvent);
    return () => {
      document.removeEventListener('resize', triggerResizeEvent);
    };
  }, [triggerResizeEvent]);

  const renderMenu = (props: UserInfo) => {
    return (
      <Menu className={styles.dropdownMenu}>
        <Menu.Item key="hello" disabled>
          你好 - {props.name} !
        </Menu.Item>
        <Menu.Item key="profile" onClick={() => redirect(`${Routes.AuthUser}/${id}`)}>
          <UserOutlined /> 个人中心
        </Menu.Item>
        <Menu.Item key="download" onClick={() => redirect(Routes.Download)}>
          <DownloadOutlined /> 数据导出记录
        </Menu.Item>
        <Menu.Item key="logout" onClick={logout}>
          <LogoutOutlined /> 退出登录
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Layout.Header className={classNames}>
      <div className={styles.menuBtn} onClick={() => onCollapse && onCollapse(!collapsed)}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <div className={styles.headerRight}>
        <div className={styles.headerHub}>
          <EnvironmentOutlined /> <Text>{hub && hub.name}</Text>
        </div>
        {hasDevice && (
          <Tooltip title={isApp ? '设备管控平台' : '综合业务管理平台'}>
            <Link to={isApp ? DeviceRoutes.Device : Routes.App}>
              <div className={styles.headerIcon}>
                <AppstoreOutlined />
              </div>
            </Link>
          </Tooltip>
        )}
        {superAdmin || (hubs && hubs.length > 1) ? (
          <Tooltip title="切换驿站">
            <Link to={Routes.SwitchHub}>
              <div className={styles.headerIcon}>
                <SwapOutlined />
              </div>
            </Link>
          </Tooltip>
        ) : null}
        <Dropdown overlay={() => renderMenu(userInfo)}>
          <div className={styles.headerIcon}>
            <Avatar src={get(userInfo, 'avatar')} name={get(userInfo, 'username')} />
          </div>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

const mapStateToProps = ({ global, loading }: any) => {
  return {
    hasMore: global.hasMore,
    notices: global.notices,
    userInfo: global.userInfo,
    collapsed: global.collapsed,
    fetchingNotices: loading.effects['global/fetchNotifications'],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onCollapse: (collapsed: boolean) => {
      dispatch({ type: 'global/changeLayoutCollapsed', payload: collapsed });
    },

    redirect: (url: string) => {
      dispatch(routerRedux.push(url));
    },

    loadMoreNotices: (params?: any) => {
      dispatch({ type: 'common/fetchNotifications', payload: params });
    },

    logout: () => {
      dispatch({ type: 'global/logout' });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const UserList = Loadable.Map({
  loader: {
    User: () => import('./UserList'),
    model: () => import('./models/UserList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const User = loaded.User.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <User {...props} />
      </Loading>
    );
  },
});

const UserCollection = Loadable.Map({
  loader: {
    UserCollection: () => import('./UserCollection'),
    model: () => import('./models/UserCollection'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const UserCollection = loaded.UserCollection.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <UserCollection {...props} />
      </Loading>
    );
  },
});

const CollectionDetail = Loadable.Map({
  loader: {
    CollectionDetail: () => import('./CollectionDetail'),
    model: () => import('./models/UserCollection'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const CollectionDetail = loaded.CollectionDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <CollectionDetail {...props} />
      </Loading>
    );
  },
});

const UserDetail = Loadable.Map({
  loader: {
    UserDetail: () => import('./UserDetail'),
    model: () => import('./models/UserDetail'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const UserDetail = loaded.UserDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <UserDetail {...props} />
      </Loading>
    );
  },
});

const FeedbackList = Loadable.Map({
  loader: {
    FeedbackList: () => import('./FeedbackList'),
    model: () => import('./models/FeedbackList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const FeedbackList = loaded.FeedbackList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <FeedbackList {...props} />
      </Loading>
    );
  },
});

export { UserList, FeedbackList, UserDetail, UserCollection, CollectionDetail };

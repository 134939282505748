import { Button, Card, List, Popover, Row, Skeleton, Tag, Typography } from 'antd';
import { format } from 'date-fns';
import { connect } from 'dva';
import React, { FC, useEffect } from 'react';

import { Avatar } from '../../../components';
import { Dispatch } from '../../../models/dispatch';
import { formatHistory, modifiedBy } from '../../../utils';
import { HistoryType, HistoryTypeStr, OrderTypeStr, Pagination } from '../../../utils/constants';
import styles from './OrderHistory.module.scss';

const { Item } = List;
const { Text } = Typography;

interface DvaProps {
  loading: boolean;
  orderHistory: any[];
  initLoading: boolean;
  pagination: Pagination;
  fetchOrderHistory: (params?: object) => void;
  fetchMoreHistory: (params?: object) => void;
}

interface InternalProps extends DvaProps {
  orderId: string;
}

export const OrderHistory: FC<InternalProps> = (props) => {
  const { orderId, loading, pagination, initLoading, orderHistory, fetchOrderHistory, fetchMoreHistory } = props;
  const { page, totalCount } = pagination;

  useEffect(() => {
    fetchOrderHistory({ orderId });
  }, [fetchOrderHistory, orderId]);

  const loadMore =
    orderHistory.length < totalCount && !initLoading && !loading ? (
      <Row justify="center" align="middle" style={{ padding: '8px 0' }}>
        <Button onClick={() => fetchMoreHistory({ orderId, page: page + 1 })}>加载更多</Button>
      </Row>
    ) : null;

  return (
    <Card title="订单轨迹" bordered={false} loading={initLoading} className={styles.orderHistory}>
      <List
        split={false}
        loading={loading}
        loadMore={loadMore}
        dataSource={orderHistory}
        renderItem={(item: any, index: number) => (
          <Item key={index} className={styles.listItem}>
            <Skeleton active avatar title={false} loading={loading}>
              <Item.Meta
                avatar={
                  <Popover content={modifiedBy(item)}>
                    <Avatar src={item.modifiedByEntity?.avatar} name={modifiedBy(item)} />
                  </Popover>
                }
                title={
                  <Row>
                    <Text style={{ marginRight: 12 }}>{format(item.createdTime, 'yyyy-MM-dd HH:mm:ss')}</Text>
                    <Tag color="#2db7f5">{OrderTypeStr[item.order.orderType][item.orderState] || '暂无'}</Tag>
                  </Row>
                }
                description={
                  <div>
                    {modifiedBy(item)}
                    {item.historyType === HistoryType.UPDATE
                      ? formatHistory(item.historyType, item.changes, item.order?.orderType)
                      : `${HistoryTypeStr[item.historyType]}了订单`}
                  </div>
                }
              />
            </Skeleton>
          </Item>
        )}
      />
    </Card>
  );
};

const mapStateToProps = ({ orderHistory, loading }: any) => ({
  pagination: orderHistory.pagination,
  orderHistory: orderHistory.orderHistory,
  loading: loading.effects['orderHistory/fetchMoreHistory'],
  initLoading: loading.effects['orderHistory/fetchOrderHistory'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchOrderHistory: (params?: object) => {
    dispatch({ type: 'orderHistory/fetchOrderHistory', payload: params });
  },

  fetchMoreHistory: (params?: object) => {
    dispatch({ type: 'pieceOrder/fetchMoreHistory', payload: params });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);

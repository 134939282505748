import { Col, DatePicker, Form, Row, Select, Table } from 'antd';
import { endOfDay, format, startOfDay } from 'date-fns';
import { connect } from 'dva';
import { debounce } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import PaginationView from '../../../components/Pagination/Pagination';
import { Dispatch } from '../../../models/dispatch';
import { Pagination, TransitModeStr, TransitStateStr } from '../../../utils/constants';
import styles from './SchedulingList.module.scss';

const Item = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface DvaProps {
  loading: boolean;
  schedulingModalList: any[];
  schedulingModalPagination: Pagination;
  fetchSchedulingModalList: (params?: any) => void;
}

interface InternalProps extends DvaProps {
  orderId: string;
}

const SchedulingList: FC<InternalProps> = (props) => {
  const { orderId, schedulingModalPagination, schedulingModalList, fetchSchedulingModalList, loading } = props;
  const [reqParams, setReqParams] = useState<any>({});

  const schedulingColumns = [
    {
      title: '设备名称',
      dataIndex: ['robot', 'name'],
    },
    {
      title: '调度任务',
      dataIndex: 'transitMode',
      render: (text: any) => text && TransitModeStr[text],
    },
    {
      title: '调度状态',
      dataIndex: 'transitState',
      render: (text: any) => (
        <span style={{ color: `${TransitStateStr[text].color}` }}>{TransitStateStr[text].text || '未知'}</span>
      ),
    },
    {
      title: '起点',
      dataIndex: 'fromWaypoint',
      render: (text: any, record: any) =>
        text && text.name + (record.formDock ? ` (${record.formDock.name || '未知桩'}) ` : ''),
    },
    {
      title: '目标点',
      dataIndex: 'toWaypoint',
      render: (text: any, record: any) => {
        let str = text ? text.name : '';
        const { chargingDock, toDock } = record;
        if (chargingDock) {
          str += ` (${chargingDock.name || '未知桩'})`;
        } else if (toDock) {
          str += ` (${toDock.name || '未知桩'})`;
        }
        return str;
      },
    },
    {
      title: '电梯',
      dataIndex: 'elevator',
      render: (text: any) => text && text.name,
    },
    {
      title: '停车位',
      dataIndex: 'bay',
      render: (text: any) => text && text.name,
    },
    {
      title: '警报',
      dataIndex: 'alert',
    },
    {
      title: '创建时间',
      dataIndex: 'createdTime',
      render: (text: any) => text && format(new Date(text), 'MM-dd HH:mm:ss'),
    },
  ];

  const onPageChange = (page: number, pageSize: number | undefined) => {
    const params = { ...reqParams, page: page - 1, pageSize };
    setReqParams(params);
  };

  const disabledDate = (current: any) => {
    return current.valueOf() > new Date().valueOf();
  };

  const onChangePickDate = (date: any, dateString: [string, string], key?: string) => {
    const start = dateString[0] !== '' ? startOfDay(new Date(dateString[0])).getTime() : '';
    const end = dateString[1] !== '' ? endOfDay(new Date(dateString[1])).getTime() : '';
    onSearchChange({ start, end });
  };

  useEffect(() => {
    fetchSchedulingModalList({ ...reqParams, context: orderId });
  }, [fetchSchedulingModalList, reqParams, orderId]);

  const onSearchChange = (value: any) => {
    setReqParams({ ...value, page: 0 });
  };

  const title = (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="调度任务">
            <Select
              allowClear
              placeholder="请选择"
              optionFilterProp="children"
              onChange={(value: string[]) => onSearchChange({ transitMode: value })}
            >
              {Object.keys(TransitModeStr).map((item: any, index: number) => (
                <Option value={item} key={index}>
                  {TransitModeStr[item]}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="调度状态">
            <Select
              allowClear
              placeholder="请选择"
              optionFilterProp="children"
              onChange={(value: string[]) => onSearchChange({ transitState: value })}
            >
              {Object.keys(TransitStateStr).map((item: any, index: number) => (
                <Option value={item} key={index}>
                  {TransitStateStr[item]?.text}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Item label="时间">
            <RangePicker
              disabledDate={(current) => disabledDate(current)}
              onChange={debounce((date: any, dateString: any) => onChangePickDate(date, dateString), 500)}
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Table
      title={() => title}
      columns={schedulingColumns}
      loading={loading}
      className={styles.wrapTaskModal}
      pagination={PaginationView({ ...schedulingModalPagination }, onPageChange)}
      dataSource={schedulingModalList}
    />
  );
};

const mapStateToProps = ({ loading, pieceOrder }: any) => {
  return {
    schedulingModalPagination: pieceOrder.schedulingModalPagination,
    schedulingModalList: pieceOrder.schedulingModalList,
    loading: loading.effects['pieceOrder/fetchSchedulingModalList'],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchSchedulingModalList: (params: any) => {
      dispatch({ type: 'pieceOrder/fetchSchedulingModalList', payload: params });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingList);

import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class UserService {
  public static fetchUserList(params?: any): Promise<any> {
    return GET(ApiUrl.USER, params);
  }

  public static postUser(params?: any): Promise<any> {
    return POST(ApiUrl.USER, params);
  }

  public static enableUser(id: string): Promise<any> {
    return POST(`${ApiUrl.USER}/${id}/enable`);
  }

  public static disableUser(id: string): Promise<any> {
    return POST(`${ApiUrl.USER}/${id}/disable`);
  }

  public static fetchAddressList(params?: object): Promise<any> {
    return GET(ApiUrl.USER_ADDRESS, params);
  }

  public static fetchLogList(params?: object): Promise<any> {
    return GET(ApiUrl.USER_HISTORY, params);
  }

  public static fetchUserRemarks(params?: object): Promise<any> {
    return GET(ApiUrl.USER_COMMENT, params);
  }

  public static fetchUserInfo(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.USER}/${id}`, params);
  }

  public static fetchUserByToken(token: string, params: object): Promise<any> {
    return GET(ApiUrl.USER_INFO, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static patchUserRemarks(params?: object): Promise<any> {
    return POST(ApiUrl.USER_COMMENT, params);
  }

  public static patchUser(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.USER + '/' + id, params);
  }

  public static postAddress(params?: object): Promise<any> {
    return POST(ApiUrl.USER_ADDRESS, params);
  }

  public static patchAddress(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.USER_ADDRESS}/${id}`, params);
  }

  public static deleteUser(id: string, params?: object): Promise<any> {
    return DELETE(`${ApiUrl.USER}/${id}`, params);
  }

  public static deleteAddress(id: string): Promise<any> {
    return DELETE(`${ApiUrl.USER_ADDRESS}/${id}`);
  }

  public static fetchDepartmentList(params?: object): Promise<any> {
    return GET(ApiUrl.USER_DEPARTMENT, params);
  }
}

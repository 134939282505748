import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const HubPickupList = Loadable.Map({
  loader: {
    HubPickupList: () => import('./HubPickupList'),
    model: () => import('./models/HubPickupList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const HubPickupList = loaded.HubPickupList.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <HubPickupList {...props} />
      </Loading>
    );
  },
});

const HubScreenReport = Loadable.Map({
  loader: {
    HubScreenReport: () => import('./HubScreenReport'),
    model: () => import('./models/HubScreenReport'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const HubScreenReport = loaded.HubScreenReport.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <HubScreenReport {...props} />
      </Loading>
    );
  },
});
export { HubPickupList, HubScreenReport };

import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const DeviceProductManage = Loadable.Map({
  loader: {
    DeviceProductManage: () => import('./DeviceProductManage'),
    model: () => import('./models/DeviceProductManage'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceProductManage = loaded.DeviceProductManage.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceProductManage {...props} />
      </Loading>
    );
  },
});

const DeviceProductManageDetail = Loadable.Map({
  loader: {
    DeviceProductManageDetail: () => import('./DeviceProductManageDetail'),
    model: () => import('./models/DeviceProductManage'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceProductManageDetail = loaded.DeviceProductManageDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceProductManageDetail {...props} />
      </Loading>
    );
  },
});

const DeviceModelManage = Loadable.Map({
  loader: {
    DeviceModelManage: () => import('./DeviceModelManage'),
    model: () => import('./models/ModelManage'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceModelManage = loaded.DeviceModelManage.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceModelManage {...props} />
      </Loading>
    );
  },
});

const DeviceSpecification = Loadable.Map({
  loader: {
    DeviceSpecification: () => import('./DeviceSpecification'),
    model: () => import('./models/DeviceSpecification'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceSpecification = loaded.DeviceSpecification.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceSpecification {...props} />
      </Loading>
    );
  },
});

const DeviceHiveSize = Loadable.Map({
  loader: {
    DeviceHiveSize: () => import('./DeviceHiveSize'),
    model: () => import('./models/DeviceHiveSize'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceHiveSize = loaded.DeviceHiveSize.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceHiveSize {...props} />
      </Loading>
    );
  },
});

const DeviceHiveSizeDetail = Loadable.Map({
  loader: {
    DeviceHiveSizeDetail: () => import('./DeviceHiveSizeDetail'),
    model: () => import('./models/DeviceHiveSize'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceHiveSizeDetail = loaded.DeviceHiveSizeDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceHiveSizeDetail {...props} />
      </Loading>
    );
  },
});

const DeviceSunlight = Loadable.Map({
  loader: {
    DeviceSunlight: () => import('./DeviceSunlight'),
    model: () => import('./models/DeviceSunlight'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceSunlight = loaded.DeviceSunlight.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceSunlight {...props} />
      </Loading>
    );
  },
});

const DeviceSunlightDetail = Loadable.Map({
  loader: {
    DeviceSunlightDetail: () => import('./DeviceSunlightDetail'),
    model: () => import('./models/DeviceSunlight'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceSunlightDetail = loaded.DeviceSunlightDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceSunlightDetail {...props} />
      </Loading>
    );
  },
});

const DeviceCandlelight = Loadable.Map({
  loader: {
    DeviceCandlelight: () => import('./DeviceCandlelight'),
    model: () => import('./models/DeviceCandlelight'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceCandlelight = loaded.DeviceCandlelight.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceCandlelight {...props} />
      </Loading>
    );
  },
});

const DeviceCandlelightDetail = Loadable.Map({
  loader: {
    DeviceCandlelightDetail: () => import('./DeviceCandlelightDetail'),
    model: () => import('./models/DeviceCandlelight'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceCandlelightDetail = loaded.DeviceCandlelightDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceCandlelightDetail {...props} />
      </Loading>
    );
  },
});

const DeviceFunctionBox = Loadable.Map({
  loader: {
    DeviceFunctionBox: () => import('./DeviceFunctionBox'),
    model: () => import('./models/DeviceFunctionBox'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceFunctionBox = loaded.DeviceFunctionBox.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceFunctionBox {...props} />
      </Loading>
    );
  },
});

const DeviceFunctionBoxDetail = Loadable.Map({
  loader: {
    DeviceFunctionBoxDetail: () => import('./DeviceFunctionBoxDetail'),
    model: () => import('./models/DeviceFunctionBox'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DeviceFunctionBoxDetail = loaded.DeviceFunctionBoxDetail.default;
    const model = loaded.model.default;

    registerModel(props.app, model);

    return (
      <Loading>
        <DeviceFunctionBoxDetail {...props} />
      </Loading>
    );
  },
});

export {
  DeviceProductManage,
  DeviceProductManageDetail,
  DeviceModelManage,
  DeviceSpecification,
  DeviceHiveSize,
  DeviceHiveSizeDetail,
  DeviceSunlight,
  DeviceSunlightDetail,
  DeviceCandlelight,
  DeviceCandlelightDetail,
  DeviceFunctionBox,
  DeviceFunctionBoxDetail,
};

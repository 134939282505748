import { Button, Col, Form, Input, Row, Select } from 'antd';
import { get } from 'lodash';
import React, { ChangeEvent, FC } from 'react';

import { splitQuery } from '../../../utils';
import {
  BooleanOptions,
  DeviceStatusOption,
  OperationModeStr,
  RobotSeries,
  RobotType,
  RobotTypeStr,
} from '../../../utils/constants';
import { RobotListParams } from '../models/typed.d';

const { Option } = Select;
const { Search } = Input;

interface InternalProps {
  items: any[];
  query: RobotListParams;
  onAdd?: () => void;
  onUpgrade?: () => void;
  onTransfer: (records: any[]) => void;
  onSearchChange: (params?: RobotListParams) => void;
}

const RobotListHeader: FC<InternalProps> = (props) => {
  const { items, query, onAdd, onTransfer, onUpgrade, onSearchChange } = props;
  const { tabKey } = query;
  const value = get(query, 'disabled', '');
  const defaultValue = value ? (value === 'true' ? 'false' : 'true') : undefined;

  return (
    <Form className="base-form">
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="机器人名称">
            <Search
              allowClear
              placeholder="请输入"
              defaultValue={get(query, 'name')}
              onSearch={(value: string) => onSearchChange({ name: value })}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => onSearchChange({ name: evt.target.value })}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="是否已激活">
            <Select
              allowClear
              placeholder="是否已激活"
              defaultValue={get(query, 'activated')}
              onChange={(value: string) => onSearchChange({ activated: value })}
            >
              {BooleanOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="是否开启调度">
            <Select
              allowClear
              placeholder="请选择"
              defaultValue={defaultValue}
              onChange={(value?: string) =>
                onSearchChange({ disabled: value === undefined ? value : value === 'true' ? 'false' : 'true' })
              }
            >
              {BooleanOptions.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="机器人状态">
            <Select
              allowClear
              mode="multiple"
              placeholder="请选择"
              defaultValue={splitQuery(query, 'deviceStatus')}
              onChange={(value: string[]) => onSearchChange({ deviceStatus: value.join() })}
            >
              {DeviceStatusOption.map((item: any, index: number) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="机器人类型">
            <Select
              allowClear
              showSearch
              mode="multiple"
              optionFilterProp="children"
              placeholder="请选择"
              defaultValue={splitQuery(query, 'robotType')}
              onChange={(value: string[]) => onSearchChange({ robotType: value.join() })}
            >
              {Object.entries(RobotTypeStr)
                .filter((item: [string, string]) =>
                  tabKey && tabKey !== 'all'
                    ? tabKey !== RobotSeries.SUNLIGHT
                      ? !item[0].startsWith('LR')
                      : item[0].startsWith('LR') || item[0] === RobotType.UNDEFINED
                    : item[0]
                )
                .map((item: [string, string], index: number) => {
                  return (
                    <Option key={index} value={item[0]}>
                      {item[1]}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="运行模式">
            <Select
              allowClear
              showSearch
              mode="multiple"
              placeholder="请选择"
              defaultValue={splitQuery(query, 'operationMode')}
              onChange={(value: string[]) => onSearchChange({ operationMode: value.join() })}
            >
              {Object.entries(OperationModeStr).map((item: [string, string], index: number) => (
                <Option key={index} value={item[0]}>
                  {item[1]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Button type="primary" onClick={onAdd}>
          添加机器人
        </Button>
        {items && items.length > 0 ? (
          <Button style={{ marginLeft: 16 }} onClick={() => onTransfer(items)}>
            批量迁移
          </Button>
        ) : null}
        {items && items.length > 0 ? (
          <Button style={{ marginLeft: 16 }} onClick={onUpgrade}>
            OTA升级
          </Button>
        ) : null}
      </Row>
    </Form>
  );
};

export default RobotListHeader;

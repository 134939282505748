import { Button, Card, Empty, Row, Tag, Timeline, Typography } from 'antd';
import { connect } from 'dva';
import React, { FC, useState } from 'react';

import { Avatar } from '../../../components';
import { Dispatch } from '../../../models/dispatch';
import { LogisticTrackStateStr } from '../../../utils/constants';

const { Text } = Typography;

interface DvaProps {
  orderId: string;
  loading: boolean;
  orderInfo: any;
  orderTrack: any;
  fetchOrderTrack: (id: string) => void;
}

export const LogisticInfo: FC<DvaProps> = (props) => {
  const { loading, orderInfo, orderId, orderTrack, fetchOrderTrack } = props;
  const { company, shipperNumber } = orderInfo;
  const { state, tracks } = orderTrack;
  const [ifHaveTracks, setIfHaveTracks] = useState(false);

  const clickLoad = () => {
    fetchOrderTrack(orderId);
    setIfHaveTracks(true);
  };

  return (
    <Card title="物流信息" bordered={false} loading={loading} bodyStyle={{ height: 480, overflow: 'auto' }}>
      <Row style={{ paddingBottom: 32, fontSize: 16 }} align="middle">
        <Avatar src={company && company.logoUrl} name={company && company.name} />
        <Text strong style={{ marginLeft: 16 }}>
          {company && company.name}
        </Text>
        <Text style={{ margin: '0 32px' }}>
          运单号: <Text strong>{shipperNumber || '暂无'}</Text>
        </Text>
        {state && <Tag color="#2db7f5">{LogisticTrackStateStr[state]}</Tag>}
      </Row>
      <Timeline>
        {tracks && tracks.length ? (
          tracks.map((item: any, index: number) => (
            <Timeline.Item key={index}>
              {item.acceptTime} {item.acceptStation}
            </Timeline.Item>
          ))
        ) : ifHaveTracks ? (
          <Empty style={{ marginTop: 40 }} description="暂无物流信息" />
        ) : null}
      </Timeline>
      <Row justify="center" style={{ padding: 16 }}>
        {shipperNumber && (
          <Button type="primary" onClick={clickLoad}>
            点击此处加载物流信息
          </Button>
        )}
      </Row>
    </Card>
  );
};

const mapStateToProps = ({ pieceOrder, loading }: any) => ({
  orderTrack: pieceOrder.orderTrack,
  orderInfo: pieceOrder.orderInfo,
  loading: loading.effects['pieceOrder/fetchOrderTrack'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchOrderTrack: (id: string) => {
    dispatch({ type: 'pieceOrder/fetchOrderTrack', payload: id });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticInfo);

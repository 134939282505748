import { Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, Switch, Transfer, TreeSelect } from 'antd';
import React, { FunctionComponent } from 'react';

interface Layout {
  gutter?: number;
  span?: number;
  offset?: number;
}

interface InternalProps {
  items: any[];
  layout?: Layout;
}

export const FormItems: FunctionComponent<InternalProps> = ({ items }) => {
  // const { gutter, offset, span } = layout;

  const getItemByType = (item: any): React.ReactNode => {
    const { type, componentProps = {} } = item;
    const { options = [], placeholder } = componentProps;

    const collection = {
      Input: <Input {...componentProps} placeholder={placeholder || '请输入'} />,

      Select: (
        <Select {...componentProps} placeholder={placeholder || '请选择'}>
          {Array.isArray(options) &&
            options.map((v) => (
              <Select.Option key={v.value} value={v.value} label={v.label}>
                {v.label}
              </Select.Option>
            ))}
        </Select>
      ),

      TextArea: <Input.TextArea {...componentProps} />,

      Radio: (
        <Radio.Group {...componentProps}>
          {Array.isArray(options) &&
            options.map((v) => (
              <Radio key={v.value} value={v.value}>
                {v.label}
              </Radio>
            ))}
        </Radio.Group>
      ),

      InputNumber: <InputNumber {...componentProps} placeholder={placeholder || '请输入'} />,

      InputPassword: <Input.Password {...componentProps} />,

      CheckboxGroup: <Checkbox.Group {...componentProps} />,

      Transfer: <Transfer {...componentProps} />,

      TreeSelect: <TreeSelect {...componentProps} />,

      Switch: <Switch {...componentProps} />,
    };

    return collection[type] ? collection[type] : null;
  };

  return (
    <Row gutter={24}>
      {items.map((v: any, key: React.Key) => {
        const { type, formItemProps = {}, render } = v;
        const { label, name, required, rules, ...others } = formItemProps;
        const _rules = required
          ? rules
            ? [{ required: true, message: `${type === 'Select' ? '请选择' : '请输入'}${label}!` }].concat(rules)
            : rules
          : rules;

        const layout = type
          ? {
              lg: 8,
              md: 12,
              xs: 24,
            }
          : {};

        return (
          <Col key={key} {...layout} span={type ? undefined : 24}>
            {type ? (
              <Form.Item name={name} label={label} rules={_rules} {...others}>
                {getItemByType(v)}
              </Form.Item>
            ) : (
              (render && render(v)) || null
            )}
          </Col>
        );
      })}
    </Row>
  );
};

export default FormItems;

import { Form, Modal, Select } from 'antd';
import { connect } from 'dva';
import React, { FC, useEffect } from 'react';

import { Dispatch } from '../../../models/dispatch';

const { Option } = Select;

interface DvaProps {
  hubOptions?: any[];
  fetchHubOption?: (params?: object) => void;
}

interface Props extends DvaProps {
  records: any[];
  loading?: boolean;
  visible?: boolean;
  onCancel?: () => void;
  afterClose?: () => void;
  onSubmit: (records: any[], params: object) => void;
}

const TransferModal: FC<Props> = (props) => {
  const { records, loading, visible, hubOptions, onCancel, onSubmit, afterClose, fetchHubOption } = props;
  const [form] = Form.useForm();
  const title = `迁移${records ? (records?.length > 1 ? '选中设备' : records[0]?.name) : '设备'}`;
  const { resetFields, validateFields } = form;

  useEffect(() => {
    if (fetchHubOption) {
      fetchHubOption();
    }
  }, [fetchHubOption]);

  const handleAfterClose = () => {
    resetFields();
    afterClose && afterClose();
  };

  const handleSubmit = async () => {
    const values = await validateFields();
    onSubmit(records, values);
  };

  return (
    <Modal
      centered
      forceRender
      title={title}
      onOk={handleSubmit}
      visible={visible}
      getContainer={false}
      onCancel={onCancel}
      confirmLoading={loading}
      afterClose={handleAfterClose}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Form.Item name="hubId" required>
          <Select placeholder="请选择驿站">
            {hubOptions
              ? hubOptions.map((item: any, index: number) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ common }: any) => {
  return {
    hubOptions: common.hubOption,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchHubOption: (params: object) => {
      dispatch({ type: 'common/fetchHubOption', payload: params });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferModal);

import { localStorageKey } from './constants';
import { isEmpty } from './utils';

/**
 * 保存跟界面视图相关的字段到 localStorage
 * @param {string} key
 * @param {string} value
 */
export function saveAppViewStore(key: string, value: string) {
  let data = JSON.parse(localStorage.getItem(localStorageKey.APP_VIEW_STORE) || '{}');
  data = isEmpty(data) ? { [key]: value } : { ...data, [key]: value };

  localStorage.setItem(localStorageKey.APP_VIEW_STORE, JSON.stringify(data));
}

/**
 * 保存跟设备监控相关的字段到 localStorage
 * @param {string} key
 * @param {string} value
 */
export function saveDeviceMonitorStore(key: string, value: string) {
  let data = JSON.parse(localStorage.getItem(localStorageKey.APP_DEVICE_MONITOR) || '{}');
  data = isEmpty(data) ? { [key]: value } : { ...data, [key]: value };

  localStorage.setItem(localStorageKey.APP_DEVICE_MONITOR, JSON.stringify(data));
}

/**
 * 获取 localStorage key
 * @param {string} key
 * @return {any} 返回数据
 */
export function getStorageKey(key: string, defaultValue?: any) {
  const data = JSON.parse(localStorage.getItem(key) || '{}');
  return !isEmpty(data) ? data : defaultValue;
}

/**
 * 移除 localStorage key
 * @param {string} key
 */
export function removeStorageKey(key: string) {
  localStorage.removeItem(key);
}

import Loadable from 'react-loadable';

import { Loading } from '../../components';

/************** Public Pages **************/

const Login = Loadable({
  loader: () => import('./Login'),
  loading: Loading,
});

const ForgetPassword = Loadable({
  loader: () => import('./ForgetPassword'),
  loading: Loading,
});

const Auth = Loadable({
  loader: () => import('./Auth'),
  loading: Loading,
});

export { Auth, Login, ForgetPassword };

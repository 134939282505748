import { WaypointType } from './constants';

// 路点类型对应的颜色
export const NODE_DEFAULT_COLOR = '#F2616D';
export const NODE_NORMAL_COLOR = '#4E5E78';
export const NODE_DOCK_COLOR = '#39B481';
export const NODE_LIFT_COLOR = '#0D86FF';
export const NODE_INTERCHANGE_COLOR = '#0D86FF';
export const NODE_BAY_COLOR = '#A8DADC';
export const NODE_PARKING_COLOR = '#457B9D';
export const NODE_HOLD_COLOR = '#457B9D';
export const NODE_CLUSTER_COLOR = 'rgb(252, 159, 7)';

/**
 * Stroke node with colors
 * @param {WaypointType} waypointType
 * @param {string} color
 */
export function strokeNode(waypointType: WaypointType): string {
  switch (waypointType) {
    case WaypointType.NORMAL:
      return NODE_NORMAL_COLOR;
    case WaypointType.DOCK:
      return NODE_DOCK_COLOR;
    case WaypointType.LIFT:
      return NODE_LIFT_COLOR;
    case WaypointType.INTERCHANGE:
      return NODE_INTERCHANGE_COLOR;
    case WaypointType.HOLD:
      return NODE_HOLD_COLOR;
    case WaypointType.BAY:
      return NODE_BAY_COLOR;
    case WaypointType.PARKING:
      return NODE_PARKING_COLOR;
    case WaypointType.CLUSTER:
      return NODE_CLUSTER_COLOR;
    default:
      return NODE_DEFAULT_COLOR;
  }
}

/**
 * slam坐标转换成像素坐标
 * @param {number} x
 * @param {number} y
 * @param {number} height
 * @param {[number, number]} origin
 * @param {number} resolution
 * @return {{x, y}} 坐标对象
 */
export function slamToPixel(x: number, y: number, height: number, origin: [number, number], resolution: number) {
  if (resolution <= 0) {
    return { x: 0, y: 0 };
  }

  return {
    x: parseInt(String((x - origin[0]) / resolution), 10),
    y: parseInt(String(height - (y - origin[1]) / resolution), 10),
  };
}

/**
 *
 * @param {[]} nodes
 * @param {number} height
 * @param {[number, number]} origin
 * @param {number} resolution
 */
export function formatNodes(nodes: any[], height: number, origin: [number, number], resolution: number) {
  if (!nodes || !(nodes && nodes.length > 0) || !origin) {
    return nodes;
  }

  return nodes.map((item) => {
    const { indoorCoordinates: coors, indoorPixelCoordinates } = item;

    if (Array.isArray(coors) && !indoorPixelCoordinates) {
      const position = slamToPixel(coors[0], coors[1], height, origin, resolution);
      item.fx = position.x;
      item.fy = position.y;
    } else {
      item.fx = indoorPixelCoordinates ? indoorPixelCoordinates[0] : 0;
      item.fy = indoorPixelCoordinates ? indoorPixelCoordinates[1] : 0;
    }

    item.color = strokeNode(item.waypointType);

    return item;
  });
}

import dva, { onActionFunc } from 'dva';
import createLoading from 'dva-loading';
import * as React from 'react';
import { createLogger } from 'redux-logger';

import { __DEV__, __TEST__ } from './env';
import { Common, Global } from './models';
import Page from './Page';
import './utils/appLogger';
import { errorHandle } from './utils/errorHandle';

import './index.css';

// Dva 中间件
const middleware: onActionFunc[] = [];

if (__DEV__ || __TEST__) {
  middleware.push(createLogger());
}

const app: any = dva({
  onError(e) {
    // 全局捕获 redux action 报错
    errorHandle(e, app._store.dispatch);
  },

  onAction: middleware,
});

// 第三方插件
app.use(createLoading());

app.router((props: any) => <Page {...props} />);

// 注册全局和通用 dva model
app.model(Global);
app.model(Common);

app.start('#root');

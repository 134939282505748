import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React, { PureComponent } from 'react';

import { DeliveryMethod } from '../../../utils/constants';
interface OrderInfo {
  orderState: string;
}

interface InternalProps {
  orderInfo: OrderInfo;
  modifyMethods: (key: React.Key) => any;
}

class ModifyMethods extends PureComponent<InternalProps> {
  public showText(params: OrderInfo) {
    if (!['PENDING'].includes(params.orderState)) {
      return true;
    } else {
      return false;
    }
  }

  public handleClick = (key: React.Key) => {
    this.props.modifyMethods(key);
  };

  public render() {
    const menu = (
      <Menu onClick={(e) => this.handleClick(e.key)}>
        <Menu.Item key={DeliveryMethod.ROBOT}>无人车配送</Menu.Item>
        <Menu.Item key={DeliveryMethod.HUB}>驿站取件</Menu.Item>
        <Menu.Item key={DeliveryMethod.MANUAL}>人工取件</Menu.Item>
        <Menu.Item key={DeliveryMethod.HIVE}>机器人配送</Menu.Item>
        <Menu.Item key={DeliveryMethod.STATIONARY}>固定快递柜</Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} disabled={this.showText(this.props.orderInfo)}>
        <Button type="link">
          修改方式 <DownOutlined />
        </Button>
      </Dropdown>
    );
  }
}
export default ModifyMethods;

import { BuildEnv, EnvType } from '../env';
interface BucketName {
  deviceResource: string;
  deviceOTA: string;
}

interface BucketConfig {
  test: BucketName;
  local: BucketName;
  development: BucketName;
  staging: BucketName;
  production: BucketName;
}

export const bucket: BucketConfig = {
  [EnvType.Local]: {
    deviceResource: 'cti-device-resource-store-dev',
    deviceOTA: 'cti-device-ota-prod',
  },
  [EnvType.Development]: {
    deviceResource: 'cti-device-resource-store-dev',
    deviceOTA: 'cti-device-ota-dev',
  },
  [EnvType.Test]: {
    deviceResource: 'cti-device-resource-store-test',
    deviceOTA: 'cti-device-ota-test',
  },
  [EnvType.Staging]: {
    deviceResource: 'cti-device-resource-store-staging',
    deviceOTA: 'cti-device-ota-stg',
  },
  [EnvType.Production]: {
    deviceResource: 'cti-device-resource-store-prod',
    deviceOTA: 'cti-device-ota-prod',
  },
};

/**
 * Stroke node with colors
 * @param {EnvType} envType
 */
export function getBucketConfig(envType: EnvType): BucketName {
  switch (envType) {
    case EnvType.Development:
      return bucket[EnvType.Development];
    case EnvType.Staging:
      return bucket[EnvType.Staging];
    case EnvType.Production:
      return bucket[EnvType.Production];
    case EnvType.Local:
      return bucket[EnvType.Local];
    case EnvType.Test:
      return bucket[EnvType.Test];
    default:
      return bucket[EnvType.Production];
  }
}

const BucketObj = getBucketConfig(BuildEnv);

const faceDir = BuildEnv === 'local' ? 'development' : BuildEnv;

// 上传文件存储桶配置
export const UploadConfig = {
  deviceResource: BucketObj.deviceResource,
  dir: 'console-web/',
  deviceOTA: BucketObj.deviceOTA,
};

// 上传人脸存储捅配置
export const UploadFaceConfig = {
  deviceResource: 'cti-face-data',
  dir: `${faceDir}/`,
};

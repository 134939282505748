import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../../components';
import { registerModel } from '../../utils';

const DisinfectList = Loadable.Map({
  loader: {
    DisinfectList: () => import('./DisinfectList'),
    model: () => import('./models/DisinfectList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DisinfectList = loaded.DisinfectList.default;
    const model = loaded.model.default;
    registerModel(props.app, model);

    return (
      <Loading>
        <DisinfectList {...props} />
      </Loading>
    );
  },
});

const DisinfectDetail = Loadable.Map({
  loader: {
    DisinfectDetail: () => import('./DisinfectDetail'),
    model: () => import('./models/DisinfectList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const DisinfectDetail = loaded.DisinfectDetail.default;
    const model = loaded.model.default;
    registerModel(props.app, model);

    return (
      <Loading>
        <DisinfectDetail {...props} />
      </Loading>
    );
  },
});

const RubbishList = Loadable.Map({
  loader: {
    RubbishList: () => import('./RubbishList'),
    model: () => import('./models/RubbishList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RubbishList = loaded.RubbishList.default;
    const model = loaded.model.default;
    registerModel(props.app, model);

    return (
      <Loading>
        <RubbishList {...props} />
      </Loading>
    );
  },
});

const RubbishDetail = Loadable.Map({
  loader: {
    RubbishDetail: () => import('./RubbishDetail'),
    model: () => import('./models/RubbishList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const RubbishDetail = loaded.RubbishDetail.default;
    const model = loaded.model.default;
    registerModel(props.app, model);

    return (
      <Loading>
        <RubbishDetail {...props} />
      </Loading>
    );
  },
});

const MaterialTransportList = Loadable.Map({
  loader: {
    MaterialTransportList: () => import('./MaterialTransportList'),
    model: () => import('./models/MaterialTransportList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const MaterialTransportList = loaded.MaterialTransportList.default;
    const model = loaded.model.default;
    registerModel(props.app, model);

    return (
      <Loading>
        <MaterialTransportList {...props} />
      </Loading>
    );
  },
});

const MaterialTransportDetail = Loadable.Map({
  loader: {
    MaterialTransportDetail: () => import('./MaterialTransportDetail'),
    model: () => import('./models/MaterialTransportList'),
  },
  loading: Loading,
  render(loaded: any, props: any) {
    const MaterialTransportDetail = loaded.MaterialTransportDetail.default;
    const model = loaded.model.default;
    registerModel(props.app, model);

    return (
      <Loading>
        <MaterialTransportDetail {...props} />
      </Loading>
    );
  },
});



export { DisinfectList, DisinfectDetail, RubbishList, RubbishDetail, MaterialTransportList, MaterialTransportDetail };

import { Modal } from 'antd';
import React, { FC } from 'react';

interface Props {
  data: object;
  visible: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const ViewJsonModal: FC<Props> = (props) => {
  const { data, onCancel, visible } = props;

  return (
    <Modal centered title="查看JSON" onOk={onCancel} visible={visible} onCancel={onCancel}>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </Modal>
  );
};

export default ViewJsonModal;

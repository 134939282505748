import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import React, { FC } from 'react';

export const CardWrapper: FC<CardProps> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Card style={{ margin: '0 16px 16px' }} bodyStyle={{ padding: 0, paddingTop: 1 }} bordered={false} {...otherProps}>
      {children}
    </Card>
  );
};

export default CardWrapper;

/* eslint-disable react-hooks/exhaustive-deps */
import { Cascader } from 'antd';
import { CascaderOptionType, CascaderProps } from 'antd/lib/cascader';
import { connect } from 'dva';
import React, { FC, useEffect } from 'react';

import { Dispatch } from '../../models/dispatch';
import { CommandType } from '../../utils/commandType';
import {
  CascaderBayOption,
  CascaderBuildingOption,
  CascaderDockOption,
  CascaderFloorOption,
  CascaderWaypointOption,
} from '../../utils/constants';

const filter = (inputValue: string, path: any[]) => {
  return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};

interface DvaProps {
  cascaderOptions: CascaderOptionType[];
  cascaderDockOptions: CascaderOptionType[];
  cascaderBayOptions: CascaderOptionType[];
  fetchBayList: (params?: object) => void;
  fetchDockOption: (params?: object) => void;
  fetchWaypointOption: (params?: object) => void;
  fetchBuildingOption: (params?: object) => void;
}

interface InternalProps extends DvaProps, CascaderProps {
  command: CommandType;
  waypointParams?: any;
  buildingParams?: any;
  buildingId?: string;
  resolveWaypoints?: boolean;
}

export const WaypointCascader: FC<InternalProps> = (props) => {
  const {
    onChange,
    command,
    buildingId,
    waypointParams,
    buildingParams,
    cascaderOptions,
    cascaderDockOptions,
    cascaderBayOptions,
    resolveWaypoints,
    fetchWaypointOption,
    fetchBuildingOption,
    fetchDockOption,
    fetchBayList,
    ...restProps
  } = props;

  const isDock = [CommandType.PICK_UP, CommandType.CHARGE, CommandType.DROP_OFF].includes(command);
  const isBay = [CommandType.PARK].includes(command);

  useEffect(() => {
    const params = {
      resolveFloors: true,
      selectable: true,
      id: buildingId,
      resolveWaypoints,
      ...buildingParams,
    };

    fetchBuildingOption(params);
  }, [fetchBuildingOption, resolveWaypoints, buildingId]);

  const handleChange = (value: any[], selectedOptions?: CascaderOptionType[]) => {
    if (!selectedOptions) {
      return;
    }

    if (!resolveWaypoints && selectedOptions.length === 2) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      const params = {
        floorId: targetOption.value,
        ...waypointParams,
      };

      if (isDock) {
        fetchDockOption(params);
      } else if (isBay) {
        fetchBayList(params);
      } else if (command === CommandType.RELOCATE) {
        fetchWaypointOption({ ...params, relocatable: true });
      } else {
        fetchWaypointOption(params);
      }
    }

    onChange && onChange(value, selectedOptions);
  };

  let tempArr: any[] = [];

  if (isDock) {
    tempArr = cascaderDockOptions;
  } else if (isBay) {
    tempArr = cascaderBayOptions;
  } else {
    tempArr = cascaderOptions;
  }

  return (
    <Cascader
      changeOnSelect
      showSearch={{ filter }}
      placeholder="请选择取件点"
      onChange={handleChange}
      {...restProps}
      options={tempArr}
    />
  );
};

const mapStateToProps = ({ common }: any) => {
  const filterOption = (key: string, label?: string) => {
    return common[key].map((item: any) => {
      return {
        value: item.id,
        label: (label && item[label]) || item.name,
      };
    });
  };

  const waypointOptions = filterOption('waypointOption', 'abbrevivation');
  const dockOptions = filterOption('dockOption');
  const bayOption = filterOption('bayList');

  const filterCascaderOptions = (key: string, arr: any[], titleArr: any[]) => {
    return common.buildingOption.map((item: any) => {
      const floorOptions = item.floors
        ? item.floors.map((item: any) => {
            const children = item[key]
              ? item[key].map((d: any) => ({ label: d.name, value: d.id }))
              : titleArr.concat(arr);

            return {
              value: item.id,
              label: item.forbidden ? item.name + '(人工)' : item.name,
              children,
            };
          })
        : [];

      return {
        label: item.name,
        value: item.id,
        isLeaf: false,
        children: CascaderFloorOption.concat(floorOptions),
      };
    });
  };

  const cascaderOptions = filterCascaderOptions('waypoints', waypointOptions, CascaderWaypointOption);
  const cascaderDockOptions = filterCascaderOptions('docks', dockOptions, CascaderDockOption);
  const cascaderBayOptions = filterCascaderOptions('bays', bayOption, CascaderBayOption);

  return {
    cascaderOptions: CascaderBuildingOption.concat(cascaderOptions),
    cascaderDockOptions: CascaderBuildingOption.concat(cascaderDockOptions),
    cascaderBayOptions: CascaderBuildingOption.concat(cascaderBayOptions),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchBuildingOption: (params?: object) => {
      dispatch({ type: 'common/fetchBuildingOption', payload: params });
    },

    fetchWaypointOption: (params?: object) => {
      dispatch({ type: 'common/fetchWaypointOption', payload: params });
    },

    fetchDockOption: (params?: object) => {
      dispatch({ type: 'common/fetchDockOption', payload: params });
    },

    fetchBayList: (params?: object) => {
      dispatch({ type: 'common/fetchBayOption', payload: params });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WaypointCascader);

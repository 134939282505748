import { ApiUrl } from './ApiConfig';
import { DELETE, GET, PATCH, POST } from './AxiosInstance';

export class DeliveryService {
  public static fetchTotalSummary(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_ORDER_SUMMARY_TOTAL, params);
  }

  // 测试删除否
  public static fetchOrderDeliverySummary(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_ORDER_DELIVERY_SUMMARY, params);
  }

  public static fetchSummaryByDate(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_ORDER_SUMMARY, params);
  }

  public static fetchOperation(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_OPERATION, params);
  }

  public static fetchDeliveryOrders(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_ORDER, params);
  }

  public static fetchOrderHistory(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_HISTORY, params);
  }

  public static fetchCompanyList(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_COMPANY, params);
  }

  public static fetchOrderTrack(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.DEL_ORDER}/${id}/track`, params);
  }

  public static fetchOrderTotalSummary(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_ORDER_SUMMARY_TOTAL, params);
  }

  public static predictCompany(params?: object) {
    return GET(ApiUrl.DEL_COMPANY, params);
  }

  public static fetchRemarkList(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_COMMENT, params);
  }

  public static postRemarkList(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_COMMENT, params);
  }

  public static postOrder(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_ORDER, params);
  }

  public static patchOrder(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.DEL_ORDER + '/' + id, params);
  }

  public static fetchCheckOrderRecord(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_CHECK_ORDER_RECORD, params);
  }

  public static patchCheckOrderRecord(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.DEL_CHECK_ORDER_RECORD + '/' + id, params);
  }

  public static postOperation(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_OPERATION, params);
  }

  public static fetchInventoryList(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_INVENTORY, params);
  }

  public static postInventoryList(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_INVENTORY, params);
  }

  public static deleteInventoryList(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.DEL_INVENTORY + '/' + id, params);
  }

  public static patchInventoryList(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.DEL_INVENTORY + '/' + id, params);
  }

  public static fetchAreaList(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_AREA, params);
  }

  public static postArea(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_AREA, params);
  }

  public static patchArea(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.DEL_AREA + '/' + id, params);
  }

  public static deleteArea(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.DEL_AREA + '/' + id, params);
  }

  public static postOrderAssignment(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_ORDER_ASSIGNMENT, params);
  }

  public static fetchPostageSummary(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_POSTAGE_SUMMARY, params);
  }

  public static fetchCompanyPostage(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_COMPANY_POSTAGE, params);
  }

  public static postCompanyPostage(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_COMPANY_POSTAGE, params);
  }

  public static patchCompanyPostage(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.DEL_COMPANY_POSTAGE + '/' + id, params);
  }

  public static deleteCompanyPostage(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.DEL_COMPANY_POSTAGE + '/' + id, params);
  }

  public static fetchPostage(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_POSTAGE, params);
  }

  public static patchPostage(id: string, params?: object): Promise<any> {
    return PATCH(ApiUrl.DEL_POSTAGE + '/' + id, params);
  }

  public static deletePostage(id: string, params?: object): Promise<any> {
    return DELETE(ApiUrl.DEL_POSTAGE + '/' + id, params);
  }

  public static postUploadPostage(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_POSTAGE_UPLOAD, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public static postPostage(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_POSTAGE, params);
  }

  public static postPrint(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_PRINT, params);
  }

  public static postCompanyEstimate(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_COMPANY_ESTIMATE, params);
  }

  public static fetchCollectionList(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_COLLECTION, params);
  }

  public static fetchCollection(id: string, params?: object): Promise<any> {
    return GET(`${ApiUrl.DEL_COLLECTION}/${id}`, params);
  }

  public static postCollection(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_COLLECTION, params);
  }

  public static patchCollection(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.DEL_COLLECTION}/${id}`, params);
  }

  public static deleteCollection(id: string): Promise<any> {
    return DELETE(`${ApiUrl.DEL_COLLECTION}/${id}`);
  }

  public static fetchDeliveryRules(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_RULES, params);
  }

  public static patchDeliveryRules(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.DEL_RULES}/${id}`, params);
  }

  public static postDeliveryRules(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_RULES, params);
  }

  public static fetchShelfList(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_SHELF, params);
  }

  public static fetchAvailableShelfList(params?: object): Promise<any> {
    return GET(ApiUrl.DEL_SHELF_AVAILABLE, params);
  }

  public static postShelf(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_SHELF, params);
  }

  public static patchShelf(id: string, params?: object): Promise<any> {
    return PATCH(`${ApiUrl.DEL_SHELF}/${id}`, params);
  }

  public static deleteShelf(id: string): Promise<any> {
    return DELETE(`${ApiUrl.DEL_SHELF}/${id}`);
  }

  public static uploadOrderTable(params?: object): Promise<any> {
    return POST(ApiUrl.DEL_ORDER_SYNC, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

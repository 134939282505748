import { Input, Modal, Tree } from 'antd';
import { DataNode } from 'rc-tree/lib/interface';
import React, { FC, useEffect, useState } from 'react';

// import options from '../../../utils/addressOptions';
import { ProvinceCity } from '../../../utils/cityCode';

const { Search } = Input;

interface InternalProps {
  visible: boolean;
  inaccessibleArea: any[];
  checkedKeys: any[];
  onCancel: () => void;
  onSubmit: (values: any[]) => void;
}

const CityModal: FC<InternalProps> = (props) => {
  const { visible, onCancel, onSubmit, checkedKeys, inaccessibleArea } = props;
  const [provinceList, setProvinceList] = useState<any[]>(ProvinceCity);
  const [defaultCheckedKeys, setDefaultCheckedKeys] = useState<any[]>(checkedKeys);

  useEffect(() => {
    setDefaultCheckedKeys(checkedKeys);
  }, [checkedKeys]);

  const onCheck = (checkedKeys: any) => {
    setDefaultCheckedKeys(checkedKeys);
  };

  const handleSubmit = () => {
    const tempList = defaultCheckedKeys.map((item: any) => {
      if (item.indexOf('{') !== -1) {
        return JSON.parse(item);
      }
      return item;
    });
    const data = tempList.map((v: any) => {
      if (v.province) {
        return v;
      } else {
        return { province: v, city: v };
      }
    });

    onSubmit(data);
    onCancel();
  };

  const handleReset = () => {
    setDefaultCheckedKeys([]);
    onCancel();
  };

  const onChange = (e: any) => {
    const { value } = e.target;
    const tempList = ProvinceCity.filter(
      (item: any) => item.label.includes(value) || item.children.some((d: any) => d.label.includes(value))
    );

    setProvinceList(tempList);
  };

  const renderTreeNodes = (data: any[]) =>
    data.map((item: any, index: number) => {
      const treeNode: DataNode = {
        title: item.label,
        key: item.label,
        children: [],
        disabled:
          item.children && item.children.length
            ? item.children.every((v: any) =>
                inaccessibleArea.includes(JSON.stringify({ province: item.label, city: v.label }))
              )
            : false,
      };
      if (item.children) {
        item.children.map(
          (value: any) =>
            treeNode.children &&
            treeNode.children.push({
              key: JSON.stringify({ province: item.label, city: value.label }),
              title: value.label,
              disabled: inaccessibleArea.includes(JSON.stringify({ province: item.label, city: value.label })),
            })
        );
        return treeNode;
      } else {
        return { title: item.label, key: index };
      }
    });

  return (
    <Modal visible={visible} title="区域设置" onCancel={onCancel} onOk={handleSubmit} afterClose={handleReset}>
      <Search style={{ marginBottom: 8 }} placeholder="区域名称" onChange={onChange} />
      <Tree checkable onCheck={onCheck} checkedKeys={defaultCheckedKeys} treeData={renderTreeNodes(provinceList)} />
    </Modal>
  );
};
export default CityModal;

import { Descriptions, Typography } from 'antd';
import React, { FC } from 'react';

import EditableCascader from './EditableCascader';
import EditableInput from './EditableInput';
import EditableNumber from './EditableNumber';
import EditableSelect from './EditableSelect';
import { InputType } from './interface.d';

const { Text } = Typography;

interface InternalProps {
  record: any;
  label?: string;
  title?: string;
  rules?: any[];
  options?: any[];
  dataIndex: any;
  editable?: boolean;
  required?: boolean;
  inputType?: InputType;
  onSubmit: (params?: object) => void;
}

export const FieldItem: FC<InternalProps> = (props) => {
  const { label, inputType, options, ...otherProps } = props;

  if (inputType === InputType.Number) {
    return (
      <Descriptions.Item label={label}>
        <EditableNumber {...otherProps}>
          <Text>{label}</Text>
        </EditableNumber>
      </Descriptions.Item>
    );
  } else if (inputType === InputType.Cascader) {
    return (
      <Descriptions.Item label={label}>
        <EditableCascader options={options || []} {...otherProps}>
          <Text>{label}</Text>
        </EditableCascader>
      </Descriptions.Item>
    );
  } else if (inputType === InputType.Select) {
    return (
      <Descriptions.Item label={label}>
        <EditableSelect options={options || []} {...otherProps}>
          <Text>{label}</Text>
        </EditableSelect>
      </Descriptions.Item>
    );
  } else {
    return (
      <Descriptions.Item label={label}>
        <EditableInput {...otherProps}>
          <Text>{label}</Text>
        </EditableInput>
      </Descriptions.Item>
    );
  }
};

export default FieldItem;

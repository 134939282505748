import { Layout } from 'antd';
import { connect } from 'dva';
import { get } from 'lodash';
import 'moment/locale/zh-cn';
import React, { FunctionComponent, useMemo } from 'react';
import { useMedia } from 'react-use';

import { BreadCrumbs, Content, ErrorBoundary, Footer, Header, Loading, SideBar } from './components';
import { Dispatch } from './models/dispatch';
import { Auth, UserInfo } from './models/typed';
import { AppRoutes, Match, RouteConfig } from './routes';
import { isEmpty } from './utils';

import './App.scss';

interface DvaProps {
  auth: Auth;
  match: Match;
  loading: boolean;
  userInfo: UserInfo;
  location: Location;
  collapsed: boolean;
  hasFooter: boolean;
  hasHeader: boolean;
  hasBreadCrumbs: boolean;
  onCollapse: (collapsed: boolean) => void;
}

interface Props extends DvaProps {
  app: any;
}

export const App: FunctionComponent<Props> = (props) => {
  const {
    app,
    auth,
    match,
    loading,
    userInfo,
    collapsed,
    location,
    hasHeader,
    onCollapse,
    hasFooter,
    hasBreadCrumbs,
  } = props;
  const pathname = match.path.replace('/', '');
  const isMobile = useMedia('(max-width: 575px)');
  const menu: RouteConfig[] = useMemo<RouteConfig[]>(() => get(userInfo, `menu.${pathname}`, []), [userInfo, pathname]);
  const hasMenu = !isEmpty(menu);

  return (
    <Layout className="App">
      <SideBar
        menu={menu}
        loading={loading}
        location={location}
        isMobile={isMobile}
        collapsed={collapsed}
        onCollapse={onCollapse}
      />
      <Layout style={{ overflowY: 'auto' }}>
        {hasHeader && <Header location={location} isMobile={isMobile} collapsed={collapsed} onCollapse={onCollapse} />}
        {hasMenu ? (
          <Content isMobile={isMobile}>
            {hasBreadCrumbs && <BreadCrumbs menu={menu} url={location.pathname} />}
            <ErrorBoundary>
              <AppRoutes app={app} auth={auth} menu={menu} />
            </ErrorBoundary>
          </Content>
        ) : (
          <Loading />
        )}
        {hasFooter && <Footer />}
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ global, loading }: any) => {
  return {
    auth: global.auth,
    userInfo: global.userInfo,
    collapsed: global.collapsed,
    hasFooter: global.hasFooter,
    hasHeader: global.hasHeader,
    hasBreadCrumbs: global.hasBreadCrumbs,
    loading: loading.effects['global/fetchUserInfo'],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onCollapse: (collapsed: boolean) => {
      dispatch({ type: 'global/changeLayoutCollapsed', payload: collapsed });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

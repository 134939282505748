import { Form } from 'antd';
import React, { createContext } from 'react';

import './EditableCell.scss';

interface FormProps {
  index: number;
  type?: 'table' | 'normal';
}

export const EditableContext = createContext({});

const EditableForm: React.FC<FormProps> = (props) => {
  const { type } = props;
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        {type === 'table' ? <tr className="editable-form" {...props} /> : <div className="editable-form" {...props} />}
      </EditableContext.Provider>
    </Form>
  );
};

EditableForm.defaultProps = {
  type: 'table',
};

export default EditableForm;

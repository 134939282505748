import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { connect } from 'dva';
import { HashRouter, Redirect, Route, Switch } from 'dva/router';
import React, { FC, useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';

import App from './App';
import { __DEV__ } from './env';
import { Dispatch } from './models/dispatch';
import { Auth as AuthTyped } from './models/typed';
import { AppPages, PublicPages } from './pages';
import { DeviceRoutes, Routes } from './routes';
import { localStorageKey } from './utils/constants';

const { SwitchHub, HubPickupList, HubReport, HubScreenReport, DashRoleList, TaskReminder } = AppPages;
const { Auth, Login, ForgetPassword, NoPermission, NotFound, ServerError } = PublicPages;

interface DvaProps {
  app: any;
  userId: string;
  auth: AuthTyped;
  logout: () => void;
  fetchUserInfo: (userId: string) => void;
}

const Page: FC<DvaProps> = (props) => {
  const { auth, app, userId, logout, fetchUserInfo } = props;

  const onStorageChange = useCallback(
    (e: any) => {
      if ((e.key === localStorageKey.APP_KEY_STORE || !e.key) && !e.newValue) {
        logout();
      }
    },
    [logout]
  );

  useEffect(() => {
    if (userId && auth.isAuthenticated) {
      fetchUserInfo(userId);
    }

    window.addEventListener('storage', onStorageChange);

    return () => window.removeEventListener('storage', onStorageChange);
  }, [auth.isAuthenticated, fetchUserInfo, onStorageChange, userId]);

  return (
    <ConfigProvider locale={zh_CN}>
      <HashRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={Routes.Dashboard} push />} />
          <Route exact path={Routes.App} render={() => <Redirect to={Routes.Dashboard} push />} />
          <Route
            exact
            path={DeviceRoutes.Device}
            render={() => <Redirect to={DeviceRoutes.DeviceProductManage} push />}
          />
          <Route path={Routes.Login} component={Login} />
          <Route path={Routes.Auth} component={Auth} />
          <Route path={Routes.ForgetPassword} component={ForgetPassword} />
          <Route path={Routes.App} render={(props: any) => <App app={app} {...props} />} />
          <Route path={DeviceRoutes.Device} render={(props: any) => <App app={app} {...props} />} />
          <Route path={Routes.SwitchHub} component={SwitchHub} />
          <Route path={Routes.TaskReminder} component={TaskReminder} />
          <Route path={Routes.RoleList} render={(props: any) => <DashRoleList app={app} {...props} />} />
          <Route path={Routes.HubPickupList} render={(props: any) => <HubPickupList app={app} {...props} />} />
          <Route path={Routes.HubReport} render={(props: any) => <HubReport app={app} {...props} />} />
          <Route path={Routes.HubScreenReport} render={(props: any) => <HubScreenReport app={app} {...props} />} />
          <Route path={Routes.NoPermission} component={NoPermission} />
          <Route path={Routes.NotFound} component={NotFound} />
          <Route path={Routes.ServerError} component={ServerError} />
          <Redirect to={Routes.NotFound} />
        </Switch>
      </HashRouter>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ global }: any) => ({
  auth: global.auth,
  userId: global.userId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => {
    dispatch({ type: 'global/logout' });
  },

  fetchUserInfo: (userId: string) => {
    dispatch({ type: 'global/fetchUserInfo', payload: userId });
  },
});

const EnhancedPage = connect(mapStateToProps, mapDispatchToProps)(Page);

export default __DEV__ ? hot(EnhancedPage) : EnhancedPage;

import { Form, Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { get } from 'lodash';
import React, { CSSProperties, FC, useState } from 'react';

import { diffData, transformValues } from '../../utils';
import EditableWrapper from './EditableWrapper';

const { Item } = Form;

interface InternalProps extends InputProps {
  record: any;
  title?: string;
  rules?: any[];
  editable?: boolean;
  dataIndex: string;
  required?: boolean;
  wrapperStyle?: CSSProperties;
  onSubmit?: (params: object) => void;
}

export const EditableInput: FC<InternalProps> = (props) => {
  let formContext: any;
  const {
    record,
    title,
    rules,
    onSubmit,
    editable,
    required,
    dataIndex,
    children,
    wrapperStyle,
    ...otherProps
  } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const validationRules = [{ required, message: `请输入${title}!` }].concat(rules || []);

  const handleEdit = (editing: boolean) => {
    setEditing(editing);
  };

  const onSave = async () => {
    if (formContext) {
      const values = await formContext.validateFields();

      if (diffData(record, values)) {
        const _val = transformValues(values);
        onSubmit && onSubmit({ id: record.id, ..._val });
        setEditing(false);
      }
    }
  };

  const renderInput = (form: any) => {
    formContext = form;
    form.setFieldsValue({ [dataIndex]: get(record, dataIndex) });

    return (
      <Item style={{ margin: 0 }} name={dataIndex} rules={validationRules}>
        <Input autoFocus allowClear onPressEnter={onSave} placeholder={`请输入${title}`} {...otherProps} />
      </Item>
    );
  };

  return (
    <EditableWrapper
      onSave={onSave}
      editing={editing}
      editable={editable}
      onEdit={handleEdit}
      renderArea={renderInput}
      wrapperStyle={wrapperStyle}
    >
      {children}
    </EditableWrapper>
  );
};

export default EditableInput;

import { Col, Form, Input, Row, Select } from 'antd';
import React, { FC } from 'react';

import { CheckInventoryDetailParams } from '../models/CheckInventoryDetail';

const Option = Select.Option;
const Search = Input.Search;

interface InternalProps {
  reqParams: CheckInventoryDetailParams;
  companyList: any[];
  handleChange: (key: string, value: any) => void;
}
const CheckInventoryTitle: FC<InternalProps> = (props: InternalProps) => {
  const { reqParams, companyList, handleChange } = props;

  return (
    <Form className="base-form" style={{ margin: '0 16px' }}>
      <Row gutter={24}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="快递单号">
            <Search
              key="orderNumber"
              placeholder="请输入快递单号"
              defaultValue={reqParams.orderNumber}
              onChange={(e) => handleChange('orderNumber', e.target.value)}
              onSearch={(value) => handleChange('orderNumber', value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="客户电话">
            <Search
              key="mobile"
              placeholder="请输入客户电话"
              defaultValue={reqParams.mobile}
              onChange={(e: any) => handleChange('mobile', e.target.value)}
              onSearch={(value: string) => handleChange('mobile', value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="包裹号">
            <Search
              placeholder="请输入包裹号"
              defaultValue={reqParams.shelfNumber}
              onChange={(e: any) => handleChange('shelfNumber', e.target.value)}
              onSearch={(value: string) => handleChange('shelfNumber', value)}
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Form.Item label="快递公司">
            <Select
              defaultValue={reqParams.companyId}
              allowClear
              placeholder="请选择快递公司"
              onChange={(value: string) => handleChange('companyId', value)}
            >
              {companyList.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CheckInventoryTitle;
